/**
 * @file base/_base.scss
 *
 * \brief A collection of architecturally high-level styles for basic elements.
 *        http://smacss.com/book/type-base
 *
 * Most of the following DO result in CSS output
 *
 */

// Partials
@import 'normalize';
//@import "sprites/*.png"; // see _variables.scss for details
@import 'animations';
@import 'helpers';
@import 'fonts';
@import 'typography';
@import 'typography-helpers';
@import 'forms-helpers';

/*
 * Shared PC/Mobile styles
 */

/*
 * Global layout
 */

html {
  overflow-y: visible;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: $color-white;
  * {
    @include box-sizing(border-box);
  }
}

p {
  margin: 0.5em 0 0;
}

a img {
  display: inline-block;
}

label {
  cursor: pointer;
}

h1 {
  @include swap_direction(margin, 0 0 20px 0);
}

h2 {
  @include swap_direction(margin, 0 0 20px 0);
}

h3 {
  @include swap_direction(margin, 0 0 15px 0);
}

h4 {
  @include swap_direction(margin, 0 0 2px 0);
}

h5,
h6 {
  @include swap_direction(margin, 0 0 5px 0);
}

hr {
  border: none;
  border-top: 1px solid #cccccc;
  height: 1px;
  padding: 0;
  margin: 20px 0;
}

.block {
  h2.block-title {
    display: none;
    visibility: hidden;
  }
}

.hidden {
  display: none;
}

.clearfix {
  @include clearfix;
}

.outer-wrap {
  @include outer-wrap;
}

.grey-gradient {
  background: image-url('bg-supercat-header.jpg') repeat-x left bottom $color-white;
}

.panel {
  @include clearfix;
}

.wrapper {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-clip: border-box;
  background-origin: border-box;
  min-height: 100%;
}

.cl-box-shadow {
  @include cl-box-shadow;
}

.green-circle {
  @include border-radius(50%);
  background-color: $color-cl-green;
  color: $color-white;
  font-weight: bold;
  display: inline-block;
  margin-top: -0.2em;
  width: 1.2em;
  height: 1.2em;
  padding: 0 0 0 0.3em;
  @if $cr19 == true {
    background-color: $cr19-bg-black;
    color: $cr19-text-white;
  }
}

img.full {
  width: 100%;
  max-width: 100%;
  height: auto;
}

#main {
  position: relative;
  padding-bottom: 84px; /* must be same height as the footer */
}

a.wrapper-link,
a.wrapper-link:active,
a.wrapper-link:hover,
a.wrapper-link:link {
  text-decoration: none;
}

/*
 * Call-to-Action Buttons
 * These styles are generated by the cta_button and product input type plugins. The CTA class adds CSS classes to the link.
 *
 * See the input type plugin class for more information:
 * sites/clinique/modules/custom/clinique_common/plugins/input_types/cta.inc
 */

.button.cta {
  &.hn-lt-std-45-light {
    font-family: $base-font-family;
  }
  &.hn-lt-std-65-medium {
    font-family: $base-bold-font-family;
  }
  &.hn-lt-std-95-black {
    font-family: $base-black-font-family;
  }
  &.hn-lt-std-56-italic {
    font-family: $base-italic-font-family;
  }
  &.hn-lt-std-ultralight {
    font-family: $base-light-font-family;
  }
  &.size-10px {
    font-size: 10px;
  }
  &.size-11px {
    font-size: 11px;
  }
  &.size-12px {
    font-size: 12px;
  }
  &.size-13px {
    font-size: 13px;
  }
  &.size-14px {
    font-size: 14px;
  }
  &.size-15px {
    font-size: 15px;
  }
  &.size-16px {
    font-size: 16px;
  }
}

.btn-add-to-bag {
  height: 37px;
  line-height: 41px;
  font-size: 17px;
  padding: 0 15px;
  background: $color-btn-green;
  text-transform: uppercase;
  color: $color-white;
  text-align: center;
  display: inline-block;
  letter-spacing: 1px;
  border: 0;
  font-family: $helvetica-bold-font-family;
  cursor: pointer;
  @if $cr19 == true {
    background-color: $cr19-bg-black;
  }
  &:hover {
    background: $color-btn-darker-green;
    color: $color-white;
    text-decoration: none;
    @if $cr19 == true {
      background-color: $cr19-bg-med;
    }
  }
}

a {
  &.pre-order-btn {
    @include pre-order-btn-styles;
    border: 1px solid $color-black;
    .mpp-box & {
      @include pre-order-btn-styles;
    }
    &.add-to-cart,
    &.add-to-bag,
    &.select_shade {
      line-height: 36px;
      @include breakpoint(767px) {
        line-height: 44px;
      }
    }
    &.pre-order-no-shop {
      pointer-events: none;
    }
  }
}

.icon-large {
  font-size: 24px;
}
/* filter icons */

.cl-icon {
  background: image-url('mpp-filter-icons-sprite.png') no-repeat 0 0;
  padding-#{$ldirection}: 42px;
  &.acne-breakout {
    background-position: 10px 3px;
    padding-#{$ldirection}: 38px;
  }
  &.acne-breakout.active {
    background-position: 10px -297px;
  }
  &.enlarged-pores {
    background-position: -253px 4px;
  }
  &.enlarged-pores.active {
    background-position: -254px -296px;
  }
  &.lines-wrinkles {
    background-position: -517px 1px;
    padding-#{$ldirection}: 37px;
  }
  &.lines-wrinkles.active {
    background-position: -518px -298px;
  }
  &.daily-care {
    background-position: 10px -67px;
    padding-#{$ldirection}: 38px;
  }
  &.daily-care.active {
    background-position: 10px -367px;
  }
  &.eye-area {
    background-position: -255px -67px;
  }
  &.eye-area.active {
    background-position: -255px -367px;
  }
  &.redness {
    background-position: -517px -67px;
    padding-#{$ldirection}: 37px;
  }
  &.redness.active {
    background-position: -517px -367px;
  }
  &.dark-spots {
    background-position: 10px -137px;
    padding-#{$ldirection}: 38px;
  }
  &.dark-spots.active {
    background-position: 10px -438px;
  }
  &.firming {
    background-position: -253px -137px;
  }
  &.firming.active {
    background-position: -253px -436px;
  }
  &.sensitivity {
    background-position: -517px -136px;
    padding-#{$ldirection}: 37px;
  }
  &.sensitivity.active {
    background-position: -517px -436px;
  }
  &.dryness {
    background-position: 10px -207px;
    padding-#{$ldirection}: 38px;
  }
  &.dryness.active {
    background-position: 10px -507px;
  }
  &.radiance {
    background-position: -253px -207px;
  }
  &.radiance.active {
    background-position: -252px -507px;
  }
  &.sun-protection {
    background-position: -517px -207px;
    padding-#{$ldirection}: 37px;
  }
  &.sun-protection.active {
    background-position: -517px -507px;
  }
}

/*
 * General forms
 */

form {
  label {
    color: $color-black;
    font-family: $helvetica-bold-font-family;
  }
  .form-text {
    background: $color-white;
    border: 1px solid #dbdbdb;
    vertical-align: middle;
    color: $color-sharp-black;
    line-height: 35px;
    padding: 0px 13px;
    &:focus {
      border-color: $color-black;
    }
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &.placeholder {
      color: $color-black;
    }
  }
}
/* selectBox styles */

.selectBox-dropdown {
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $color-black;
  width: 160px !important;
  padding-#{$rdirection}: 40px;
  position: relative;
  border: solid 1px #dbdbdb;
  height: 22px;
  line-height: 22px;
  text-decoration: none;
  outline: none;
  vertical-align: middle;
  background: $color-white;
  display: inline-block;
  cursor: pointer;
  text-align: #{$ldirection};
  .selectBox-label {
    width: 100% !important;
    line-height: 24px;
    @include swap_direction(padding, 0 0 0 12px);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }
}

.selectBox-label .swatch-color,
.page-spp .selectbox-selectBox-dropdown-menu .swatch-color {
  width: 23px;
  height: 23px;
  display: block;
  float: #{$ldirection};
  @include swap_direction(margin, 0 8px 0 0);
  background-position: 50% !important;
}

.selectBox-options,
.selectBox-options li,
.selectBox-options li a {
  list-style: none;
  display: block;
  cursor: default;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.selectBox-options li {
  padding: 0;
  a {
    text-decoration: none;
    color: #666666;
    letter-spacing: 1px;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-#{$ldirection}: 5px solid transparent;
  border-#{$rdirection}: 5px solid transparent;
  border-top: 5px solid #cccccc;
}

.selectBox-dropdown:focus,
.selectBox-menuShowing {
  text-decoration: none;
  border: 1px solid $color-black;
  border-bottom: 0;
}

/*
 * User login form - This is technically an admin page that uses the non-admin theme, therefore we'll make it look prettier here
 */

#user-login {
  padding: 50px 20px;
  .form-type-textfield,
  .form-type-password,
  .form-actions {
    padding: 10px 0;
  }
}

/*
 * Generic Overlays
 */

.overlay-container {
  background-color: $color-white;
  border: 1px solid #cccccc;
  overflow: auto;
  padding: 20px;
  width: auto;
  position: absolute;
  z-index: 10002;
  .close-container {
    position: absolute;
    top: 10px;
    #{$rdirection}: 10px;
    z-index: 999;
    a.close-link {
      background: image-url('icon-close.png') no-repeat;
      display: block;
      width: 22px;
      height: 21px;
      overflow: hidden;
      text-indent: -9999px;
      font-size: 0;
      line-height: 0;
      cursor: pointer;
    }
  }
  &.close-link-white a.close-link {
    background: image-url('/media/export/cms/icon-close-white.png') no-repeat;
  }
}

.overlay-background {
  background-color: $color-black;
  #{$ldirection}: 0;
  opacity: 0.5;
  filter: alpha(opacity = 50);
  position: absolute;
  top: 0;
  z-index: 10001;
  @if $cr19 == true {
    background-color: $cr19-bg-dark-transparent;
    opacity: 1;
  }
}

#foreground-node {
  z-index: 10000;
  max-width: 900px;
}

#overlay-background {
  z-index: 9999;
}
/* Afterpay Modal Styles */

.elc-iframe-modal-mask {
  .elc-iframe-modal-wrapper {
    height: 100%;
    @media #{$cr19-medium-up} {
      height: auto;
      max-height: 630px;
    }
    .icon-close {
      border: none;
      background-color: $color-white;
    }
    .elc-iframe {
      padding-bottom: 20px;
      @media #{$cr19-medium-up} {
        padding-bottom: unset;
      }
    }
    .sticky-nav-active & {
      margin-top: 0;
      @media #{$cr19-medium-up} {
        margin-top: 90px;
      }
    }
  }
}
/* Ocean Blue and Foundation Finder Popup Styles */

#foreground-node.ob_overlay {
  max-width: 980px;
  padding: 0;
  background: none;
  border: none;
}

#foreground-node.ob_overlay #boImg {
  display: none;
}

#foreground-node.ff_overlay {
  padding: 0;
}

#foreground-node.ob_overlay .close-container,
#foreground-node.ff_overlay .close-container {
  top: 5px;
  #{$rdirection}: 7px;
}

#foreground-node.ob_overlay .close-container a.close-link {
  background: image-url('/media/export/cms/icon-close-grey.png') no-repeat;
}

#foreground-node.ob_overlay.ipad_ob_overlay {
  overflow-x: hidden;
  height: 565px !important;
}

#foreground-node.ob_overlay.ipad_ob_overlay iframe {
  -moz-transform: scale(0.79);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.79);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.79);
  -webkit-transform-origin: 0 0;
}

#foreground-node.offer-overlay {
  padding: 0px;
  img {
    width: 660px;
    height: 363px;
  }
}

/*
 * Togglemenus - custom updates to support frontend bugs with the togglemenus/toolbar
 */

#togglemenus {
  .menu {
    li {
      &:hover {
        > .menu-item {
          cursor: pointer;
        }
      }
    }
  }
}

div#toolbar {
  z-index: 9999;
}

#toolbar {
  font-size: 0.9em;
  a {
    font-size: inherit;
  }
  .menu-container {
    a {
      font-size: 0.9em;
    }
  }
}

#toolbar,
.togglemenus {
  &.clearfix {
    overflow: visible;
  }
}

/*
 * Contextual links
 * See cl_base/templates/block.tpl.php for positioning of the .contextual-links-container element
 */

.block {
  .contextual-links-container {
    position: relative;
    top: 0;
    #{$ldirection}: 0;
    width: 100%;
    height: 0;
    overflow: visible;
    div.contextual-links-wrapper {
      z-index: 99999;
    }
  }
}

/*
 * General error messages/notices
 */

div.error,
ul.error_messages li {
  color: $color-notice-pink;
  margin-bottom: 5px;
}

ul.error_messages_display {
  background-color: transparent;
}

input,
select {
  &.error {
    border: 1px solid $color-notice-pink !important;
  }
}

input[type='checkbox'] {
  &.error {
    outline: 2px solid $color-notice-pink;
    &:checked {
      outline: none;
    }
  }
}

/*
 * Social Share buttons
 */

.social-share {
  width: 100%;
  overflow: auto;
  .share-button {
    background: transparent url(/media/images/css/icons-share-buttons.png) 0 0 no-repeat;
    float: #{$rdirection};
    height: 20px;
    @include swap_direction(margin, 0 10px 0 0);
    overflow: hidden;
    text-indent: -9999px;
    font-size: 0;
    line-height: 0;
    &.facebook {
      background-position: -68px 0;
      width: 11px;
    }
    &.twitter {
      background-position: -86px 0;
      width: 19px;
    }
    &.pinterest {
      background-position: -111px 0;
      width: 19px;
    }
  }
}

.back-to-top {
  position: fixed;
  bottom: 2em;
  #{$rdirection}: 0px;
  text-decoration: none;
  color: $color-black;
  background-color: rgb(235, 235, 235);
  opacity: 0.8;
  font-size: 12px;
  padding: 1em;
  display: none;
  &:hover {
    background-color: rgb(135, 135, 135);
    opacity: 0.5;
  }
}

.cart-product-preorder {
  float: #{$ldirection};
  @include breakpoint($medium-mid) {
    width: 100%;
  }
  @include breakpoint($medium-max) {
    float: inherit;
  }
}

body div#foreground-node.cboxIframe {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 10% !important;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

// 2019 Redesign
// Background color updated to Overlays
@if $cr19 == true {
  div#cboxOverlay {
    background: $cr19-bg-dark-transparent;
  }
}

// Outline style on focused elements for keyboard accessibility
a,
button,
input,
select,
textarea,
iframe,
[role="button"],
[tabindex] {
  &:focus {
    outline: none !important;
  }
  &:focus-visible {
    @include outline;
  }
}
