/* Foundation-Finder redesgin */

body.section-foundation-finder {
  @media (min-width: $ab-ipad-large-down) and (max-width: 768px) {
    #header {
      .outer-wrap {
        max-width: 768px;
      }
      .top-right {
        table-layout: fixed;
        max-width: 596px;
      }
      .offer-promo-carousel-v1 {
        width: 310px;
        word-wrap: break-word;
      }
      .offer-promo-v1 {
        .headline {
          white-space: initial;
        }
      }
    }
    .footer-top-v1 {
      max-width: 768px;
      .social-links {
        width: 14%;
      }
      .signup-forms {
        width: 64%;
      }
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    #header {
      .outer-wrap {
        max-width: 1024px;
      }
      .top-right {
        table-layout: fixed;
        max-width: 852px;
      }
      .offer-promo-carousel-v1 {
        width: 385px;
        word-wrap: break-word;
      }
      .offer-promo-v1 {
        .headline {
          white-space: initial;
        }
      }
    }
    .footer-top-v1 {
      .signup-forms {
        width: 64%;
      }
    }
  }
  #foundation-finder-quiz {
    .clear {
      clear: both;
    }
    .ff-disabled {
      opacity: 0.3;
      pointer-events: none;
    }
    .foundation-finder.js-foundation-finder--v1 {
      @include swap_direction(padding, 0px);
    }
    .slick-next.slick-arrow {
      display: none !important;
    }
    .slick-track {
      display: block;
      @media (max-width: 640px) {
        width: 100% !important;
        display: -webkit-box;
      }
    }
    @media (max-width: 640px) {
      .picker {
        .picker-handle {
          margin-top: 4px;
        }
      }
    }
    .foundation-site-content {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: sans-serif;
    }
    .ff-quiz__slide {
      width: 100%;
      height: 678px !important;
      margin: 0;
      .ff_slide_content {
        h2 {
          font-size: 40px;
          line-height: 1.075em;
          font-weight: normal;
          text-align: center;
          color: $color-cl-soft-black;
          font-family: $base-thin-font-family;
          @include swap_direction(padding, 30px 0 0 0);
          @include breakpoint($ab-small-down) {
            font-size: 36px;
          }
        }
        .ff-quiz__subheadline {
          font-size: 20px;
          text-align: center;
          padding-bottom: 15px;
        }
      }
      #ff_quiz_undertone_popup {
        h2 {
          @include breakpoint($ab-small-down) {
            font-size: 36px;
          }
        }
      }
      &.ff-quiz__slide--7,
      &.ff-quiz__slide--8 {
        background: url('/media/images/foundation-finder/slide_goal_bg.jpg') no-repeat;
        @include breakpoint(767px) {
          background: url('/media/images/foundation-finder/slide_goal_bg_mob.jpg') no-repeat;
          background-size: cover;
        }
      }
    }
    .ff-quiz__slide--0 {
      background-size: cover;
      background-repeat: no-repeat;
    }
    .ff-quiz__slide-container {
      //margin: 0 auto;
      @include swap_direction(margin, 0 auto);
      @include swap_direction(padding, 50px 0px 0px 50px);
    }
    .ff-quiz__subhead--top {
      font-size: 30px;
      line-height: 1.075em;
      margin: 15px 0 !important;
      //padding: 0 5%;
      @include swap_direction(padding, 0 5%);
      font-family: $base-thin-font-family;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      @include swap_direction(padding, 20px 0px 0px 0px);
    }
    .ff-quiz__headline {
      font-family: $base-thin-font-family;
    }
    .ff-quiz__headline1 {
      font-size: 42px;
      line-height: 1.075em;
      @include swap_direction(margin, 0 0 15px 0);
      @include swap_direction(padding, 0 5%);
      font-weight: normal;
    }
    .ff-quiz__headline2 {
      font-size: 19px;
      line-height: 1.075em;
      @include swap_direction(margin, 0 0 15px 0);
      @include swap_direction(padding, 0 5%);
      font-weight: normal;
    }
    .ff-quiz__subhead--bottom {
      font-size: 16px;
      line-height: 1.075em;
      @include swap_direction(margin, 0 0 30px 0);
      @include swap_direction(padding, 0 5%);
      font-family: $helvetica-bold-font-family;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      @include swap_direction(padding, 20px 0px);
    }
    .ff-quiz__button {
      font-size: 20px;
      @include swap_direction(padding, 9px 15px);
      margin-#{$ldirection}: 5%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: $color-black;
      border: 2px solid $color-black;
      color: $color-white !important;
      display: inline-block;
      font-family: sans-serif;
      font-size: 14px;
      font-weight: 100;
      //padding: 6px 10px 6px 10px;
      @include swap_direction(padding, 6px 10px 6px 10px);
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      //padding: 0px;
      @include swap_direction(padding, 0px);
      //margin: 0px;
      @include swap_direction(margin, 0px);
    }
    #mobileBC {
      position: absolute;
      z-index: 1;
    }
    .ff-quiz__header {
      //padding: 20px 0 20px 100px;
      @include swap_direction(padding, 20px 0 20px 100px);
      display: none;
    }
    .ff-quiz__nav-menu {
      font-size: 13px;
      line-height: 1.075em;
      li {
        float: #{$ldirection};
        color: gray;
        + {
          li {
            &:before {
              content: '/';
              //padding: 0px 5px;
              @include swap_direction(padding, 0px 5px);
              display: inline-block;
              text-decoration: none;
            }
          }
        }
      }
      .active {
        color: $color-black;
        font-weight: bold;
      }
      .past-state {
        color: $color-black;
        text-decoration: none;
        font-weight: bold;
      }
    }
    .ff-quiz__nav-item {
      cursor: pointer;
    }
    .ff-quiz__nav-item.selected {
      color: $color-black;
    }
    .ff-quiz__nav-item.active {
      .ff-quiz__nav-text {
        text-decoration: underline;
      }
    }
    .ff-quiz__nav-text {
      display: inline-block;
    }
    .ff_slide_content {
      //margin: 15px auto 0;
      @include swap_direction(margin, 15px auto 0);
    }
    .foundation-finder_skintone_selection {
      li {
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
      }
      width: 92%;
      text-align: center;
      ul {
        li {
          //margin: 3px;
          @include swap_direction(margin, 3px);
          a {
            display: block;
          }
          img {
            max-width: 206px;
            //margin: 0px 10px;
            @include swap_direction(margin, 0px 10px);
            @media (min-width: 1280px) {
              max-width: 247px;
            }
            &.active {
              opacity: 0.4;
              border: 1px solid $color-black;
            }
          }
        }
        &.ff-quiz__gallery {
          @media (max-width: 768px) and (orientation: portrait) {
            margin-#{$ldirection}: 0;
          }
        }
      }
    }
    .foundation-finder_undertone_selection {
      width: 570px;
      text-align: center;
      .picker-handle {
        margin-#{$rdirection}: 12px;
        margin-top: 5px;
      }
      .cool-neutral {
        display: table-cell;
        vertical-align: middle;
        .picker-radio {
          @include swap_direction(margin, 0 0 0 10px);
        }
        p {
          font-size: 15px;
          min-height: 45px;
          //margin: 5px 0 0 0px;
          @include swap_direction(margin, 0 0 0 38px);
        }
      }
      .warm-neutral {
        display: table-cell;
        vertical-align: middle;
        .picker-radio {
          @include swap_direction(margin, 0 0 0 10px);
        }
        p {
          font-size: 15px;
          min-height: 45px;
          //margin: 5px 0 0 0px;
          @include swap_direction(margin, 0 0 0 38px);
        }
      }
      label {
        font-size: 25px;
        line-height: 1.075em;
        font-family: $base-thin-font-family;
      }
      p {
        font-family: $base-thin-font-family;
      }
      .undertone-cool_neutral,
      .undertone-warm_neutral {
        @include swap_direction(padding, 0);
        text-align: #{$ldirection};
      }
      .undertone-cool_neutral {
        .cool-neutral {
          .coolneutral-check {
            float: #{$ldirection};
            width: 10%;
            vertical-align: middle;
            //margin: 5px 0 0 20px;
            @include swap_direction(margin, 5px 0 0 20px);
          }
          .cool-neutral-section {
            float: #{$ldirection};
            width: 65%;
          }
        }
      }
      .undertone-warm_neutral {
        .warm-neutral {
          .warmneutral-check {
            float: #{$ldirection};
            width: 10%;
            vertical-align: middle;
            //margin: 5px 0 0 20px;
            @include swap_direction(margin, 5px 0 0 20px);
          }
          .warm-neutral-section {
            float: #{$ldirection};
            width: 65%;
          }
        }
      }
    }
    #ff_quiz_undertone_popup {
      display: none;
      h2 {
        font-family: $base-thin-font-family;
        font-size: 40px;
        line-height: 1.075em;
        font-weight: normal;
        text-align: center;
        color: $color-cl-soft-black;
        @include swap_direction(padding, 45px 0 0 0);
      }
      .untertone-finding_popup {
        font-size: 14px;
        background-color: $color-cl-grey-3;
        text-align: center;
        width: 400px;
        @include swap_direction(padding, 15px 0 30px);
        //margin: 35px auto 0;
        @include swap_direction(margin, 35px auto 0);
        position: relative;
        @include breakpoint($ab-small-down) {
          width: 90%;
        }
        .undertone-finding_wrist {
          p,
          p + p {
            //margin: 0;
            @include swap_direction(margin, 0px);
            padding-bottom: 6.5px;
            padding-top: 6.5px;
          }
          p + p + p {
            padding-bottom: 5px;
            padding-top: 5px;
          }
        }
        .undertone-finding_jewelry {
          margin-top: 45px;
          p,
          p + p {
            //margin: 0;
            @include swap_direction(margin, 0px);
            padding-bottom: 6.5px;
            padding-top: 6.5px;
          }
          p + p + p {
            padding-bottom: 5px;
            padding-top: 5px;
          }
        }
        #closeButton {
          position: absolute;
          #{$rdirection}: 15px;
          top: 15px;
          font-size: 13px;
          cursor: pointer;
        }
      }
    }
    .foundation-finder_coverage {
      width: 877px;
      .cen-skip {
        text-align: center;
      }
      .picker-handle {
        margin-#{$rdirection}: 12px;
        margin-top: 0px;
      }
      @media (min-width: 768px) {
        .picker {
          min-width: 200px;
          min-height: 90px;
        }
      }
      .coverage-container {
        //padding: 65px 0 0;
        @include swap_direction(padding, 65px 0 0);
        float: #{$ldirection};
        position: relative;
        margin-#{$ldirection}: 100px;
        img {
          width: 189px;
        }
      }
      .cov-txt {
        position: absolute;
        top: 120px;
        #{$ldirection}: -30px;
        label {
          position: absolute;
          z-index: -1;
        }
      }
      label {
        font-size: 25px;
        font-family: $base-thin-font-family;
      }
    }
    .foundation-finder_form {
      width: 92%;
      text-align: center;
      @media (min-width: $ab-ipad-large-down) and (max-width: 768px) {
        margin-#{$rdirection}: 0 !important;
        margin-#{$ldirection}: 30px;
        width: 100%;
      }
    }
    .foundation-finder_finish {
      .selection_skip {
        //margin: 2% 0 0 50% !important;
        //margin: 0 auto;
        @include swap_direction(margin, 0px auto);
        display: block;
        text-align: center;
        margin-top: 20px;
        clear: both;
      }
      @media (min-width: $ab-ipad-large-down) and (max-width: 768px) {
        margin-#{$rdirection}: 0 !important;
        margin-#{$ldirection}: 30px;
        width: 100%;
      }
    }
    .foundation-finder_skin-goal {
      text-align: justify;
      .selection_skip {
        @include swap_direction(margin, 2% 0 0 0.8%);
      }
      h2 {
        @include swap_direction(margin, 30px 0px 20px 10px);
        font-size: 40px;
        line-height: 1.075em;
        font-weight: normal;
        text-align: center;
        color: $color-black;
        @include swap_direction(padding, 20px 0 0 0);
        font-family: $base-thin-font-family;
      }
    }
    .foundation-finder_results-page {
      //margin: 0 auto;
      @include swap_direction(margin, 0px auto);
      @include swap_direction(padding, 30px 0 0 0);
    }
    .undertone-cool_neutral {
      background-color: $color-light-pink;
      width: 250px;
      height: 250px;
      float: #{$ldirection};
      margin-#{$ldirection}: 20px;
      display: table;
    }
    .undertone-warm_neutral {
      background-color: $color-cl-pink-2;
      width: 250px;
      height: 250px;
      float: #{$ldirection};
      margin-#{$ldirection}: 20px;
      display: table;
    }
    .selection_skip {
      text-decoration: none;
      color: $color-black;
      display: inline-block;
      margin-top: 30px;
      font-size: 20px;
      font-family: $base-thin-font-family;
    }
    .undertone-finding {
      font-size: 19px;
      line-height: 1.075em;
      //margin: 25px 0 30px 0;
      @include swap_direction(margin, 25px 0 30px 0);
      //padding: 0 5%;
      @include swap_direction(padding, 0 5%);
      font-family: $helvetica-regular-font-family;
      a {
        color: $color-darkest-grey;
        font-family: $helvetica-regular-font-family;
        text-decoration: underline;
      }
    }
    .foundation-finder_form-selection {
      li {
        float: #{$ldirection};
        font-size: 25px;
        text-align: center;
        font-family: $base-thin-font-family;
        .picker.picker-radio .picker-handle {
          margin-#{$rdirection}: 12px;
        }
      }
      width: 848px;
      //margin: 0 auto;
      @include swap_direction(margin, 0 auto);
      @include breakpoint($ab-small-down) {
        width: 100%;
      }
      img {
        display: block;
      }
    }
    .foundation-finder_finish-selection {
      max-width: 1004px;
      @include swap_direction(margin, 0 auto);
      img {
        display: block;
      }
      ol {
        li {
          p {
            font-size: 15px;
            @include swap_direction(margin, 0 0 15px 28px);
            min-height: 40px;
            font-family: $base-thin-font-family;
          }
        }
      }
      li {
        float: #{$ldirection};
        font-size: 25px;
        @include swap_direction(padding, 0 0 0 3%);
        margin-bottom: 30px;
        width: 25%;
        .picker-radio {
          font-family: $base-thin-font-family;
          margin-#{$ldirection}: 0;
          margin-bottom: 0;
          .picker-handle {
            margin-#{$rdirection}: 12px;
          }
        }
      }
    }
    .foundation-finder_skin-goal-selection {
      margin-#{$ldirection}: 33%;
      li {
        font-size: 25px;
        .picker-radio {
          font-family: $base-thin-font-family;
          .picker-handle {
            margin-#{$rdirection}: 12px;
          }
        }
      }
    }
    .save-restart {
      float: #{$rdirection};
      .saveToProfile,
      .restartQuiz {
        display: inline-block;
        margin-#{$ldirection}: 40px;
      }
    }
    .refine-results-expanded_view {
      @include swap_direction(padding, 0 0 0 4%);
      position: relative;
      margin-bottom: 2%;
      li {
        float: #{$ldirection};
        font-size: 25px;
        @include swap_direction(padding, 30px 0 0 0);
        width: 14%;
        font-family: $base-thin-font-family;
        a {
          text-decoration: none;
          color: $color-black;
          font-family: $base-thin-font-family;
        }
      }
      li {
        &.ff-quiz__nav-skingoal {
          width: 22%;
          @media (max-width: 480px) {
            width: 165px;
            display: block;
            word-wrap: break-word;
            padding-bottom: 30px;
            #ref_skingoal1,
            #ref_skingoal2 {
              width: 105px;
              display: block;
            }
          }
        }
        &.ff-quiz__nav-skingoal:last-child {
          position: absolute;
          #{$rdirection}: 6.75%;
          bottom: -30%;
          padding-top: 0;
          @media (max-width: 768px) {
            bottom: -8%;
            #{$ldirection}: 50%;
            #{$rdirection}: initial;
            width: 50%;
          }
          @media (max-width: $ab-ipad-large-down) {
            bottom: -10%;
          }
        }
      }
      span.recap {
        display: block;
        font-size: 14px !important;
        @include swap_direction(padding, 10px 0 0 0);
        text-transform: uppercase;
      }
    }
    .refine-results {
      .refine-my-results {
        @include swap_direction(padding, 0 0 0 4%);
        display: inline-block;
        font-size: 14px;
        color: $color-black;
      }
      border-top: 1px solid $color-lightest-grey;
      border-bottom: 1px solid $color-lightest-grey;
      //@include swap_direction(padding, 10px 0 20px 0);
      //padding: 10px 0;
      @include swap_direction(padding, 10px 0);
    }
    #close-results {
      float: #{$rdirection};
      @include swap_direction(margin, 0 90px 0 0);
      text-decoration: none;
      color: $color-black;
    }
    .recommended-products {
      @include swap_direction(padding, 35px 0);
      max-width: 1008px;
      //margin: 0 auto;
      @include swap_direction(margin, 0 auto);
    }
    .recommended-product-view {
      float: #{$ldirection};
      width: 42%;
      @include swap_direction(margin, 0 2% 0 0);
      @include breakpoint($ab-small-down) {
        width: 100%;
        text-align: center;
        .rating_img {
          font-size: 13px;
        }
        .read_all_review {
          display: none;
        }
      }
    }
    .recommended-product-description {
      float: #{$ldirection};
      width: 56%;
      @include breakpoint(767px) {
        width: 100%;
        @include swap_direction(padding, 0 0 0 30px);
      }
      .shade-selection {
        .foundation-finder-save {
          float: #{$rdirection};
          @include swap_direction(margin, 30px 0 0);
          @media #{$medium-up} {
            @media #{$portrait} {
              @include swap_direction(margin, 30px 55px 0 0);
            }
            @media #{$landscape} {
              @include swap_direction(margin, 10px 50px 0 0);
            }
          }
          @media #{$large-up} {
            @include swap_direction(margin, 10px 55px 0 0);
          }
        }
      }
    }
    .description-header {
      color: #18bd8b;
      font-size: 28px;
      .superscript-text {
        font-size: 16px;
        vertical-align: super;
      }
    }
    .description-content {
      //padding: 10px 0 10px 0;
      @include swap_direction(padding, 10px 0 10px 0);
      font-size: 14px;
      line-height: 1.3em;
    }
    .shade-selection-header {
      font-size: 24px;
      @include swap_direction(margin, 10px 0);
      font-family: $helvetica-light-font-family;
    }
    .select_shade {
      .price {
        font-size: 18px;
        @include swap_direction(margin, 30px 0 15px);
        font-family: $helvetica-light-font-family;
      }
    }
    .price {
      font-size: 18px;
      @include swap_direction(margin, 15px 0);
    }
    .more-matches-header {
      font-size: 24px;
    }
    .shade-selection {
      li {
        float: #{$ldirection};
        //padding: 10px 20px 0 0;
        @include swap_direction(padding, 10px 20px 0 0);
      }
      figcaption {
        text-align: center;
      }
    }
    .recommended-products {
      .rating_img {
        vertical-align: middle;
      }
    }
    .selected-shade {
      //padding: 30px 0 15px 0;
      @include swap_direction(padding, 30px 0 15px 0);
      line-height: 1.9em;
      p {
        font-size: 18px;
      }
      button {
        color: $color-white;
        background-color: $color-cl-green-3;
        outline: none;
        //padding: 5px 10px;
        @include swap_direction(padding, 5px 10px);
        border: none;
        cursor: pointer;
      }
    }
    .more-matches {
      button {
        color: #fff;
        background-color: $color-cl-green-3;
        outline: none;
        //padding: 5px 10px;
        @include swap_direction(padding, 5px 10px);
        border: none;
        cursor: pointer;
      }
      text-align: center;
      @include swap_direction(padding, 30px 0);
      border-top: 1px solid $color-lightest-grey;
      li {
        float: #{$ldirection};
        //padding: 20px 0 40px 100px;
        @include swap_direction(padding, 20px 0 40px 100px);
        width: 16%;
      }
      figure {
        //padding: 0 0 20px 0;
        @include swap_direction(padding, 0 0 20px 0);
      }
    }
    .more-recommendations {
      li {
        float: #{$ldirection};
        width: 15%;
        padding-#{$rdirection}: 55px;
        height: 330px;
        font-size: 15px;
      }
      //padding: 0 100px;
      @include swap_direction(padding, 0 100px);
      .price {
        color: #a5a5a5;
        line-height: 1.7em;
        @include swap_direction(margin, 0);
      }
    }
    .recommendation-header {
      text-align: center;
      line-height: 1.5em;
      margin-bottom: 40px;
      h2 {
        color: $color-cl-soft-black;
      }
      a {
        display: block;
        font-size: 18px;
        line-height: 2em;
        color: $color-cl-soft-black;
      }
    }
    .foundation-finder {
      //padding: 0px;
      @include swap_direction(padding, 0px);
      .ff-quiz__slide--1 {
        background-color: $color-cl-grey-4;
        cursor: pointer;
        .ff-quiz__slide-container {
          background: url('/media/images/foundation-finder/ff-home-banner-desktop.png') $color-porcelain no-repeat 75% 100%;
          background-size: auto auto;
          background-position: #{$rdirection} top;
          //margin: 0 auto;
          @include swap_direction(margin, 0 auto);
          min-height: 632px;
          //padding: 52px 0 0 25px;
          @include swap_direction(padding, 62px 0 0 25px);
          width: 900px;
          background-color: $color-cl-grey-4;
          font-size: 16px;
          .ff-quiz-slide-header {
            font-weight: bold;
          }
          .ff-quiz {
            &__desc {
              font-size: 17px;
            }
            &__legal {
              width: 305px;
              margin: 15px 0;
            }
          }
          @include breakpoint($ab-ipad-large-down) {
            background-size: 75%;
            background-position: bottom $rdirection;
          }
          @include breakpoint($ab-small-down) {
            width: 100%;
            min-height: 600px;
            background-image: url('/media/images/foundation-finder/ff-home-banner.png');
            background-size: 85%;
          }
          @media (min-width: $ab-ipad-large-down) and (max-width: 768px) {
            width: 768px;
            padding-#{$ldirection}: 25px !important;
          }
        }
      }
    }
    .ff-quiz__button.ff-quiz__button--start {
      font-family: $helvetica-regular-font-family;
      font-size: 0.9em;
      margin-top: 10px;
      @include swap_direction(padding, 4px 10px 3px);
    }
    .ff-quiz__header.active {
      display: block;
    }
    .foundation-finder-mobile-view__header {
      display: none;
    }
    .progress-preview-dropdown {
      width: 103px;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;
      background: url(../images/select-downarrow.png) no-repeat #{$rdirection};
      background-size: 10px 10px;
      display: inline-block;
      position: relative;
    }
    .foundation-finder-results {
      h2.meet_header {
        @include swap_direction(padding, 30px 0 15px);
        text-align: center;
        color: $color-cl-soft-black;
        font-family: $base-thin-font-family;
        @include breakpoint(767px) {
          text-align: center;
          //padding: 36px 0 0 0;
          @include swap_direction(padding, 36px 0 0 0);
          color: $color-black;
        }
      }
      .refresh-section-wrapper {
        width: 90%;
        @include swap_direction(margin, 20px auto 0);
        text-align: $rdirection;
        .result-start-over-link {
          color: $color-cl-soft-black;
          text-transform: uppercase;
          @include swap_direction(padding, 0 20px 0 10px);
          background: url('/media/images/foundation-finder/ff_result_startover.png') no-repeat #{$rdirection};
          background-size: 12px;
        }
      }
      .top_options {
        text-transform: uppercase;
        font-size: 12px;
        float: #{$rdirection};
        .save_option,
        .restart_option {
          float: #{$ldirection};
          //margin: 10px 10px 0 0;
          @include swap_direction(margin, 10px 10px 0 0);
          position: relative;
          padding-#{$rdirection}: 25%;
        }
        .save_option::after {
          content: '';
          width: 10px;
          height: 10px;
          background: url('/media/export/cms/images/plus_icon.png') no-repeat;
          background-size: 100%;
          position: absolute;
          #{$rdirection}: 0;
          top: 1px;
        }
      }
    }
    .cl-mpp-product-layout-v1 {
      .mpp-grid {
        ul.view-small {
          li.mpp-box {
            height: auto;
            width: 182px;
            @include swap_direction(margin, 0 12px 30px 0);
            @include swap_direction(padding, 0);
            float: none;
            @media (max-width: 768px) {
              @include swap_direction(margin, 0 0 30px);
              width: 100%;
              border-bottom: 1px solid $color-lightest-grey;
              .product-info {
                float: #{$rdirection};
                width: 180px;
              }
            }
            .sm_img {
              width: 181px;
              @media (max-width: 768px) {
                width: 135px;
                float: #{$ldirection};
              }
            }
            .product-name {
              color: $color-cl-soft-black;
            }
            .price {
              font-size: 13px;
            }
            .reviews {
              img {
                height: 13px;
              }
            }
          }
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
        }
      }
    }
    .foundation-finder_results-more_recommendations {
      padding-top: 30px;
      .recommendation-header {
        a.match_option {
          font-family: $helvetica-bold-font-family;
        }
      }
    }
    /* media queries */

    @media (max-width: 768px) {
      .ff-quiz__nav-menu li + li::before {
        content: '';
      }
      .ff-quiz__nav-menu li {
        float: none;
      }
      .ff-quiz__slide {
        width: 100%;
        height: 652px !important;
        //margin: 0px;
        @include swap_direction(margin, 0px);
        border: none !important;
      }
      .ff-quiz__slide-container {
        //padding: 70px 0 0 20px;
        @include swap_direction(padding, 70px 0 0 20px);
      }
      .ff-quiz__headline1 {
        font-size: 30px;
      }
      .foundation-finder-mobile-view__header {
        display: none;
        border-bottom: 1px solid $color-black;
        //padding: 20px 10px 10px 10px;
        @include swap_direction(padding, 20px 10px 10px 10px);
        font-size: 14px;
        width: 90%;
        //margin: 0 auto;
        @include swap_direction(margin, 0 auto);
        .progress-steps {
          float: #{$ldirection};
        }
        .progress-preview-dropdown {
          float: #{$rdirection};
          select {
            font-size: 16px;
            border: none;
            outline: none;
          }
        }
      }
      .foundation-finder-mobile-view__header.showHdr {
        display: block;
      }
      #mobileBC {
        &.hide {
          display: none;
        }
        &.show {
          display: block;
        }
      }
      .ff-quiz__header {
        display: none;
        width: 100%;
        //padding: 20px 32px 0 0;
        @include swap_direction(padding, 20px 32px 0 0);
        text-align: #{$rdirection};
        position: absolute;
        #{$rdirection}: 0px;
        background-color: $color-white;
        z-index: 1;
        li {
          //padding: 0 0 20px 0;
          @include swap_direction(padding, 0 0 20px 0);
          font-size: 14px;
        }
      }
      #stepTxt {
        vertical-align: middle;
        display: inline-block;
        font-size: 14px;
      }
      .foundation-finder_skintone_selection {
        width: 310px;
        h2 {
          font-size: 28px;
          padding-top: 25px;
        }
        ul {
          margin-#{$ldirection}: 12px;
          li {
            //padding: 0 5px 2px 0;
            @include swap_direction(padding, 0 5px 2px 0);
            img {
              max-width: 130px;
              //margin: 0px;
              @include swap_direction(margin, 0px);
            }
          }
        }
      }
      .foundation-finder_coverage {
        label {
          font-size: 25px;
          font-family: $helvetica-light-font-family;
        }
        h2 {
          font-size: 28px;
          padding-top: 25px;
        }
        width: 310px;
        .coverage-container {
          //padding: 30px 0 0;
          @include swap_direction(padding, 30px 0 0);
          .cov-txt {
            top: 70px;
            #{$ldirection}: -35px;
          }
          img {
            width: 130px;
          }
        }
      }
      .foundation-finder_undertone_selection {
        h2 {
          font-size: 28px;
          padding-top: 25px;
        }
        width: 305px;
        label {
          font-size: 18px;
          display: inline-block;
          margin-top: 3px;
          text-align: $ldirection;
          width: 80px;
        }
        .undertone-cool_neutral {
          width: 47%;
          height: 145px;
          margin-#{$ldirection}: 10px;
          margin-#{$ldirection}: 3px !important;
          text-align: $ldirection;
          .cool-neutral {
            .picker-radio {
              @include swap_direction(margin, 0 0 0 15px);
            }
            p {
              width: 80px;
              font-size: 13px;
              @include swap_direction(margin, 5px 0 0 43px);
              text-align: $ldirection;
            }
            .cool-neutral-section {
              text-align: justify;
              margin-#{$ldirection}: 15px;
            }
          }
        }
        .undertone-warm_neutral {
          width: 47%;
          height: 145px;
          margin-#{$ldirection}: 10px;
          text-align: $ldirection;
          .warm-neutral {
            .picker-radio {
              @include swap_direction(margin, 0 0 0 15px);
            }
            p {
              width: 80px;
              font-size: 13px;
              @include swap_direction(margin, 5px 0 0 43px);
              text-align: #{$ldirection};
            }
            .warm-neutral-section {
              text-align: justify;
              margin-#{$ldirection}: 15px;
            }
          }
        }
        .undertone-finding {
          font-size: 16px;
        }
      }
      #ff_quiz_undertone_popup {
        .undertone-finding_popup {
          width: 320px;
        }
      }
      .foundation-finder_form {
        h2 {
          font-size: 28px;
          padding-top: 25px;
        }
      }
      .foundation-finder_finish {
        h2 {
          font-size: 28px;
          padding-top: 25px;
        }
      }
      .foundation-finder_skin-goal {
        //margin: 15px auto 0;
        @include swap_direction(margin, 15px auto 0);
        h2 {
          font-size: 42px;
          //padding: 20px 0 0 0;
          @include swap_direction(padding, 20px 0 0 0);
        }
        .selection_skip {
          display: block;
          font-size: 18px;
          //margin: 5% 0 0 50%;
          //margin: 0 auto;
          @include swap_direction(margin, 0 auto);
          text-align: center;
        }
        width: 310px;
      }
      .foundation-finder_form-selection {
        img {
          display: inline-block;
          width: 32%;
          vertical-align: middle;
        }
        li {
          text-align: justify;
          font-size: 20px;
          width: 223px;
          //padding: 10px 0 0 30px;
          @include swap_direction(padding, 10px 0 0 30px);
          float: none;
        }
      }
      .foundation-finder_finish-selection {
        width: 340px;
        margin-#{$ldirection}: 10px;
        margin: 0 auto;
        @include swap_direction(margin, 0 auto);
        li {
          font-size: 20px;
          width: 50%;
          height: 245px; /* Might need to be adjusted to 235px height. See comment on ".selection_skip" bellow. */
          //padding: 0px;
          @include swap_direction(padding, 0px);
          margin-bottom: 0;
        }
        ol {
          margin-#{$ldirection}: 5px;
          li {
            .picker-radio {
              margin-bottom: 5px; /* Adjusted as per 479-103_FoundationFinder_Mobile_V_Redesign_Prep_5.png mockup. */
            }
            p {
              font-size: 13px;
              @include swap_direction(
                margin,
                0 0 8px 0
              ); /* Adjusted as per 479-103_FoundationFinder_Mobile_V_Redesign_Prep_5.png mockup. */
              //margin: 10px 0 0 15px 0;
              min-height: 35px;
              text-align: #{$ldirection};
            }
          }
        }
        img {
          width: 100px;
          //margin: 0; /* Adjusted as per 479-103_FoundationFinder_Mobile_V_Redesign_Prep_5.png mockup. */
          @include swap_direction(margin, 0);
        }
        .selection_skip {
          display: inline-block;
          font-size: 18px;
          margin-#{$ldirection}: 0; /* Adjusted as per 479-103_FoundationFinder_Mobile_V_Redesign_Prep_5.png mockup. */
          margin-top: 0; /* NOTE: This is not exactly as the 479-103_FoundationFinder_Mobile_V_Redesign_Prep_5.png mockup, but closer than before. To adjust it exactly as the mockup the parent "li" element may need to be adjusted to a height of 235px. */
        }
      }
      .foundation-finder_skin-goal-selection {
        margin-#{$ldirection}: 3%;
        text-align: justify;
        li {
          font-size: 17px;
          .picker.picker-radio .picker-handle {
            margin-#{$rdirection}: 10px;
            margin-top: 2px;
          }
        }
      }
      .refine-results {
        .refine-my-results {
          //padding: 0 0 0 20px;
          @include swap_direction(padding, 0 0 0 20px);
          display: inline-block;
          font-size: 14px;
          color: $color-black;
        }
        border-top: 1px solid $color-lightest-grey;
        border-bottom: 1px solid $color-lightest-grey;
        //padding: 10px 0 20px 0;
        //padding: 10px 0;
        @include swap_direction(padding, 10px 0);
      }
      #close-results {
        //margin: 0 20px 0 0;
        @include swap_direction(margin, 0 20px 0 0);
      }
      .refine-results-expanded_view {
        width: 90%;
        @include swap_direction(padding, 0);
        @include swap_direction(margin, 20px auto 0);
        li {
          width: 50%;
          padding-#{$ldirection}: 20px;
          padding-bottom: 20px;
          //padding: 0px 0px 20px 20px;
          @include swap_direction(padding, 0px 0px 20px 20px);
          span.recap {
            //padding: 0px;
            @include swap_direction(padding, 0px);
          }
        }
        li:nth-child(6) {
          width: 50%;
          span.recap {
            margin-bottom: 22px;
          }
        }
      }
      .cl-mpp-product-layout-v1 {
        //padding: 0px;
        @include swap_direction(padding, 0px);
        li.mpp-box {
          height: 180px !important;
          float: none;
          //padding: 0px;
          @include swap_direction(padding, 0px);
          img.sm_img {
            width: 135px;
          }
          .product-info {
            position: relative;
            #{$ldirection}: auto;
            margin-top: 55px;
            margin-bottom: 0px;
          }
        }
      }
      .product-list.full_width {
        //padding: 0px;
        @include swap_direction(padding, 0px);
        .product-thumb {
          img {
            width: 320px;
          }
          .rating_img {
            width: auto !important;
          }
        }
        .product-info {
          //padding: 0 20px;
          @include swap_direction(padding, 0 20px);
        }
        .btn-add-to-bag {
          //margin: 20px 0px;
          @include swap_direction(margin, 20px 0px);
        }
      }
      .ff-quiz {
        .ff-quiz__carousel {
          .slick-list {
            overflow: auto !important;
          }
        }
      }
    }
    @media (min-width: $ab-ipad-large-down) and (max-width: 768px) {
      .ff-quiz {
        .ff-quiz__carousel {
          .slick-list {
            overflow: hidden !important;
          }
        }
      }
    }
    @media (min-width: 480px) and (max-width: 600px) {
      .foundation-finder_undertone_selection {
        width: 400px;
      }
      #ff_quiz_undertone_popup {
        width: 400px;
        //margin: 0 auto;
        @include swap_direction(margin, 0 auto);
      }
    }
    @media (min-width: 480px) and (max-width: 768px) {
      .foundation-finder_skintone_selection {
        width: 82%;
        h2 {
          font-size: 32px;
          padding-top: 25px;
        }
        ul {
          margin-#{$ldirection}: 12px;
          li {
            //padding: 0 5px 2px 0;
            @include swap_direction(padding, 0 5px 2px 0);
            img {
              max-width: 206px;
              //margin: 0px;
              @include swap_direction(margin, 0px);
            }
          }
        }
      }
      .foundation-finder_undertone_selection {
        h2 {
          font-size: 32px;
          padding-top: 25px;
        }
        //width: 400px;
        .undertone-cool_neutral {
          width: 47%;
          height: 165px;
          margin-#{$ldirection}: 10px;
          .cool-neutral {
            .picker-radio {
              @include swap_direction(margin, 0 0 0 15px);
            }
            p {
              width: 101px;
              @include swap_direction(padding, 0 31px 0 0);
              @include swap_direction(margin, 5px 0 0 43px);
            }
            .cool-neutral-section {
              text-align: justify;
              margin-#{$ldirection}: 15px;
            }
          }
        }
        .undertone-finding {
          font-size: 18px;
        }
        .undertone-warm_neutral {
          height: 165px;
          width: 47%;
          .warm-neutral {
            .picker-radio {
              @include swap_direction(margin, 0 0 0 15px);
            }
            p {
              width: 101px;
              @include swap_direction(padding, 0 31px 0 0);
              @include swap_direction(margin, 5px 0 0 43px);
            }
            .warm-neutral-section {
              text-align: justify;
              margin-#{$ldirection}: 15px;
            }
          }
        }
      }
      .foundation-finder_coverage {
        h2 {
          font-size: 32px;
          padding-top: 25px;
        }
        width: 100%;
        .coverage-container {
          width: 25%;
          margin-#{$ldirection}: 8%;
        }
      }
      .foundation-finder_form-selection {
        width: 100%;
        li {
          width: 25%;
          float: #{$ldirection};
        }
        img {
          width: 60%;
        }
      }
      .foundation-finder_finish-selection {
        width: 95%;
        li {
          width: 25%;
        }
      }
      .ff-quiz__slide.ff-quiz__slide--7,
      .ff-quiz__slide.ff-quiz__slide--8 {
        background-size: cover;
      }
      .foundation-finder_skin-goal {
        width: 90%;
        .foundation-finder_skin-goal-selection {
          margin-#{$ldirection}: 0;
          li {
            width: 50%;
            float: #{$ldirection};
            &:nth-child(odd) {
              clear: both;
            }
          }
        }
      }
      .undertone-warm_neutral {
        width: 47%;
        height: 165px;
        margin-#{$ldirection}: 10px;
      }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      .ff-quiz__slide-container {
        @include swap_direction(padding, 50px 0px 0px 50px);
      }
      .foundation-finder_undertone_selection {
        width: 560px;
        label {
          width: auto;
        }
        .undertone-cool_neutral {
          .cool-neutral {
            .picker-radio {
              @include swap_direction(margin, 0 0 0 15px);
            }
            p {
              @include swap_direction(margin, 5px 0 0 43px);
            }
          }
        }
        .undertone-warm_neutral {
          .warm-neutral {
            .picker-radio {
              @include swap_direction(margin, 0 0 0 15px);
            }
            p {
              @include swap_direction(margin, 5px 0 0 43px);
            }
          }
        }
      }
      .foundation-finder_coverage,
      .foundation-finder_form-selection {
        width: 768px;
      }
      .foundation-finder_form-selection {
        li {
          width: 192px;
          float: #{$ldirection};
          padding-#{$ldirection}: 0px;
          img {
            display: block;
            margin-top: 20px;
            width: 100px;
          }
        }
      }
      .foundation-finder_finish-selection {
        //margin: auto;
        @include swap_direction(margin, auto);
        width: 768px;
        li:nth-child(1) {
          padding-#{$ldirection}: 10px;
        }
        li {
          width: 190px;
          position: relative;
          p {
            width: 165px;
          }
        }
        img {
          position: absolute;
          top: 100px;
          width: 100px;
        }
      }
      .foundation-finder_skin-goal {
        width: 768px;
        .foundation-finder_skin-goal-selection {
          margin-#{$ldirection}: 5%;
          li {
            float: #{$ldirection};
            font-size: 20px;
            //padding: 0 0 15px;
            @include swap_direction(padding, 0 0 15px);
            width: 50%;
          }
        }
      }
    }
    @media (min-width: $ab-ipad-large-down) and (max-width: 768px) {
      .foundation-finder_skintone_selection {
        ul {
          li {
            img {
              max-width: 200px;
            }
          }
        }
      }
    }
    @media (min-width: 1024px) {
      .ff-quiz__slide-container {
        //padding: 100px 0px 0px 200px;
        @include swap_direction(padding, 100px 0px 0px 200px);
      }
    }
    .shade {
      float: #{$ldirection};
      padding-bottom: 7px;
      width: 186px;
      .shade-content {
        .search-swatch-container {
          width: 118px;
          height: 121px;
          margin-bottom: 10px;
        }
        &.active {
          img {
            outline: 2px solid $color-light-grey;
            outline-offset: 3px;
          }
        }
      }
    }
    .ff-quiz__gallery-arrow {
      @include transition(background 0.2s ease-in-out);
      @include transform(translateY(-50%));
      top: 37%;
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      #{$ldirection}: 0;
      z-index: 2;
      cursor: pointer;
      //padding: 0;
      @include swap_direction(padding, 0px);
      height: 50px;
      line-height: 50px;
      width: 25px;
      font-size: 18px;
      color: $color-white;
      text-align: center;
      display: block;
      @media (max-width: 480px) {
        top: 34%;
        #{$ldirection}: -1px;
      }
      @media (min-width: 768px) {
        top: 38%;
        height: 105px;
        line-height: 105px;
        width: 40px;
        font-size: 32px;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.9);
      }
      .icon {
        #{$ldirection}: 0px;
        height: 50px;
        display: block;
        position: relative;
        @media (min-width: 768px) {
          #{$ldirection}: 0px;
          margin-top: 60%;
        }
      }
    }
    .ff-quiz__gallery-arrow--next {
      #{$ldirection}: auto;
      #{$rdirection}: 0;
      .icon {
        #{$ldirection}: auto;
        #{$rdirection}: 0px;
        background: url('/media/images/foundation-finder/ff_right_arrow.png') no-repeat scroll
          center center;
        @media (min-width: 768px) {
          #{$rdirection}: 0px;
        }
        &::before {
          content: none;
        }
      }
    }
    .ff-quiz__gallery-arrow--prev {
      .icon {
        background: url('/media/images/foundation-finder/ff_left_arrow.png') no-repeat scroll center
          center;
        &::before {
          content: none;
        }
      }
    }
  }
  &#diagnostics,
  &#landing {
    .foundation-finder {
      .outer-wrap {
        max-width: 100%;
        .refresh-section-wrapper {
          width: 90%;
          @include swap_direction(margin, 20px auto 0);
          text-align: #{$rdirection};
          .result-start-over-link {
            color: $color-cl-soft-black;
            text-transform: uppercase;
            @include swap_direction(padding, 0 20px 0 10px);
            background: url('/media/images/foundation-finder/ff_result_startover.png') no-repeat #{$rdirection};
            background-size: 12px;
          }
        }
        .recommended-products,
        .meet_header {
          max-width: 960px;
          @include swap_direction(margin, 0 auto);
          .shade-selection .shade {
            width: 178px;
          }
        }
      }
    }
  }
}
/*  End Foundation-Finder redesgin */
