img.full {
  width: auto;
}

#foreground-node {
  max-height: 100%;
  &.notify-me-container {
    height: 245px;
    width: 345px;
    .tiny-waitlist-overlay {
      .align-r {
        input {
          width: 100%;
        }
      }
    }
  }
}

fieldset {
  border: 0;
}

.page-spp .badge .badge-inner img {
  width: 65px;
}

.pc_spp_view {
  .sticky_content {
    .spp_product_status {
      clear: none;
      text-align: center;
      display: inline-block;
      float: left;
      margin-left: 3px;
    }
  }
}

.mpp-product,
.mpp-product-hover-view {
  .badge .badge-inner img {
    width: 60px;
  }
}
/* ApplePay integration */

#js-top-buttons-panel,
#js-bottom-buttons-panel {
  .error {
    display: none;
  }
}

#js-bottom-buttons-panel {
  .viewcart-buttons-panel {
    &.applepay {
      &.panel {
        .content {
          position: relative;
          padding-left: 0;
          padding-right: 0;
        }
      }
      .error {
        display: block;
        float: left;
        width: 100%;
        padding-top: 5px;
      }
      .continue-buttons {
        .continue-checkout,
        .paypal-checkout {
          display: inline-block;
        }
        .continue-checkout {
          float: right;
        }
        .or {
          float: right;
          display: inline;
          margin-top: 12px !important;
        }
        &.applepay-enabled {
          position: relative;
          width: 157px;
          height: 120px;
          padding: 0 !important;
          margin-bottom: 30px;
          text-align: center;
          .continue-checkout,
          .paypal-checkout {
            position: absolute;
            display: block !important;
            float: none;
            width: 100%;
          }
          .continue-checkout {
            top: 0;
          }
          .paypal-checkout {
            bottom: 0;
          }
          .or {
            position: absolute;
            top: 40px;
            float: none;
            display: block;
            width: 100%;
            text-align: center;
            margin: 12px 0 !important;
          }
        }
      }
      .applepay-integration-wrapper {
        float: right;
        width: 100%;
        padding: 10px 13px;
        border: 1px solid #b7b7b7;
        background: #ebebeb;
        &.applepay-error {
          border-color: #e61f26;
          background-color: #ffe2e4;
        }
        .applepay-button-container {
          display: inline-block;
          vertical-align: middle;
        }
        div.applepay-button-container {
          width: 64%;
          text-align: justify;
          .divide {
            position: relative;
            padding-right: 25px;
            .form-item {
              margin: 0;
            }
            input[type='checkbox'] {
              position: absolute;
              top: calc(50% - 6px);
              right: 0;
              width: 15px;
              height: 15px;
            }
            .label-content {
              a {
                display: inline !important;
              }
            }
          }
        }
        span.applepay-button-container {
          width: 34%;
          text-align: center;
          .apple-pay-button {
            display: block !important;
          }
        }
        .applepay-button-container {
          text-align: left;
          section.divide {
            /* float: left; */
          }
          .apple-pay-button {
            overflow: hidden;
            text-indent: 100%;
            white-space: nowrap;
            -webkit-appearance: -apple-pay-button;
            -apple-pay-button-style: black;
          }
          .apple-pay-button.button-style-buy {
            -apple-pay-button-type: buy;
          }
          .apple-pay-button.button-style-set-up {
            -apple-pay-button-type: set-up;
          }
        }
        @media (min-width: 40em) and (max-width: 641px) {
          span.applepay-button-container {
            display: block;
            float: left;
            padding-left: 0;
          }
        }
        @media (max-width: 40em) {
          .applepay-button-container {
            display: block;
            min-width: 100%;
            padding-left: 0;
            .btn {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 40em) {
  #js-bottom-buttons-panel {
    .viewcart-buttons-panel {
      &.applepay {
        &.panel {
          .content {
            float: none;
          }
        }
        .continue-shopping {
          margin-bottom: 1em !important;
        }
        .continue-buttons {
          &.applepay-enabled {
            height: 170px;
            .continue-checkout,
            .paypal-checkout {
              float: none !important;
              width: 100% !important;
            }
            .continue-checkout {
              margin-bottom: 7px !important;
            }
            .or {
              position: absolute;
              top: 50px;
              width: 100% !important;
            }
          }
        }
        .applepay-integration-wrapper {
          display: table;
          table-layout: fixed;
          float: right;
          clear: both;
          width: 100%;
          float: none;
          margin: 0;
          div.applepay-button-container {
            display: table-footer-group;
            float: none;
            width: auto;
            text-align: left;
            .divide {
              margin-top: 8px;
            }
            .error {
              margin: 5px 0 !important;
              background: transparent !important;
            }
          }
          span.applepay-button-container {
            display: table-header-group;
            float: none;
            width: auto;
          }
        }
      }
    }
  }
}
/* Applepay logged in state */
body.elc-user-state-logged-in #js-bottom-buttons-panel .viewcart-buttons-panel.applepay .applepay-integration-wrapper {
  border: none;
  background: none;
  padding-right: 0;
  & span.applepay-button-container {
    @media (max-width: 40em) {
      display: block;
      padding-right: 12px;
    }
    @media (min-width: 40em) {
      width: 157px;
      float: right;
    }
  }
}

body#viewcart.elc-user-state-anonymous #main.single #js-bottom-buttons-panel span.applepay-button-container {
  padding-left: 10px;
}

@media only screen and (max-width: 51em) and (orientation: landscape) {
  body#viewcart #main.single #js-bottom-buttons-panel .applepay {
    & div.applepay-integration-wrapper {
      margin-top: 8px;
    }
    .applepay-enabled {
      & a.paypal-checkout {
        right: 0;
        & img {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      & span.or {
        width: 160px;
        right: 0;
      }
      & a.continue-checkout {
        right: 0;
        width: 157px;
      }
    }
  }
}
/* NOTIFICATIONS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_notification div.bt-content {
  position: relative;
  border: 1px solid black;
  padding: 15px;
  background-color: white;
  z-index: 1001;
  font-size: 12px;
  line-height: 16px;
}

#bt_notification div.bt-content a.bt-close-link {
  position: absolute;
  top: 10px;
  right: 10px;
}
/* CUSTOM POSITIONING */
#bt_notification div.bt-bottom-right {
  position: fixed;
  bottom: 0px;
  right: 10px;
  width: 250px;
  border-bottom-width: 0;
}

#bt_notification div.bt-bottom-full {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-bottom-width: 0;
}
/* DEVICES */
#bt_notification div.bt-content-mobile {
  padding: 10px;
}

#bt_notification div.bt-content-mobile a.bt-close-link {
  display: none;
}
/* BRAND SPECIFICS @JM */
#bt_notification div.bt-content {
  border-color: #000;
  border-width: 10px;
  padding: 20px 25px 20px 20px;
}

#bt_notification div.bt-content a {
  color: #5dba98;
  font-weight: bold;
}

#bt_notification div.bt-content a.bt-close-link {
  display: block;
  width: 15px;
  height: 15px;
  background: url('/media/export/images/cookies/close_x.png') 50% 50% no-repeat;
  text-indent: -9999em;
}

#bt_notification div.bt-content div.bt-pp-section {
  margin-bottom: 10px;
}

#bt_notification div.bt-content a.btn {
  display: block;
  margin-top: 10px;
  background: #000;
  color: #fff;
  font-family: $base-bold-font-family;
  text-align: center;
  letter-spacing: 0;
  border: 0;
  padding: 4px 10px 3px;
  zoom: 1;
  line-height: 1.5;
  font-size: 0.9em;
  text-transform: uppercase;
}

#bt_notification div.bt-content h6 {
  font-size: 1.17em;
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_settings div.bt-content {
  border: 1px solid black;
  padding: 15px;
  background-color: white;
  z-index: 999999;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 1.5em;
}

#bt_settings div.bt-content div.bt-intro {
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy {
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy p {
  margin-top: 1em;
}
/* TOGGLE SLIDER */
#bt_settings div.bt-content div.slide,
#bt_settings div.bt-content ul.slide-toggle {
  position: relative;
  overflow: hidden;
  width: 525px;
}

#bt_settings div.bt-content div.slide {
  margin: 20px 0;
}

#bt_settings div.bt-content ul.slide-toggle {
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent url('/media/export/images/cookies/all-off.png') 50% 100% no-repeat;
  background-size: contain;
}

#bt_settings div.bt-content div.mask {
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 0px;
}

#bt_settings div.bt-content div.mask ul.slide-toggle {
  background-image: url('/media/export/images/cookies/all-on.png');
}

#bt_settings div.bt-content div.mask div.drag {
  position: absolute;
  bottom: 12px;
  right: 70px;
  width: 26px;
  height: 11px;
  background: transparent url('/media/export/images/cookies/icon_arrow.png') 50% 100% no-repeat;
}

#bt_settings div.bt-content ul.slide-toggle li {
  cursor: pointer;
  float: left;
  width: 160px;
  height: 138px;
  list-style: none;
}

#bt_settings div.bt-content ul.slide-toggle li.middle {
}

#bt_settings div.bt-content ul.slide-toggle li.selected {
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: block;
  font-weight: bold;
  text-align: center;
  margin: 5px;
}
/* PRIVACY INFORMATION */
#bt_settings div.bt-content .bt-privacy-info {
  display: none;
  overflow: hidden;
}

#bt_settings div.bt-content .bt-privacy-info.selected {
  display: block;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  padding: 1em 1em 1em 40px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will h3 {
  background-image: url('/media/export/images/cookies/icon_safe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot h3 {
  background-image: url('/media/export/images/cookies/icon_unsafe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div {
  width: 48%;
  margin-right: 2%;
  float: left;
}

#bt_settings div.bt-content .bt-privacy-info div ul {
  list-style-type: none;
  padding-left: 0;
  margin: 1em;
  padding-left: 0px !important;
}

#bt_settings div.bt-content .bt-privacy-info div ul li {
  margin: 0.8em 0;
  padding-left: 30px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  list-style: none;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will ul li {
  background-image: url('/media/export/images/cookies/icon_safe_thick.png');
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot ul li {
  background-image: url('/media/export/images/cookies/icon_unsafe_thick.png');
}
/* CUSTOM POSITIONING */

/* DEVICES */
#bt_settings div.bt-content-mobile ul.slide-toggle {
  background-image: url('/media/export/images/cookies/all-off.png');
}

#bt_settings div.bt-content-mobile div.mask ul.slide-toggle {
  background-image: url('/media/export/images/cookies/all-on.png');
}

#bt_settings div.bt-content-mobile div.slide {
  margin: 20px auto;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile ul.slide-toggle {
  width: 288px;
}

#bt_settings div.bt-content-mobile div.mask div.drag {
  right: 36px;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li span {
}

#bt_settings div.bt-content-mobile .bt-privacy-info div {
  width: 100%;
  float: none;
}
/* BRAND SPECIFICS */
#bt_settings div.bt-content {
  border-color: #000;
  border-width: 2px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

#bt_settings div.bt-content-desktop {
  padding: 2em;
  height: 100%;
}

#bt_settings div.bt-content div.bt-intro h2 {
  padding: 0;
  margin: 0 0 1em 0;
  border: none;
  text-transform: none;
  color: #000;
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: none;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  background-color: #f5f5f5;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile ul.slide-toggle {
  width: 246px;
  padding-left: 0 !important;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li {
  width: 82px;
  height: 70px;
}

#bt_settings div.bt-content-mobile div.mask div.drag {
  right: 30px;
  bottom: 5px;
}

.customer-service-section {
  #bt_settings {
    div {
      display: inline;
    }
  }
}

.tealium {
  display: none !important;
}

.device-mobile {
  #bt_settings div.bt-content div.slide {
    margin: 20px auto;
    width: 246px;
  }
  #bt_settings div.bt-content ul.slide-toggle {
    width: 246px;
    padding-left: 0px !important;
  }
  #bt_settings div.bt-content ul.slide-toggle li {
    width: 82px;
    height: 70px;
  }
  #bt_settings div.bt-content .bt-privacy-info div {
    width: 100%;
    float: none;
  }
  #bt_settings div.bt-content div.mask div.drag {
    right: 30px;
    bottom: 5px;
  }
  #bt_notification div.bt-bottom-right {
    width: 100%;
    right: 0px;
  }
  #bt_notification div.bt-content a.btn {
    height: 45px;
    font-size: 18px;
    line-height: 44px;
    padding: 0;
  }
}

.tiny-waitlist-overlay {
  width: 100%;
  .field {
    @media (min-width: 768px) {
      margin-top: 12px;
    }
    margin-top: 8px;
    margin-bottom: 15px;
  }
  form .field {
    label {
      color: $color-cl-darker-grey;
      font-weight: normal;
      text-transform: capitalize;
      font-family: $font-helvetica45-light, 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
      margin-right: 10px;
      @media (max-width: 768px) {
        margin-bottom: 8px;
      }
    }
    input {
      height: 30px;
      width: 160px;
      @media (max-width: 768px) {
        color: $color-cl-darker-grey;
        width: 100%;
      }
    }
    .email-address {
      label {
        @media (max-width: 768px) {
          margin-bottom: 2px;
        }
      }
    }
  }
  .align-r input {
    background: none repeat scroll 0 0 $color-btn-green;
    border: 0;
    color: $color-white;
    float: right;
    @include header(16px, $font-helvetica45-medium-helvetica, $font-weight-bold);
    height: 30px;
    padding: 0 13px;
    text-transform: uppercase;
    border-radius: 0;
    @media (max-width: 768px) {
      height: 34px;
      text-align: center;
      width: 100%;
    }
  }
  .tooltip {
    margin-top: 0;
    @media (max-width: 768px) {
      margin: 20px 0px;
    }
    .tooltiptext-over {
      padding: 5px;
      bottom: 123%;
      @media (max-width: 768px) {
        bottom: 105%;
      }
    }
  }
}

.forgot-pass-gdpr {
  color: $color-black;
  font-size: 14px;
  line-height: 1.375;
  &__notify {
    background-color: $color-light-orange;
    padding: 20px;
  }
  &__guest {
    border-top: 1px solid $color-black;
    margin-top: 20px;
    padding-top: 20px;
  }
  &__reset-link {
    &:link,
    &:visited {
      .messages & {
        color: $color-white;
      }
    }
    .checkout-index & {
      width: auto;
      padding: 3px 5%;
    }
    margin-top: 11px;
  }
}

.checkout-confirm {
  &__email-id {
    font-weight: 700;
  }
}

.password-field {
  position: relative;
  #password_reset & {
    @media screen and (min-width: $medium-m) {
      margin-bottom: 0;
    }
  }
  &__info {
    display: flex;
    width: 100%;
    background-color: $color-white;
    z-index: 99;
    margin-top: 5px;
    &::before {
      @media #{$medium-up} {
        content: '';
        position: absolute;
        top: 43%;
        right: 100%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent $color-btn-grey transparent transparent;
      }
      #signin &,
      #gnav_signin &,
      .loyalty_popover & {
        left: 50%;
        border-color: $color-btn-grey transparent transparent transparent;
        top: 100%;
      }
      #registration & {
        top: 57px;
        right: 49%;
        border-color: $color-btn-grey transparent transparent transparent;
      }
    }
    @media #{$medium-up} {
      #confirmation-page &,
      #password_reset &,
      .signin-overlay-loyalty &,
      .registration-page &,
      #signin &,
      #gnav_signin &,
      #registration &,
      .loyalty_popover & {
        position: absolute;
        border: 1px solid $color-black;
      }
      #confirmation-page & {
        left: 35%;
        top: -20px;
        width: auto;
      }
      #password_reset & {
        top: -15%;
        width: auto;
        left: 40%;
      }
      #signin &,
      #gnav_signin & {
        width: 50%;
        left: 0%;
        bottom: 40px;
      }
      #gnav_signin &,
      .loyalty_popover & {
        &.hidden {
          display: none;
        }
      }
      .loyalty_popover & {
        width: 80%;
        left: 5%;
        top: -68px;
      }
      #registration & {
        width: auto;
        bottom: 5px;
        left: 16%;
      }
      .registration-page & {
        width: 50%;
        left: 105%;
        top: -17px;
        @media screen and (min-width: $medium-m) {
          width: 100%;
        }
      }
    }
  }
  #registration & {
    clear: both;
  }
  &__rules {
    column-count: 2;
    .registration-page &,
    #confirmation-page &,
    #password_reset &,
    #gnav_signin &,
    #signin & {
      @media #{$medium-up} {
        column-count: 1;
      }
      @media screen and (max-width: $medium-mid) {
        padding: 0 10px;
      }
    }
    padding: 10px;
    font-size: 0.8em;
    list-style: none;
    > li {
      display: flex;
      align-items: center;
      padding: 3px 0;
      color: $color-btn-grey;
      transition: 0.2s;
      white-space: nowrap;
      &::before {
        content: $cross-mark;
        display: inline-block;
        color: $color-red;
        font-size: 1em;
        line-height: 0;
        margin: 0 6px 0 0;
        transition: 0.2s;
      }
      &.pass {
        color: $color-green;
        &::before {
          color: $color-green;
          content: $check-mark;
          text-shadow: 0 0 8px rgba($color-black, 0.5);
        }
      }
    }
  }
}

#password_reset {
  .password_container {
    width: auto;
    margin-bottom: 15px;
    float: none;
    div.label {
      margin-bottom: 0;
    }
  }
}

.passwordreset {
  #content > div {
    margin-bottom: 10px;
  }
}
