.in-account-module {
  &__wrapper {
    @media #{$small-only} {
      padding: 0 20px;
    }
    display: flex;
    flex-wrap: wrap;
  }
  &__imageBox {
    @media #{$small-only} {
      width: 100%;
    }
    width: 25%;
    img {
      width: 100%;
    }
  }
  &__contentArea {
    @media #{$small-only} {
      @include swap_direction(padding, 0 5px 0 0);
      width: 100%;
    }
    @include swap_direction(padding, 0 0 0 5px);
    display: flex;
    align-items: center;
    width: 70%;
  }
  &__content {
    display: block;
    margin-bottom: 10px;
  }
  &__accept-elements {
    margin-bottom: 5px;
  }
  &__privacy-policy {
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
    &--label {
      p {
        margin: auto;
      }
    }
    @media #{$small-only} {
      align-items: flex-start;
      &--label {
        margin-top: -0.2em;
      }
    }
    &--checkbox {
      margin-#{$rdirection}: 5px;
      margin-top: 2px;
      &:focus {
        outline: none !important;
      }
      @media #{$small-only} {
        margin-top: 0;
      }
    }
  }
  &__button {
    white-space: normal;
    line-height: 20px;
    &--disable {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }
  &__gdpr {
    position: relative;
    margin: 0 0 20px;
    width: 100%;
    @media #{$cr19-large-up} {
      margin: 0 0 5px;
      display: flex;
    }
    &:hover > &--overlay {
      display: block;
      z-index: 99;
    }
    &--label {
      cursor: pointer;
      text-decoration: underline;
      @media #{$cr19-large-up} {
        padding: 5px;
      }
    }
    &--overlay {
      background-color: $color-white;
      border-radius: 5px;
      border: 1px solid $color-black;
      bottom: 25px;
      color: $cr19-text-black;
      display: none;
      padding: 15px;
      position: absolute;
      #{$ldirection}: 0;
      width: 300px;
      @media #{$cr19-large-up} {
        width: 400px;
      }
      a {
        color: $cr19-text-black;
        display: inline;
        text-decoration: underline;
        &:hover {
          color: $cr19-text-black;
        }
      }
      p {
        margin: 0;
      }
    }
  }
}
