/* Maintenance Page  */

.maintenance-container {
  .maintenance-content {
    float: #{$ldirection};
    margin-top: 50px;
    width: 50%;
    .oops {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .maintenance-image {
    float: #{$rdirection};
    width: 50%;
  }
}
/* mobile */
.device-mobile .maintenance-container {
  .maintenance-content {
    margin-top: 20px;
    width: 100%;
    .oops {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .maintenance-image {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
