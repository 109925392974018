/* Font face declarations should be compassified */

@font-face {
  font-family: 'HelveticaNeueLTStd45Light';
  src: url('#{$netstorage-font-path}latin/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd65Medium';
  src: url('#{$netstorage-font-path}latin/4c6503c9-859b-4d3b-a1d5-2d42e1222415.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd95Black';
  src: url('#{$netstorage-font-path}latin/feb77f4f-9e6b-4f88-909a-66199fd402ed.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd56Italic';
  src: url('#{$netstorage-font-path}latin/2381d918-136d-444f-8391-db0cba6da388.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-UltLt';
  src: url('#{$netstorage-font-path}latin/7f1f2a7d-3837-4c93-b373-f03c5da3f9a1.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd55Roman';
  src: url('#{$netstorage-font-path}latin/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GTSectraFineRegular';
  src: url('#{$netstorage-font-path}GTSectra/GTSectra-Fine-Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GTSectraFineMediumItalic';
  src: url('#{$netstorage-font-path}GTSectra/GTSectra-Fine-Medium-Italic.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd35Thin';
  src: url('#{$netstorage-font-path}latin/278bef59-6be1-4800-b5ac-1f769ab47430.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd75Bold';
  src: url('#{$netstorage-font-path}latin/8fda1e47-19be-46c7-8d83-8d4fb35572f0.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTStd85Heavy';
  src: url('#{$netstorage-font-path}latin/54085041-5049-4b91-939c-49980c66abab.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'CliniqueHelveticaNeueRegular';
  src: url('#{$netstorage-font-path}HelveticaNeue/HelveticaNeue-Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'CliniqueHelveticaNeueLight';
  src: url('#{$netstorage-font-path}HelveticaNeue/HelveticaNeue-Light.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'CliniqueHelveticaNeueMedium';
  src: url('#{$netstorage-font-path}HelveticaNeue/HelveticaNeue-Medium.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'CliniqueHelveticaNeueBold';
  src: url('#{$netstorage-font-path}HelveticaNeue/HelveticaNeue-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'CliniqueHelveticaNeueItalic';
  src: url('#{$netstorage-font-path}HelveticaNeue/HelveticaNeue-Italic.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoRegular';
  src: url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Condensed-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NittiBold';
  src: url('#{$netstorage-font-path}Nitti/Nitti-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NittiMedium';
  src: url('#{$netstorage-font-path}Nitti/Nitti-Medium.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NittiRegular';
  src: url('#{$netstorage-font-path}Nitti/Nitti-Regular.woff2');
  font-display: swap;
}

/* Neuzeit-Office Font face declarations */

/**
 * @license
 * MyFonts Webfont Build ID 3125386, 2015-11-13T13:16:45-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: NeuzeitOffice-Bold by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neuzeit-office/bold/
 *
 * Webfont: NeuzeitOffice-BoldItalic by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neuzeit-office/bold-italic/
 *
 * Webfont: NeuzeitOffice-Italic by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neuzeit-office/italic/
 *
 * Webfont: NeuzeitOffice-Regular by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neuzeit-office/regular/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3125386
 * Licensed pageviews: 1,000,000
 * Webfonts copyright: Copyright &#x00A9; 2006 - 2008 Linotype GmbH, www.linotype.com.
 * All rights reserved. This font software may not be reproduced, modified, disclosed or
 * transferred without the express written approval of Linotype GmbH. Neuzeit is a
 * trademark of Linotype Gmb
 *
 * © 2015 MyFonts Inc
 */

@font-face {
  font-family: 'NeuzeitOffice-Bold';
  src: url('#{$netstorage-font-path}NeuzeitOffice/2FB08A_0_0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NeuzeitOffice-Regular';
  src: url('#{$netstorage-font-path}NeuzeitOffice/2FB08A_3_0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NeuzeitOffice-BoldItalic';
  src: url('#{$netstorage-font-path}NeuzeitOffice/2FB08A_1_0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NeuzeitOffice-Italic';
  src: url('#{$netstorage-font-path}NeuzeitOffice/2FB08A_2_0.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('#{$netstorage-font-path}FontAwesome/fontawesome-webfont.woff2') format('woff2');
  font-display: swap;
}
