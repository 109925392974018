/* MIXINS */

@mixin ff_options {
  border: 2px solid $color-btn-green;
  color: $color-btn-green;
  float: #{$ldirection};
  font-weight: bold;
  margin-#{$rdirection}: 20px;
  @include swap_direction(padding, 5px);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@mixin add_to_bag_button {
  background-color: $color-btn-green;
  color: $color-white;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
  text-align: center;
  text-transform: uppercase;
}
/* END OF MIXINS */

#ff_spp #ff-header h1.h_result {
  display: block;
}

#ff_spp #ff-header h1.h_no_result {
  width: 720px;
  display: none;
}

#ff_spp #ff-header h2.h_no_result_sub {
  display: none;
}

#ff_spp #ff-header.vss .h_result,
#ff_spp #ff-header.vss .h_result_sub {
  display: none;
}

#ff_spp #ff-header.vss h1.h_no_result,
#ff_spp #ff-header.vss h2.h_no_result_sub {
  display: block;
}
/* Prod */
#ff_spp #product-results .result > div {
  float: left;
}

#ff_spp #product-results .quick-info-link {
  position: absolute;
}

#ff_spp #product-results .result .prod_attribute {
  position: relative;
  clear: both;
  width: 411px;
  margin-bottom: 18px;
}

#ff_spp #product-results .result dl.prod_attribute_list {
  width: 100%;
  margin-bottom: 5px;
}

#ff_spp #productresults .result .shades .shade {
  position: relative;
  float: left;
  width: 171px;
  height: 170px;
  @include swap_direction(margin, 0 10px 0 10px);
  cursor: pointer;
}

#ff_spp #product-results .result .prod_attribute .prod_description {
  width: 388px;
  padding-#{$rdirection}: 10px;
  line-height: 16px;
  overflow: auto;
}

#ff_spp #product-results .result .prod_attribute .prod_description_header {
  position: absolute;
  top: auto;
  bottom: 0;
  #{$rdirection}: 0px;
  width: 13px;
  height: 11px;
  overflow: hidden;
  cursor: pointer;
  background: url(/media/images/foundation_finder/btn_desc_arrow.png) no-repeat 0 -11px;
  display: none;
}

#ff_spp #product-results .result .prod_attribute .prod_description_header.collapsed {
  background-position: 0 0;
  bottom: auto;
  top: 0;
}

#ff_spp #product-results .result .prod_attribute .accordion-content {
  display: none;
}

#ff_spp #product-results .result .shades {
  display: block;
  position: relative;
  width: 100%;
}

#ff_spp #product-results .result .shades .results-header {
  font-size: 24px;
  font-family: $helvetica-light-font-family;
}
/* active */

#ff_spp #product-results .result .shades .shade.active img {
  width: 206px;
  height: 205px;
}

#ff_spp .add-to-bag {
  @include swap_direction(margin, 6px 0 16px 0);
}
/* #ff_spp a.btn-add-to-bag,
#ff_spp a.btn-add-to-bag img { display: block; } */

#ff_spp #product-results .result .prod_return {
}

#ff_spp #product-results .result .prod_return h3 {
  /* Bug 164583 */
  font-size: 16px;
  color: #409138;
  margin-bottom: 12px;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-size-adjust: none;
  color: #333333;
  line-height: 13px;
}

#ff_spp .ff_divider {
  clear: both;
  width: 100%;
  padding-bottom: 45px;
  border-top: 1px solid #dadada;
}
/* WWW */

#ff_spp #works-with-results .results-header .loading {
  position: absolute;
  bottom: 0;
  #{$ldirection}: 0;
}

#ff_spp #works-with-results .results div.qs {
  padding: 9px;
  border: 1px solid #231f20;
}

#ff_spp #works-with-results .result .image a.quickshop-link img.quickshop-btn {
  display: none;
}

#ff_spp #works-with-results .result .image a.quickshop-link img.qs-active {
  display: block;
  position: absolute;
  bottom: 18px;
  #{$ldirection}: 18px;
}

#ff_spp .result .shades .shade .search-swatch-container .search-swatch-container {
  width: 20px;
  height: 20px;
}
/*  Bug d160617 */

#works-with-results div.first.last.single {
  width: 20px;
  height: 20px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
}

.search-swatch-container span {
  margin-#{$ldirection}: 6px;
}

#ff_spp {
  font-size: 12px;
  color: #333333;
  .btn-add-to-bag {
    height: auto;
  }
  .ff_spp_product {
    .ff_header_center {
      @include swap_direction(padding, 0 0 0 23px);
      margin: 0 auto;
      width: 960px;
    }
    #ff_header_container {
      height: 200px;
      background: url('/media/images/foundation_finder/bg_header_ff_results.png') repeat scroll 0 0
        rgba(0, 0, 0, 0);
      @include swap_direction(margin, 40px 0 60px 0);
      #ff-header {
        margin-bottom: 35px;
        h1 {
          font-size: 36px;
          color: $color-btn-green;
        }
        h2 {
          font-size: 22px;
          color: $color-text-black;
          text-transform: capitalize;
          span {
            text-transform: none;
          }
          .product-name {
            color: $color-btn-green;
          }
        }
      }
      .ff_social {
        .ff_save {
          @include ff_options;
        }
        .ff_retake {
          @include ff_options;
        }
        .ff_social_share {
          li {
            width: 30px;
            float: #{$ldirection};
            a {
              display: block;
              height: 25px;
            }
            &.ff_facebook {
              a {
                background: url('/media/images/foundation_finder/facebook_icon.png') no-repeat
                  scroll 5px 5px rgba(0, 0, 0, 0);
              }
            }
            &.ff_twitter {
              a {
                background: url('/media/images/foundation_finder/twitter_icon.png') no-repeat scroll
                  5px 5px rgba(0, 0, 0, 0);
              }
            }
            &.ff_pinterest {
              a {
                background: url('/media/images/foundation_finder/pinterest_icon.png') no-repeat
                  scroll 5px 5px rgba(0, 0, 0, 0);
              }
            }
          }
        }
      }
    } /* #ff_header_container */

    #product-results {
      padding-#{$ldirection}: 23px;
      margin: 0 auto;
      width: 960px;
      &.ff_account_page {
        padding: 40px 0 0 0 !important;
        .prod_description_header {
          display: none;
        }
      }
      .image {
        float: #{$ldirection};
        width: 40%;
        margin-#{$rdirection}: 20px;
        position: relative;
        .prod_image {
          display: block;
          height: auto;
          width: 375px;
        }
        .rating {
          color: $color-btn-green;
          position: relative;
          top: 10px;
          #product_rating_image {
            height: auto;
            vertical-align: text-top;
          }
          .review-count {
            margin-#{$rdirection}: 10px;
          }
        }
      }
      .prod_details {
        float: #{$ldirection};
        width: 55%;
        dl.prod_attribute_list {
          dt {
            float: #{$ldirection};
            font-weight: normal;
            margin-#{$rdirection}: 5px;
            text-transformation: none;
          }
          dd {
            font-weight: bold;
          }
        }
        .prod_return {
          display: none;
        }
        .add-to-bag {
          .prod_price {
            font-size: 16px;
            font-family: $helvetica-light-font-family;
            margin-bottom: 10px;
          }
          .selected_shade {
            font-size: 16px;
            font-family: $helvetica-light-font-family;
            margin-bottom: 10px;
          }
        }
        .shades {
          .results-header {
            font-size: 24px;
            font-family: $helvetica-light-font-family;
          }
          .results {
            margin: 40px 0;
            .shade {
              float: #{$ldirection};
              height: auto;
              margin-#{$rdirection}: 30px;
              position: relative;
              width: 110px;
              &.active {
                border: none;
                .shade-content {
                  border: 1px solid #bdbdbd;
                }
              }
              .shade-content {
                height: 130px;
                margin-bottom: 10px;
              }
              .search-swatch-container {
                position: relative;
                .shade_ol {
                  display: block;
                  width: 100px;
                  height: 100px;
                  z-index: 4;
                  position: relative;
                }
                .first.last.single {
                  position: absolute;
                  width: 100px;
                  height: 100px;
                  top: 0;
                }
              }
              .shade-name {
                text-align: center;
                margin-top: 5px;
              }
              img {
                display: none;
              }
            }
          }
        }
      } /* prod_details */
    } /* #product-results */
  } /* .ff_spp_product */

  #works-with-results {
    padding-#{$ldirection}: 23px;
    overflow: auto;
    .results-header {
      float: #{$ldirection};
      width: 34%;
      h2 {
        color: $color-text-black;
      }
      .additional_products {
        color: $color-text-black;
        font-size: 22px;
        font-family: $helvetica-light-font-family;
        line-height: 22px;
      }
    }
    .results {
      float: #{$rdirection};
      width: 64%;
      .result {
        float: #{$ldirection};
        width: 175px;
        margin-#{$rdirection}: 15px;
        .image {
          width: 150px;
          height: 150px;
          margin-bottom: 20px;
          a {
            img {
              width: 150px;
            }
          }
        }
        .prod_details {
          .spp,
          .rating,
          .shades {
            margin-bottom: 5px;
          }
          .shade {
            overflow: auto;
            margin-top: 10px 0;
            .search-swatch-container {
              float: #{$ldirection};
              @include swap_direction(margin, 2px 10px 0 0);
              width: 40%;
            }
            .shade-name {
              float: #{$ldirection};
              width: 50%;
            }
          }
        }
      }
    }
  } /* works-with-results */
} /* #ff_spp */

h2.according_to_ff {
  color: $color-text-black;
  span {
    color: $color-cl-green;
    display: inline-block;
  }
}
