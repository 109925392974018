.mix-and-match {
  margin: 20px auto;
  &--formatter {
    padding: 30px 0 0;
    .basic-responsive-v1 {
      display: none;
    }
  }
  &--slider {
    -ms-touch-action: auto;
    touch-action: auto;
    *.slick-slider {
      -ms-touch-action: auto;
      touch-action: auto;
    }
    margin: 0 auto;
    padding-bottom: 30px;
    max-width: 900px;
    .slick-prev {
      @include arrow-prev(30px, $color-black);
      color: $color-black;
      [dir='rtl'] & {
        // Swap direction and side on RTL
        @include arrow-next(30px, $color-black);
      }
    }
    .slick-next {
      @include arrow-next(30px, $color-black);
      color: $color-black;
      [dir='rtl'] & {
        // Swap direction and side on RTL
        @include arrow-prev(30px, $color-black);
      }
    }
    .slick-next,
    .slick-prev {
      &.slick-disabled {
        cursor: default;
      }
      &.slick-disabled:after,
      &.slick-disabled:before {
        opacity: 0.25;
      }
    }
  }
  &--step--slider {
    @include start-breakpoint($medium-min) {
      width: 100%;
      max-width: none;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 30px;
    }
    max-width: 250px;
    &.slick-slider {
      margin-bottom: 0;
    }
    .slick-prev {
      @include arrow-prev(30px, $color-black);
      color: $color-black;
      [dir='rtl'] & {
        // Swap direction and side on RTL
        @include arrow-next(30px, $color-black);
      }
    }
    .slick-next {
      @include arrow-next(30px, $color-black);
      color: $color-black;
      [dir='rtl'] & {
        // Swap direction and side on RTL
        @include arrow-prev(30px, $color-black);
      }
    }
    .slick-prev,
    .slick-next {
      &.slick-disabled:before,
      &.slick-disabled:after {
        display: none;
      }
    }
  }
  &--item {
    @include start-breakpoint($medium-min) {
      padding-top: 0;
    }
    width: 200px;
    position: relative;
    padding-top: 20px;
    outline: none;
    margin: 0 auto;
    &-unavailable--wrapper {
      display: none;
    }
    &.unavailable > * {
      cursor: not-allowed;
      opacity: 0.5;
      &.mix-and-match--item-unavailable--wrapper {
        display: block;
      }
      &.mix-and-match--select--btn {
        display: none;
      }
    }
    &.disabled:not(.unavailable) {
      & > * {
        opacity: 0.5;
      }
      &:hover * {
        &.mix-and-match--item--details--wrapper,
        &.mix-and-match--item--details--wrapper > * {
          opacity: 1;
        }
        @include start-breakpoint($medium-min) {
          opacity: 1;
        }
      }
    }
    &--container {
      @include start-breakpoint($medium-min) {
        flex-direction: row;
        justify-content: flex-end;
      }
      display: flex;
      flex-direction: column;
      .mix-and-match--item {
        &:first-child {
          @include start-breakpoint($medium-min) {
            order: initial;
          }
          order: 2;
        }
        &:last-child {
          @include start-breakpoint($medium-min) {
            order: initial;
          }
          order: 1;
        }
      }
    }
  }
  .mix-and-match--title {
    @include start-breakpoint($medium-min) {
      margin-bottom: 15px;
    }
    flex: 0 1 100%;
    h3 {
      @include start-breakpoint($medium-min) {
        font-size: 60px;
        line-height: 59px;
        padding-#{$ldirection}: 0;
      }
      color: $color-black;
      padding-#{$ldirection}: 30px;
      font-size: 40px;
      line-height: 39px;
      font-family: $helvetica-bold-font-family;
      &.mix-and-match--item--final--title {
        max-width: 80%;
        font-size: 20px;
        line-height: 19px;
      }
    }
    .mix-and-match--item--final--price {
      padding-#{$ldirection}: 30px;
    }
  }
  &--item--title {
    margin-top: -20px;
    h4 {
      @include start-breakpoint($medium-min) {
        font-size: 16px;
        text-align: #{$ldirection};
      }
      text-align: center;
      color: $color-black;
      font-family: $helvetica-bold-font-family;
    }
    &.unavailable {
      .mix-and-match--item-unavailable--wrapper {
        display: block;
      }
      cursor: not-allowed;
    }
  }
  &--item-base {
    @include start-breakpoint($medium-min) {
      min-height: 400px;
    }
    min-height: 430px;
    width: 150px;
    .mix-and-match--item--image-small {
      width: 70%;
    }
    .mix-and-match--item--details {
      &--wrapper {
        @include start-breakpoint($medium-min) {
          #{$ldirection}: -10%;
          height: 170px;
        }
        p {
          max-width: 100px;
        }
      }
    }
  }
  &--item--final {
    &--price {
      @include start-breakpoint($medium-min) {
        font-size: 20px;
        margin-bottom: 20px;
      }
      font-size: 25px;
    }
  }
  &--item-base:not(.unavailable):hover,
  &--item-base.selected,
  &--item-base.highlight,
  &--item-option:not(.unavailable):hover,
  &--item-option.selected,
  &--item-option.highlight {
    @include start-breakpoint($medium-min) {
      transform: scale(1.1);
      z-index: 1;
      &.first {
        transform-origin: 0% 50%;
      }
      &.last {
        transform-origin: 100% 50%;
      }
      h4 {
        font-family: $helvetica-bold-font-family;
        font-weight: 600;
      }
      .mix-and-match--select--btn {
        cursor: pointer;
        display: inline-block;
      }
    }
  }
  &--item--wrapper {
    outline: none;
    &.slick-slide {
      margin-bottom: 0;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      img.hidden {
        display: none;
      }
    }
    &--final {
      &.slick-slide {
        @include start-breakpoint($medium-min) {
          flex-direction: row;
        }
        flex-direction: column;
        justify-content: flex-end;
      }
      .mix-and-match--item {
        @include start-breakpoint($medium-min) {
          width: 150px;
          margin: 0;
        }
        width: 250px;
        padding-top: 0;
        margin-top: -35px;
      }
      .mix-and-match--item ~ .mix-and-match--item {
        @include start-breakpoint($medium-min) {
          margin-#{$ldirection}: 50px;
          padding-top: 100px;
        }
        width: 300px;
        margin-#{$ldirection}: 30px;
        margin-top: -10px;
        h3 {
          color: $color-black;
          font-family: $helvetica-bold-font-family;
          font-size: 20px;
        }
      }
      .mix-and-match--item--details {
        &--wrapper {
          @include start-breakpoint($medium-min) {
            #{$ldirection}: -170px;
            top: 27%;
            width: 300px;
          }
          top: calc(20% - 30px);
          p {
            font-size: 16px;
            line-height: 18px;
            max-width: 140px;
          }
        }
      }
      .mix-and-match--item--details--btn {
        @include start-breakpoint($medium-min) {
          top: 26%;
          #{$rdirection}: -12%;
        }
        top: 27%;
        #{$rdirection}: 30px;
      }
    }
  }
  &--item-option {
    @include start-breakpoint($medium-min) {
      max-width: 70px;
    }
    .mix-and-match--item-unavailable--wrapper {
      display: none;
    }
    .mix-and-match--item--image,
    .mix-and-match--item--image-small {
      max-width: 90px;
      margin: 0 auto;
    }
    .mix-and-match--item--details {
      &--wrapper {
        @include start-breakpoint($medium-min) {
          #{$ldirection}: -70%;
        }
      }
      &--btn {
        @include start-breakpoint($medium-min) {
          #{$rdirection}: -12px;
          top: -12px;
        }
        #{$rdirection}: 75px;
        top: 8px;
      }
    }
    &.first {
      .mix-and-match--item--details {
        &--wrapper {
          @include start-breakpoint($medium-min) {
            #{$ldirection}: -40%;
          }
        }
      }
    }
    &.last {
      .mix-and-match--item--details {
        &--wrapper {
          @include start-breakpoint($medium-min) {
            #{$ldirection}: -80%;
          }
        }
      }
    }
  }
  &--item--image,
  &--item--image-small,
  &--item--image-final {
    @include start-breakpoint($medium-min) {
      width: 100%;
    }
    width: 53%;
    margin: 0 auto;
    min-height: 300px;
  }
  &--item--image--container {
    @include start-breakpoint($medium-min) {
      height: 460px;
      width: 300px;
      padding: 70px;
      margin: 0 0 25px 0;
      border: 1px solid $color-white;
    }
    height: 410px;
    width: 250px;
    padding: 20px 0 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 25px;
    text-align: center;
    &:hover {
      border: 1px solid $color-black;
    }
  }
  &--item--details {
    &--wrapper {
      @include start-breakpoint($medium-min) {
        top: 15%;
        height: 200px;
        width: 180px;
      }
      position: absolute;
      padding: 10px;
      background-color: $color-white;
      top: 0;
      #{$ldirection}: 5%;
      width: 90%;
      height: 80%;
      background-position: center center;
      color: $color-white;
      font-family: $helvetica-light-font-family;
      p {
        font-family: $helvetica-light-font-family;
        margin-#{$ldirection}: 10px;
        strong {
          font-weight: 600;
        }
      }
    }
    &--text {
      max-width: 90%;
    }
    &--btn {
      @include start-breakpoint($medium-min) {
        #{$rdirection}: 5%;
        top: -10px;
        line-height: 10px;
      }
      padding: 6px;
      position: absolute;
      #{$rdirection}: 22%;
      top: 1%;
      border: 1px solid $color-black;
      border-radius: 31px;
      font-size: 30px;
      line-height: 20px;
      height: 31px;
      width: 31px;
      cursor: pointer;
    }
    &--close-btn {
      cursor: pointer;
      position: absolute;
      #{$rdirection}: 10px;
      top: 0;
      font-size: 24px;
    }
  }
  a.mix-and-match--cta,
  &--select--btn {
    height: auto;
    background-color: $color-black;
    color: $color-white;
    text-transform: uppercase;
    line-height: 1.5;
    font-size: 0.9em;
    text-align: center;
    letter-spacing: 0;
    border: 0;
    padding: 4px 10px 3px;
    &:hover {
      opacity: 0.8;
    }
    &.disabled,
    &.disabled:hover {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  a.mix-and-match--cta {
    @include start-breakpoint($medium-min) {
      order: initial;
      width: auto;
    }
    order: 3;
    margin: 0 auto;
    width: 300px;
    cursor: pointer;
    padding: 4px 25px 5px;
    font-size: 1.1em;
  }
  &--select--btn {
    @include start-breakpoint($medium-min) {
      margin: 0;
      display: none;
      max-width: none;
    }
    padding: 5px 25px;
    font-size: 1em;
    max-width: 90%;
    margin: 0 auto;
  }
  &--slider--dots {
    @include start-breakpoint($medium-min) {
      padding-#{$ldirection}: 0;
    }
    padding-#{$ldirection}: 30px;
    margin: 0 auto;
    max-width: 900px;
    display: flex;
    align-items: center;
    font-size: 30px;
    * {
      opacity: 0.5;
    }
    .mix-and-match--slider--icon {
      align-self: flex-end;
      &.selected,
      &.selected ~ span,
      &.selected.active ~ span {
        opacity: 1;
      }
      &.active ~ span {
        opacity: 0.5;
      }
      &.active {
        opacity: 1;
        border-bottom: 3px solid $color-black;
        margin-bottom: -8px;
        padding-bottom: 5px;
      }
      &.loaded {
        cursor: pointer;
      }
    }
    &.hidden {
      display: none;
    }
    span {
      @include swap_direction(margin, 0 5px -15px 10px);
    }
  }
}

.container-padding {
  @include swap_direction(padding, 30px 150px 0 150px);
}

.mix-and-match--grid-view {
  .mix-and-match {
    &--slider {
      max-width: none;
    }
    &--step--slider {
      @include start-breakpoint($medium-min) {
        &[data-is-carousel='1'] {
          max-width: 90%;
        }
        justify-content: space-evenly;
        max-width: 100%;
      }
      &.slick-slider {
        margin: 0 auto;
      }
      max-width: 310px;
    }
    &--item {
      @include start-breakpoint($medium-min) {
        padding-top: 0;
        width: 300px;
        &.slick-slide {
          img.hidden {
            display: none;
          }
        }
        &-unavailable--wrapper {
          margin-#{$ldirection}: 25px;
        }
      }
      width: 170px;
      &.unavailable {
        pointer-events: none;
      }
      &--container {
        @include start-breakpoint($medium-min) {
          display: flex;
          margin: 0 auto;
        }
        display: inline-block;
        width: 180%;
        max-width: 700px;
        margin-top: 50px;
      }
      &--title {
        @include start-breakpoint($medium-min) {
          margin: 0;
        }
        @include swap_direction(margin, 0 0 0 25px);
        font-weight: 600;
        h4 {
          font-size: 16px;
          text-align: #{$ldirection};
          font-weight: 600;
        }
      }
      &-base {
        @include start-breakpoint($medium-min) {
          min-height: 500px;
          max-width: 400px;
          width: auto;
          display: flex;
          flex-direction: column;
        }
      }
      &--final {
        &--price {
          margin: 67px 0 20px;
          font-weight: 600;
        }
        &--text {
          width: 235px;
        }
      }
      &--text-wrapper {
        height: 150px;
      }
    }
    .mix-and-match--select--btn {
      display: block;
    }
    &.unavailable > * {
      &:hover {
        border: none;
      }
    }
    &--title {
      h3 {
        @include start-breakpoint($medium-min) {
          font-size: 30px;
          padding-#{$ldirection}: 80px;
        }
        font-size: 20px;
        line-height: 25px;
        &.mix-and-match--item--final--title {
          font-size: 40px;
          @include start-breakpoint($medium-min) {
            font-size: 20px;
          }
        }
      }
    }
    &--item--title {
      @include start-breakpoint($medium-min) {
        margin: 0;
      }
      @include swap_direction(margin, 0 0 0 25px);
      font-weight: 600;
      h4 {
        font-size: 16px;
        text-align: #{$ldirection};
        font-weight: 600;
      }
    }
    &--item--wrapper--final {
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    &--item-base:not(.unavailable):hover,
    &--item-base.selected,
    &--item-base.highlight,
    &--item-option:not(.unavailable):hover,
    &--item-option.selected,
    &--item-option.highlight {
      @include start-breakpoint($medium-min) {
        transform: initial;
        z-index: auto;
        &.first {
          transform-origin: initial;
        }
        &.last {
          transform-origin: initial;
        }
      }
    }
    &--item--wrapper {
      @include start-breakpoint($medium-min) {
        margin-#{$ldirection}: 60px;
      }
      border-top: 1px solid $color-light-grey;
      float: none;
      margin-top: 0;
      &--base {
        @include start-breakpoint($medium-min) {
          display: flex;
          justify-content: space-around;
          flex-direction: row;
          flex-wrap: wrap;
        }
        border: none;
      }
      &--option {
        .mix-and-match--step--slider {
          @include start-breakpoint($medium-min) {
            margin-#{$ldirection}: 3%;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
          }
          @media #{$xlargexxl-up} {
            margin-#{$ldirection}: 5%;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
          }
        }
      }
      &--final {
        @include start-breakpoint($medium-min) {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          width: 100%;
        }
        .mix-and-match--item--container {
          .mix-and-match--item ~ .mix-and-match--item {
            @include start-breakpoint($medium-min) {
              margin-top: 0;
            }
            @include swap_direction(margin, -260px 0 0 25px);
            float: #{$ldirection};
          }
        }
        .mix-and-match--item ~ .mix-and-match--item {
          width: 300px;
          margin-#{$ldirection}: 30px;
        }
        &--item--image--container {
          @include start-breakpoint($medium-min) {
            height: 460px;
            width: 300px;
            padding: 70px;
            margin: 0 0 25px 0;
          }
          height: 410px;
          width: 250px;
          padding: 20px 0 0;
          display: flex;
          align-items: center;
          margin: 0 auto;
          margin-bottom: 25px;
          text-align: center;
          border: 1px solid $color-white;
          &:hover {
            border: 1px solid $color-black;
          }
        }
      }
    }
    &--slider--dots {
      @include start-breakpoint($medium-min) {
        @include swap_direction(padding, 10px 0 30px 90px);
      }
      @include swap_direction(padding, 10px 0 30px 30px);
      flex: 0 1 100%;
      max-width: none;
      &.hidden {
        display: none;
      }
      * {
        opacity: 1;
      }
    }
    &.slick-slide {
      justify-content: center;
    }
    .mix-and-match--item {
      @include start-breakpoint($medium-min) {
        @include swap_direction(margin, 0 15px 40px 0);
        width: 325px;
      }
    }
    .mix-and-match--item ~ .mix-and-match--item {
      @include start-breakpoint($medium-min) {
        padding-top: 0;
      }
      margin-top: 0;
    }
    h3 {
      &.mix-and-match--item--final--subtitle {
        font-size: 15px;
        margin: 0;
      }
    }
  }
  .mix-and-match--item-option {
    @include start-breakpoint($medium-min) {
      min-height: initial;
      display: block;
    }
    max-width: 500px;
    min-height: 500px;
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .mix-and-match--item--image,
  .mix-and-match--item--image-small,
  .mix-and-match--item--image-final {
    @include start-breakpoint($medium-min) {
      width: 170px;
    }
    width: 70%;
    &.hidden {
      display: none;
    }
  }
  .mix-and-match--item--image-small {
    width: 55%;
  }
  .mix-and-match--item--details {
    &--text {
      @include start-breakpoint($medium-min) {
        margin: 0 0 42px 0;
      }
      @include swap_direction(margin, 0 0 30px 25px);
      height: 60px;
      font-size: 16px;
      p {
        margin: 0;
      }
    }
  }
  .mix-and-match--item--final--choices {
    margin-#{$rdirection}: 70px;
    p {
      font-size: 15px;
    }
    img {
      float: #{$rdirection};
      margin-top: -60px;
      margin-#{$rdirection}: -50px;
    }
  }
  .mix-and-match--item--selected {
    border: 1px solid $color-black;
  }
  .mix-and-match--item--tick {
    @include start-breakpoint($medium-min) {
      top: 20px;
      #{$ldirection}: 20px;
    }
    background: url(/media/export/cms/mix_and_match/icons/checked.png);
    width: 30px;
    height: 30px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    #{$ldirection}: 60px;
    top: 30px;
  }
  a.mix-and-match--cta,
  .mix-and-match--select--btn {
    background-color: $color-white;
    text-transform: none;
    text-align: #{$ldirection};
    color: $color-black;
    border: none;
    padding: 0;
  }
  a.mix-and-match--cta {
    @include start-breakpoint($medium-min) {
      width: 300px;
    }
    background: $color-black;
    padding: 0;
    letter-spacing: 2px;
    width: 100%;
    font-size: 12px;
    height: 37px;
    line-height: 41px;
    color: $color-white;
    text-align: center;
  }
  .mix-and-match--select--btn {
    @include start-breakpoint($medium-min) {
      display: block;
      margin: 0;
    }
    @include swap_direction(margin, 0 0 0 25px);
    text-transform: uppercase;
  }
  .mix-and-match--editorial--description {
    h3 {
      @include start-breakpoint($medium-min) {
        font-size: 20px;
      }
      font-size: 16px;
    }
  }
  .mix-and-match--editorial,
  .mix-and-match--editorial--items {
    @include start-breakpoint($medium-min) {
      padding-#{$ldirection}: 30px;
      justify-content: space-around;
      width: 100%;
    }
    -ms-touch-action: auto;
    touch-action: auto;
    opacity: 1;
    padding-#{$ldirection}: 10px;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    margin: 0 auto;
    padding-bottom: 30px;
    &--description {
      @include start-breakpoint($medium-min) {
        margin: 0 50px 15px;
      }
      @include swap_direction(margin, 0 0 15px 35px);
      font-size: 15px;
      opacity: 1;
      width: 182px;
      h3 {
        @include start-breakpoint($medium-min) {
          font-size: 20px;
          margin: 0;
          position: unset;
          font-family: $helvetica-bold-font-family;
          width: 100%;
        }
        font-family: $font-helvetica65-medium-verdana;
        color: $color-black;
        position: absolute;
        width: 30%;
        margin-#{$ldirection}: -20%;
        margin-top: 47px;
        font-size: 16px;
      }
    }
    .mix-and-match--editorial--icon {
      @include start-breakpoint($medium-min) {
        height: 120px;
        width: 60px;
        margin-#{$ldirection}: 50px;
      }
      height: 70px;
      width: 35px;
      margin-#{$ldirection}: 0;
    }
    span {
      @include start-breakpoint($medium-min) {
        @include swap_direction(margin, 0 14px 0 -10px);
      }
      @include swap_direction(margin, 0 15px 0 -25px);
    }
  }
  .mix-and-match--editorial--items {
    @include start-breakpoint($medium-min) {
      width: auto;
      margin: 0 auto;
    }
    width: 58%;
    margin: 0;
  }
  .image-fit-content {
    width: 290px;
    height: 450px;
    max-width: none;
  }
  .no-padding {
    padding: 0;
  }
  .underlined {
    @include start-breakpoint($medium-min) {
      padding: 0 0 3px;
    }
    padding: 0 0 1px;
    border-bottom: 1.2px solid $color-btn-gray;
  }
}
