body.loyalty-marketing-page {
  .loyalty_mrkt_ldng {
    div.node-elc-nodeblock:first-child {
      width: 100%;
    }
    .btn_sign_in {
      &:active {
        color: $color-black;
      }
    }
  }
}

#cboxContent {
  .loyalty_popover {
    .loyalty_popover__content {
      .loyalty_popover__welcome-option {
        .loyalty_popover__header {
          margin: 10px 0 0 18px;
        }
      }
    }
  }
  .loyalty_popover.loyalty_popover--response.invalid_used {
    .loyalty_popover__welcome {
      h4.loyalty_popover__header {
        margin-top: 2px;
      }
      div.loyalty_popover__margin {
        h3.loyalty_popover__sub-header {
          font-size: 16px;
        }
        div.loyalty_popover__choose {
          font-size: 16px;
        }
      }
    }
  }
}

div.loyalty_mrkt_ldng.not_loyalty_member {
  .loyalty_sticky_nav__input {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    input#loyalty_account_sticky__checkbox {
      display: none;
    }
    .loyalty_popover__checkbox-container {
      .form-checkbox + label.styled-checkbox {
        border: 1px solid $color-light-grey;
        display: inline-block;
        position: relative;
        vertical-align: bottom;
        width: 12px;
        height: 12px;
      }
      .form-checkbox:checked + label.styled-checkbox::after {
        background-color: $color-cl-green-2;
        color: $color-white;
        content: '✓';
        font-size: 10px;
        height: 12px;
        left: 0px;
        line-height: 12px;
        position: absolute;
        top: -2px;
        width: 10px;
        padding: 0 0 0 2px;
      }
      label.for-styled-checkbox {
        display: inline;
        float: right;
        font-family: $base-font-family;
        font-size: 12px;
        line-height: 12px;
        margin-left: 0;
        width: 95%;
      }
    }
  }
  .loyalty_join_now {
    width: 80%;
  }
}

.logged-in .loyalty-nav__terms .twocol .gnav-loyalty-join-block {
  margin: 0px;
  .image-wrapper {
    margin-top: 0px;
  }
}

.loyalty__nav {
  clear: both;
  .post_signing_error {
    margin: 0px;
  }
}

.loyalty__popup,
.loyalty__nav {
  .post_signing_error {
    font-size: 12px;
  }
  .loyalty_post_signin_popover__input .loyalty_popover__checkbox-container,
  .loyalty_my_account_auth__input .loyalty_popover__checkbox-container {
    left: 4%;
    top: 10px;
    width: 90%;
    font-size: 12px;
    color: $color-black;
    position: relative;
    .form-checkbox {
      display: none;
    }
    .form-checkbox + label.styled-checkbox {
      border: 1px solid $color-light-grey;
      display: inline-block;
      position: relative;
      vertical-align: bottom;
      width: 12px;
      height: 12px;
    }
    .form-checkbox:checked + label::after {
      background-color: $color-cl-green-2;
      color: $color-white;
      content: '✓';
      font-size: 10px;
      height: 12px;
      left: 0px;
      line-height: 12px;
      position: absolute;
      top: -2px;
      width: 10px;
      padding: 0 0 0 2px;
    }
    label.for-styled-checkbox {
      display: inline;
      float: right;
      font-family: $base-font-family;
      font-size: 12px;
      line-height: 12px;
      margin-left: 0;
      width: 93%;
    }
  }
}

#review-panel {
  .review-panel__loyalty-points {
    border-bottom: 0;
    padding: 0 0 15px;
    .review-panel__loyalty-points__description {
      float: left !important;
      padding: 0 20px 0 0;
      width: 100%;
    }
  }
}

#navigation {
  .nav-menu {
    .mm-formatter-v1 .mm-menu li span.title {
      padding: 0 6px;
    }
  }
}

#gnav_signin,
#main {
  .signin_container {
    .inner-block {
      #new-account,
      .register {
        .loyalty__block {
          background: url(/media/export/cms/clinique_loyalty/5_19_CL_enrollgnav_register_v3.png) left bottom;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

body {
  &.loyalty_offer_signup {
    div#colorbox {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
  &#landing.is-loyalty-member {
    .account.breadcrumb {
      padding-bottom: 80em;
    }
  }
}

#loyalty__page__status {
  top: 250px;
  #loyalty__panel__offers__sku {
    .flex-viewport {
      .slides {
        img {
          max-height: 158px;
        }
      }
    }
    .flex-direction-nav {
      .flex-next {
        @include breakpoint-range($medium-l, $medium-max) {
          right: 0;
        }
      }
    }
  }
}

#loyalty_marketing_points_sections {
  #points_perks {
    .pointsbar {
      width: 100%;
    }
  }
}

body.device-mobile {
  .account-section.loyalty-page {
    .loyalty-page__content {
      #loyalty__panel__offers__sku {
        ul.slides li img {
          width: auto;
        }
      }
    }
  }
}

#offer-code-panel {
  .checkout_offer_flexslider.carousel {
    li {
      margin: 0;
      div {
        margin: 0 20px;
        img {
          margin: 0 auto;
          max-width: 90%;
          width: 90%;
        }
      }
      &.not_meet {
        .checkout__offer-code__wallet-item__image-container img,
        .checkout__offer-code__wallet-item__inner {
          opacity: 0.5;
        }
      }
    }
    .flex-direction-nav {
      a {
        height: auto;
        margin: 0;
        top: 21%;
        width: 7px;
      }
    }
    .flex-control-paging {
      bottom: auto;
      top: 225px;
      width: 90%;
      li {
        height: 10px;
        margin: 0 1px;
        width: 10px;
      }
    }
    @media only screen and (max-width: $small-l) and (orientation: portrait) {
      .flex-control-paging {
        top: 70%;
      }
    }
  }
}

.offer-code-wallet__item {
  text-align: center;
  .checkout__offer-code__wallet-item__image-container {
    margin-bottom: 16px;
    padding: 0;
    @media #{$medium-up} {
      margin-bottom: 35px;
    }
  }
  .checkout__offer-code__wallet-item__inner {
    float: none;
    padding: 40px 0 20px 0;
    width: auto;
  }
  .checkout__offer-code__wallet-item__image {
    display: inline-block;
    width: 35%;
    @media #{$medium-up} {
      width: 144px;
    }
  }
  .checkout__offer-code__wallet-item__header {
    font-size: 21px;
    @media #{$medium-up} {
      font-size: 22px;
    }
  }
  .checkout__offer-code__wallet-item__description {
    font-size: 15px;
    line-height: 23px;
    @media #{$medium-up} {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .loyalty__panel__offers__offer-code-wrap {
    @media #{$medium-up} {
      font-size: 12px;
      letter-spacing: 0.15em;
      margin: 6px 0;
      text-transform: uppercase;
      .loyalty__panel__offers__offer-code {
        font-weight: bold;
      }
    }
  }

  // buttons
  .checkout__offer-code__wallet-item__button-container {
    margin-top: 20px;
    position: relative;
    @media #{$medium-up} {
      text-align: center;
    }
  }
  &.is-applied {
    .offer-code-wallet__button--apply {
      display: none;
    }
    .offer-code-wallet__button--remove {
      display: inline-block;
    }
    .checkout__offer-code__wallet-selected {
      display: inline-block;
    }
    .checkout__offer-code__wallet-item__promo-applied {
      display: block;
      font-size: 12px;
      margin-top: 20px;
    }
  }
  .checkout__offer-code__wallet-item__button-loader {
    background: url(/media/images/account/loyalty/ajax-loader.gif) no-repeat center center;
    display: none;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .offer-code-wallet__button--apply,
  .offer-code-wallet__button--remove {
    background-color: $color-cl-green-2;
    border: 1px solid $color-white;
    color: $color-white;
    display: inline-block;
    font-family: $font-helvetica65-medium-verdana;
    height: 33px;
    line-height: 33px;
    margin-top: 5px;
    padding: 0 10px;
    text-transform: uppercase;
  }
  .offer-code-wallet__button--remove {
    background-color: $color-white;
    border: 1px solid $color-cl_green-2;
    color: $color-cl-green-2;
    display: none;
  }
  .checkout__offer-code__wallet-selected {
    display: none;
    pointer-events: none;
  }
  .checkout__offer-code__wallet-item__promo-applied {
    display: none;
  }
  .checkout__offer-code__wallet-item__link-container {
    margin-top: 20px;
  }
  .checkout__offer-code__wallet-item__link {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-decoration: underline;
    text-transform: uppercase;
  }
}

.multi {
  #loyalty-enrollment {
    padding-left: 1em;
    background: url('/media/export/cms/clinique_loyalty/order-confirmation-box.jpg') right 30px/37% no-repeat !important;
    @media screen and (max-width: 768px) {
      background: none !important;
      padding-left: 0;
      border-top: none !important;
    }
    #info {
      a {
        color: $cr19-text-grey;
      }
      .h_loyalty {
        color: $color-black;
      }
    }
  }
  .loyalty_join {
    padding: 0;
    width: 55%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    #form--loyalty_join--submit-confirm {
      background-color: $color-black;
    }
  }
}

.account-loyalty {
  .section-content {
    .loyalty__panel__points__bg {
      &__slide1,
      &__slide2,
      &__slide3 {
        position: unset;
      }
      &__slide-container {
        border: none;
      }
    }
    .tier-point-msg {
      &.next-tier-points-msg,
      &.next-anniversary-msg {
        margin: 50px 0 0;
      }
    }
  }
}
