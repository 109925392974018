.promo_product_row {
  // background: transparent url($template_base_path + '/three-step_row_v1/bg-three-step-row.png') center 230px no-repeat;
  border: 1px solid transparent;
  clear: both;
  overflow: hidden;
  @include swap_direction(padding, 10px 0 0 0);
  position: relative;
  height: 410px;
  &:hover {
    border-color: #231f20;
  }
  &.power-couples-row-v1,
  &.three-step-row-v1 {
    padding: 10px 0;
    height: auto;
  }
  .row-info {
    float: #{$ldirection};
    padding: 0 1% 0 5.5%;
    width: 40%;
    h3 {
      color: #1a1a1a;
      font-size: 36px;
      line-height: 1;
      @include swap_direction(margin, 80px 0 0 0);
      .three-step-formatter-rows &,
      .power-couples-formatter-rows & {
        margin-top: 40px;
      }
    }
    .subheading {
      color: #1a1a1a;
      font-size: 22px;
      font-family: $helvetica-light-font-family;
      line-height: 1;
      @include swap_direction(margin, 10px 0 0 0);
    }
    .button {
      font-size: 18px;
      font-weight: bold;
      @include swap_direction(margin, 45px 0 65px 0);
    }
  }
  .row-products {
    float: #{$ldirection};
    padding: 0 5.5% 0 0;
    width: 60%;
  }
  .product {
    border: 1px solid transparent;
    color: #808080;
    float: #{$ldirection};
    font-size: 13px;
    margin: 0;
    @include swap_direction(padding, 45px 0 10px 0);
    width: 33%;
    position: relative;
    .three-step-formatter-rows &,
    .power-couples-formatter-rows & {
      padding: 10px 0;
      .product-image {
        margin-bottom: 0;
      }
      .btn-favorite,
      .btn-shopnow,
      .btn-quickview {
        display: block;
        visibility: hidden;
      }
      .btn-shopnow {
        margin-bottom: 0;
      }
      &:hover {
        .btn-favorite,
        .btn-shopnow,
        .btn-quickview {
          visibility: visible;
        }
      }
    }
    .badge {
      position: absolute;
      top: 35px;
      #{$ldirection}: 18px;
      display: table;
      width: 60px;
      height: 60px;
      text-align: center;
      @include border-radius(50%);
      color: #fff;
      z-index: 1;
      padding: 0;
      line-height: 11px;
      font-family: $helvetica-light-font-family;
      vertical-align: middle;
      margin: 0;
      font-size: 11px;
      text-transform: uppercase;
      text-align: center;
      &.badge_1,
      &.badge_2,
      &.badge_3,
      &.badge_4,
      &.badge_5,
      &.badge_92,
      &.badge_94,
      &.badge_96,
      &.badge_97 {
        background: #fe6cb5;
      }
      /* for longer badge names */
      &.badge_96 {
        font-size: 12px;
      }
      /*
      &.badge_30 {
        background: $color-cl-green-2;
      }
      */
      html.ie7 & {
        text-align: center;
      }
      .badge-inner {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        html.ie7 & {
          position: relative;
          margin-top: inherit;
          *clear: expression(
            style.marginTop = '' +
              (
                offsetHeight < parentNode.offsetHeight ?
                  parseInt((parentNode.offsetHeight - offsetHeight) / 2) + 'px': '0'
              ),
            style.clear = 'none',
            0
          );
        }
      }
    }
    .btn-favorite,
    .btn-shopnow,
    .btn-quickview {
      display: none;
    }
    .btn-shopnow {
      @include swap_direction(margin, 10px 0px 10px 20%);
    }
    .btn-quickview {
      position: absolute;
      #{$ldirection}: 20%;
      top: 160px;
      z-index: 100;
      background-color: #fff;
      border: solid 1px #808080;
      font-weight: normal;
      color: #808080;
    }
    .btn-favorite {
      position: absolute;
      top: 7px;
      #{$rdirection}: 7px;
      height: 25px;
      width: 25px;
      background: url($img_base_path + '/btn-favorite-sprite.png') no-repeat 0px 0px;
    }
    .product-image {
      @include swap_direction(margin, 0 0 10px 0);
      text-align: center;
      img {
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        width: auto;
      }
    }
    .product-info {
      padding: 0 20%;
    }
    .product-title,
    .product-subline,
    .product-subheading {
      color: $product-color-title;
      min-height: 34px;
    }
    .product-price {
      color: $product-color-price;
    }
    &:hover {
      background: #fff;
      border-color: #231f20;
      .btn-favorite {
        display: block;
        &:hover,
        &.active {
          background-position: 0px -24px;
        }
      }
      .btn-shopnow {
        display: inline-block;
      }
      .btn-quickview {
        display: block;
        &:hover {
          color: $color-cl-green-2;
          border-color: $color-cl-green-2;
          @if $cr19 == true {
            border-color: $cr19-text;
            color: $cr19-text;
          }
        }
      }
    }
    .caret {
      background: url('/media/export/images/qs-caret.png') no-repeat scroll 50% 0 rgba(0, 0, 0, 0);
      height: 12px;
      #{$ldirection}: 0;
      padding: 0;
      position: absolute;
      top: 385px;
      width: 182px;
      z-index: 9000;
    }
  }
}
/* product hover state */

#quickshop {
  top: -2px;
}
/* Shaded Row uses SKUIDs */
.shaded-row-add-all-button-v1.promo_product_row {
  .product-info {
    padding: 0 10%;
  }
}
