.cart-confirm-wrapper {
  font-family: $helvetica-light-font-family;
  min-height: 200px;
  width: 100%;
  text-align: #{$ldirection};
  @include swap_direction(padding, 0 0 0 8px);
  .price {
    display: inline;
  }
}

.cart-confirm-wrapper a.close {
  display: block;
  width: 11px;
  height: 11px;
  background: url(/sites/jomalone/themes/jm_base/img/close.png) no-repeat 0 0;
  text-indent: -9999px;
  overflow: hidden;
  position: absolute;
  #{$rdirection}: 20px;
  top: 20px;
  border-bottom: none;
}

.cart-confirm-wrapper h2 {
  color: $color-cl-soft-black;
  @include swap_direction(margin, 0 20px 5px 0);
  white-space: normal;
  font-size: 22px;
  font-family: $helvetica-light-font-family;
  letter-spacing: -0.3mm;
  line-height: 1.1;
}

.cart-confirm-wrapper h2.cart-confirm-heading {
  background: transparent url('/media/images/global/nav/top/calendar-icon.png') no-repeat scroll 0
    5px;
  color: #46ab8c;
  font-size: 13px;
  letter-spacing: normal;
  font-family: $helvetica-regular-font-family;
  line-height: 15px;
  height: 34px;
  margin: 0 0 30px;
  padding-#{$ldirection}: 35px;
  text-align: #{$ldirection};
  width: auto;
  p {
    line-height: 15px;
    margin: 0;
  }
}

.cart-confirm-wrapper .prod {
  @include swap_direction(padding, 0 20px 0 0);
  margin: 7px 0;
  border-bottom: solid 1px #ccc;
  width: 384px;
}

.cart-confirm-wrapper .prod:last-child {
  border-bottom: none;
}

.cart-confirm-wrapper .cart-product-img {
  display: block;
  float: #{$ldirection};
  width: 90px;
  border: none;
  text-align: center;
  color: #808080;
  line-height: 11px;
  .shade_thumb {
    float: #{$ldirection};
    width: 19px;
    height: 19px;
    @include swap_direction(margin, 0 5px 5px 7px);
    .shade_thumb_2 {
      width: 50%;
      height: 100%;
      float: #{$ldirection};
    }
    .shade_thumb_4 {
      width: 50%;
      height: 50%;
      float: #{$ldirection};
    }
  }
  .shadename {
    width: 60px;
    font-size: 11px;
    line-height: 11px;
    height: 19px;
    white-space: normal;
    text-align: #{$ldirection};
    display: inline-block;
    font-family: $helvetica-regular-font-family;
  }
}

.cart-confirm-wrapper .shade_info {
  @include swap_direction(margin, 5px 0 25px 0);
  height: 19px;
}

.cart-confirm-wrapper .cart-product-img img {
  width: auto;
}

.cart-confirm-wrapper .prod-info {
  width: 274px;
  float: #{$rdirection};
  .sample_color {
    color: $color-text-black;
  }
}

.cart-confirm-wrapper .cart-product-name {
  border-bottom: none;
  color: $color-cl-darker-grey;
  display: inline;
  float: #{$ldirection};
  font-family: $helvetica-regular-font-family;
  font-size: 13px;
  height: auto;
  line-height: 1.2;
  margin: 10px 0 15px;
  max-width: 55%;
  padding: 0 0 2px;
  text-transform: none;
  text-align: #{$ldirection};
  width: auto;
  white-space: normal;
}

.cart-confirm-wrapper .cart-product-name .shade_info {
  // override in place for when shade is in product name vs image container
  @include swap_direction(margin, 5px 0 5px 0);
  .shade_thumb {
    @include swap_direction(margin, 0 5px 5px 0);
    float: #{$ldirection};
    width: 19px;
    height: 19px;
    .shade_thumb_2 {
      width: 50%;
      height: 100%;
      float: #{$ldirection};
    }
    .shade_thumb_3 {
      width: 33%;
      height: 100%;
      float: #{$ldirection};
    }
    .shade_thumb_4 {
      width: 50%;
      height: 50%;
      float: #{$ldirection};
    }
  }
  .shadename {
    width: auto;
    font-size: 11px;
    line-height: 11px;
    height: 19px;
    white-space: normal;
    text-align: #{$ldirection};
    display: inline-block;
    font-family: $helvetica-regular-font-family;
  }
}

.cart-confirm-wrapper .cart-product-name a {
  font-size: 13px;
  font-family: $helvetica-regular-font-family;
  line-height: 1.2;
}

.cart-confirm-wrapper .prod-details {
  display: inline;
  float: #{$rdirection};
}

.cart-confirm-wrapper .right-copy.qty-price {
  margin-top: 10px;
  font-size: 13px;
  font-family: $helvetica-regular-font-family;
  line-height: 1.2;
}

.cart-confirm-wrapper .size {
  display: none;
}

.cart-confirm-wrapper .qty {
  margin-#{$rdirection}: 48px;
  color: #1f1f1f;
}

.cart-confirm-wrapper .left-copy,
.cart-confirm-wrapper .right-copy {
  width: auto;
}

.cart-confirm-wrapper .left-copy {
  float: #{$ldirection};
  font-size: 13px;
  font-family: $helvetica-regular-font-family;
  line-height: 1.2;
  color: #1f1f1f;
}

.cart-confirm-wrapper .right-copy {
  float: #{$rdirection};
  text-align: #{$rdirection};
}

.cart-confirm-wrapper .buttons .right-copy {
  width: auto;
}

.cart-confirm-wrapper .items,
.cart-confirm-wrapper .subtotal,
.cart-confirm-wrapper .buttons,
.cart-confirm-wrapper .total {
  padding: 10px 0 2px;
  font-weight: normal;
  font-size: 13px;
  font-family: $helvetica-regular-font-family;
  line-height: 1.2;
}

.cart-confirm-wrapper .items {
  float: #{$ldirection};
  width: 40%;
}

.cart-confirm-wrapper .subtotal {
  width: 212px;
}

.cart-confirm-wrapper .subtotal .left-copy {
  margin: 0 0 5px;
  text-align: #{$rdirection};
  width: 130px;
}

.cart-confirm-wrapper .total_div {
  width: 100%;
  padding-bottom: 10px;
  .offer-code-applied {
    color: #ea326a;
    padding: 0;
    width: 100%;
  }
  .last {
    color: $color-text-black;
    float: #{$rdirection};
    width: 130px;
    font-family: $helvetica-bold-font-family;
    text-transform: uppercase;
    @include swap_direction(padding, 0 0 5px 0);
  }
  .total {
    width: 90px;
    float: #{$rdirection};
    text-align: #{$rdirection};
    @include swap_direction(padding, 0 19px 5px 0);
  }
}

.cart-confirm-wrapper .items .right-copy a {
  display: none;
}

.cart-confirm-wrapper .buttons {
  border-top: solid 1px #ccc;
  padding: 10px 0 0;
  .text {
    text-align: #{$rdirection};
    color: #808080;
    font-size: 13px;
    font-family: $helvetica-regular-font-family;
    line-height: 1.2;
  }
}

.cart-confirm-wrapper .items.plural .item-in-cart,
.cart-confirm-wrapper .items .items-in-cart {
  display: none;
}

.cart-confirm-wrapper .items.plural .items-in-cart {
  display: inline;
}

.cart-confirm-wrapper .items a {
  border-bottom: solid 1px #000;
}

.cart-confirm-wrapper .buttons .left-copy a {
  border: none;
  font-variant: normal;
  font-size: 15px;
  font-family: $helvetica-light-font-family;
  line-height: 1.2;
  word-spacing: -0.3mm;
  &.button-secondary {
    border: solid 1px $color-btn-green;
    font-size: 0.9em;
  }
}

.gnav-cart-dropdown .gnav-item-content.empty,
.gnav-cart-dropdown.hover .gnav-item-content.empty {
  display: none;
}

.cart-confirm-wrapper .buttons .right-copy a.btn-primary {
  cursor: pointer;
  height: 25px;
}

.cart-confirm-wrapper .cart-overlay-products {
  height: auto;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 1px solid #ccc;
}

.cart-confirm-wrapper .cart-overlay-products-heading {
  border-bottom: 1px solid #ccc;
  font-size: 11px;
  font-family: $helvetica-regular-font-family;
  text-transform: uppercase;
  padding-bottom: 2px;
  margin-top: 20px;
}

.cart-confirm-wrapper .cart-overlay-products-heading div {
  margin: 0;
  &.hright {
    padding-#{$rdirection}: 10px;
  }
}

.cart-confirm-wrapper .cart-overlay-products-heading div.right-copy {
  float: none;
  margin-top: -15px;
}

.cart-confirm-wrapper .cart-overlay-products-heading .hcenter {
  float: #{$ldirection};
}

.cart-confirm-wrapper .cart-overlay-products-heading .hcenter {
  display: inline;
  text-align: #{$rdirection};
  width: 280px;
}

.cart-confirm-wrapper .last-item-added {
  background-color: $color-cl-green;
}
