.page-wrapper--offer-kit {
  margin: 0;
}

.offer-kit-multi-sku--formatter {
  margin: 0 auto;
  max-width: 1076px;
  min-width: 320px;
  overflow: hidden;
}

.offer-kit--container {
  margin: 0 auto;
  max-width: none;
  .offer-kit--option,
  .offer-kit--image--container,
  .offer-kit--image-small--wrapper {
    position: relative;
  }
  .offer-kit--image-small--container {
    margin: 15px 0 0;
    float: none;
    position: relative;
    width: 100%;
    .no-longer-available-txt {
      font-size: 20px;
      position: relative;
      text-align: center;
    }
    .offer-kit--details--text {
      overflow-y: scroll;
      height: 100%;
    }
  }
  .offer-kit-multi-sku--step {
    padding: 15px 0 3%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: top;
    @include start-breakpoint($medium-min) {
      margin: 0;
      padding: 50px 0;
      background-position: center;
    }
    &.offer-kit--step--alt {
      .offer-kit--step--container,
      .offer-kit--image--container {
        margin: 0;
      }
    }
  }
  .offer-kit--option--container {
    position: relative;
    @include start-breakpoint($medium-min) {
      display: flex;
      justify-content: center;
      align-items: stretch;
    }
    .offer-kit--option {
      display: none;
      &.offer-kit--option--mobile {
        margin: 20px 0 0;
        text-align: center;
        width: auto;
        display: block;
        &.offer-kit--is-sold-out {
          background-color: $color-white;
        }
        @include start-breakpoint($medium-min) {
          display: none;
        }
      }
      @include start-breakpoint($medium-min) {
        margin: 10px 25px;
        text-align: #{$ldirection};
        font-size: 22px;
        display: flex;
        flex-wrap: wrap;
        font-family: $base-thin-font-family;
        max-width: 50%;
      }
      &--sold-out {
        cursor: not-allowed;
        opacity: 0.3;
      }
      &--label {
        display: none;
        @include start-breakpoint($medium-min) {
          display: inline-block;
          cursor: default;
          font-family: $helvetica-light-font-family;
          margin-#{$ldirection}: 0;
          flex-grow: 1;
          text-align: center;
          &.for-styled-checkbox {
            display: inline;
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
      &--select {
        @include swap_direction(margin, 15px 10px 0 90px);
        padding: 5px 15px;
        background-color: $color-black;
        border: none;
        color: $color-white;
        display: none;
        font-family: $helvetica-bold-font-family;
        font-size: 15px;
        text-transform: uppercase;
        width: 150px;
        align-self: flex-start;
        @include start-breakpoint($medium-min) {
          display: block;
        }
      }
      &--submit {
        margin: -3px auto 10px;
        padding: 15px;
        background-color: $color-black;
        border: none;
        color: $color-white;
        font-family: $helvetica-bold-font-family;
        font-size: 18px;
        text-transform: uppercase;
        width: 95%;
        letter-spacing: 1.5px;
        @include start-breakpoint($medium-min) {
          display: none;
        }
      }
      &--select,
      &--submit {
        &.disabled {
          background-color: $color-light-grey !important;
          border: 1px solid $color-light-grey !important;
          cursor: not-allowed;
        }
        &.clicked {
          background-color: $color-white;
          border: 1px solid $color-black;
          color: $color-black;
        }
      }
      .regular-checkbox + label.styled-checkbox {
        display: none;
      }
      // New PC imgs position
      .offer-kit--image {
        cursor: pointer;
        height: auto;
        margin-#{$rdirection}: 0;
        max-width: none;
        width: 100%;
      }
      .prod-subheading {
        color: $color-sharp-black;
        font-size: 23px;
        line-height: 23px;
        overflow-y: auto;
        height: 150px;
      }
      .prod-name {
        color: $color-sharp-black;
        font-size: 21px;
        line-height: 25px;
        margin-#{$ldirection}: 45px;
        margin-top: 10px;
      }
    }
    .offer-kit--option--label ~ .offer-kit--option--select {
      margin: 10px 0 0;
    }
    .offer-kit--image--container {
      margin: 0 auto;
      position: relative;
      .no-longer-available-txt {
        display: block;
        font-size: 20px;
        text-align: center;
        position: relative;
        &.hidden {
          display: none;
        }
      }
    }
  }
  &.disable-select-buttons {
    .offer-kit--option--container {
      .offer-kit--option {
        .offer-kit--option--select,
        .offer-kit--option--submit {
          background-color: $color-light-grey;
          cursor: not-allowed;
        }
      }
    }
  }
  .offer-kit-multi-sku--step:not([data-step='1']) .offer-kit--option--container {
    @include start-breakpoint($medium-min) {
      flex-wrap: wrap;
      width: 100%;
    }
    .offer-kit--option {
      @include start-breakpoint($medium-min) {
        width: 25%;
        margin: 0;
        & > * {
          max-width: 200px;
        }
      }
      justify-content: center;
      align-items: center;
      flex-wrap: none;
      flex-direction: column;
      &--label {
        align-self: flex-start;
        @include start-breakpoint($medium-min) {
          align-self: center;
        }
      }
      &--select {
        align-self: center;
      }
    }
  }
  .offer-kit--details {
    margin: 5px 0;
    font-size: 12px;
    &--close {
      font-size: 20px;
      position: absolute;
      #{$rdirection}: 15px;
      top: 10px;
      cursor: pointer;
    }
    &--link-mobile {
      position: absolute;
      top: 4.5%;
      #{$ldirection}: 3.5%;
      width: 8%;
      img {
        width: 100%;
      }
    }
    a {
      font-size: 12px;
      color: $color-black;
      font-family: $helvetica-bold-font-family;
      text-transform: uppercase;
    }
    &--overlay {
      padding: 20px;
      width: 95%;
      #{$ldirection}: 2.5%;
      background: $color-light-grey none repeat scroll 0 0;
      display: none;
      height: 100%;
      text-align: #{$ldirection};
      position: absolute;
      top: 0;
      opacity: 0.95;
      z-index: 1;
      @include start-breakpoint($medium-min) {
        width: 100%;
        #{$ldirection}: 0;
      }
      h3 {
        font-weight: bold;
      }
      .prod-name {
        margin: 0;
        color: $color-sharp-black;
        font-size: 30px;
        line-height: 33px;
      }
      .offer-kit--details {
        &--text {
          color: $color-black;
          h3 {
            color: $color-black;
            font-size: 20px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  .offer-kit--option--image--alt {
    margin: 0;
  }
  .offer-kit--header--container {
    display: none;
    .offer-kit--number {
      font-size: 43px;
    }
    .offer-kit--header {
      margin: 5px 0 0;
      font-size: 150%;
    }
    .offer-kit--number,
    .offer-kit--header {
      float: none;
      position: relative;
      font-family: $helvetica-bold-font-family;
      text-align: center;
    }
    @include start-breakpoint($medium-min) {
      display: block;
      .offer-kit--number {
        font-size: 29px;
      }
      .offer-kit--header {
        font-size: 30px;
        line-height: 30px;
        p {
          margin: 20px 0 0;
          font-size: 17px;
          font-family: $base-thin-font-family;
        }
      }
    }
  }
  .offer-kit--cta {
    margin: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    .offer-kit--button--add,
    .offer-kit--button--oos {
      margin: 40px 0 0;
      padding: 10px 20px;
      background-color: $color-black;
      color: $color-light-white;
      display: none;
      font-weight: bold;
      text-transform: uppercase;
      width: auto;
    }
    .offer-kit--button--add {
      max-width: 90%;
      cursor: pointer;
      text-align: center;
      font-family: $helvetica-bold-font-family;
      font-size: 15px;
      font-weight: normal;
      display: inline-block;
      @include start-breakpoint($medium-min) {
        min-width: 150px; // center align CTA
      }
      &.disabled {
        background-color: $color-light-grey !important;
        border: 1px solid $color-light-grey !important;
        cursor: not-allowed;
      }
    }
    .offer-kit--button--oos {
      background-color: $color-light-grey;
      cursor: not-allowed;
    }
    &.offer-kit--is-loading {
      .offer-kit--button--add,
      .offer-kit--button--oos {
        background-color: $color-light-grey;
        color: $color-light-white;
        cursor: not-allowed;
      }
    }
    .offer-kit--loading {
      background: url('/media/export/cms/479-01 Clinique_LoadingGif_R22.gif') no-repeat 0 0
        $color-white;
      background-size: 35px;
      cursor: pointer;
      display: none;
      float: #{$ldirection};
      font-size: 12px;
      font-weight: bold;
      height: 35px;
      letter-spacing: 0.15em;
      line-height: 45px;
      margin-#{$ldirection}: 10%;
      margin-top: 40px;
      text-transform: uppercase;
      width: 35px;
    }
    &.offer-kit--loaded {
      .offer-kit--loading {
        display: none;
      }
    }
  }
  .offer-kit--oos {
    display: none;
  }
  /* success overlay */
  .offer-kit--success-overlay {
    &--container {
      display: none;
    }
    &--content {
      background: $color-white none repeat scroll 0 0;
      color: $color-black;
      padding-bottom: 50px;
      text-align: center;
    }
    &--header {
      font-family: $base-thin-font-family;
      font-size: 60px;
      line-height: 30px;
      margin-bottom: 35px;
      margin-top: 50px;
      width: 100%;
    }
    &--copy {
      margin: 15px auto;
      text-align: #{$ldirection};
      width: 90%;
      h2 {
        color: $color-black;
        font-size: 40px;
      }
    }
    &--button--container {
      margin-top: 30px;
    }
  }
}
