.button {
  @include button;
  @include button-cancel-offer;
}

.offerspick {
  @include swap_direction(padding, 0 15px);
  width: 100%;
  @media #{$cr19-medium-up} {
    @include swap_direction(padding, 20px 0);
    &--selected {
      min-height: auto;
    }
    &__offers {
      @include swap_direction(margin, 10px auto);
      @include swap_direction(padding, 0);
      width: 960px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;
    }
    &.offerspick__grid {
      @include swap_direction(padding, 0 0 20px 0);
      .offerspick__offer {
        @include swap_direction(margin, 0 0.5% 50px 0);
        @include swap_direction(padding, 0 0 42px 0);
        width: 33%;
        border: none;
        box-sizing: border-box;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        position: relative;
        &__prod-header {
          @include swap_direction(margin, 0 0 20px 0);
          min-height: 385px;
          flex: none;
        }
        &__details {
          @include swap_direction(padding, 0 10px 0 0);
          min-height: 90px;
        }
        &__price {
          font-size: 16px;
        }
        &__product_size {
          @include swap_direction(padding, 7px 0);
          display: inline-block;
          font-size: 20px;
          line-height: 1.2;
        }
        &:nth-child(3n) {
          @include swap_direction(margin, 0 0% 50px 0);
        }
      }
      .offerspick__header {
        @include swap_direction(margin, 0 auto);
        width: 960px;
        &__subtitle {
          @include swap_direction(padding, 0 0 0 20px);
          display: inline-block;
          width: 70%;
          color: #{$color-black};
        }
        &__choices {
          @include swap_direction(padding, 0 20px);
          width: 29%;
          display: inline-block;
          text-align: #{$rdirection};
        }
      }
      .offerspick__footer {
        @include swap_direction(padding, 0);
        @include swap_direction(margin, 10px auto);
        width: 960px;
        a {
          @include swap_direction(padding, 8px 27px);
          float: #{$rdirection};
          width: auto;
          font-family: $helvetica-bold-font-family;
          font-size: 14px;
          letter-spacing: 1px;
          border: 2px solid $color-black;
          background-color: $color-white;
          color: $color-black;
          text-decoration: none;
          &.hidden {
            display: none;
          }
        }
      }
    }
  }
  &__banner__header {
    @include swap_direction(margin, 0 auto);
    max-width: 1200px;
    &__image {
      width: 100%;
    }
  }
  &__header {
    @media #{$cr19-medium-up} {
      background: #{$color-lightest-grey-2};
    }
    @include swap_direction(padding, 0);
    &__title {
      @media #{$cr19-medium-up} {
        @include swap_direction(padding, 50px);
        font-size: 36px;
        text-align: center;
      }
      @include swap_direction(padding, 17px 0 10px 0);
      font-size: 26px;
      text-align: #{$ldirection};
      font-family: #{$helvetica-light-font-family};
      background-color: #{$color-white};
    }
    &__subtitle {
      font-size: 14px !important;
      @media #{$cr19-medium-up} {
        @include swap_direction(padding, 0 0 0 20px);
        display: inline-block;
        width: 70%;
      }
      color: #{$color-black};
    }
    &__inner {
      img {
        width: 100%;
      }
    }
    &__choices {
      @include swap_direction(margin, 10px 0 0);
      font-family: $helvetica-bold-font-family;
      display: inline-block;
      width: 50%;
      @media #{$cr19-medium-up} {
        width: 29%;
        font-family: $helvetica-light-font-family;
        text-align: #{$rdirection};
      }
    }
    &__remove {
      @include swap_direction(padding, 20px 0px 20px 0);
      background-color: #{$color-white};
      text-align: #{$rdirection};
      display: inline-block;
      width: 48%;
      @media #{$cr19-medium-up} {
        @include swap_direction(padding, 30px 0 5px);
        text-align: #{$ldirection};
        text-decoration: underline;
        width: 100%;
        display: block;
      }
    }
    &__url {
      @media #{$cr19-medium-up} {
        font-size: 14px;
      }
      font-family: $helvetica-bold-font-family;
      color: #{$color-black};
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1px;
      &:hover,
      &:focus {
        color: #{$color-black};
        text-decoration: underline;
      }
    }
  }
  &__footer {
    @include swap_direction(padding, 20px 0);
    a {
      @include swap_direction(margin, 10px 0);
      width: 100%;
      &.hidden {
        display: none;
      }
    }
  }
  &__tc {
    @include swap_direction(margin, 0 auto);
    text-align: #{$ldirection};
    max-width: 960px;
    @media #{$cr19-medium-up} {
      text-align: #{$rdirection};
    }
  }
  &__offers {
    @include swap_direction(padding, 20px 0);
  }
  &__offer {
    @include swap_direction(margin, 12px 0 0 0);
    @include swap_direction(padding, 0 0 14px 0);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    @media #{$cr19-medium-up} {
      @include swap_direction(padding-bottom, 42px);
      position: relative;
    }
    &__header {
      @include swap_direction(padding, 0 0 14px 0);
      @media #{$cr19-medium-up} {
        font-size: 20px;
        line-height: 1.2;
      }
      font-size: 16px;
      font-family: $helvetica-bold-font-family;
      line-height: 1;
      text-align: #{$ldirection};
    }
    &__price {
      @include swap_direction(padding, 0 0 10px 0);
      display: none;
    }
    &__subheader {
      @include swap_direction(padding, 0 0 10px 0);
      @media #{$cr19-medium-up} {
        font-size: 20px;
        line-height: 1.2;
      }
      font-family: $helvetica-light-font-family;
    }
    &__product_size {
      font-family: $helvetica-light-font-family;
    }
    &__prod-header {
      @include swap_direction(margin, 0 10px 0 0);
      flex: 1;
      border: none;
      background-color: #{$color-lightest-grey-white};
    }
    &__footer {
      flex: 1;
      @media #{$cr19-medium-up} {
        flex: none;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
    &.is_selected {
      border-bottom: 0;
      .offerspick__button--remove {
        display: inline-block;
      }
      .offerspick__button--add {
        display: none;
      }
    }
    &.not_selected {
      pointer-events: none;
      opacity: 0.4;
    }
    &.is_soldout {
      opacity: 0.4;
      .offerspick__button--soldout {
        display: inline-block;
      }
      .offerspick__button--add {
        display: none;
      }
    }
  }
  &__button__container {
    @include swap_direction(margin, 10px 0);
    text-align: #{$ldirection};
    @media #{$cr19-medium-up} {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
    }
  }
  &__button--remove {
    display: none;
    background-color: #{$color-black};
    color: #{$color-white};
  }
  &__button--soldout {
    display: none;
    border: 1px solid #{$color-black};
    color: #{$color-black};
    background: #{$color-white};
    &:hover {
      border: 1px solid #{$color-black};
      color: #{$color-black};
      background: #{$color-white};
    }
  }
  &__shades {
    @include swap_direction(margin, 26px 0 0 0);
    display: none;
  }
  &__shade {
    @include swap_direction(margin, 0 0 6px 0);
    display: none;
    &__name,
    &__price,
    &__unit_price {
      font-size: 13px;
      display: none;
      @media #{$cr19-medium-up} {
        font-size: 13px;
      }
    }
    &__name {
      @include swap_direction(margin, 10px 0);
    }
    &__image {
      @include swap_direction(padding, 10px);
      width: 100%;
      @media #{$cr19-medium-up} {
        display: inline;
      }
    }
    &__dot {
      @include swap_direction(margin, 0);
      @media #{$cr19-medium-up} {
        @include swap_direction(margin, 0 auto);
      }
    }
  }
  &__button--add,
  &__button--remove {
    @media #{$cr19-medium-up} {
      @include swap_direction(padding, 8px 27px);
      width: auto;
      position: absolute;
      bottom: 0;
    }
    @include swap_direction(padding, 5px);
    font-size: 14px;
    font-family: $helvetica-bold-font-family;
    letter-spacing: 1px;
    border-width: 2px;
    width: 100%;
  }
  &-action-buttons {
    .continue-checkout {
      @include swap_direction(padding, 8px 27px);
      font-family: $helvetica-bold-font-family;
      font-size: 14px;
      letter-spacing: 1px;
      background-color: $color-black;
      text-decoration: none;
      &:hover {
        background-color: $color-black;
      }
    }
  }
  &__clear-all {
    @include swap_direction(margin, 8px 0 0 0);
    float: #{$ldirection};
  }
}
