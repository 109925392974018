.egiftcards-v1 {
  ul.error_messages {
    background-color: #fff;
  }
  ul.error_messages li {
    background-clip: padding-box;
    border-radius: 3px;
    color: $color-notice-pink;
    font-size: 12px;
    line-height: 1.3em;
    margin: 10px 0;
    padding: 4px 1px;
  }
  .top-padding {
    padding-top: 115px;
    padding-bottom: 95px;
  }
  .breadcrumbs {
    padding-bottom: 60px;
    font-weight: normal;
    margin-top: 10px;
    color: #1a1a1a;
  }
  #giftcard-balance .breadcrumbs {
    margin-#{$ldirection}: 3px;
  }
  .breadcrumbs > a {
    color: #1a1a1a;
  }
  input.form-submit:hover {
    background-color: $color-cl-green-2 !important;
    @if $cr19 == true {
      background-color: $cr19-bg-black !important;
    }
  }
  /* need to be verify START */
  .wrapper p.error {
    display: none;
  }
  /* need to be verify END */
  /* eGiftcard page & About Page */

  .wrapper {
    background: none;
    padding: 0px;
    margin: 0px;
  }
  #remaining-balance,
  .remaining_on_card {
    margin-#{$ldirection}: 3px;
  }
  /*
  #giftcard-about #wrapper,
  #egiftcard #wrapper,
  #giftcard-balance #wrapper {
    margin: 0 auto;
    max-width: 975px;
    @include swap_direction(padding,115px 0 0 0);
  }
  */

  #wrapper #content {
    width: 100%;
  }
  /* Header style */
  h1.myaccount-hdr {
    color: $color-cl-green-2;
    font-size: 36px;
    font-weight: normal;
    line-height: 100%;
    margin-bottom: 5px;
    margin-top: 20px;
    @if $cr19 == true {
      color: $cr19-text-black;
    }
  }
  h1.giftcard_balance {
    color: $color-cl-green-2;
    font-size: 22px;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 100%;
    margin-bottom: 2px;
    margin-top: 10px;
    width: 300px;
    @if $cr19 == true {
      color: $cr19-text-black;
    }
  }
  .rightpanel_content {
    margin-#{$ldirection}: 5px;
  }
  #giftcard-balance fieldset.giftcard p {
    color: #808080;
    font-size: 15px;
    font-family: $helvetica-light-font-family;
    line-height: 1.14em;
    margin-bottom: 17px;
    margin-#{$ldirection}: 0px;
    width: auto;
  }
  .return_to_cart {
    margin-bottom: 10px;
    display: inline-block;
  }
  #giftcard-about #content #giftcard-buy-header p {
    color: #a2a2a2;
    font-size: 15px;
    font-family: $helvetica-light-font-family;
    margin-#{$ldirection}: 3px;
    margin-bottom: 0px;
  }
  #giftcard-about #content #giftcard-buy-header {
    margin-bottom: 52px;
  }
  #content #giftcard-buy-header p {
    color: #a2a2a2;
    font-size: 15px;
    font-family: $helvetica-light-font-family;
    margin-#{$ldirection}: 3px;
    margin-bottom: 15px;
  }
  #giftcard-buy-header a.learn-more {
    font-size: 15px;
    font-family: $helvetica-light-font-family;
  }
  .form_element.email_container {
    float: #{$ldirection};
    width: 250px;
    margin-bottom: 22px;
  }
  .form_element.email_container > input {
    background: none repeat scroll 0 0 #f8f6f6;
    border: 1px solid #cccccc;
    height: 37px;
    width: 235px;
    padding-#{$ldirection}: 5px;
    color: #1f1f1f;
    /*  color:#808080; */
  }
  fieldset.fs div.form_element div.txt-theme label {
    color: #1f1f1f;
    padding-bottom: 5px;
  }
  fieldset.fs div.form_element label {
    color: #ffffff;
    display: inline-block;
    padding-bottom: 5px;
    text-transform: capitalize;
    width: auto;
  }
  #egiftcard .skuSelect,
  #egiftcard .form_element > input {
    border-radius: 3px;
  }
  .giftcard .form_value .drp_val {
    position: absolute;
  }
  .form_element.from_container > input {
    background: none repeat scroll 0 0 #f8f6f6;
    border: 1px solid #cccccc;
    height: 38px;
    padding-#{$ldirection}: 5px;
    width: 235px;
    color: #1f1f1f;
    font-family: $helvetica-light-font-family;
    /*  color:#1F1F1F; */
  }
  .form_element.value_container.select_menu {
    width: 250px;
  }
  #theme-panel {
    margin-top: 20px;
    height: 100px;
  }
  .txt-theme {
    color: #1f1f1f;
    font-size: 12px;
    padding-bottom: 12px;
  }
  .txt-theme label {
    font-family: $helvetica-light-font-family;
    text-transform: capitalize;
  }
  .theme {
    float: #{$ldirection};
    height: 73px;
    margin-#{$rdirection}: 20px;
    width: 73px;
  }
  .border {
    border: 2px solid #bdbdbd;
  }
  .form_container {
    float: #{$ldirection};
    width: 300px;
    @include swap_direction(margin, 52px 0px 0px 388px);
  }
  .form_value {
    color: #ffffff;
    float: #{$ldirection};
    font-size: 150px;
    font-family: $helvetica-light-font-family;
    font-weight: normal;
    @include swap_direction(margin, 274px 0 0 50px);
    width: 200px;
  }
  .giftcard_container {
    width: 975px;
    display: inline-block;
    margin-#{$ldirection}: 2px;
  }
  #giftcard-buy-header {
    float: #{$ldirection};
    margin-bottom: 19px;
    width: 600px;
    padding-#{$rdirection}: 20px;
  }
  .skuSelect {
    background: none repeat scroll 0 0 #fafafa;
    border: 1px solid #cccccc;
    height: 35px;
    padding-top: 7px;
    width: 235px;
    color: #1f1f1f;
  }
  .gc_note {
    color: #1f1f1f;
    font-size: 11px;
    margin-top: 5px;
    display: inline-block;
  }
  .custom_card {
    color: #1f1f1f;
    float: #{$ldirection};
    font-size: 12px;
    padding: 22px 0 11px;
    width: 100%;
  }
  #giftcard-balance fieldset.giftcard {
    width: 960px;
    background: url('/media/images/pc/egift/bg_chkbalance.png') no-repeat;
    height: auto;
    padding-#{$ldirection}: 18px;
    margin-bottom: 20px;
    background-size: 100% 100%;
  }
  fieldset.fs {
    width: 975px;
    height: 532px;
    /* background: url("/media/images/pc/egift/bg_giftcard_pink.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0); */
    border: none;
  }
  .class_img {
    position: relative;
  }
  .class_img img {
    #{$ldirection}: 1px;
    position: absolute;
  }
  #egiftcard span.giftcard_overlay {
    height: 532px;
    position: absolute;
    width: 975px;
  }
  fieldset.fs div.form_element input {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 17px;
    font-family: $helvetica-light-font-family;
    font-weight: normal;
    height: 34px;
    padding-#{$ldirection}: 12px;
    width: 251px;
  }
  .giftcard_container .message_container textarea {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 17px;
    font-family: $helvetica-light-font-family;
    height: 227px;
    padding: 10px;
    width: 255px;
  }
  .giftcard_submit_btn {
    @include swap_direction(margin, 24px 15px 10px 0);
    font-size: 15px;
    font-family: $helvetica-light-font-family;
  }
  #egiftcard .giftcard_submit_btn input {
    float: #{$rdirection};
  }
  #giftcard-balance .giftcard_submit_btn {
    @include swap_direction(margin, 12px 0 0px 0);
    font-size: 15px;
    font-family: $helvetica-light-font-family;
    width: 150px;
    float: #{$ldirection};
  }
  #content .egiftcard_information p {
    font-size: 11px;
    @include swap_direction(margin, 0 0 15px 0px);
    width: 900px;
  }
  #scroller {
    display: none;
  }
  .imgclinque {
    position: absolute;
    @include swap_direction(margin, 57px 0px 0px 52px);
  }
  .txtclinque {
    position: absolute;
    @include swap_direction(margin, 129px 0px 0px 90px);
    font-size: 40px;
    font-family: $helvetica-light-font-family;
    color: #fff;
  }
  .divider {
    border-#{$ldirection}: 1px solid #cccccc;
    display: table-cell;
    float: #{$ldirection};
    height: 108px;
    @include swap_direction(margin, 25px 0px 0px 0px);
    width: 2px;
  }
  .rightpanel_container {
    float: #{$ldirection};
    width: auto;
    @include swap_direction(margin, 13px 0px 0px 59px);
  }
  .right-panel {
    width: auto;
    float: #{$ldirection};
  }
  .rightpanel_header {
    font-size: 22px;
    font-family: $helvetica-light-font-family;
    color: #000;
  }
  .gift_balance {
    font-size: 15px;
    font-family: $helvetica-light-font-family;
    width: 300px;
  }
  .divclinque {
    position: absolute;
    margin-#{$ldirection}: 591px;
    margin-top: 220px;
  }
  .giftcard_terms {
    margin-top: 15px;
    margin-#{$ldirection}: 3px;
    @include swap_direction(margin, 3px 0 10px 0);
    font-size: 15px;
    font-family: $helvetica-light-font-family;
  }
  p.abt_qtns {
    color: #1a1a1a;
    font-weight: bold;
    padding-bottom: 2px;
    padding-top: 3px;
  }
  p.abt_ans {
    color: #1a1a1a;
    padding-bottom: 17px;
  }
  p.abt_grp {
    color: #231f20;
    padding-bottom: 2px;
  }
  #content p {
    @include swap_direction(margin, 0 0 2px 0);
  }
  .abt_egiftcard {
    font-size: 20px;
    font-family: $helvetica-light-font-family;
    width: 545px;
    top: 20px;
    @include swap_direction(margin, 0px 0px 30px 0px);
    color: #1a1a1a;
  }
  ul.terms li {
    list-style-type: disc;
    color: #1a1a1a;
  }
  #giftcard-about .last_desc {
    margin-top: 4px;
  }
  menu,
  ol {
    @include swap_direction(padding, 0 0 0 15px);
  }
  /* Gift Card Balance Page */
  #giftcard-balance #giftcard-buy-header {
    margin-bottom: 14px;
    margin-#{$ldirection}: 3px;
  }
  #giftcard-about ul.terms_bot {
    margin-bottom: 2px;
  }
  #giftcard-about ul.terms {
    margin-top: 5px;
    line-height: 20px;
    padding-#{$ldirection}: 14px;
    margin-bottom: 17px;
  }
  #giftcard-about .max_purchase {
    margin-top: 18px;
  }
  #giftcard-about ul.terms.last {
    margin-bottom: 0px;
  }
  .gc_ecard_balance {
    width: 960px;
    margin-#{$ldirection}: 3px;
  }
  #giftcard-balance .gc_ecard_balance strong {
    color: $color-cl-green-2;
    font-size: 22px;
    font-family: $helvetica-light-font-family;
    @if $cr19 == true {
      color: $cr19-text-black;
    }
  }
  #giftcard-balance .gc_ecard_box_text {
    color: #1a1a1a;
    font-size: 15px;
    font-family: $helvetica-light-font-family;
    margin-bottom: 15px;
  }
  #giftcard_balance fieldset.fs div.form_element input {
    background-color: #fafafa;
    border: 1px solid #e4e4e4;
    color: #1f1f1f;
    font-size: 13px;
    width: 235px;
    height: 36px;
    padding-#{$ldirection}: 7px;
    font-family: $helvetica-light-font-family;
  }
  #giftcard-balance fieldset.fs div.form_element {
    float: none;
    @include swap_direction(margin, 5px 15px 25px 0);
  }
  #content {
    float: #{$rdirection};
    margin: 0;
  }
  #sidebar {
    width: 200px;
    margin: 20px 0 0;
    float: #{$ldirection};
  }
  /* #sidebar { float: #{$rdirection}; }
  #nav-account ul { list-style: none; }
  #nav_giftcards ul { list-style: none; @include swap_direction(margin,0 0 0 5px); }

  #nav_giftcards .section-content img { @include swap_direction(margin,5px 0 0 0); }

  #wrapper {
    background: url("/media/images/css/dotted_y_l-r.gif") repeat-y scroll left top transparent;
    margin: 15px 0 70px;
    @include swap_direction(padding,0 35px 20px 15px);
  }

  #col-2 .section-content p {@include swap_direction(margin,0 0 10px 0);}

  #giftcard-balance #wrapper,
  #giftcard-about #wrapper,
  #egiftcard #wrapper,
  #giftcard-retrieval #wrapper {
    background: url(/media/images/css/dotted_y_l-r.gif) repeat-y top left;
    @include swap_direction(padding,0 35px 30px 15px);
    margin-top: 15px;
    position: relative;
  }

  #giftcard-balance #wrapper #col-1,
  #giftcard-about #wrapper #col-1,
  #egiftcard #wrapper #col-1,
  #giftcard-retrieval #wrapper #col-1 { width: 540px; }

  #giftcard-balance #wrapper #col-2,
  #giftcard-about #wrapper #col-2,
  #egiftcard #wrapper #col-2,
  #giftcard-retrieval #wrapper #col-2 { width: 250px; @include swap_direction(margin,15px 0 0 0); }

  #giftcard-buy-header { width: 500px; float: #{$ldirection}; }

  h2 { font-size: 16px; }

  #remaining-balance {
    @include swap_direction(margin,20px 0 0 0);
    font-size: 16px;
    font-family: $helvetica-light-font-family;
  }

  #remaining-balance .giftcard_balance_amount {
    color: #326445;
    margin-#{$ldirection}: 10px;
    font-weight: bold;
  }

  p.remaining_on_card {
    font-size: 16px;
    font-family: $helvetica-light-font-family;
  }

  p.question {
    color: #326445;
    font-weight: bold;
  }

  .egift_data_wrapper {
    margin: 20px 10px;
  }

  .egift_data_wrapper td {
    font-size: 16px;
    font-family: $helvetica-light-font-family;
    color: #326445;
    padding: 4px;
  }

  .egift_data_wrapper td.egift_number {
    font-weight: bold;
  }

  .gc_note { font-size: 10px; }

  fieldset.fs div.form_element.message_container {
    width: 450px;
  }

  .giftcard_submit_btn {
    @include swap_direction(margin,3px 0 10px 0);
  }

  .giftcard_terms {
    @include swap_direction(margin,3px 0 10px 0);
  }

  /* ..... override cl_global.css / move this to cl_global.css ..... */
  .section-box {
    /* font-size: 11px; */
  }
  .section-box h2 {
    border-top-width: 0 !important;
    border-top-width: 1px;
    /* font-size: 11px; */
    line-height: 10px;
    padding: 0.8em 7px 0.6em;
    text-transform: uppercase;
  }
  .section-box h2:first-child {
    border-top-width: 1px !important;
  }
  .section-box h3 {
    color: #000;
    /* font-size: 11px; */
    font-weight: normal;
    margin-bottom: 1em;
  }
  .section-box p {
    margin: 0.7em 0 0.8em;
  }
  .section-box p a {
    text-decoration: underline;
  }
  .section-box#customer-service-box {
    background: #fcfcfc url(/images/css/bg_grad_cs_box.gif) repeat-x top;
    /* min-height: 94px; */
  }
  .content-block {
    /* font-size: 11px; */
    @include swap_direction(padding, 0 2em 1em 0);
  }
  .content-block p {
    margin-bottom: 1em;
  }
  .content-block .action-set {
    padding: 0 0 1em;
    text-align: #{$rdirection};
  }
  .content-header {
    border-top: 1px solid #ddd;
    @include swap_direction(padding, 16px 4px 0 3px);
  }
  .list-header {
    font-weight: normal;
    margin-bottom: 0.5em;
  }
  /* ..... END: override cl_global.css   ..... */

  /* ---------------  wrapper  --------------- */
  .wrapper {
    background: url(/media/images/css/dotted_y_l-r.gif) repeat-y top left;
    @include swap_direction(margin, 11px 0 70px 0);
    min-height: 300px;
    @include swap_direction(padding, 8px 35px 20px 15px);
  }
  .wrapper #col-1 {
    float: #{$ldirection};
    padding: 14px 0 0;
    width: 680px;
  }
  #confirmation-wrapper #col-1,
  #profile-wrapper #col-1 {
    width: 520px;
  }
  .wrapper #col-2 {
    float: #{$rdirection};
    width: 200px;
  }
  .wrapper h1 {
    color: #000;
    font-size: 22px;
  }
  /* ... disabled form-item ... */
  .disabled label {
    color: #999;
    cursor: default;
  }
  /* ... form-errors ... */
  .form-errors {
    color: $color-notice-pink;
    /* letter-spacing: 0.01em; */
    list-style: none;
    margin: -13px 0 13px;
    position: relative;
  }
  .form-errors li {
    background: url(/images/css/ico_err.gif) no-repeat 0 0.2em;
    @include swap_direction(padding, 0 0 0 6px);
  }
  /* ... overlay : will likely be deployed within lightbox ... */
  .overlay {
    _background: #000;
    _filter: alpha(opacity=21);
    height: 100%;
    #{$ldirection}: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99998;
  }
  .overlay-layer {
    background: #fff;
    border: 1px solid #efeeee;
    min-height: 380px;
    _height: 380px;
    margin: 0 auto;
    @include swap_direction(padding, 20px 25px 20px 12px);
    position: relative;
    top: 15%;
    width: 348px;
    z-index: 99998;
  }
  .overlay h3 {
    color: #000;
    font-size: 22px;
    font-weight: normal;
  }
  .overlay .action-close {
    position: absolute;
    #{$rdirection}: 20px;
    top: 20px;
  }
  .overlay .action-close a {
    background: url(/images/css/ico_close.gif) no-repeat left center;
    font-size: 10px;
    line-height: 1em;
    padding-#{$ldirection}: 14px;
    text-transform: uppercase;
  }
  .overlay-footer {
    /* font-size: 11px; */
    line-height: 1.3em;
    /* letter-spacing: 0.005em; */
    padding-#{$rdirection}: 60px;
  }
}
