.social-login {
  .social-login__container {
    text-align: center;
    padding: 20px 0;
    .logged-in & {
      padding-top: 0;
    }
  }
  .social-login__divider {
    font-family: $helvetica-bold-font-family;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding: 0 33px;
    width: 100%;
    margin: 0 auto 20px;
    &:before {
      content: '';
      background: $color-cl-green-2;
      width: 100%;
      height: 2px;
      display: inline-block;
      position: absolute;
      top: 43%;
      #{$ldirection}: 0;
      @if $cr19 == true {
        background-color: $cr19-bg-black;
      }
    }
    .inner,
    h13__inner {
      @include swap_direction(padding, 0 7px 0 9px);
      background: $color-white;
      display: inline-block;
      position: relative;
      font-family: $base-pro-bolder-font-family;
    }
  }
  .social-login__email-opt-in {
    margin-top: 20px;
    padding-bottom: 0;
    text-align: #{$ldirection};
    display: flex;
    -webkit-display: flex;
    -moz-display: flex;
    @media #{$medium-up} {
      display: block;
    }
    .social-login__opt-in-label {
      font-family: $helvetica-light-font-family;
      padding-#{$ldirection}: 10px;
    }
  }
  .social-login__terms {
    margin-top: 15px;
    padding: 0;
    text-align: #{$ldirection};
    @media #{$medium-up} {
      text-align: center;
      padding: 0 20px;
    }
    p {
      margin: 0;
    }
  }
  .social-login__title,
  .social-login__info {
    display: none;
  }
}

.signin_container {
  .facebook-button {
    display: none;
  }
}

#signin {
  .wrapper-signin-block {
    .social-login__email-opt-in {
      text-align: #{$ldirection};
      margin-#{$ldirection}: 36%;
      @media #{$medium-up} {
        margin-#{$ldirection}: 30%;
      }
    }
    .social-login__terms {
      @include swap_direction(padding, 0 20px 0 43px);
      @media #{$medium-up} {
        text-align: #{$ldirection};
        padding-#{$ldirection}: 30%;
      }
    }
  }
  .checkout_signin {
    .social-login {
      width: 89%;
      .social-login__divider {
        @media #{$medium-up} {
          width: 100%;
        }
      }
      .social-login__email-opt-in {
        padding: 0 20px;
        @media #{$medium-up} {
          padding: 0 20px;
        }
      }
      .social-login__terms {
        padding: 0 20px;
        @media #{$medium-up} {
          padding: 0 20px;
        }
      }
    }
  }
  .signin_container.clearfix,
  .signin-block-content {
    .social-login {
      .social-login__email-opt-in {
        text-align: #{$ldirection};
        margin-top: 20px;
        padding-bottom: 0;
        .social-login__opt-in-label {
          padding-#{$ldirection}: 10px;
          @media #{$medium-up} {
            padding-#{$ldirection}: 0;
          }
        }
      }
      .social-login__terms {
        text-align: #{$ldirection};
        padding: 0;
      }
    }
  }
  #signin-block {
    .social-login {
      .social-login__email-opt-in {
        input[type='checkbox'] {
          width: auto;
          height: auto;
          @media #{$medium-up} {
            padding-#{$ldirection}: 0;
          }
        }
        label {
          color: $color-darker-grey;
          margin-#{$ldirection}: 10px;
          font-family: $helvetica-regular-font-family;
        }
      }
    }
  }
}
/* Account Index Page */
.account_landing_wrapper {
  .account-header-panel {
    .account-profile__connected-accounts {
      @include swap_direction(padding, 5px 0 0 7px);
      color: $color-darker-grey;
      opacity: 0.8;
      font-weight: 300;
    }
  }
}
/* GNAV styling */
.site-utils__dropdown__content {
  .social-login {
    .social-login__container {
      @include swap_direction(padding, 22px 28px 0 24px);
    }
  }
}

#gnav_signin .signin_submit {
  @media #{$medium-up} {
    margin-bottom: 0;
  }
}

.gnav-my-account-v1 {
  .pane {
    padding-bottom: 0 !important;
  }
  .social-login.gnav {
    .social-login__container {
      padding: 0;
    }
    .social-login__divider {
      @media #{$medium-up} {
        margin-bottom: 10px;
      }
      .inner {
        background-color: $color-light-white;
      }
    }
    .social-login__email-opt-in {
      margin-top: 0;
      text-align: #{$ldirection};
      .social-login__email-opt-in-label {
        margin-top: 20px;
        font-family: $helvetica-light-font-family;
        @media #{$medium-up} {
          margin-top: 15px;
        }
      }
    }
    .social-login__terms {
      text-align: #{$ldirection};
      padding: 0;
      @media #{$medium-up} {
        margin-top: 7px;
      }
    }
  }
}
/* Disconnect button styling */
#facebook_disconnect {
  display: inline-flex;
  input.form-submit {
    height: 29px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
    margin-#{$ldirection}: 20px;
  }
}
/* Order confirmation page */
.checkout-confirmation-page,
.multi {
  .social-login {
    .social-login__container {
      padding-bottom: 0;
      text-align: center;
      @media #{$medium-up} {
        text-align: #{$ldirection};
      }
    }
    .social-login__terms,
    .social-login__divider {
      display: none;
    }
    .social-login__title {
      display: block;
    }
    .social-login__info {
      margin-bottom: 10px;
      display: block;
    }
    .social-login__email-opt-in {
      input[type='checkbox'] {
        position: relative;
        top: 8px;
        margin-#{$rdirection}: 10px;
        @media #{$medium-up} {
          top: 3px;
        }
      }
      label {
        padding: 0;
        color: $color-darker-grey;
      }
    }
  }
}
/* Social info section on Account preferences page */
.social-info {
  margin-top: 30px;
  .social-info__content {
    p {
      @include swap_direction(margin, 0 0 30px 0);
      padding: 0;
      line-height: 1.45em;
    }
    padding-#{$ldirection}: 0;
    font-family: $helvetica-bold-font-family;
  }
  .social-info__connect {
    margin-bottom: 30px;
    letter-spacing: 1px;
  }
  &.outer-wrap & {
    &__header {
      font-weight: inherit;
      padding-#{$ldirection}: 0;
    }
  }
}

#registration.device-mobile {
  .registration_content {
    .social-info {
      .social-info__content {
        p {
          margin-bottom: 25px;
        }
        input.form-submit {
          padding: 0 10px;
        }
      }
    }
  }
}
/* Book Appointment */
.appointments-container {
  .sign-in-container {
    .sign-in-form-container {
      .social-login {
        .social-login__email-opt-in {
          display: flex;
          margin-top: 15px;
          .social-login__opt-in-checkbox {
            width: auto;
            margin-bottom: 0;
          }
          .social-login__opt-in-label {
            margin-top: 10px;
            font-family: $base-pro-roman-font-family;
          }
        }
        .social-login__terms {
          margin-top: 10px;
          text-align: #{$ldirection};
          padding: 0;
        }
      }
    }
  }
}
