/*
* Clinical Reality landing page styles
*/

$color-lightest-grey-3: #f0f0f0;
$color-bg-lighter-grey: #ececec;
$color-bg-product-img: #fafafa;
$color-bg-hero-banner: #eee;
$ccr-wrapper-width: 1124px;
$minimum-device-width: 320px;
$maximum-device-width: 375px;

.clinical-reality {
  .hide-button {
    opacity: 0;
  }
  @include start-breakpoint($medium-min) {
    max-width: $ccr-wrapper-width;
    margin: 0 auto;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  &__intro {
    .mobile_hidden {
      display: none;
      @include start-breakpoint($medium-min) {
        display: inline-block;
      }
    }
    @include start-breakpoint($medium-min) {
      position: relative;
      background: $color-lightest-grey-2;
    }
    display: none;
    background: $color-bg-lighter-grey;
    &.active {
      display: block;
      -webkit-animation: slide-down 2s;
      -moz-animation: slide-down 2s;
    }
    &--reality-img {
      @include start-breakpoint($medium-min) {
        width: 255px;
      }
      width: 200px;
    }
    &--banner-content {
      display: block;
      @include start-breakpoint($medium-min) {
        display: inline-block;
        width: 60%;
      }
    }
    &--analysis-content {
      display: block;
      padding-top: 40px;
      padding-#{$ldirection}: 15px;
      @include start-breakpoint($medium-min) {
        display: inline-block;
        position: absolute;
        height: 250px;
        padding: 0;
        top: 0;
        bottom: 0;
        #{$rdirection}: 12px;
        width: 40%;
        margin: auto;
      }
      @include breakpoint-range($medium-min, $medium-ipad-p) {
        #{$rdirection}: 0;
      }
    }
    &--title {
      @include header(2em, $helvetica-bold-font-family);
      line-height: 1;
      padding-top: 35px;
      @include start-breakpoint($medium-min) {
        @include header(2.47em, $helvetica-bold-font-family);
        padding-top: 38px;
        margin-top: 0;
        line-height: 1.1;
      }
      @include breakpoint-range($medium-min, $medium-ipad-p) {
        @include header(2.07em);
        padding-top: 30px;
      }
    }
    &--subtitle {
      @include header(1.08em, $helvetica-light-font-family);
      &:last-of-type {
        padding-bottom: 10px;
        @include start-breakpoint($medium-min) {
          padding-bottom: 18px;
        }
        @include breakpoint-range($medium-min, $medium-ipad-p) {
          padding-bottom: 25px;
        }
        margin-top: 0;
      }
      @include start-breakpoint($medium-min) {
        @include header(1.24em, $helvetica-light-font-family);
      }
    }
    &--analysis {
      @include link(1.25em, $helvetica-bold-font-family);
      line-height: 1.2;
      &:hover {
        color: $color-cl-darker-grey;
      }
      &-arrow {
        height: 10px;
        margin-#{$ldirection}: 6px;
        @include start-breakpoint($medium-min) {
          height: 15px;
        }
      }
      @include start-breakpoint($medium-min) {
        @include header(2em, $helvetica-bold-font-family);
      }
    }
    &--iframe {
      &-pc,
      &-mobile {
        border: 0;
        width: 100%;
        height: 630px;
        overflow: hidden;
      }
      &-pc {
        display: none;
        @include start-breakpoint($large-min) {
          display: block;
        }
      }
      &-mobile {
        display: none;
        @include breakpoint($medium-ipad-p) {
          display: block;
          height: calc(100dvh - #{$navbar-sm});
        }
        // ipad landscape specific style
        @media only screen and (min-width: $medium-max) and (max-width: $large-min) and (orientation: landscape) {
          display: block;
        }
      }
    }
    &--video {
      width: 100%;
      height: auto;
    }
    ~ div {
      .clinical-reality-block--mobile-full-screen & {
        display: none;
        @include start-breakpoint($cr19-large) {
          display: block;
        }
      }
    }
  }
  &__ba-iframe {
    display: block;
    -webkit-animation: slide-down 2s;
    -moz-animation: slide-down 2s;
    -o-animation: slide-down 2s;
    animation: slide-down 2s;
  }
  .pc_hidden {
    display: block;
    @include start-breakpoint($medium-min) {
      display: none;
    }
  }
  .mobile_hidden {
    display: none;
    @include start-breakpoint($medium-min) {
      display: inline-block;
      vertical-align: top;
      width: 39%;
      height: 100%;
      position: relative;
    }
  }
  &__recommended-products--disclaimer {
    margin-top: 10px;
    display: inline-block;
    font-family: $helvetica-bold-font-family;
    font-size: 16px;
    &-copy {
      font-family: $helvetica-regular-font-family;
      font-size: 16px;
    }
  }
}

.full-viewport {
  @include breakpoint-range(0, $medium-ipad-p) {
    #page-footer-region,
    // hide only gnav top when CCR iframe uses full viewport
    .gnav-header-formatter__top {
      display: none;
    }
  }
}

#clinical-reality,
.clinical-reality,
.diagnostics-header {
  .outer-wrap {
    max-width: $ccr-wrapper-width;
  }
  background-color: $color-white;
  &.outer-wrap {
    clear: both;
  }
}

#landing,
#diagnostics {
  .clinical-reality__intro {
    display: block;
    max-width: $ccr-wrapper-width;
    margin: auto;
    @include start-breakpoint($large-min) {
      margin: auto auto 5em;
    }
    position: relative;
  }
  .blue-ocean-merch-window {
    display: none;
  }
  .clinical-reality {
    max-width: none;
  }
  .clinical-reality__results-intro {
    margin-bottom: 30px;
  }
  .clinical-reality__recommended-products {
    .loading-status {
      position: absolute;
      bottom: 12px;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      margin: auto;
    }
  }
}

#landing,
#diagnostics {
  .clinical-reality {
    margin-top: 30px;
  }
  .clinical-reality__modules--foundation-finder {
    border-bottom: 0;
  }
  .clinical-reality__results-intro {
    margin-bottom: 16px;
    padding: 0;
    &--title {
      font-family: $helvetica-light-font-family;
      font-size: 36px;
      line-height: 38px;
      color: $color-black;
      width: auto;
      margin-top: 16px;
      margin-bottom: 13px;
      span {
        color: $color-cl-green;
      }
    }
    &--intro-retake {
      margin-#{$rdirection}: 0;
      padding: 5px;
      border: solid 2px $color-cl-green;
      color: $color-cl-green;
      background: none;
      font-size: 12px;
    }
    &--description {
      width: auto;
      height: auto;
      float: none;
      clear: both;
      font-size: 16px;
      min-height: 0;
    }
    &--last-taken {
      overflow: auto;
      font-family: $helvetica-regular-font-family;
      font-size: 16px;
    }
    &--description-copy {
      display: none;
    }
    &-button-options {
      margin-top: 12px;
    }
  }
}

#diagnostics {
  .clinical-reality {
    &__results-intro--intro-retake {
      line-height: 31px;
      @include start-breakpoint($medium-m) {
        line-height: 1.5;
      }
    }
    .outer-wrap {
      padding: 0 20px;
    }
  }
}

.clinical-reality {
  &__modules {
    border-bottom: solid 1px $color-lightest-grey-3;
    overflow: auto;
    @include swap_direction(padding, 0 0 60px 0);
    margin-bottom: 20px;
    @include start-breakpoint($medium-m) {
      @include swap_direction(padding, 0 0 96px 0);
      margin-bottom: 24px;
    }
    &--no-skin-products {
      margin-bottom: 0;
      border: 0;
    }
    &--recommended-products {
      .ccr-flexslider .flex-viewport {
        max-height: 730px;
        @include start-breakpoint($medium-m) {
          max-height: 665px;
        }
      }
    }
  }
  &__results-intro {
    overflow: auto;
    padding: 30px 0 30px 0;
    border: none;
    margin-bottom: 0;
    &-button-options {
      .clinical-reality--button {
        @include start-breakpoint($medium-m) {
          max-width: 258px;
        }
        @include start-breakpoint($medium-max) {
          max-width: calc(50% - 10px);
        }
        @include start-breakpoint($large-min) {
          max-width: 258px;
        }
      }
    }
    &--user-name {
      text-transform: capitalize;
    }
    @include start-breakpoint($medium-m) {
      padding: 96px 0 60px 0;
      margin-bottom: 0;
      clear: both;
    }
    &--title {
      @include header(32px, $helvetica-bold-font-family);
      width: 100%;
      margin-bottom: 5px;
      /* iPhone non plus devices */
      @media only screen and (min-device-width: $minimum-device-width) and (max-device-width: $maximum-device-width) {
        @include header(24px, $helvetica-bold-font-family);
        line-height: 30px;
      }
      @include start-breakpoint($medium-min) {
        @include header(48px, $helvetica-bold-font-family);
        margin-bottom: 20px;
        color: $color-black;
        width: 48%;
        float: #{$ldirection};
        line-height: 50px;
      }
    }
    &--description {
      width: 100%;
      @include header(20px, $helvetica-bold-font-family);
      padding: 0;
      color: $color-black;
      min-height: 100px;
      @include start-breakpoint($medium-min) {
        @include header(28px, $helvetica-bold-font-family);
        width: 48%;
        float: #{$rdirection};
      }
      p {
        line-height: 20px;
        @include start-breakpoint($medium-min) {
          line-height: 30px;
        }
      }
    }
    &--description-copy {
      padding: 0;
      margin: 0;
      min-height: 100px;
    }
  }
  &--button {
    color: $color-black;
    border: solid 2px $color-grey;
    background-color: $color-white;
    font-family: $helvetica-bold-font-family;
    font-size: 16px;
    padding: 16px 30px;
    margin: 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    &:hover {
      color: $color-black;
      background-color: $color-white;
      border-color: $color-black;
    }
    @include start-breakpoint($medium-m) {
      float: #{$ldirection};
      width: auto;
      margin-#{$rdirection}: 20px;
      margin-bottom: 0;
    }
    @include start-breakpoint($medium-max) {
      padding: 20px 50px;
      margin-bottom: 0;
      max-width: 258px;
    }
  }
  &__recommended-products--add-to-bag,
  &__results-intro--intro-retake {
    margin: 0;
  }
  &__intro--intro-save {
    margin-bottom: 20px;
    @include start-breakpoint($medium-m) {
      margin-#{$rdirection}: 20px;
    }
  }
  &__button-options {
    overflow: auto;
    margin-top: 15px;
    float: #{$ldirection};
    width: 100%;
    @include start-breakpoint($medium-m) {
      width: auto;
      margin-top: 27px;
    }
  }
  &__save-button-options {
    padding: 0;
    margin-top: 28px;
    @include start-breakpoint($medium-m) {
      padding-#{$ldirection}: 20px;
      margin-top: 35px;
    }
  }
  &__diagnostic--landing {
    .add-all-to-bag {
      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
  &__recommended-products {
    &--product-price {
      margin-top: 10px;
      display: block;
      font-size: 16px;
      @include start-breakpoint($medium-m) {
        font-size: 20px;
      }
    }
    &--video {
      background-image: url(/media/export/cms/ccr_loading_animation.gif);
      background-repeat: no-repeat;
      background-position: center 40px;
    }
    &--image-banner {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    &-container {
      padding-bottom: 48px;
      a.clinical-reality__skin-products--add-all-bag {
        margin-top: 10px;
      }
    }
    &--container {
      .flexslider .slides > li {
        display: block;
        position: relative;
      }
      @include start-breakpoint($medium-m) {
        padding: 20px;
      }
      @include start-breakpoint($large-min) {
        padding: 0;
      }
      a.product-add-to-bag {
        border: 2px solid $color-grey;
        background: $color-white;
        color: $color-black;
        font-size: 16px;
        text-transform: none;
        font-family: $helvetica-bold-font-family;
        padding: 20px;
        min-width: 100px;
        line-height: 16px;
        height: auto;
        margin: 0;
        @include start-breakpoint($medium-m) {
          min-width: 210px;
          line-height: 1.5;
        }
      }
    }
    &--learn-more {
      margin-bottom: 20px;
      @include start-breakpoint($medium-m) {
        margin-bottom: 0;
      }
    }
    &--disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .outer-wrap {
    max-width: $ccr-wrapper-width;
  }
  &__article-container--wink {
    overflow: auto;
    padding-#{$ldirection}: 0;
    padding-#{$rdirection}: 0;
    border-bottom: 0;
    @include start-breakpoint($medium-m) {
      padding-#{$ldirection}: 20px;
      padding-#{$rdirection}: 20px;
      border-bottom: solid 1px $color-lightest-grey-3;
    }
    @include start-breakpoint($large-min) {
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
    }
  }
  &__module--title {
    font-family: $helvetica-bold-font-family;
    font-size: 20px;
    letter-spacing: 0.005em;
    text-transform: none;
    color: $color-black;
    margin-bottom: 40px;
    line-height: 1;
    @include start-breakpoint($medium-m) {
      font-size: 28px;
      line-height: 34px;
    }
    &-copy {
      text-transform: lowercase;
    }
  }
  &__module--header {
    padding: 20px 0;
    height: 185px;
    background-image: url(/sites/clinique/themes/cl_base/img/bg-supercat-header.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: 45px;
  }
  &__module-full-width.outer-wrap {
    max-width: none;
    height: auto;
    padding: 20px;
    @include start-breakpoint($medium-max) {
      padding: 20px 0;
    }
  }
  &__foundation-finder,
  &__save-results {
    width: 100%;
    @include start-breakpoint($medium-min) {
      border-top: 1px solid $color-grey;
      width: 48%;
      float: #{$ldirection};
      padding-top: 16px;
    }
  }
  &__foundation-finder {
    margin-#{$rdirection}: 2%;
    .clinical-reality__module--title {
      text-transform: capitalize;
    }
    .category-title {
      display: none;
    }
    .clinical-reality__mpp--product {
      .product-image {
        @include start-breakpoint($medium-m) {
          min-height: 247px;
          margin-bottom: 0;
        }
        @include start-breakpoint($medium-max) {
          min-height: 266px;
        }
        @include start-breakpoint($large-min) {
          min-height: 303px;
        }
      }
    }
  }
  &__save-results {
    overflow: auto;
    @include breakpoint($medium-m) {
      border-top: solid 1px $color-lightest-grey-3;
      margin-top: 40px;
      padding-top: 20px;
    }
    &--cta {
      @include breakpoint($medium-m) {
        margin-bottom: 0;
      }
    }
    &--left {
      width: 50%;
      @include start-breakpoint($medium-m) {
        width: 45%;
      }
      float: #{$ldirection};
    }
    &--right {
      width: 50%;
      @include start-breakpoint($medium-m) {
        width: 55%;
      }
      font-size: 16px;
      float: #{$rdirection};
      padding-#{$ldirection}: 20px;
    }
    .clinical-reality__save-button-options {
      padding-#{$ldirection}: 0;
      @include start-breakpoint($medium-m) {
        padding-#{$ldirection}: 20px;
      }
    }
    .clinical-reality--button {
      @include start-breakpoint($medium-max) {
        margin-#{$rdirection}: 0;
      }
    }
    &--image {
      max-width: 280px;
      @include breakpoint-range(0, $medium-m) {
        max-width: none;
      }
      width: 100%;
    }
    &--description {
      padding: 0;
      margin: 0;
    }
    .clinical-reality__module--title-username {
      text-transform: lowercase;
    }
    .clinical-reality__module--title-copy {
      text-transform: capitalize;
    }
  }
  &__article--cta {
    font-family: $helvetica-light-font-family;
    font-size: 16px;
    border-bottom: solid 1px $color-black;
    padding-bottom: 4px;
    line-height: 1;
    color: $color-black;
    margin-top: 19px;
    font-weight: normal;
    display: inline-block;
    letter-spacing: 1px;
    &:hover {
      color: $color-black;
    }
  }
  &__article--wink {
    height: auto;
    max-height: none;
    max-width: none;
    overflow: hidden;
    padding: 0;
    width: 100%;
    font-family: $helvetica-light-font-family;
    margin-bottom: 36px;
    @include start-breakpoint($medium-m) {
      margin: 0 auto;
      max-width: 550px;
      width: 50%;
      float: #{$ldirection};
    }
    .article-section-header-text {
      margin: 0;
      padding: 0;
      text-align: center;
    }
    .article-cat-tag {
      font-family: $helvetica-light-font-family;
      font-kerning: normal;
      font-size: 13px;
      letter-spacing: 0.13em;
      text-transform: uppercase;
      color: $color-black;
      @include swap_direction(padding, 16px 0 6px);
    }
    .article-title {
      display: block;
      margin-bottom: 0;
      padding-bottom: 19px;
      font-family: $helvetica-bold-font-family;
      font-size: 24px;
      letter-spacing: 0.005em;
      text-transform: capitalize;
      color: $color-black;
    }
    .article-subtitle {
      font-size: 15px;
      line-height: normal;
      max-width: auto;
      overflow: hidden;
      padding: 0;
      font-family: $helvetica-light-font-family;
      text-align: #{$ldirection};
      color: $color-black;
      @include start-breakpoint($medium-m) {
        width: 70%;
      }
    }
    img.article-img {
      height: auto;
      max-width: none;
      width: 100%;
    }
  }
  &__article--left {
    @include start-breakpoint($medium-m) {
      padding-#{$rdirection}: 10px;
    }
    @include start-breakpoint($large-min) {
      padding-#{$rdirection}: 0;
      margin-#{$rdirection}: 12px;
    }
  }
  &__article--right {
    margin-bottom: 0;
    @include start-breakpoint($medium-m) {
      padding-#{$ldirection}: 10px;
    }
    @include start-breakpoint($large-min) {
      padding-#{$ldirection}: 0;
      margin-#{$ldirection}: 12px;
    }
  }
}

@-webkit-keyframes slide-down {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes slide-down {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Recommended products modules */
.clinical-reality__modules--recommended-products {
  .flexslider {
    padding: 0;
  }
  .flex-viewport {
    max-width: $ccr-wrapper-width;
    max-height: 660px;
  }
  .clinical-reality__button-options {
    margin-top: 40px;
    @include start-breakpoint($medium-m) {
      margin-top: 25px;
    }
  }
  .clinical-reality__recommended-products {
    position: relative;
    .clinical-reality__recommended-product {
      position: relative;
    }
    &--image-container {
      min-height: 330px;
      margin-bottom: 20px;
      @include start-breakpoint($medium-min) {
        min-height: 660px;
        margin-bottom: 0;
      }
      background: $color-bg-hero-banner;
      z-index: 0;
      position: relative;
      &.clinical-reality__recommended-products-no-eyebrow {
        background: none;
      }
    }
    &--image {
      background-size: 100% auto;
      @include start-breakpoint($medium-min) {
        background-size: auto;
      }
      width: auto;
      background-repeat: no-repeat;
      background-position: #{$rdirection} bottom;
      min-height: inherit;
    }
    &--name {
      position: absolute;
      top: 20px;
      #{$ldirection}: 20px;
      @include start-breakpoint($medium-m) {
        top: 48px;
        #{$ldirection}: 48px;
      }
      font-size: 16px;
      font-weight: bold;
    }
    &--mix {
      background: url(/media/clinicalreality/results_page/mix.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
      height: 30px;
      width: 50px;
      top: 20px;
      #{$rdirection}: 20px;
      @include start-breakpoint($medium-m) {
        height: 50px;
        width: 80px;
        #{$rdirection}: 48px;
        top: 48px;
      }
      position: absolute;
      #{$ldirection}: auto;
    }
    &--carousel {
      position: absolute;
      top: 40%;
      #{$ldirection}: 20px;
      @include start-breakpoint($medium-m) {
        bottom: 48px;
        top: auto;
        #{$ldirection}: 48px;
      }
      z-index: 1;
    }
    &--title {
      margin-bottom: 30px;
    }
    &--header h2 {
      float: #{$ldirection};
      width: 50%;
      height: 100%;
    }
    &--description {
      position: relative;
      #{$ldirection}: auto;
      #{$rdirection}: auto;
      transform: none;
      @include start-breakpoint($medium-min) {
        #{$ldirection}: 48px;
        position: absolute;
        top: 50%;
        width: 41%;
        transform: translate(0, -50%);
      }
    }
    &--description-content {
      @include header(18px, $helvetica-bold-font-family);
      @include start-breakpoint($medium-m) {
        @include header(26px, $helvetica-bold-font-family);
      }
      color: $color-black;
      z-index: 1;
      .category-title {
        font-size: 16px;
        margin-bottom: 7px;
      }
    }
    &--product {
      margin: 0;
      color: $color-black;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
    .clinical-reality__button-options {
      .loading-status {
        overflow: auto;
        max-width: 60px;
        float: #{$rdirection};
      }
    }
    &--separator {
      margin-top: 5px;
      margin-bottom: 5px;
      @include start-breakpoint($medium-m) {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      color: $color-black;
    }
    &--image-banner {
      width: 100%;
    }
    &--details {
      width: 100%;
      position: relative;
    }
  }
}

.clinical-reality__modules-skin {
  @include start-breakpoint($medium-m) {
    border: none;
    margin-bottom: 0;
  }
}

.clinical-reality__foundation-finder--products,
.clinical-reality__invisible-damage--products {
  .flexslider {
    padding: 0;
  }
  .flex-control-nav,
  .flex-direction-nav {
    display: none;
  }
  .clinical-reality__mpp--product-shop-now {
    min-width: auto;
    @include start-breakpoint($medium-max) {
      min-width: 210px;
    }
  }
  .clinical-reality__mpp--product {
    margin-bottom: 0;
    &-image {
      display: block;
    }
    .product-title {
      min-height: 54px;
    }
    .product-image {
      width: 40%;
      @include start-breakpoint($medium-max) {
        width: 50%;
      }
      float: #{$ldirection};
      &:hover {
        .product-add-to-bag {
          display: none;
        }
      }
    }
    .product-info {
      width: 60%;
      @include start-breakpoint($medium-max) {
        width: 50%;
      }
      display: inline-block;
      padding-#{$ldirection}: 24px;
    }
  }
  .clinical-reality__foundation--carousel,
  .clinical-reality__invisible--carousel {
    #{$ldirection}: calc(40% + 24px);
    top: 206px;
    @include start-breakpoint($medium-m) {
      top: 214px;
    }
    @include start-breakpoint($medium-max) {
      #{$ldirection}: 54%;
    }
    position: absolute;
    z-index: 1;
  }
}

.clinical-reality__mpp {
  &--account-page {
    .clinical-reality__mpp--product {
      margin-bottom: 0;
      .product-image,
      .product-info {
        width: 100%;
        @include start-breakpoint($medium-m) {
          width: 95%;
          margin-#{$rdirection}: 2%;
        }
      }
      .product-image img {
        max-height: 75%;
      }
      .product-add-to-bag {
        text-align: center;
      }
      .product-price {
        display: none;
      }
    }
    .flexslider-disabled {
      .clinical-reality__mpp--product {
        width: 100%;
        margin-#{$rdirection}: 0;
        @include start-breakpoint($medium-m) {
          width: 23%;
          float: #{$ldirection};
          margin-#{$rdirection}: 2%;
        }
        .product-image,
        .product-info {
          width: 100%;
          margin-#{$rdirection}: 0;
        }
      }
    }
  }
  &--product {
    &-status {
      color: $color-black;
      margin-top: 10px;
    }
    &-soldout {
      cursor: default;
      pointer-events: none;
    }
    &-coming-soon {
      margin-top: 10px;
      .btn-comingsoon,
      .email-me {
        clear: #{$ldirection};
        float: #{$ldirection};
        margin-bottom: 3px;
      }
    }
    &-shop-now {
      margin-top: 35px;
      min-width: 210px;
    }
    .product-image {
      min-height: 270px;
      @include start-breakpoint($medium-m) {
        margin-bottom: 22px;
        min-height: 298px;
      }
      @include start-breakpoint($medium-max) {
        min-height: 349px;
      }
      position: relative;
      width: auto;
      background: $color-bg-product-img;
      margin-bottom: 20px;
      img {
        max-height: 270px;
        #{$ldirection}: 50%;
        transform: translate(-50%, 0);
        width: auto;
        @include start-breakpoint($medium-m) {
          max-height: none;
        }
        position: absolute;
        bottom: 0;
        max-width: 90%;
      }
      &:hover {
        .product-add-to-bag {
          display: none;
          @include start-breakpoint($medium-m) {
            display: block;
          }
          &.disabled {
            pointer-events: none;
            cursor: default;
            color: $color-light-grey;
          }
        }
      }
    }
    .product-info {
      display: inline-block;
      vertical-align: bottom;
      width: auto;
      font-size: 16px;
      margin-bottom: 10px;
      float: #{$ldirection};
      @include start-breakpoint($medium-m) {
        min-height: 94px;
        float: none;
      }
      .product-title {
        margin-bottom: 10px;
        a {
          font-family: $helvetica-regular-font-family;
          color: $color-black;
        }
      }
      .category-title {
        font-weight: bold;
        margin-bottom: 12px;
      }
      .product-price,
      .combined-product-price {
        color: $color-dark-grey;
      }
    }
    .product-add-to-bag {
      position: absolute;
      bottom: 12px;
      width: 90%;
      #{$ldirection}: 50%;
      display: none;
      transform: translate(-50%, 0);
    }
    .small-product-add-to-bag {
      background: transparent;
      color: $color-black;
      text-transform: capitalize;
      font-size: 14px;
      line-height: 14px;
      height: auto;
      border-bottom: 1px solid $color-black;
      padding-bottom: 5px;
      width: auto;
      display: inline-block;
      @include start-breakpoint($medium-m) {
        display: none;
      }
    }
    margin-bottom: 60px;
    @include start-breakpoint($medium-m) {
      margin-bottom: 94px;
    }
  }
  &--carousel {
    position: absolute;
    bottom: 17px;
    z-index: 1000;
    @include start-breakpoint($medium-m) {
      bottom: 36px;
    }
  }
}

.clinical-reality__skin-products--products {
  .ccr-flexslider {
    .flex-direction-nav {
      display: block;
      a {
        top: 30%;
      }
    }
    .clinical-reality__skin-products--product {
      .flex-direction-nav {
        display: none;
      }
    }
  }
}

.clinical-reality__modules--recommended-products {
  &.clinical-reality__mpp--account-page {
    .ccr-flexslider {
      .flex-direction-nav {
        display: block;
        a {
          top: 30%;
        }
      }
    }
  }
}

.ccr-flexslider {
  padding: 0;
  &.flexslider-disabled {
    .clinical-reality__mpp--product-shop-now {
      margin-top: 35px;
    }
  }
  .flex-control-nav,
  .flex-direction-nav {
    display: none;
    .flex-next,
    .flex-next:hover {
      #{$rdirection}: 23px;
    }
  }
  .flex-viewport {
    max-height: 546px;
    @include start-breakpoint($medium-m) {
      max-height: 665px;
    }
  }
  &--left-nav,
  &--right-nav {
    text-decoration: none;
    margin-#{$rdirection}: 17px;
    float: #{$ldirection};
    background-image: url(/media/export/cms/nav-arrows.png);
    background-repeat: no-repeat;
    height: 24px;
    opacity: 1;
    position: relative;
    text-indent: -9999px;
    width: 9px;
  }
  &--right-nav {
    background-position: #{$rdirection} 0;
    width: 10px;
  }
  &--status {
    margin: 8px 0 0;
    font-size: 16px;
    display: inline-block;
  }
  .ccr-flexslider--nav {
    display: none;
  }
  &.clinical-reality-slider-active {
    .ccr-flexslider--nav {
      display: block;
    }
  }
}

.clinical-reality__skin-products {
  .flex-viewport {
    max-width: $ccr-wrapper-width;
  }
  &--product {
    width: 100%;
    float: #{$ldirection};
    @include start-breakpoint($medium-min) {
      width: 23%;
      margin-#{$rdirection}: 2%;
    }
  }
  .skin-slider {
    .clinical-reality__skin-products--product {
      width: 100%;
      @include start-breakpoint($medium-m) {
        width: 95%;
      }
    }
    .clinical-reality__skin-products--carousel {
      display: inline-block;
    }
  }
  &--carousel {
    width: auto;
    display: inline-block;
    margin-#{$rdirection}: 20px;
  }
}
/* Styles needs to be overridden */
.device-mobile {
  h3.clinical-reality__module--title {
    margin-bottom: 40px;
  }
  .diagnostics-header {
    .clinical-reality__module-full-width.outer-wrap {
      padding: 20px;
    }
  }
}

#landing .recommended-for-you {
  display: none;
}

.clinical-reality-before-after--build {
  display: none;
  &.active {
    display: block;
  }
}

.clinical-reality__ba--build {
  .ccr-flexslider .flex-viewport {
    max-height: 730px;
  }
  .clinical-reality {
    &__button-options {
      @include start-breakpoint($medium-m) {
        width: 477px;
      }
    }
    &__recommended-products {
      &--see-your-future,
      &--see-your-future:hover {
        color: $color-white;
        background-color: $color-black;
        border-color: $color-black;
      }
      &--see-your-future {
        background: $color-black url(/media/images/icons/take_photo.png) no-repeat 70px;
        background-size: 50px;
      }
      &--learn-more {
        margin-bottom: 20px;
      }
    }
  }
}

.device-mobile {
  .clinical-reality {
    &__modules--recommended-products,
    &__foundation-finder--products,
    &__invisible-damage--products {
      .flex-direction-nav {
        display: none;
      }
    }
  }
}
.clinical-reality {
  &__results-intro-button-options {
    display: block !important;
  }
  &__recommended-products {
    &--learn-more,
    &--add-to-bag {
      color: $color-black;
      float: $ldirection;
      font-family: $helvetica-bold-font-family;
      font-size: 18px;
      font-weight: 400;
      margin-top: 20px;
      text-decoration: underline;
    }
    &--add-to-bag {
      float: $rdirection;
    }
    &--description-content {
      .loading-status {
        float: $rdirection;
        height: auto !important;
        max-width: 60px;
        overflow: auto;
      }
    }
  }
  &__modules--recommended-products {
    .category-title {
      #{$ldirection}: auto;
      color: $color-black;
      font-size: 16px;
      font-weight: 700;
      position: absolute;
      top: 10px;
    }
    .clinical-reality {
      &__recommended-products--image-container {
        min-height: 373px;
      }
      &__button-options {
        margin-top: 10px;
      }
    }
  }
  &__other-recommended-products {
    padding-bottom: 20px;
    .clinical-reality__recommended-product {
      overflow: auto;
    }
  }
  &__button-options {
    .clinical-reality__recommended-products {
      &--learn-more,
      &--add-to-bag {
        text-decoration: none;
      }
    }
  }
  &__recommended-products--carousel.ccr-flexslider--top-nav {
    #{$ldirection}: auto;
    #{$rdirection}: 20px;
    top: 0;
  }
  &__results-intro--title {
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .clinical-reality__alternate-images {
    .clinical-reality__recommended-products--image-container {
      background: none;
      margin-top: 20px;
    }
    .category-title {
      top: -20px;
    }
    .ccr-flexslider--top-nav {
      top: -8px;
    }
    .slick-dots {
      bottom: -22px;
      li {
        button::before {
          font-size: 10px;
        }
      }
    }
    .slick-slider {
      touch-action: inherit;
    }
    .clinical-reality__video-playicon::before {
      #{$ldirection}: 50%;
      background: url('#{$base-theme-path}/img/icons/src/new-play-icon.svg') center center no-repeat;
      background-size: 70px 70px;
      content: '';
      filter: invert(1);
      height: 70px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      z-index: 100;
    }
    .clinical-reality__product-alt--videos {
      height: 28vh;
      width: 100%;
    }
  }
}

#clinical-reality {
  height: auto;
  .clinical-reality {
    &.outer-wrap {
      padding: 0 20px;
    }
    &__invisible-damage {
      margin-#{$rdirection}: 2%;
      width: 100%;
      &--title {
        font-family: $helvetica-bold-font-family;
        font-size: 20px;
        letter-spacing: 0.005em;
        line-height: 1;
        margin-bottom: 30px;
        margin-top: 10px;
        text-transform: none;
      }
      &--subtitle {
        color: $color-black;
        font-family: $helvetica-regular-font-family;
        font-size: 16px;
        margin-bottom: 30px;
      }
      .clinical-reality--button {
        background-color: $color-black;
        background-size: 50px;
        border-color: $color-black;
        color: $color-white;
      }
    }
    &__recommended-products--learn-more {
      font-family: $helvetica-regular-font-family;
      font-size: 16px;
    }
  }
}
