// Signin Page
#gnav_signin {
  .signin_container {
    width: auto;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 13px;
    .row {
      width: 100%;
      overflow: hidden;
    }
    footer {
      overflow: hidden;
      padding-top: 0;
      width: 100%;
      p {
        margin-top: 15px;
      }
    }
    .pane {
      display: none;
      @include swap_direction(padding, 35px 0 17px 0);
      h2.myaccount-hdr {
        float: #{$ldirection};
      }
      h2.myaccount-hdr + p {
        float: #{$ldirection};
        @include swap_direction(margin, 10px 10px 10px 0px);
        a {
          margin-#{$ldirection}: 10px;
        }
      }
      &.pane-loading {
        width: 100%;
        height: 100%;
        background-color: #fff;
        text-align: center;
        padding: 50px 0;
      }
    }
    .rpc-messages .error,
    .rpc-messages .loading {
      display: none;
      padding: 8px 12px;
      margin: 10px 0;
    }
    form {
      .row {
        &.privacy-form-container,
        &.email_promotions {
          input[type='checkbox'] {
            vertical-align: 12px;
          }
          span {
            display: inline-block;
            width: 32%;
            margin-#{$ldirection}: 7px;
          }
          label {
            width: 95%;
            span {
              color: $color-btn-dark-gray;
              font-family: $helvetica-light-font-family;
            }
          }
        }
        .show {
          input {
            width: auto;
            height: auto;
            padding-#{$ldirection}: 0px;
          }
          label {
            text-transform: none;
          }
        }
        margin-bottom: 15px;
      }
      label {
        padding-bottom: 5px;
        display: inline-block;
      }
    }
    .remember-me {
      float: #{$ldirection};
      margin-top: 10px;
      margin-#{$ldirection}: 20px;
    }
    .show,
    .forgot-pw {
      display: inline-block;
      margin-#{$ldirection}: 10px;
      padding-top: 2px;
      vertical-align: middle;
    }
    .sign_text {
      margin-bottom: 15px;
      margin-top: 0;
    }
    fieldset {
      border: medium none;
      height: auto;
      margin-bottom: 0;
      padding: 0px;
    }
    #new-account .signin-header {
      margin-bottom: 0;
    }
    #signin-block {
      form {
        input[type='text'],
        input[type='email'],
        input[type='password'] {
          height: 36px;
          padding-#{$ldirection}: 7px;
          width: 235px;
          line-height: 15px;
        }
      }
    }
    .sign-in {
      .login-email {
        width: 55%;
        float: #{$ldirection};
        @if $loyalty == true {
          width: 43%;
        }
      }
    }
  }
  #signin-block.inner-block {
    width: 100%;
  }
  .signin_submit {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .submit-note {
    margin-top: 6px;
  }
  #signin-block input[type='checkbox'] {
    width: auto;
  }
  .remember-me span {
    margin-#{$ldirection}: 5px;
  }
}
