a {
  cursor: pointer;
}

.favorite-products {
  @include swap_direction(padding, 40px 0 30px 0);
}

.favorite-products header h2 {
  color: #231f20;
  @include swap_direction(margin, 0 0 25px 0);
}

.favorite-products header h2 a {
  color: #46ab8c;
  display: inline-block;
  font-size: 22px;
  @include swap_direction(padding, 0 0 0 20px);
  vertical-align: top;
}

.favorite-products .wishlist-header-content .button {
  font-size: 18px;
}

.favorite-products .share-favorites {
  float: #{$rdirection};
  height: 20px;
}

.favorite-products .share-favorites .share-button {
  background: transparent url(/media/images/css/icons-share-buttons.png) 0 0 no-repeat;
  float: #{$rdirection};
  height: 20px;
  @include swap_direction(margin, 0 0 0 10px);
  overflow: hidden;
  text-indent: -9999px;
}

.favorite-products .share-favorites .print {
  background-position: 0 0;
  width: 26px;
}

.favorite-products .share-favorites .email {
  background-position: -35px 0;
  width: 25px;
}

.favorite-products .share-favorites .facebook {
  background-position: -68px 0;
  width: 11px;
}

.favorite-products .share-favorites .twitter {
  background-position: -86px 0;
  width: 19px;
}

.favorite-products .share-favorites .pin-it-button {
  background-position: -111px 0;
  width: 19px;
}

.favorite-products .save-list-anon {
  clear: both;
  float: #{$rdirection};
  @include swap_direction(margin, 10px 0 0 0);
}

.favorite-products-grid {
  clear: both;
}

.favorite-products-grid .favorite {
  float: left;
  width: 175px;
  margin: 10px 8px;
  height: 400px;
}
