@import '../base/base';

.product-kit {
  color: $color-black;
  margin: 0 auto 30px;
  max-width: $cr19-max-width-xlarge;
  &__banner-image {
    height: 380px;
    mix-blend-mode: multiply;
    outline: 0;
    width: 100%;
    @media #{$medium-up} {
      float: $rdirection;
      height: 300px;
      margin: 0;
      padding: 0;
    }
  }
  &__banner {
    background: $cr19-bg-light;
    height: 380px;
    margin-bottom: 20px;
    width: 100%;
    @media #{$medium-up} {
      height: 300px;
      width: 100%;
    }
  }
  &__content {
    margin: 0 auto;
    max-width: 80%;
    @media #{$medium-up} {
      display: inline;
    }
  }
  &__products.slick-slider {
    margin-bottom: 0;
    width: 100%;
    @media #{$medium-up} {
      margin-bottom: 12px;
    }
  }
  &__banner-info {
    height: 183px;
    padding-#{$ldirection}: 20px;
    position: absolute;
    width: 335px;
    @media #{$medium-up} {
      height: 300px;
      #{$ldirection}: 0;
      padding-#{$ldirection}: 62px;
      padding-top: 30px;
      width: 640px;
      z-index: 1;
    }
    @media #{$medium-only} {
      padding-top: 0;
    }
  }
  &__image-container {
    background: $cr19-bg-light;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
    width: 190px;
    .product-kit-small__image {
      height: 0;
      visibility: hidden;
    }
    @media #{$medium-up} {
      border: 2px solid transparent;
      margin-bottom: 10px;
      width: 94%;
    }
  }
  &__image {
    width: 100%;
    @media #{$medium-up} {
      margin-bottom: 0;
    }
  }
  &__menu-item {
    border-top: 1px solid $color-grey;
    padding: 10px 0 20px;
    @media #{$medium-up} {
      padding: 0;
      position: relative;
    }
    &:nth-of-type(3) {
      border-bottom: 1px solid $color-grey;
    }
  }
  &__selected-product {
    letter-spacing: 1px;
    padding: 0;
  }
  &__banner-name {
    color: $color-cl-light-black;
    font: normal 400 26px / 1 $helvetica-bold-font-family;
    letter-spacing: -0.02em;
    margin-bottom: 4px;
    padding-top: 15px;
    text-align: $ldirection;
    @media #{$medium-up} {
      font-size: 50px;
    }
  }
  &__content-wrapper {
    margin: 0 30px;
    width: auto;
    @media #{$medium-up} {
      margin: 0;
      padding-#{$ldirection}: 72px;
      padding-#{$rdirection}: 72px;
      width: 100%;
    }
  }
  &__banner-content {
    bottom: 12px;
    position: relative;
    width: 317px;
    @media #{$medium-up} {
      padding-#{$rdirection}: 18px;
      width: 100%;
    }
  }
  &__banner-sub-heading {
    color: $color-black;
    font: normal 400 20px / 1 $helvetica-bold-font-family;
    letter-spacing: -0.02em;
    @media #{$medium-up} {
      font-size: 26px;
    }
  }
  &__banner-description {
    color: $color-black;
    font: normal 400 15px / 1.4 $helvetica-regular-font-family;
    letter-spacing: 0.01em;
    margin-top: 5px;
    @media #{$medium-up} {
      @include swap_direction(padding, 0 0 0 2px);
      font-size: 17px;
      text-align: $ldirection;
    }
  }
  &__banner-heading-list {
    font: normal 700 17px / 1.4 $helvetica-regular-font-family;
    letter-spacing: 0.02em;
  }
  &__image-content {
    @media #{$medium-up} {
      display: inline;
    }
  }
  &__label {
    cursor: pointer;
    @media #{$medium-up} {
      display: block;
      padding: 0;
    }
  }
  &__label-info {
    display: inline-block;
    width: 90%;
    @media #{$medium-up} {
      display: block;
    }
  }
  &__product-shades,
  &__product-sizes {
    .sbHolder {
      width: 185px;
      height: 36px;
      @media #{$medium-up} {
        width: 100%;
      }
      .sbToggle {
        margin-top: 6px;
      }
      .sbSelector {
        font: normal 400 13px / 2.4 $helvetica-bold-font-family;
        height: 34px;
        letter-spacing: 0.02em;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        white-space: nowrap;
      }
    }
    .sbOptions {
      max-height: 112px !important; // stylelint-disable-line declaration-no-important
      li {
        padding: 0;
        border-bottom: 1px solid $color-light-grey;
      }
      a {
        color: $color-black;
        display: flex;
        font: normal 400 13px / 2.4 $helvetica-bold-font-family;
        letter-spacing: 0.02em;
        text-align: $rdirection;
      }
    }
  }
  &__product-shades {
    a {
      justify-content: space-between;
    }
  }
  &__product-sizes {
    .sbHolder {
      background-color: unset;
    }
    .sbSelector {
      margin: 0;
    }
    ul {
      overflow: auto !important;
    }
    .sbOptions {
      a {
        justify-content: #{$ldirection};
        .filtered-swatch {
          display: block;
        }
      }
    }
  }
  &__product-thumb {
    display: inline-block;
    max-width: 85%;
    @media #{$medium-up} {
      display: inline-flex;
      width: auto;
    }
    .product-kit-small__image {
      height: 112px;
      visibility: visible;
      width: 100%;
    }
    .product-kit {
      &__product-size {
        display: block;
      }
      &__summary {
        min-height: 0;
        min-width: 300px;
      }
      &__products-list {
        display: inline-block;
        height: auto;
        margin-bottom: 5px;
        position: relative;
        width: 225px;
        @media #{$medium-up} {
          margin-bottom: 0;
          width: auto;
        }
      }
      .icon-selected {
        display: none;
      }
      &__image-container {
        @include swap_direction(margin, 0 10px 0 0);
        border: none;
        display: none;
        height: 125px;
        overflow: hidden;
        vertical-align: top;
        width: 100px;
        @media #{$medium-up} {
          @include swap_direction(margin, 10px 18px 10px 0);
          display: inline-block;
        }
        .product-kit__image {
          height: 100%;
          vertical-align: inherit;
          width: 100%;
          @media #{$medium-up} {
            margin-top: 0;
          }
        }
      }
      &__info {
        display: inline-block;
        width: 75%;
        @media #{$medium-up} {
          margin-#{$ldirection}: 0;
          min-width: 500px;
          min-height: 65px;
          position: absolute;
          top: 65px;
        }
        .product-kit {
          &__product-title {
            font-size: 16px;
            letter-spacing: 0.02em;
            line-height: 1.4;
            @media #{$medium-up} {
              font-size: 17px;
              margin-bottom: 6px;
              min-height: 0;
            }
          }
          &__product-subtitle {
            display: none;
            @media #{$medium-up} {
              font-size: 16px;
              letter-spacing: -0.05em;
              line-height: 1.2;
            }
          }
          &__image,
          &__product-shades,
          &__product-sizes,
          &__details,
          &__button {
            display: none;
          }
        }
      }
    }
  }
  &__product-info {
    letter-spacing: -0.05em;
    margin: 0;
    @media #{$medium-up} {
      display: none;
      margin: 15px 0 12px;
    }
    &.pc-hidden {
      .product-kit__product-size {
        display: none;
      }
    }
  }
  &__summary {
    min-height: 200px;
    min-width: 190px;
    @media #{$medium-up} {
      min-height: 230px;
      min-width: 140px;
    }
  }
  &__product-title {
    color: $color-cl-dark-grey;
    font: normal 400 15px / 1.4 $helvetica-regular-font-family;
    letter-spacing: 0.02em;
    @media #{$medium-up} {
      font-size: get-rem(17px);
    }
    @media #{$medium-only} {
      font-size: get-rem(16px);
    }
    &.title-active {
      color: $color-black;
    }
  }
  &__product-subtitle {
    color: $color-cl-dark-grey;
    font: normal 400 16px / 1.4 $helvetica-regular-font-family;
    letter-spacing: 0.02em;
  }
  &__details {
    margin-bottom: 20px;
    padding-top: 10px;
    a {
      font: normal 400 17px / 1 $helvetica-regular-font-family;
    }
  }
  &__sku-info {
    color: $color-cl-dark-grey;
    flex-grow: 1;
    font: normal 400 15px / 1.4 $helvetica-regular-font-family;
    letter-spacing: 0.02em;
  }
  &__product-size {
    display: none;
  }
  &__info {
    letter-spacing: 0.1em;
    width: 88%;
    @media #{$medium-up} {
      margin: 0;
    }
  }
  &__total-price {
    color: $color-cl-light-black;
    display: flex;
    flex-direction: row;
    font: normal 400 28px / 1.4 $helvetica-bold-font-family;
    letter-spacing: -0.02em;
    .strike-out-price {
      color: $color-light-grey-2;
      font-size: 17px;
      position: relative;
      text-decoration: line-through;
    }
    @media #{$medium-up} {
      bottom: 5px;
      float: $ldirection;
      font-size: 28px;
      justify-content: flex-end;
      position: relative;
      width: 73%;
    }
  }
  &__discount-price {
    align-content: center;
    color: $color-black;
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 1.4;
  }
  &__total-discount {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding-#{$ldirection}: 15px;
  }
  &__discount-percent {
    bottom: 5px;
    color: $color-light-grey-2;
    font-size: 15px;
    position: relative;
  }
  &__cta-container {
    @media #{$medium-up} {
      display: inline-block;
      float: $rdirection;
      height: 64px;
      margin-#{$ldirection}: 10px;
      width: 268px;
    }
  }
  &__cart-detail {
    margin: 20px 30px 15px;
    width: auto;
    @media #{$medium-up} {
      border-width: 2px;
      margin: 20px 0 0;
      padding-bottom: 0;
      padding-#{$ldirection}: 72px;
      padding-#{$rdirection}: 72px;
    }
  }
  &__add-to-bag {
    background-color: $color-grey;
    color: $color-white;
    font: normal bold 17px / 1 $helvetica-regular-font-family;
    margin: 19px 0;
    padding: 25px 15px;
    width: 100%;
    pointer-events: none;
    text-align: center;
    @media #{$medium-up} {
      bottom: 20px;
      font-size: 16px;
      letter-spacing: 2px;
      position: relative;
    }
  }
  &__add-to-bag.active {
    background-color: $pick_x_title-color;
    cursor: pointer;
    pointer-events: auto;
  }
  &__accordion-option {
    display: inline-block;
    float: $rdirection;
    @media #{$medium-up} {
      margin: 0;
      padding: 0;
      position: absolute;
      #{$rdirection}: 0;
      top: 26px;
    }
  }
  &__button {
    background-color: $color-white;
    border: 2px solid $color-grey;
    border-color: $color-grey;
    box-sizing: border-box;
    color: $color-cl-light-black;
    cursor: pointer;
    display: inline-block;
    font: normal bold 17px / 1.4 $helvetica-regular-font-family;
    height: 64px;
    letter-spacing: -0.01em;
    margin-bottom: 30px;
    outline: none;
    padding: 2px;
    text-align: center;
    width: 185px;
    @media #{$medium-up} {
      width: 90%;
    }
    &:hover {
      border: 2px solid $color-cl-light-black;
    }
  }
  .slick-arrow {
    &::before {
      content: none;
    }
  }
  .slick-prev {
    top: 33%;
    #{$ldirection}: -25px;
    transform: rotate(-180deg);
    background: url('#{$base-theme-path}/img/icons/src/arrow-16.svg') no-repeat center;
    @media #{$medium-up} {
      height: 30px;
      width: 30px;
      #{$ldirection}: -35px;
    }
  }
  .slick-disabled {
    opacity: 0.2;
    pointer-events: none;
  }
  .slick-next {
    #{$rdirection}: -20px;
    top: 33%;
    background: url('#{$base-theme-path}/img/icons/src/arrow-16.svg') no-repeat center;
    @media #{$medium-up} {
      height: 30px;
      width: 30px;
      #{$rdirection}: -15px;
    }
  }
  .slick-list {
    @include swap_direction(padding, 0 33% 0 0);
    @media #{$medium-up} {
      padding: 0;
    }
  }
  &__products-list {
    &.selected {
      border: none;
      outline: none;
    }
    &[tabindex]:focus {
      outline: none !important;
    }
  }
  .edit,
  .select {
    background: url('/media/images/icons/plus-icon.png') no-repeat;
    background-size: contain;
    display: inline-block;
    height: 14px;
    margin-top: 13px;
    width: 14px;
  }
  .close {
    background: url('/media/images/icons/plus-icon.png') no-repeat;
    background-size: contain;
    display: inline-block;
    height: 14px;
    margin-top: 13px;
    position: relative;
    #{$rdirection}: 0;
    top: 2px;
    transform: rotate(45deg);
    width: 14px;
  }
  .icon-option {
    color: $pick_x_title-color;
    display: none;
    font: normal 400 13px / 1.4 $helvetica-regular-font-family;
    text-shadow: 0 0 0 $color-black;
    @media #{$medium-up} {
      display: inline-block;
      font-size: 17px;
      letter-spacing: 0;
      margin-#{$rdirection}: 14px;
    }
  }
  .icon-selected {
    background: url('/media/export/cms/customkit/Tickmark.png') no-repeat;
    bottom: 8.17%;
    #{$ldirection}: 5.42%;
    position: absolute;
    #{$rdirection}: 9.58%;
    top: 4.92%;
  }
  .accordion {
    color: $pick_x_title-color;
    cursor: pointer;
    display: block;
    font: normal 400 22px / 1.4 $helvetica-bold-font-family;
    margin-top: 4px;
    letter-spacing: -0.02em;
    @media #{$medium-up} {
      font-size: 28px;
      margin-top: 24px;
      margin-bottom: 26px;
    }
  }
  .selected {
    .product-kit__image {
      border: 1px solid $cr19-border-dark;
      box-sizing: border-box;
    }
    .product-kit__button {
      background-color: $color-cl-dark-grey;
      border-color: $color-cl-dark-grey;
      color: $color-white;
    }
  }
  .filtered-swatch {
    display: block;
    float: $ldirection;
    height: 16px;
    margin: 7px -5px;
    width: 16px;
  }
}

.section-custom-kit {
  @media #{$medium-up} {
    .page-header {
      background: $color-white;
    }
  }
}

.editable {
  .product-kit__first-phrase {
    &.accordion {
      @media #{$medium-up} {
        #{$ldirection}: 115px;
        margin: 0;
        position: absolute;
        top: 25px;
      }
    }
  }
  .product-kit__accordion-option {
    bottom: 32px;
    position: relative;
    @media #{$medium-up} {
      bottom: 10px;
      position: absolute;
    }
  }
}
