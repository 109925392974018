.node-elc-nodeblock.contextual-links-region,
.block-template-mm-3col-1tout-v1.contextual-links-region {
  position: static;
}

.mm_3col_1tout_v1 {
  overflow: hidden;
  padding: 0 0 15px;
  .block-template-tout-product-v1,
  .block-template-tout-image-v1,
  .block-template-tout-image-link-v1,
  .block-template-tout-ff-v1 {
    float: #{$rdirection};
  }
  .block-template-tout-product-v1 {
    width: 33%;
  }
  .block-template-tout-image-v1,
  .block-template-tout-image-link-v1,
  .block-template-tout-ff-v1 {
    width: 37%;
  }
}
/*
 This is a quick and dirty fix for CL CN for the holiday weekend.
 The above used approach ".block-template-tout-product-v1" etc. is very unreliable.
 When any market clone a template this ".block-template-tout-product-v1" will be ".block-template-tout-product-v2" for that market, making it fail to get the above fix.
 This must be addressed by CL IL who orignally introduced this issue.
 */

html[lang='zh-e-CN'] {
  .mm_3col_1tout_v1 {
    .tout-image.tout {
      width: 37%;
    }
    .tout {
      float: right;
      width: 33%;
    }
  }
}
/* end of quick and dirty fix */

.mm_3col_1tout_v1 .tout {
  border-#{$ldirection}: 1px solid #dbdbdb;
  min-height: 290px;
  @include swap_direction(margin, 0 0 15px 0);
  @include swap_direction(padding, 0 0 0 25px);
}

.mm_3col_1tout_v1 .tout-image.tout {
  padding-#{$ldirection}: 15px;
}

.mm_3col_1tout_v1 .col {
  float: #{$ldirection};
  padding-top: 15px;
  width: 20.5%;
}

.mm_3col_1tout_v1.no-col-3 {
  .col {
    width: 30.5%;
    &.col-1 {
      padding-#{$rdirection}: 25px;
    }
  }
}

.mm_3col_1tout_v1 .col h2 {
  color: #282828;
  font-family: $helvetica-bold-font-family;
  font-size: 13px;
  line-height: 13px;
  @include swap_direction(margin, 0 0 5px 0);
  text-transform: uppercase;
}

.mm_3col_1tout_v1 .col ul {
  list-style: none;
  @include swap_direction(margin, 0 0 30px 0);
  padding: 0;
}

.mm_3col_1tout_v1 .col li {
  list-style: none;
  margin: 0;
  @include swap_direction(padding, 0 0 2px 0);
}

.mm_3col_1tout_v1 .col li a {
  @include cl-standard-link(#282828, false, $color-cl-green-2-darker);
  font-size: 15px;
  font-family: $helvetica-light-font-family;
  @if $cr19 == true {
    @include cl-standard-link(#282828, false, $cr19-text);
  }
}
/*
.mm_3col_1tout_v1 .product {
  color: #808080;
  font-size: 13px;
  @include swap_direction(margin,0 0 15px 0);
}
.mm_3col_1tout_v1 .product .product-image {
  float: #{$ldirection};
  margin: 0 9% 0 0;
  text-align: center;
  width: 37%;
}
.mm_3col_1tout_v1 .product .product-image img {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto;
}
.mm_3col_1tout_v1 .product .product-info {
  overflow: hidden;
}
.mm_3col_1tout_v1 .product .product-title {
  color: #231f20;
}
.mm_3col_1tout_v1 .product .button {
  @include swap_direction(margin,10px 0 0 0);
}
.mm_3col_1tout_v1 .product .button a {
  background: #5eb997;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  @include swap_direction(padding,7px 10px 4px 10px);
  text-transform: uppercase;
}
.mm_3col_1tout_v1 .product .button a:hover {
  text-decoration: none;
}
*/

.mm_3col_1tout_v1 .explore {
  bottom: 15px;
  clear: both;
  font-size: 15px;
  font-family: $helvetica-light-font-family;
  position: absolute;
  width: 14%;
}

.mm_3col_1tout_v1 .explore a.button.inverse {
  background-color: $color-white;
  border: 1px solid $color-cl-green-2;
  color: $color-cl-green-2;
  @if $cr19 == true {
    background-color: $color-white;
    border-color: $cr19-border-light;
    color: $cr19-text;
  }
}

@media screen and (max-width: 768px) {
  .mm_3col_1tout_v1 .tout {
    display: none;
  }
  .mm_3col_1tout_v1 .col {
    width: 33%;
  }
  .mm_3col_1tout_v1.no-col-3 {
    .col {
      width: 50%;
    }
  }
}
