.account-favorites-mylists {
  margin: 40px 0;
}

.account-favorites-mylists .outer-wrap {
  position: relative;
}

.account-favorites-mylists header {
  @include swap_direction(margin, 0 0 30px 0);
  width: 64%;
}

.account-favorites-mylists header h3 {
  color: #231f20;
  font-size: 36px;
  margin: 0;
}

.account-favorites-mylists header p {
  font-size: 22px;
  font-family: $helvetica-light-font-family;
  margin: 0;
}

.account-favorites-mylists .lists {
  min-height: 400px;
}

.account-favorites-mylists .lists .no-products .create-wishlist-form {
  display: none;
}

.account-favorites-mylists .lists .list {
  border: 1px solid lightgrey;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  display: inline-block;
  height: 400px;
  margin: 0 2% 20px 0;
  @include swap_direction(padding, 10px 15px 10px 15px);
  position: relative;
  vertical-align: top;
  width: 31%;
}

.account-favorites-mylists .lists .list > a {
  display: block;
  height: 300px;
  overflow: hidden;
}

.account-favorites-mylists .lists .list a.button {
  height: auto;
  margin: 0 auto;
  width: 40%;
}

.account-favorites-mylists .lists .list h3 {
  font-size: 22px;
  line-height: 1;
  @include swap_direction(margin, 0 0 10px 0);
}

.account-favorites-mylists {
  .lists {
    .list {
      .wishlist-block {
        height: 320px;
        overflow-y: auto;
      }
    }
  }
}

.account-favorites-mylists .lists .list h3 a {
  color: $color-cl-green;
}

.account-favorites-mylists .lists .list .subtitle,
.account-favorites-mylists .lists .list .subtitle p {
  font-size: 22px;
  font-family: $helvetica-light-font-family;
  line-height: normal;
}

.account-favorites-mylists .lists .list img {
  float: #{$ldirection};
  height: auto;
  width: 50%;
}

.account-favorites-mylists .lists .list .items-1 {
  float: none;
  width: 100%;
}

.account-favorites-mylists .lists .create-list img {
  float: none;
  width: 100%;
}

.account-favorites-mylists .lists .list-of-the-month {
  display: inline-block;
  top: 0;
}

.account-favorites-mylists .lists .list-of-the-month.no-products {
  display: inline-block;
  height: auto;
  position: absolute;
  /* bottom: 0; */
}

.account-favorites-mylists .lists .list-of-the-month.no-products a:hover {
  text-decoration: none;
}

.account-favorites-mylists .lists .list-of-the-month.no-products h3 {
  font-size: 17px;
  @include swap_direction(margin, 0 0 20px 0);
}

.account-favorites-mylists .lists .list-of-the-month.no-products h4 {
  font-size: 22px;
  @include swap_direction(margin, 0 0 10px 0);
}

.account-favorites-mylists .lists .list-of-the-month.no-products h4 .edited {
  color: #808080;
  display: block;
  font-size: 13px;
  font-family: $helvetica-regular-font-family;
}

.account-favorites-mylists .lists .list-of-the-month img {
  display: block;
  float: none;
  width: 100%;
}

.account-favorites-mylists .lists .list .favorite-actions {
  bottom: 10px;
  color: #808080;
  #{$ldirection}: 15px;
  position: absolute;
}

.account-favorites-mylists .lists .list .favorite-actions a {
  color: #808080;
}

.account-favorites-mylists .lists .create-list.no-products {
  border: none;
  @include swap_direction(padding, 10px 15px 10px 0);
  width: 64%;
}

.account-favorites-mylists ol {
  display: none;
  @include swap_direction(margin, 0 0 10px 0);
  width: 80%;
}

.account-favorites-mylists .lists .no-products ol {
  display: block;
}

.account-favorites-mylists ol li {
  font-size: 15px;
  font-family: $helvetica-light-font-family;
  line-height: 17px;
  @include swap_direction(margin, 0 0 30px 0);
  overflow: hidden;
}

.account-favorites-mylists ol li .number {
  float: #{$ldirection};
  font-size: 36px;
  font-family: $helvetica-light-font-family;
  line-height: 1;
  width: 30px;
}

.account-favorites-mylists ol li p {
  font-size: 15px;
  font-family: $helvetica-light-font-family;
  margin: 0;
  line-height: 17px;
  @include swap_direction(padding, 0 0 0 30px);
}

.account-favorites-mylists .add-to-list-overlay {
  display: none;
}

.overlay-container .add-to-list-overlay {
  display: block;
}

.overlay-container .add-to-list-overlay h3 {
  @include swap_direction(margin, 10px 0 15px 0);
}

.overlay-container .add-to-list-overlay .lists .add-to-list {
  display: block;
}

.add-to-list-overlay.no-lists h3,
.overlay-container .add-to-list-overlay.no-lists .lists {
  display: none;
}
