#ups-validation-notification-overlay {
  .ups-validation-header {
    margin-top: 32px;
    margin-bottom: 14px;
    text-align: center;
    font-size: 32px;
    padding: 0 70px;
    @media #{$medium-up} {
      padding: 0;
    }
  }
  .ups-validation-text {
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    padding: 0 20px;
    @media #{$medium-up} {
      margin-bottom: 40px;
      padding: 0;
    }
  }
  .address-header {
    margin-bottom: 0.5em;
  }
  .address-grid {
    float: #{$ldirection};
    @include swap_direction(padding, 0 20px 0 40px);
    width: 100%;
    padding: 20px;
    @media #{$medium-up} {
      width: 100%;
      width: 50%;
    }
    .button {
      margin-top: 14px;
    }
    .button.keep-address {
      text-transform: uppercase;
      font-weight: 400;
      background-color: $color-white;
      border: solid 1px $color-dusty-gray;
      color: $color-dusty-gray;
    }
  }
  .address-grid.suggested-address {
    float: #{$ldirection};
    border-#{$ldirection}: none;
    @media #{$medium-up} {
      border-#{$ldirection}: 1px solid $color-cl-green;
      float: #{$rdirection};
    }
  }
  .address-grid.user-address {
    border-top: 1px solid $color-cl-green;
    @media #{$medium-up} {
      border-top: 0;
    }
  }
}
