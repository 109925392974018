.enhanced-viewcart-page {
  .wrapper {
    .multi#main {
      .outer-wrap {
        background-color: $color-white;
        margin-top: 52px;
        #sidebar {
          max-width: 360px;
          padding: 0;
          @media #{$cr19-medium-up} {
            margin-#{$rdirection}: 0;
            width: 38%;
          }
          @media #{$cr19-large-up} {
            padding-top: 11px;
            width: 34%;
          }
          @media #{$cr19-medium-portrait-only} {
            margin: 0;
          }
          @media #{$cr19-medium-landscape-only} {
            padding-#{$rdirection}: 16px;
          }
          .viewcart-buttons-panel {
            border: 1px solid $color-grey;
            border-bottom: 0;
            padding: 16px 16px 11px;
          }
          .order-summary__content {
            border: 1px solid $color-grey;
            border-top: 0;
            padding: 0 16px;
          }
          .section-box {
            margin: 0;
            padding: 0;
            &.checkout-button-wrapper {
              .checkout-buttons {
                .button {
                  font-size: 15px;
                  font-weight: 500;
                  height: 45px;
                  margin-bottom: 11px;
                  padding: 12px 0;
                  .lock-icon {
                    margin-#{$rdirection}: 4px;
                    width: 24px;
                    &__image {
                      display: inline-block;
                      vertical-align: top;
                    }
                  }
                }
                .checkout-btns {
                  align-items: center;
                  display: flex;
                  font-family: $font-helvetica65-medium-helvetica;
                  font-weight: 500;
                  justify-content: center;
                  letter-spacing: 1px;
                  line-height: 1.34;
                  margin-bottom: 6px;
                }
                .checkout-paypal-btn {
                  background-color: $color-white;
                  background-position: 50%;
                  box-shadow: none;
                  margin-bottom: 10px;
                }
              }
              .payment-logo-container {
                display: flex;
                justify-content: center;
                padding: 5px 0;
                &__item {
                  height: 16.5px;
                  margin-#{$rdirection}: 13px;
                  width: 24px;
                  &--visa {
                    background: url('/media/images/checkout/visa.png') no-repeat center;
                  }
                  &--master-card {
                    background: url('/media/images/checkout/mastercard.png') no-repeat center;
                  }
                  &--maestro-card {
                    background: url('/media/images/checkout/maestro.png') no-repeat center;
                  }
                  &--discover {
                    background: url('/media/images/checkout/discover.png') no-repeat center;
                  }
                  &--american-express {
                    background: url('/media/images/checkout/ae.png') no-repeat center;
                  }
                  &--afterpay {
                    background: url('/media/images/checkout/afterpay_logo.png') no-repeat center;
                    width: 58px;
                  }
                  &--visa,
                  &--master-card,
                  &--maestro-card,
                  &--discover,
                  &--american-express,
                  &--afterpay {
                    background-size: 100%;
                  }
                }
                &__item:last-child {
                  margin-#{$rdirection}: 0;
                }
              }
            }
            &.need-help {
              .need-help__content,
              .need-help__link {
                color: $color-black;
                font-family: $helvetica-regular-font-family;
                letter-spacing: 0.25px;
                margin-top: 19px;
              }
              .need-help__link {
                margin-top: 20px;
                text-decoration: underline;
              }
            }
            &.offer-code {
              margin-bottom: 21px;
              padding-top: 24px;
            }
            .section-content {
              color: $color-darkest-grey;
              font-family: $helvetica-regular-font-family;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0;
              line-height: 1.4;
              .offer-code {
                &__message {
                  color: $color-error-msg;
                  font-family: $helvetica-regular-font-family;
                  font-size: 14px;
                  line-height: 1.4;
                  margin: 0 0 17px;
                  padding-#{$ldirection}: 32px;
                  position: relative;
                  &:last-child {
                    margin-bottom: 9px;
                  }
                  a {
                    font-family: $helvetica-regular-font-family;
                    &:hover {
                      text-decoration: underline;
                    }
                  }
                  .message-icon {
                    #{$ldirection}: 0;
                    background: url('/media/images/cart_enhancement/error-icon.png') no-repeat
                      center;
                    background-size: 16px;
                    height: 16px;
                    position: absolute;
                    top: 2px;
                    width: 16px;
                  }
                }
                &__byline {
                  .deferred,
                  .success {
                    .message-icon {
                      height: 20px;
                      top: 0;
                      width: 20px;
                    }
                  }
                  .deferred {
                    color: $color-alert-msg;
                    .message-icon {
                      background: url('/media/images/cart_enhancement/attention-icon.png') no-repeat
                        center;
                      background-size: 20px;
                    }
                    a {
                      color: $color-alert-msg;
                    }
                  }
                  .success {
                    color: $color-success-msg;
                    .message-icon {
                      background: url('/media/images/cart_enhancement/success-icon.png') no-repeat
                        center;
                      background-size: 20px;
                    }
                  }
                }
                &__success {
                  color: $color-success-msg;
                  min-height: 20px;
                  margin-bottom: 12px;
                  padding-#{$ldirection}: 32px;
                  position: relative;
                  .message-icon {
                    #{$ldirection}: 0;
                    background: url('/media/images/cart_enhancement/success-icon.png') no-repeat
                      center;
                    background-size: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    width: 20px;
                  }
                  &--text,
                  &--edit {
                    font-family: $helvetica-regular-font-family;
                    font-size: 14px;
                    line-height: 1.4;
                  }
                  &--edit {
                    #{$rdirection}: 0;
                    color: $color-black;
                    cursor: pointer;
                    font-weight: bold;
                    position: absolute;
                    text-decoration: underline;
                    text-transform: uppercase;
                  }
                }
                &__field-wrapper {
                  display: flex;
                  justify-content: space-between;
                }
                &-field {
                  display: inline-block;
                  height: 54px;
                  @media #{$cr19-medium-up} {
                    margin-#{$rdirection}: 10px;
                    width: calc(100% - 110px);
                  }
                  @media #{$cr19-xlarge-up} {
                    width: calc(100% - 140px);
                  }
                  .offer-code__box {
                    appearance: none;
                    background-color: $color-white;
                    border: 1px solid $color-grey;
                    border-radius: 0;
                    box-shadow: none;
                    color: $color-black-87opacity;
                    font-family: $helvetica-regular-font-family;
                    font-size: 17px;
                    height: 56px;
                    line-height: 1.4;
                    margin: 0 0 20px;
                    outline: none;
                    width: 100%;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    @media #{$cr19-medium-up} {
                      padding: 0 10px;
                    }
                    @media #{$cr19-large-up} {
                      padding: 0 15px;
                    }
                  }
                  label {
                    background-color: $color-white;
                    color: $color-black-88opacity;
                    font-size: 15px;
                    font-weight: 500;
                    @media #{$cr19-medium-up} {
                      #{$ldirection}: 10px;
                    }
                    @media #{$cr19-large-up} {
                      #{$ldirection}: 15px;
                    }
                  }
                  .label-mode + label {
                    color: $color-black-88opacity;
                    font-size: 12px;
                    letter-spacing: normal;
                    line-height: 1.4;
                  }
                  .label-content {
                    text-transform: capitalize;
                  }
                }
                &__apply-btn {
                  border: 1px solid $color-grey;
                  border-radius: 0;
                  color: $color-cl-darker-grey;
                  font-family: $helvetica-bold-font-family;
                  font-size: 15px;
                  font-weight: 500;
                  height: 56px;
                  margin: 0;
                  padding: 0;
                  text-transform: inherit;
                  &:hover {
                    border-color: $color-grey;
                  }
                  @media #{$cr19-medium-up} {
                    width: 100px;
                  }
                  @media #{$cr19-xlarge-up} {
                    width: 130px;
                  }
                }
              }
            }
            &.order-summary-panel {
              padding-top: 5px;
              .order-summary-panel {
                &__header {
                  color: $color-cl-darker-grey;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 0.23px;
                  margin: 0 0 2px;
                  text-transform: capitalize;
                }
                &__content {
                  padding-bottom: 20px;
                  .checkout-order-status {
                    margin: 0 0 8px;
                  }
                  .order-summary__details {
                    margin: 0;
                  }
                }
              }
              .checkout-order-status {
                margin: 0;
                .shipping-method {
                  margin: 5px 0 13px;
                  &__select {
                    @include swap_direction(padding, 10px 61px 10px 16px);
                    border-radius: 0;
                    font-size: 17px;
                    height: 44px;
                    text-overflow: ellipsis;
                  }
                  &::after {
                    #{$rdirection}: 17px;
                    border: solid transparent;
                    border-width: 7px 5px 0;
                    border-top-color: $color-cl-light-black;
                    content: '';
                    top: 20px;
                  }
                }
              }
              .order-summary {
                margin: 0;
                &__label,
                &__value {
                  line-height: 1.43;
                  margin-bottom: 7px;
                }
                &__label {
                  color: $color-light-grey-2;
                  font-family: $helvetica-regular-font-family;
                  letter-spacing: 0.28px;
                  .retail-info {
                    position: relative;
                  }
                  .terms-tooltip {
                    #{$ldirection}: 15%;
                    &__info-icon {
                      #{$rdirection}: 0;
                    }
                    &__text {
                      #{$ldirection}: 0;
                      width: 200px;
                    }
                    &__up-arrow-icon {
                      #{$rdirection}: 39%;
                    }
                  }
                }
                &__value {
                  color: $color-black;
                  letter-spacing: 0.34px;
                }
                &__total-label,
                &__total-value {
                  color: $color-black;
                  font-family: $helvetica-regular-font-family;
                  font-weight: bold;
                  letter-spacing: 0.28px;
                  line-height: 1.6;
                  padding: 0;
                }
              }
              .afterpay,
              .afterpay-messages-container {
                color: $pick_x_title-color;
                font-size: 12px;
                font-weight: normal;
                line-height: 1.4;
                margin-top: 8px;
                padding-#{$rdirection}: 15%;
                position: relative;
                width: 100%;
                .ap-logo,
                .afterpay-logo {
                  height: 16px;
                  vertical-align: middle;
                  width: 80px;
                }
                .afterpay-learnmore {
                  #{$rdirection}: 0;
                  position: absolute;
                  top: 0;
                  &__icon {
                    background-color: $color-white;
                    height: 15px;
                    line-height: 1.6;
                    position: relative;
                    width: 15px;
                    &::before {
                      #{$rdirection}: 0;
                      background: url(/sites/clinique/themes/cl_base/img/icons/src/information.svg)
                        no-repeat center;
                      content: '';
                      display: inline-block;
                      height: 15px;
                      position: absolute;
                      width: 15px;
                    }
                  }
                }
              }
            }
          }
          .section-box.section-loyalty-offers {
            .section-header {
              color: $color-cl-darker-grey;
              font-family: $helvetica-bold-font-family;
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 0.23px;
              line-height: 2;
              margin: 0;
              padding: 8px 0;
              width: 217px;
            }
            .offer_title {
              margin: 10px 0;
              text-align: #{$ldirection};
              font-weight: bold;
            }
            .offer-code-wallet {
              &__content {
                align-items: flex-start;
                display: flex;
              }
              &__code-info--applied {
                @include swap_direction(margin, 0 15px 0 0);
                display: inline-block;
                height: 90px;
                min-height: auto;
                width: 90px;
              }
              &__image {
                width: 100%;
              }
              &__description {
                flex: 1;
              }
              &__item {
                .offer_desc {
                  color: $color-black-88opacity;
                  font-size: 12px;
                  font-weight: 500;
                  letter-spacing: 0.4px;
                  line-height: 1.34;
                  margin-bottom: 4px;
                  text-align: $ldirection;
                }
                .more_link {
                  color: $color-black-64opacity;
                  font-size: 12px;
                  margin: 0;
                }
              }
              .offer_apply {
                margin: 0;
                .btn-shopnow {
                  background-color: $color-white;
                  border: 2px solid $color-grey;
                  border-radius: 0;
                  color: $color-cl-darker-grey;
                  font-size: 15px;
                  font-weight: bold;
                  height: 45px;
                  line-height: 1.6;
                  margin: 0;
                  padding: 10px 16px 12px;
                  width: 100%;
                  &.not_meet,
                  &.disable_offer {
                    color: $color-grey;
                    cursor: not-allowed;
                  }
                }
              }
              .flex-direction-nav {
                .flex-next {
                  #{$rdirection}: -4px;
                  background: url('/media/images/cart_enhancement/arrow-right.png') no-repeat center;
                  float: $rdirection;
                }
                .flex-prev {
                  background: url('/media/images/cart_enhancement/arrow-left.png') no-repeat center;
                  float: $ldirection;
                }
                .flex-next,
                .flex-prev {
                  background-position: center;
                  background-size: contain;
                  height: 14px;
                  margin-top: -31px;
                  position: relative;
                  width: 20px;
                  &::before {
                    content: '';
                  }
                }
              }
              .flex-direction-nav,
              .flex-disabled {
                cursor: pointer;
                display: block;
                opacity: 1;
              }
              .flex-disabled {
                cursor: none;
                opacity: 0.5;
                pointer-events: none;
              }
              .flex-control-paging {
                #{$ldirection}: 0;
                #{$rdirection}: 0;
                display: block;
                height: 12px;
                margin: 0 auto;
                top: 85%;
                width: 80%;
                li {
                  height: 12px;
                  margin: 0 8px;
                  width: 12px;
                  a {
                    height: 12px;
                    width: 12px;
                  }
                }
              }
            }
            .loyalty_points {
              color: $color-black-64opacity;
              font-size: 12px;
              letter-spacing: 0.4px;
              text-align: $ldirection;
            }
            .slides {
              .offer-code-wallet__item {
                border: 1px solid $color-grey;
                padding: 16px;
              }
              .flex-active-slide {
                height: auto;
                margin-bottom: 47px;
              }
            }
          }
          .additional-info-links {
            border: 0;
          }
        }
      }
    }
  }
}

#viewcart.enhanced-cart-page {
  #main.multi.empty {
    #sidebar.sidebar-empty-cart {
      border: 0;
      margin-top: 0;
      padding: 0;
      .need-help.section-box {
        border: 0;
        .need-help__content {
          margin-top: 0;
        }
      }
    }
  }
}
