.page-spp {
  .support {
    overflow: hidden;
    .live_chat_text,
    #lpchat-eaa {
      float: left;
    }
    .live_chat_text {
      height: 37px;
      line-height: 33px;
      padding-right: 10px;
    }
  }
  .col1 {
    img {
      float: left;
    }
    .spp-share-container {
      clear: both;
      float: left;
      width: 100%;
    }
  }
  .options {
    border-top: 1px solid #dbdbdb;
    padding-top: 13px;
    .price2.strike-thru-price,
    .price {
      display: inline;
    }
  }
}

.module-spp-detail {
  .replenishment {
    display: none;
  }
  .smoosh-wrapper {
    .duo-smoosh {
      img {
        float: none;
      }
    }
  }
  .page-spp {
    .col2 {
      .options {
        .ppu {
          padding: 6px 0 12px 0;
          font-size: 13px;
        }
      }
    }
  }
}

.mpp-product-group-v1 {
  .mascara-product-v1 {
    .product-image {
      margin: 40px 20px 0px 0px;
    }
    .product-info {
      padding: 0 35% 0 0;
    }
    .row {
      min-height: 450px;
    }
  }
}

.page-products-makeup-eye-liners {
  .cl-mpp-product-layout-v1 {
    .mpp-filter-content {
      .mpp-filter-column1:first-child {
        display: none;
      }
    }
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-grid {
    #quickshop-wrapper {
      .module-spp-detail {
        .ppu {
          padding: 6px 0 12px 0;
        }
      }
    }
    li.mpp-box {
      .badge {
        &.badge_123 {
          background: #ff543a;
        }
        &.badge_126 {
          background: #ff543a;
        }
        &.badge_130 {
          background: #fe6cb5;
        }
      }
    }
    .options {
      border-top: 1px solid #dbdbdb;
      padding-top: 13px;
      clear: left;
      .price2,
      .price {
        display: inline;
      }
    }
  }
}
