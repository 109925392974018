/// standalone shared keyframes animation
@keyframes elc-shimmer {
  0% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}

@keyframes elc-shimmer-rtl {
  0% {
    background-position: bottom left;
  }
  100% {
    background-position: top right;
  }
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: none;
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: none;
  }
}
