.sells-with {
  padding: 0 20px;
  @media #{$medium-up} {
    padding: 0;
  }
  @if $cr19 == true {
    padding: 0;
  }
  &--description--product-name {
    color: $color-cl-green;
  }
  &--title {
    margin-bottom: 10px;
    margin-#{$ldirection}: 10px;
    @media #{$medium-up} {
      margin-#{$ldirection}: 0;
    }
  }
  h3 {
    font-size: 15px;
    color: $color-black;
  }
  &-description {
    @if $cr19 == true {
      min-height: 120px;
      line-height: 24px;
      @media #{$cr19-medium-up} {
        min-height: 72px;
      }
    }
  }
  ul.sells-with-grid {
    display: flex;
    justify-content: flex-start;
    @if $cr19 == true {
      justify-content: center;
    }
    margin-bottom: 10px;
    li {
      margin-#{$rdirection}: 5px;
      padding: 5px;
      cursor: pointer;
      img {
        width: 100%;
      }
      &.active {
        img {
          border-bottom: 2px solid $color-black;
        }
      }
      &.unavailable {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  .grid .outer-wrap {
    padding-left: 0;
  }
  &--panel {
    &.border {
      border-bottom: 1px solid $color-light-grey;
      padding: 20px 15px;
    }
    .button-wrapper a.add-to-cart.button {
      width: 100%;
      font-size: 18px;
      line-height: 44px;
    }
  }
  &--cta-button {
    width: auto;
    padding: 3px 5px;
  }
}

.btn-add-to-bag,
.add-to-cart {
  &.sells-with--disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

@if $cr19 == true {
  .page-footer {
    .sells-with {
      display: none;
    }
  }
}
