.waypoint-marker {
  display: block;
  width: 100%;
  height: 0;
}

.vertical-waypoint-dots {
  position: fixed;
  top: 40%;
  #{$rdirection}: 0;
  width: 38px;
  z-index: 9999;
  .item {
    cursor: pointer;
    background-position: center center;
    background-repeat: no-repeat;
    height: 25px;
    &.first {
      background-image: url($libraries_base_path + '/spp_waypoint_nav/toc-up.png');
    }
    &.last {
      background-image: url($libraries_base_path + '/spp_waypoint_nav/toc-down.png');
    }
  }
  .dot {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
    -o-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
    height: 6px;
    @include swap_direction(margin, 6px 0 6px 16px);
    transition: -webkit-box-shadow 0.3s ease 0s;
    transition: -moz-box-shadow 0.3s ease 0s;
    transition: -o-box-shadow 0.3s ease 0s;
    transition: box-shadow 0.3s ease 0s;
    width: 6px;
    .title {
      display: none;
      position: absolute;
      top: -5px;
      #{$rdirection}: 20px;
      text-align: #{$rdirection};
      min-width: 300px;
    }
    &.hover {
      .title {
        display: block;
      }
    }
    &.active,
    &.hover {
      background-color: rgba(0, 0, 0, 0);
      background-image: none;
      -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
      -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
      -o-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
    }
  }
}
