.diagnostics-merch-window {
  overflow: hidden;
  &.has-image {
    background-color: transparent;
    background-position: top #{$rdirection};
    background-repeat: no-repeat;
    .content {
      @include swap_direction(padding, 0 41% 0 0);
    }
  }
  .screenshot {
    float: #{$ldirection};
    @include swap_direction(margin, 65px 3% 65px 0);
    width: 41%;
    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  .content {
    @include swap_direction(margin, 65px 0 0 0);
    overflow: hidden;
    h2 {
      font-size: 30px;
      line-height: 1;
      margin: 0;
    }
    .subtitle {
      font-size: 22px;
      font-family: $helvetica-light-font-family;
      line-height: 1;
      @include swap_direction(margin, 5px 0 0 0);
    }
    .button {
      @include swap_direction(margin, 20px 0 0 0);
    }
  }
}

.foundation-finder-merch-window-v1 {
  .screenshot {
    @include swap_direction(margin, 29px 3% 5px 0);
  }
  .content {
    @include swap_direction(margin, 29px 0 0 0);
  }
}
