.random-gift-picker-formatter {
  width: 100%;
  position: relative;
  overflow: visible;
  font-size: 0;
  .clinique-spinner-delivery-msg {
    @include breakpoint($small-max) {
      margin-bottom: 0;
      display: block;
    }
    @include swap_direction(padding, 1px);
    display: none;
    box-shadow: 0px 5px 10px $color-btn-gray;
    margin-bottom: -20px;
    background-color: $color-white;
    width: 100%;
    z-index: 101;
    height: auto;
    p {
      @include swap_direction(margin, 15px 10px);
      font-size: 16px;
      line-height: 16px;
      color: $color-cerise-red;
      font-family: $font-helvetica65-medium-helvetica;
      font-weight: 400;
    }
  }
  .clinique-spinner-prize-list {
    @include breakpoint($small-max) {
      display: none;
    }
    @include swap_direction(padding, 4px 2px);
    @include swap_direction(margin, 0 auto);
    max-width: 912px;
    border: 6px solid;
    margin-top: 100px;
    .clinique-spinner-prize-list--inner {
      @include swap_direction(padding, 20px 30px);
      border: 1px solid;
      ul {
        display: flex;
        text-align: center;
        justify-content: space-between;
        margin-top: 20px;
      }
      .clinique-spinner-prize-item {
        text-align: center;
        font-size: 18px;
        line-height: 19px;
        max-width: 220px;
        img {
          width: 210px;
          height: 210px;
        }
        h4 {
          font-family: $font-helvetica65-medium-verdana;
          color: $color-black;
        }
        p {
          @include swap_direction(margin, 0px);
        }
      }
      h2 {
        @include swap_direction(margin, 0px);
        font-size: 30px;
        line-height: 30px;
        font-family: $helvetica-bold-font-family;
      }
    }
  }
  #game-unavailable .button,
  #game-error .button {
    background-color: $color-white;
    outline: $color-monte-carlo 1px solid;
    color: $color-monte-carlo;
    border: 1px solid $color-monte-carlo;
    outline-offset: -3px;
    font-size: 18px;
    line-height: 18px;
    min-width: 200px;
    font-family: $font-helvetica65-medium-verdana;
    font-weight: normal;
    position: relative;
    &:hover:before {
      content: ' ';
      background-color: $color-black-1opacity;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
    }
  }
  #game-error .pop-up-box-inner,
  #game-unavailable .pop-up-box-inner {
    @include swap_direction(padding, 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    p {
      font-size: 18px;
      line-height: 19px;
      font-family: $font-helvetica45-light;
      &.small {
        font-size: 12px;
        line-height: 16px;
      }
    }
    h2 {
      color: $color-black;
      font-family: $helvetica-bold-font-family;
      font-size: 30px;
      line-height: 30px;
    }
    @include breakpoint($small-max) {
      h2 {
        font-size: 26px;
      }
      .button {
        max-width: 200px;
      }
    }
  }
  #game-error p.unavailable-terms,
  #game-unavailable p.unavailable-terms {
    @include swap_direction(padding, 0 30px);
    position: absolute;
    width: 100%;
    bottom: 30px;
    left: 0;
  }
  #loading {
    width: 100%;
    height: 100%;
    background: $color-black-7opacity;
    z-index: 4;
    position: absolute;
    .loading-inner {
      width: 100%;
      height: 100%;
      text-align: center;
      p.loading-title {
        margin-top: 200px;
      }
    }
  }
  .loading-dots {
    width: 20px;
    display: inline-block;
    text-align: left;
    font-size: 16px;
  }
  .pop-up-box {
    position: absolute;
    width: 100%;
    height: 100%;
    @include breakpoint($small-max) {
      height: auto;
      &.pop-up-box--win-box {
        justify-content: flex-start;
        flex-direction: column;
      }
    }
    max-height: 920px;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    z-index: 3;
    text-align: center;
    background-color: $color-black-7opacity;
    &.hidden {
      display: none;
    }
    &.pop-up-box--game-unavailable {
      @include breakpoint($small-max) {
        height: 100%;
        align-items: center;
      }
      .clinique-spinner-delivery-msg {
        position: absolute;
        top: 0;
      }
      .pop-up-box-inner {
        @include breakpoint($small-max) {
          max-width: 90%;
          border: 6px solid $color-spin-pink;
          outline: 2px solid $color-spin-pink;
          outline-offset: -11px;
          font-size: 28px;
        }
        width: 600px;
        height: 400px;
        h2 {
          @include breakpoint($small-max) {
            font-size: 24px;
            line-height: 24px;
            margin-top: 0px;
          }
          margin-top: 70px;
          line-height: 30px;
          font-size: 30px;
          font-family: $helvetica-bold-font-family;
        }
        .pop-up-box-title-banner {
          @include breakpoint($small-max) {
            max-width: 50%;
            top: -125px;
            #{$ldirection}: 25%;
          }
        }
      }
      .pop-up-box-title-banner {
        @include swap_direction(padding, 0);
        background-position: center center;
        background-repeat: no-repeat;
        display: block;
        width: 300px;
        height: 250px;
        position: absolute;
        background-size: contain;
        top: -115px;
      }
    }
    .pop-up-box-inner {
      @include swap_direction(padding, 0 40px);
      display: table;
      width: $small-l;
      height: $small-l;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: $color-white;
      font-size: 14px;
      .win-remaining-goes {
        @include breakpoint($small-max) {
          display: none;
        }
        &--mobile {
          display: none;
          @include breakpoint($small-max) {
            display: block;
          }
        }
      }
    }
    .pop-up-footer {
      @include swap_direction(padding, 10px);
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .button {
      @include swap_direction(padding, 15px 10px);
      background-color: $color-light-grey;
      display: inline-block;
      min-width: 185px;
      color: $color-black;
      border: 1px solid $color-black;
      text-decoration: none;
    }
    .pop-up-box-inner p {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 16px;
    }
    .popup-social-facebook {
      background: url('/media/export/cms/spinner/social/facebook.png');
      width: 30px;
      height: 30px;
    }
    .popup-social-twitter {
      background: url('/media/export/cms/spinner/social/twitter.png');
      width: 30px;
      height: 30px;
    }
    .popup-social-pinterest {
      background: url('/media/export/cms/spinner/social/pinterest.png');
      width: 30px;
      height: 30px;
    }
    .popup-social-btn {
      @include swap_direction(margin, 10px);
      display: inline-block;
      text-indent: -999px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
  .error-message {
    display: none;
  }
  .help-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: none;
    position: absolute;
    background: $color-black-7opacity;
    > * {
      position: absolute;
    }
    p:not(.help-text-title) {
      font-size: 16px;
      line-height: 22px;
      display: inline-block;
    }
  }
  #loading .loading-inner p:not(.loading-title) {
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
  }
  .help-overlay p.help-text-title,
  #loading .loading-inner p.loading-title {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .help-overlay .help-text-main {
    text-align: center;
    width: 450px;
    left: calc(50% - 225px);
    top: 50%;
    margin-top: -200px;
  }
  #need-help-text {
    font-weight: bold;
    position: absolute;
    bottom: 10px;
    width: 100%;
    font-size: 12px;
    left: 632px;
  }
  #terms-and-conditions-text {
    font-weight: bold;
    position: absolute;
    bottom: 10px;
    width: 100%;
    font-size: 12px;
    left: 897px;
  }
  #game-unavailable .button {
    margin-top: 20px;
  }
  #terms-and-conditions {
    display: none;
    position: relative;
    > div {
      @include swap_direction(padding, 20px);
      p {
        line-height: 16px;
      }
      > #terms-close-btn {
        position: absolute;
        cursor: pointer;
      }
    }
  }
}

@include breakpoint($small-l) {
  .random-gift-picker-formatter {
    .pop-up-box {
      height: 100%;
      top: 0;
      margin-top: 0;
    }
  }
}

@include start-breakpoint($medium-m) {
  .random-gift-picker-formatter {
    .random-gift-picker-formatter {
      min-width: $medium-m;
    }
    #terms-and-conditions {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      position: absolute;
      background: $color-black-7opacity;
      > div {
        @include swap_direction(padding, 20px);
        position: absolute;
        top: 50%;
        height: 350px;
        @include swap_direction(margin, -175px 0 0 -250px);
        width: 500px;
        left: 50%;
        z-index: 999;
        overflow-x: scroll;
        > #terms-close-btn {
          top: 10px;
          right: 0;
        }
      }
    }
  }
}

@include breakpoint($small-m) {
  .random-gift-picker-formatter {
    #game-unavailable.pop-up-box .pop-up-box-inner p {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 40px 10px);
      font-size: 12px;
      line-height: 14px;
    }
    .help-overlay p:not(.help-text-title) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

@include breakpoint($medium-m) {
  .random-gift-picker-formatter {
    min-height: 87vh;
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .button {
      float: none;
    }
    #need-help-text {
      left: 0;
      text-align: center;
      @include swap_direction(padding, 0 20px);
      font-size: 11px;
      bottom: 10px;
    }
    #terms-and-conditions-text {
      left: 0;
      text-align: center;
      @include swap_direction(padding, 0 20px);
      font-size: 11px;
      bottom: -10px;
    }
    .help-overlay {
      .help-text-main {
        width: 80%;
        left: 50%;
        @include swap_direction(margin, 0 0 0 -40%);
        top: 20px;
      }
      p.help-text-title {
        font-size: 32px;
      }
    }
    #game-unavailable.pop-up-box .pop-up-box-inner {
      @include swap_direction(padding, 0 5px);
    }
    #terms-and-conditions > div > #terms-close-btn {
      top: 35px;
      right: 20px;
    }
  }
}

@include breakpoint-range($small-m, $medium-m) {
  .help-overlay p:not(.help-text-title) {
    font-size: 14px;
    line-height: 16px;
  }
  .border.bottom-border {
    background-position: calc(50% + 270px);
  }
}

@include breakpoint-range($medium-m, $large-xxl) {
  .random-gift-picker-formatter {
    .random-gift-picker-formatter {
      .border.bottom-border {
        background-position: calc(50% + 190px);
      }
      #need-help-text {
        left: calc(50% - 154px);
      }
      #terms-and-conditions-text {
        left: calc(50% + 113px);
      }
    }
  }
}

section.clinique-spinner,
.random-gift-picker-formatter {
  @include swap_direction(padding, 0px);
  text-align: center;
  font-family: 'HelveticaNeueLTStd55Roman', Helvetica, Arial, Sans-Serif;
  position: relative;
}

section.clinique-spinner p,
.random-gift-picker-formatter p {
  font-family: 'HelveticaNeueLTStd35Thin', Helvetica, Arial, Sans-Serif;
}

section.clinique-spinner {
  .ir {
    display: inline-block;
    overflow: hidden;
    text-indent: -9999px;
    text-align: left;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .spinner-grad {
    background: linear-gradient(to bottom, $color-athens-gray, $color-white);
  }
  h2 {
    @include swap_direction(margin, 40px auto 0);
    z-index: 2;
    position: relative;
    font-family: 'HelveticaNeueLTStd35Thin';
    color: $color-black;
  }
  .game-bg {
    width: 100%;
    height: 360px;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
  }
  .game-block {
    @include swap_direction(margin, -80px auto 0);
    position: relative;
    overflow: hidden;
  }
  .game-spinners {
    @include swap_direction(margin, 100px auto 0);
    @include swap_direction(padding, 5px);
    max-width: 980px;
    font-size: 0;
    background-color: $color-btn-grey;
    background-image: url('/media/export/cms/spinner/LP_Frame_Mobile_Highlight.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  .game-spinners-border {
    @include swap_direction(padding, 15px 4px);
    border: 1px solid $color-white;
    background-color: transparent;
  }
  .game-spinner {
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0 10px);
    width: 280px;
    height: 280px;
    max-width: 31%;
    border: 0;
    background-color: $color-white;
    display: inline-block;
    overflow: hidden;
    position: relative;
    .game-icon {
      width: 100%;
      height: 280px;
      position: relative;
      background-size: auto 60%;
      background-position: center calc(50% - 10px);
      background-repeat: no-repeat;
    }
    .game-icon-caption {
      position: absolute;
      left: 0;
      bottom: 25px;
      width: 100%;
      color: $color-black;
      text-align: center;
      font-size: 20px;
    }
    .spinner-container {
      position: relative;
    }
  }
  p.clinique-spinner-mainline {
    @include swap_direction(margin, 40px auto 0);
    display: block;
    font-size: 20px;
    font-family: $font-helvetica45-light;
    line-height: 24px;
    font-weight: 100;
    max-width: 600px;
  }
  .play-button {
    @include swap_direction(margin, 40px auto 0);
    @include swap_direction(padding, 15px 40px);
    text-transform: uppercase;
    text-align: center;
    z-index: 2;
    font-size: 24px;
    font-family: $font-helvetica65-medium-helvetica;
    display: inline-block;
    outline: 1px solid $color-white;
    outline-offset: -5px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.5s;
    position: relative;
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  .button:hover:before,
  .play-button:hover:before {
    content: ' ';
    background-color: $color-black-1opacity;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
  #remaining-spins {
    @include swap_direction(margin, 10px auto 0);
    font-size: 20px;
    @include breakpoint($medium-max) {
      font-size: 14px;
    }
    text-transform: uppercase;
    font-family: $font-helvetica65-medium-verdana;
    font-weight: normal;
  }
  .subline-tcs {
    @include swap_direction(padding, 30px 0);
    @include swap_direction(margin, 60px auto 0);
    text-align: center;
    background-color: $color-porcelain;
    p {
      @include swap_direction(margin, 0 auto);
      max-width: 900px;
      font-size: 12px;
      line-height: 18px;
      color: $color-cl-dark-grey;
    }
  }
  .clinique-spinner-title {
    width: 315px;
    height: 168px;
  }
  section {
    &#play-again-popup {
      background-color: $color-black-7opacity;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1001;
      align-items: center;
      justify-content: center;
      display: none;
      &.active {
        display: flex;
      }
      .play-again-popup-box {
        @include swap_direction(padding, 20px);
        max-width: 600px;
        display: none;
      }
      .play-again-popup-container {
        @include swap_direction(padding, 20px);
        background-color: $color-white;
        border: 10px solid $color-black;
        outline: 2px solid $color-black;
        outline-offset: -15px;
        box-shadow: 0 0 10px $color-black-1opacity;
        h2 {
          @include swap_direction(margin, -100px auto 0);
        }
        p {
          @include swap_direction(margin, 10px auto 0);
          font-size: 18px;
          &.sub-header {
            @include swap_direction(margin, 15px auto 0);
            font-size: 30px;
            line-height: 30px;
            font-family: $helvetica-bold-font-family;
          }
          &.sub-line {
            @include swap_direction(margin, 10px auto 0);
          }
        }
      }
      .play-button {
        @include swap_direction(margin, 25px auto 0);
        @include swap_direction(padding, 10px 40px);
        font-size: 20px;
        background-color: $color-white;
        border: 1px solid $color-black;
        outline: 1px solid $color-black;
        outline-offset: -3px;
      }
      .play-again-popup-container div.play-again-tcs p {
        @include swap_direction(margin, 20px auto 5px);
        font-size: 11px;
        color: $color-cl-dark-grey;
        display: block;
      }
    }
    &#game-win-box {
      z-index: 100;
      &.loading {
        .pop-up-box-inner {
          opacity: 1;
        }
        .button {
          opacity: 0.7;
          pointer-events: none;
          a {
            pointer-events: none;
          }
        }
        opacity: 1;
        .win-info,
        .win-image {
          opacity: 0.2;
        }
        .win-message-items {
          background: url(/media/export/cms/479-01%20Clinique_LoadingGif_R22.gif) no-repeat center
            center;
        }
      }
      .pop-up-box-inner {
        @include swap_direction(padding, 0);
        width: 600px;
        height: 680px;
        position: relative;
        display: block;
        .win-banner {
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 50px 0 0 -50px);
          background-position: center center;
          background-repeat: no-repeat;
          display: block;
          text-indent: -999px;
          overflow: hidden;
          width: 700px;
          height: 120px;
        }
      }
      &.pop-up-box .pop-up-footer {
        @include swap_direction(padding, 0 50px 50px);
        color: $color-cl-dark-grey;
        font-size: 12px;
      }
      .win-message-items {
        @include swap_direction(padding, 0 35px);
        display: flex;
      }
      .win-image,
      .win-image--mobile {
        @include swap_direction(margin, 0);
        width: 220px;
        height: 400px;
        display: inline-block;
        align-items: center;
        justify-content: center;
        vertical-align: top;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .win-image {
        width: 260px;
        &--mobile {
          display: none;
        }
        @include breakpoint($small-max) {
          display: none;
          &--mobile {
            display: block;
          }
        }
      }
      .win-info {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        width: 340px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        h2 {
          @include swap_direction(margin, 0 auto);
          font-size: 30px;
          font-weight: 600;
          font-family: $font-helvetica65-medium-verdana;
        }
        @include breakpoint($small-max) {
          .win-offer-message {
            font-size: 20px;
            line-height: 21px;
          }
        }
      }
      .win-offer-message {
        @include swap_direction(margin, 20px auto 0);
        @include swap_direction(padding, 0 15px);
        font-size: 18px;
        line-height: 19px;
        font-weight: 200;
        font-family: $font-helvetica45-light;
      }
    }
  }
}

section.clinique-spinner section#game-win-box {
  .button {
    background-color: $color-monte-carlo;
    border: 0px;
    outline: $color-white 1px solid;
    outline-offset: -2px;
    font-size: 18px;
    color: $color-white;
  }
  .button,
  .play-again-btn {
    min-width: 200px;
    font-family: $font-helvetica65-medium-verdana;
    font-weight: normal;
    position: relative;
  }
  .remaining-spins {
    font-size: 14px;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    font-family: $font-helvetica65-medium-verdana;
  }
  .play-again-btn {
    @include swap_direction(margin, 10px auto 0);
    background-color: $color-white;
    outline-color: $color-monte-carlo;
    color: $color-monte-carlo;
    border: 1px solid $color-monte-carlo;
    outline-offset: -3px;
    font-size: 18px;
  }
  .win-box-btns {
    @include breakpoint($small-max) {
      display: flex;
      justify-content: center;
    }
    @include swap_direction(margin, 20px auto 0);
    .single-btn-box {
      @include breakpoint($small-max) {
        display: flex;
      }
      .apply_offer_subtext--mobile {
        @include swap_direction(padding, 0 5px);
        font-family: $font-helvetica65-medium-verdana;
        font-weight: 600;
      }
    }
    .right-btn-box,
    .single-btn-box {
      .button {
        @include breakpoint($small-max) {
          @include swap_direction(padding, 0 10px);
          min-width: 0;
          font-size: 16px;
          &.large-btn {
            min-width: 170px;
          }
        }
      }
    }
  }
  .apply_offer_subtext {
    font-size: 16px;
    font-weight: 600;
    font-family: $font-helvetica65-medium-verdana;

    &--mobile {
      @include breakpoint($small-max) {
        display: block;
      }
      display: none;
    }
  }
}
/* IE Fixes */
section.clinique-spinner section#game-win-box .win-offer-message {
  width: 100%;
}
/* iPad */
@include breakpoint($small-max) {
  section.clinique-spinner {
    .game-block {
      @include swap_direction(margin, -80px auto 0);
    }
    .game-bg {
      height: 155px;
      background-size: cover;
    }
    .game-spinners {
      @include swap_direction(padding, 1px);
      @include swap_direction(margin, 60px auto 0);
      width: 95%;
    }
    .game-spinners-border {
      @include swap_direction(padding, 5px 0);
    }
    .game-spinner {
      @include swap_direction(padding, 1px);
      @include swap_direction(margin, 0 1%);
      width: 31%;
      height: 140px;
      .game-icon {
        background-size: contains;
        background-position: center calc(50% - 10px);
        height: 140px;
        &-win {
          background-size: auto 55%;
          background-position: center calc(50%);
        }
      }
      .game-icon-caption {
        position: absolute;
        left: 0;
        bottom: 15px;
        width: 100%;
        color: $color-black;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
      }
    }
    p.clinique-spinner-mainline {
      @include swap_direction(margin, 15px auto 0);
      @include swap_direction(padding, 0 10px);
      font-size: 24px;
      line-height: 25px;
    }
  }
}
/* Mobile */
@include breakpoint($small-max) {
  section {
    &.clinique-spinner {
      h2 {
        @include swap_direction(margin, 10px auto 0);
      }
      #play-button {
        @include swap_direction(margin, 20px auto 0);
        font-size: 18px;
        outline-offset: -2px;
      }
      .subline-tcs {
        @include swap_direction(margin, 20px auto 0);
        @include swap_direction(padding, 20px);
      }
      section {
        &#play-again-popup {
          background-size: 61px 9px;
          .play-again-popup-box {
            @include swap_direction(padding, 15px);
            box-shadow: none;
          }
          .play-again-popup-container {
            border-width: 5px;
            outline-width: 1px;
            outline-offset: -7px;
            h2 {
              @include swap_direction(margin, -110px auto 0);
            }
            p {
              &.sub-header {
                font-size: 28px;
                line-height: 30px;
                font-weight: 100;
              }
              &.sub-line {
                @include swap_direction(margin, 20px auto 0);
                font-size: 16px;
                font-weight: 100;
              }
            }
          }
          .play-button {
            @include swap_direction(padding, 15px 40px);
          }
          .play-again-popup-container div.play-again-tcs p {
            @include swap_direction(margin, 20px auto 0);
            font-size: 10px;
          }
        }
        &#game-win-box .pop-up-box-inner {
          @include swap_direction(padding, 20px 10px);
          width: 100%;
          height: auto;
          overflow: hidden;
          .win-banner {
            @include swap_direction(margin, 45px auto 0);
            width: 100%;
            height: 70px;
          }
          .button {
            max-width: 200px;
          }
        }
      }
    }
    &.clinique-spinneG section#game-win-box .win-message-items {
      display: block;
    }
    &.clinique-spinner section#game-win-box {
      .win-image,
      .win-image--mobile {
        height: 300px;
        width: 100%;
        background-size: contain;
      }
      .win-info {
        height: auto;
        width: 100%;
        background-size: contain;
        display: block;
      }
      .win-offer-message {
        @include swap_direction(margin, 0 auto);
        font-size: 16px;
      }
      .win-message-items {
        @include swap_direction(padding, 0 10px);
        display: block;
      }
      .win-box-btns {
        @include swap_direction(margin, 0 auto);
      }
    }
  }
  section.clinique-spinner section#game-win-box {
    .button,
    .play-again-btn {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 10px auto 0);
      width: 100%;
    }
  }
  .random-gift-picker-formatter {
    #game-error .button,
    &#game-unavailable .button {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 10px auto 0);
      width: 100%;
    }
    .pop-up-box .pop-up-footer {
      @include swap_direction(padding, 0 0 30px);
      @include swap_direction(margin, 10px auto 0);
      position: relative;
    }
  }
  /* Image replacement */
  section.clinique-spinner .clinique-spinner-title {
    width: 204px;
    height: 106px;
  }
}
