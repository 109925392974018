#tabbed-subnav-uk__nav-container {
  .tabs {
    text-align: center;
    font-size: 0; /* fixes whitespace issue between li's */
    .tab-item {
      display: inline-block;
      padding: 14px 20px;
      border-right: 1px solid #fff;
      a {
        background: url('/media/export/cms/events/arrow_white.png') no-repeat right center;
        color: #fff;
        font-size: 21px;
        padding-right: 15px;
        font-weight: initial;
        text-rendering: optimizelegibility;
        -webkit-font-smoothing: antialiased;
      }
    }
    .first {
      border-left: 1px solid #fff;
    }
    .tab-item.active {
      background-color: #fff !important;
      a {
        background: url('/media/export/cms/events/arrow_green.png') no-repeat right center;
        color: #010101;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  #tabbed-subnav-uk__nav-container {
    .tabs {
      display: table;
      width: 100%;
      .tab-item {
        display: table-cell;
        padding: 6px 10px;
        border-left: 1px solid #fff;
        border-right: 0;
        box-shadow: inset 0 9px 20px -12px rgba(0, 0, 0, 0.4);
        a {
          font-size: 18px;
          display: block;
        }
      }
      .first {
        border: 0;
      }
    }
  }
}

.content_container {
  padding-top: 35px;
  @if $cr19 == true {
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .device-mobile #header .btn_navigation {
    position: inherit;
  }
  .site-logo-responsive-v1 {
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .device-mobile .block-template-search-mobile-v1 {
    position: inherit !important;
    left: 0px;
  }
}
