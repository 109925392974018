#quickshop-wrapper {
  width: 100%;
}

#quickshop {
  display: none;
  width: auto;
  max-width: 900px;
  padding: 23px 0;
  position: relative;
  border-top: 1px solid $color-shade-grey;
  border-bottom: 1px solid $color-shade-grey;
  .close {
    cursor: pointer;
    background: image-url('icon-close.png') no-repeat 0 0;
    width: 20px;
    height: 21px;
    top: 15px;
    #{$rdirection}: 15px;
    position: absolute;
    color: transparent;
    text-indent: -999em;
  }
  .shades .shade-dd {
    .col2 {
      width: 76%;
    }
  }
  .size-options .sizes-dd {
    .col2 {
      width: 76%;
    }
  }
}

.elc-user-state-logged-in
  .module-spp-detail
  .icon-heart-message.favorites-add-success
  .favorites-added-signedIn {
  display: block;
}

.elc-user-state-logged-in
  .module-spp-detail
  .icon-heart-message.favorites-add-success
  .favorites-added-signedOut {
  display: none;
}

[data-productid='PROD35510'] {
  &.module-spp-detail .sizes ul li a {
    background-image: none;
  }
  .module-spp-detail .sizes ul li a {
    background-image: none;
  }
}

.module-spp-detail {
  .skin-type {
    border-top: 1px solid $color-shade-grey;
    padding-top: 13px;
    h3 {
      @include swap_direction(margin, 0 0 15px 0);
      color: $color-black;
      font-size: 15px;
    }
  }
  .col1 {
    @include swap_direction(margin, 0 30px 0 0);
    float: #{$ldirection};
    width: 65%;
    .badge {
      @include border-radius(50%);
      position: absolute;
      top: 300px;
      #{$ldirection}: 500px;
      display: table;
      width: 50px;
      height: 50px;
      color: $color-white;
      z-index: 1;
      padding: 0;
      line-height: 8px;
      font-family: $helvetica-light-font-family;
      vertical-align: middle;
      margin: 0;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      &.badge_1,
      &.badge_2,
      &.badge_3,
      &.badge_4,
      &.badge_5,
      &.badge_17,
      &.badge_92,
      &.badge_94,
      &.badge_96,
      &.badge_97 {
        background: $color-hot-pink;
      }
      /* for longer badge names */
      &.badge_96 {
        font-size: 12px;
      }
      &.badge_30 {
        background: $color-cl-green-2;
        @if $cr19 == true {
          background-color: $cr19-bg-black;
          color: $cr19-text-white;
        }
      }
      html.ie7 & {
        text-align: center;
      }
      .badge-inner {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        html.ie7 & {
          position: relative;
          margin-top: expression(
            offsetHeight < parentNode.offsetHeight ?
              parseInt((parentNode.offsetHeight - offsetHeight) / 2) + 'px': '0'
          );
          clear: none;
        }
      }
      &.badge--medium,
      &.badge--large,
      &.badge--small {
        top: 0;
        #{$ldirection}: 0;
      }
    }
  }
  .prod-hd {
    color: $color-cl-green-2;
    font-size: 22px;
    font-family: $helvetica-light-font-family;
    line-height: 27px;
    margin-bottom: 15px; // TODO: candidate for globalization (spacing/margin styles)
    @if $cr19 == true {
      color: $cr19-text;
    }
  }
  .benefits-term {
    font-weight: bold;
  }
  .prod-desc {
    @include swap_direction(margin, 0 0 10px 0);
    color: $color-black;
    font-size: 13px;
    line-height: 19px;
    /* max-height: 20px; */
    overflow: hidden;
  }
  .read-more-link {
    /* text-transform: lowercase; */
    white-space: nowrap;
    &:hover {
      text-decoration: none;
    }
  }
  .benefits,
  .skin-types-top {
    color: $color-black;
    margin: 0;
  }
  .benefits .term,
  .skin-types-top .term {
    font-family: $helvetica-light-font-family;
  }
  .benefits .list,
  .skin-types-top .list {
    font-family: $helvetica-bold-font-family;
  }
  .price {
    border-top: 1px solid $color-shade-grey;
    font-size: 18px;
    font-family: $helvetica-light-font-family;
    display: block;
    color: $color-btn-grey;
    clear: both;
    margin: 5px 0;
    padding-top: 13px;
    &.price2 {
      border: none;
      color: $color-discount-price;
      margin-top: 0;
      padding-top: 0;
    }
  }
  .strike-thru-price {
    border-top: 1px solid $color-shade-grey;
    font-size: 18px;
    font-family: $helvetica-light-font-family;
    display: block;
    color: $color-shade-grey;
    clear: both;
    margin: 5px 0 0;
    padding-top: 13px;
    text-decoration: line-through;
    & ~ .strike-thru-price {
      display: none; // only show the first one
    }
  }
  .icons {
    color: $color-light-grey;
    display: inline;
    padding-#{$ldirection}: 10px;
    [class^='icon-']:hover {
      color: $color-cl-dark-grey;
      cursor: pointer;
    }
    .icon-heart:before {
      content: '\f08a';
    }
    .icon-heart:hover,
    .icon-heart.active {
      color: $color-hot-pink;
      &:before {
        content: '\f004';
      }
    }
  }
  .icon-large {
    font-size: 24px;
  }
  .icon-message {
    border: 1px solid $color-black;
    display: none;
    padding: 5px;
    text-transform: uppercase;
    text-align: #{$ldirection};
    width: 345px;
    > span {
      display: none;
    }
  }
  .icon-message.active {
    display: inline-block;
  }
  .icon-heart-message.favorites-add-success {
    .favorites-added-signedIn {
      display: none;
    }
    .favorites-added-signedOut {
      display: block;
    }
    .favorites-exists {
      display: none;
    }
  }
  .icon-heart-message.favorites-add-exists {
    .favorites-exists {
      display: block;
    }
  }
  .clear {
    clear: both;
    float: none;
  }
  a.view-full {
    color: $color-cl-green-2;
    font-size: 20px;
    text-decoration: none;
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    transition: color 0.2s ease;
    display: block;
    padding-top: 15px;
    @if $cr19 == true {
      color: $cr19-text;
    }
    i {
      display: inline-block;
      margin-#{$ldirection}: 5px;
    }
  }
  .shades {
    border-top: 1px solid $color-shade-grey;
    padding-top: 13px;
    .col1 {
      display: inline-block;
      float: none !important;
      margin: -25px 0 0 0 !important;
      padding: 0;
      width: 22% !important;
      vertical-align: top;
    }
    h3.col1 {
      color: $color-black;
      margin: 0 !important;
    }
    .col2 {
      @include swap_direction(padding, 0 0 10px 0);
      display: inline-block;
      float: none !important;
      margin: 0 !important;
      width: 76% !important;
      vertical-align: top;
    }
  }
  .shade-dd {
    padding-bottom: 7px;
  }
  .size-options {
    border-top: 1px solid $color-shade-grey;
    padding-top: 13px;
    .col1 {
      display: inline-block;
      float: none !important;
      margin: -25px 0 0 0 !important;
      padding: 0;
      width: 22% !important;
      vertical-align: top;
    }
    h3.col1 {
      color: $color-black;
      margin: 0 !important;
    }
    .col2 {
      @include swap_direction(padding, 0 0 10px 0);
      display: inline-block;
      float: none !important;
      margin: 0 !important;
      width: 76% !important;
      vertical-align: top;
      .sbHolder {
        .sbSelector {
          margin: 0;
        }
        .sbOptions li {
          padding: 0;
        }
      }
    }
  }
  .size-dd {
    padding-bottom: 7px;
  }
  .smoosh-wrapper {
    .single-smoosh {
      overflow: hidden;
      padding: 0;
      border: none;
      outline: none;
      img {
        display: block;
        height: 121px;
        width: 100%;
        margin-bottom: -1px;
        margin-top: -1px;
        margin-#{$rdirection}: -1px;
        margin-#{$ldirection}: 0px;
      }
    }
    .duo-smoosh {
      margin: 10px 0;
      overflow: hidden;
      padding: 0;
      border: none;
      outline: none;
      img {
        display: block;
        height: 100%;
        width: 100%;
        /* These below are to prevent lines in between images in various Apple/Safari browsers. */
        margin-bottom: -1px;
        margin-top: -1px;
        margin-#{$rdirection}: -1px;
        margin-#{$ldirection}: 0px;
      }
    }
    .trio-smoosh {
      margin: 20px 0;
      overflow: hidden;
      padding: 0;
      border: none;
      outline: none;
      div {
        float: #{$ldirection};
      }
      img {
        display: block;
        height: auto;
        width: 100%;
        /* These below are to prevent lines in between images in various Apple/Safari browsers. */
        margin-bottom: -1px;
        margin-top: -1px;
        margin-#{$rdirection}: -1px;
        margin-#{$ldirection}: 0px;
      }
    }
    .quad-smoosh {
      margin: 10px 0;
      overflow: hidden;
      position: relative;
      padding: 0;
      border: none;
      outline: none;
      div {
        float: #{$ldirection};
        height: 50%;
        width: 50%;
      }
      img {
        display: block;
        height: 60px;
        width: 61px;
        /* These below are to prevent lines in between images in various Apple/Safari browsers. */
        margin-bottom: -1px;
        margin-top: -1px;
        margin-#{$rdirection}: -1px;
        margin-#{$ldirection}: -1px;
      }
    }
  }
  .shade-chooser {
    li {
      display: inline-block;
    }
    .shade-box {
      cursor: pointer;
      display: inline-block;
      width: 34px;
      height: 34px;
      overflow: hidden;
      border: 2px solid $color-white;
      &.active,
      &:hover {
        border: 2px solid $product-color-price;
      }
      .multi-2 {
        height: 50%;
        width: 100%;
        float: #{$ldirection};
      }
      .multi-3 {
        height: 100%;
        width: 33%;
        float: #{$ldirection};
      }
      .multi-4 {
        height: 50%;
        width: 50%;
        float: #{$ldirection};
      }
    }
    &.big .shade-box {
      width: 48px;
      height: 47px;
    }
  }
  .shade-info {
    margin-bottom: 10px;
    color: $color-black;
    strong {
      font-family: $helvetica-bold-font-family;
      font-weight: normal;
    }
  }
  .shades ul.more {
    display: none;
    float: #{$rdirection};
    color: $product-color-price;
    margin: 7px 0;
    font-size: 11px;
  }
  .more li {
    display: inline-block;
    margin: 0 -4px 0 0;
    a {
      color: $product-color-price;
      padding: 0 5px;
      &.active {
        color: $color-black;
      }
    }
  }
  .sizes {
    border-top: 1px solid $color-shade-grey;
    padding-top: 13px;
    h3 {
      font-size: 15px;
      color: $color-black;
    }
    ul {
      @include swap_direction(margin, 0 0 10px 0);
      padding: 0;
      li {
        display: inline-block;
        margin: 0;
        float: #{$ldirection};
        a {
          @include swap_direction(padding, 27px 5px 0 5px);
          @include swap_direction(margin, 0 5px 5px 0);
          display: block;
          width: 66px;
          height: 56px;
          /* IE8 hack */
          background: url('/media/export/images/ddml_pc.png') no-repeat -128px 6px;
          border: 1px solid $color-shade-grey;
          border-radius: 3px;
          color: $color-darker-grey;
          font-size: 12px;
          line-height: 12px;
          outline: none;
          @if $cr19 == true {
            color: $cr19-text-grey;
          }
          &.Tube {
            background: url('/media/export/images/tube_pc.png') no-repeat -128px 6px;
          }
          &.Fragrance {
            background: url('/media/export/images/fragrance_pc.png') no-repeat -126px 4px;
          }
          &.Square {
            background: url('/media/export/images/square_bottle_pc.png') no-repeat -127px 6px;
          }
          &.Tall {
            background: url('/media/export/images/tall_bottle_pc.png') no-repeat -126px 6px;
          }
          &.Jar {
            background: url('/media/export/images/jar_pc.png') no-repeat -126px 1px;
          }
          &.active {
            background-position: 0 -5px;
            border: 1px solid $color-black;
            color: $color-black;
            @if $cr19 == true {
              color: $cr19-text;
            }
          }
          &:hover {
            text-decoration: none;
            color: $color-cl-green-2;
            @if $cr19 == true {
              color: $cr19-text;
            }
          }
        }
      }
    }
  }
  .spp_product_status {
    padding-top: 8px;
    color: $color-wild-wtraberry;
    .coming_soon_stock_msg,
    .temp_out_of_stock_msg {
      a {
        clear: left;
        float: #{$ldirection};
        margin-bottom: 3px;
        &.btn-comingsoon {
          width: auto;
        }
        &.btn-email {
          display: none;
        }
        &.email-me {
          text-decoration: none;
        }
      }
    }
  }
  .replenishment {
    @include swap_direction(margin, 10px 0 0 0);
    background: url('/media/images/global/nav/top/calendar-icon.png') no-repeat scroll 0 0;
    color: $color-tradewind;
    font-size: 11px;
    height: 25px;
    padding-#{$ldirection}: 35px;
    text-align: #{$ldirection};
    cursor: pointer;
    width: 250px;
    @if $cr19 == true {
      color: $cr19-text;
    }
  }
  .sp-options {
    padding-#{$ldirection}: 15px;
    display: none;
  }
}

/*
**    Skin type
**/

.skin-type {
  border-top: 1px solid $color-shade-grey;
  padding-top: 13px;
  ul.quickshop-type {
    * {
      zoom: 1;
    }
    @include swap_direction(margin, 9px 0 10px 0);
    padding: 0;
    &:before {
      content: ' ';
      display: table;
    }
    &:after {
      content: ' ';
      display: table;
      clear: both;
    }
    li {
      float: #{$ldirection};
      display: block;
      margin: 0;
      vertical-align: top;
      &:first-child {
        margin-#{$ldirection}: 0px;
      }
      a {
        @include swap_direction(padding, 8px 5px 5px 5px);
        @include swap_direction(margin, 0 5px 5px 0);
        height: initial;
        width: 72px;
        border: 1px solid $color-shade-grey;
        border-radius: 3px;
        background: $color-white;
        display: block;
        text-align: #{$ldirection};
        font-size: 10px;
        color: $color-darker-grey;
        &:hover {
          color: $color-cl-green-2;
          @if $cr19 == true {
            color: $cr19-text;
          }
        }
        &.active {
          border: 2px solid $color-black;
          color: $color-black;
          background: $color-white;
        }
        span {
          @include swap_direction(margin, 0 0 6px 0);
          display: block;
          text-align: center;
          width: 17px;
          height: 17px;
          line-height: 20px;
          -webkit-font-smoothing: antialiased;
          color: $color-white;
          font-size: 12px;
          font-family: $helvetica-bold-font-family;
          border-radius: 50%;
          &.type-1 {
            background: $color-monte-carlo;
          }
          &.type-2 {
            background: $color-melanie;
          }
          &.type-3 {
            background: $color-wewak;
          }
          &.type-4 {
            background: $color-aqua-island;
          }
        }
        .type {
          float: #{$ldirection};
        }
        .label {
          clear: both;
        }
      }
      &.type-1 span {
        background: $color-monte-carlo;
      }
      &.type-2 span {
        background: $color-melanie;
      }
      &.type-3 span {
        background: $color-wewak;
      }
      &.type-4 span {
        background: $color-aqua-island;
      }
    }
  }
}

.quickshop-type {
  @include swap_direction(margin, 9px 0 10px 0);
  li {
    @include swap_direction(margin, 0 5px 5px 0);
    float: #{$ldirection};
    display: block;
    vertical-align: top;
    &:first-child {
      margin-#{$ldirection}: 0px;
    }
    a {
      @include swap_direction(padding, 8px 5px 5px 5px);
      height: 72px;
      width: 72px;
      border: 1px solid $color-shade-grey;
      border-radius: 3px;
      background: $color-white;
      display: block;
      text-align: #{$ldirection};
      font-size: 10px;
      color: $color-darker-grey;
      &.active {
        border: 2px solid $color-black;
        color: $color-black;
        background: $color-white;
      }
      &:hover {
        text-decoration: none;
        color: $color-cl-green-2;
        @if $cr19 == true {
          color: $cr19-text;
        }
      }
      span {
        @include swap_direction(margin, 0 0 6px 0);
        display: block;
        text-align: center;
        width: 17px;
        height: 17px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased;
        color: $color-white;
        font-size: 12px;
        font-family: $helvetica-bold-font-family;
        border-radius: 50%;
        &.type-1 {
          background: $color-monte-carlo;
        }
        &.type-2 {
          background: $color-melanie;
        }
        &.type-3 {
          background: $color-wewak;
        }
        &.type-4 {
          background: $color-aqua-island;
        }
      }
    }
    &.type-1 span {
      background: $color-monte-carlo;
    }
    &.type-2 span {
      background: $color-melanie;
    }
    &.type-3 span {
      background: $color-wewak;
    }
    &.type-4 span {
      background: $color-aqua-island;
    }
  }
}

.sbHolder {
  width: 220px;
  float: #{$ldirection};
  .sbOptions li {
    &.multi-shade {
      padding: 0;
      .shade_icon {
        width: 24px;
        .multi-4 {
          height: 12px;
          width: 12px;
          float: #{$ldirection};
        }
        .multi-3 {
          height: 24px;
          width: 8px;
          float: #{$ldirection};
        }
        .multi-2 {
          height: 24px;
          width: 12px;
          float: #{$ldirection};
        }
      }
      a {
        padding-#{$ldirection}: 28px;
      }
    }
  }
  .shade_icon {
    width: 32px;
    .multi-4 {
      height: 12px;
      width: 12px;
      float: #{$ldirection};
    }
    .multi-3 {
      height: 24px;
      width: 8px;
      float: #{$ldirection};
    }
    .multi-2 {
      height: 24px;
      width: 12px;
      float: #{$ldirection};
    }
  }
}

.module-spp-detail .col2 {
  float: #{$ldirection};
  width: 30%;
  color: $color-black;
  @include breakpoint($small-max) {
    width: 100%;
  }
  .abstract ul {
    list-style: disc;
    margin: 10px;
    padding: 10px;
  }
  h2 {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 5px;
    a {
      color: $color-cl-green-2;
      @if $cr19 == true {
        color: $cr19-text;
      }
    }
  }
  .ratings {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
    .review_percentage {
      font-size: 22px;
      font-family: $helvetica-light-font-family;
    }
  }
  .reviews {
    h3 {
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 5px;
      color: $color-black;
    }
    h4 {
      margin-bottom: 5px;
      color: $color-black;
      .user {
        color: $color-cl-green-2;
        @if $cr19 == true {
          color: $cr19-text;
        }
      }
    }
    p {
      margin-bottom: 10px;
    }
    .pros strong {
      font-family: $helvetica-bold-font-family;
      font-weight: normal;
    }
    .write a {
      @include cl-standard-link(black, false, $color-cl-green-2-darker);
    }
  }
  .price {
    border-top: 1px solid $color-shade-grey;
    display: block;
    font-size: 18px;
    font-family: $helvetica-light-font-family;
    margin-bottom: 5px;
    padding-top: 13px;
    & ~ .price {
      display: none; // only show the first one
    }
  }
  .shade-dd .col1 {
    font-size: 13px;
    width: 22%;
    margin: 0;
  }
  .shade-dd .col2 {
    width: 76%;
  }
}

.multi_qs_select {
  font-size: 22px;
  font-family: $helvetica-light-font-family;
  font-weight: normal;
  padding-bottom: 10px;
  color: $color-black;
  width: 80%;
}

div.tiny-waitlist-overlay {
  form {
    p {
      @include swap_direction(margin, 15px 0 0 0);
    }
    .field {
      label {
        margin: 0;
      }
      input[type='text'] {
        width: 100%;
      }
    }
  }
}

@include breakpoint(767px) {
  #quickshop {
    &.module-spp-detail {
      .replenishment {
        max-width: 250px;
        width: auto;
        span:last-child {
          line-height: 12px;
          float: #{$ldirection};
        }
      }
    }
  }
}
/* Pre Order related Styles */
.spp-sticky-bar {
  .spp_product_status {
    .pre_order_msg {
      display: none;
    }
  }
}

.module-spp-detail,
.product {
  .spp_product_status {
    .pre_order_active_msg,
    .pre_order_sold_msg {
      color: $color-cl-darker-grey;
      @include breakpoint($medium-mid) {
        margin-bottom: 15px;
      }
    }
  }
  .sticky-add-to-bag {
    &__section {
      .product-full__add-to-bag {
        .add-to-bag__container {
          .pre-order-btn {
            background: $color-white;
            color: $color-black;
          }
          .pre-order-no-shop {
            background: $color-btn-gray;
          }
        }
      }
    }
  }
}

.pre_order_checkout_message {
  font-size: 12px;
}

.preorder-msg {
  width: 50%;
}
