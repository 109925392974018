.store-locator-v1 .sbHolder {
  ::-webkit-scrollbar-button:vertical:decrement {
    height: 13px; /* up scroll button height */
    background: url(/media/export/images/mobile_sku_arrow_up.png) 1px 3px no-repeat;
  }
  ::-webkit-scrollbar-button:vertical:decrement:active {
    border: 1px solid grey;
  }
  ::-webkit-scrollbar-button:vertical:increment {
    height: 13px; /* down scroll button height */
    background: url(/media/export/images/mobile_sku_arrow_dn.png) 1px 2px no-repeat;
  }
  ::-webkit-scrollbar-button:vertical:increment:active {
    border: 1px solid grey;
  }
}

.store-locator-v1 .store-locator-body .outer-wrap .door_row.hidden-ie8 {
  display: none;
}
