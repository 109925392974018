.elc-user-state-logged-in {
  #foreground-node.product-fave-overlay-cbox {
    overflow: visible;
  }
}
.product-fave-overlay-cbox {
  @media #{$small-only} {
    border: none;
  }
  border: 1px solid $color-dark-grey;
  .close-container {
    top: 30px;
    right: 30px;
  }
  .loyalty__popup {
    @media #{$small-only} {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 20px 0 0 0);
    }
    &.left {
      @media #{$small-only} {
        width: 100%;
        @include swap_direction(padding, 0);
        @include swap_direction(margin, 0);
      }
      width: 42%;
      float: #{$ldirection};
      img {
        width: 100%;
        @media #{$small-only} {
          position: absolute;
          z-index: -1;
          right: 0;
          width: 165px;
        }
      }
    }
    &.right {
      float: #{$rdirection};
      width: 55%;
      font-size: 18px;
      @include swap_direction(padding, 40px 0);
      @include swap_direction(margin, 0 0 0 15px);
      .loyalty_post_signin_terms {
        font-size: 12px;
        line-height: 12px;
      }
      @media #{$small-only} {
        width: 100%;
        font-size: 14px;
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
      }
      h2 {
        font-size: 30px;
        font-weight: normal;
        @include swap_direction(margin, 0 0 10px 0);
        @media #{$small-only} {
          font-size: 30px;
          width: 180px;
        }
      }
      h3 {
        color: $color-black;
        font-weight: bold;
        font-size: 19px;
        font-family: $helvetica-regular-font-family;
        @media #{$small-only} {
          color: $color-black;
          font-weight: bold;
          font-size: 22px;
          font-family: $helvetica-regular-font-family;
          width: 180px;
          @include swap_direction(margin, 20px 0 20px 0);
        }
      }
      p {
        @media #{$small-only} {
          @include swap_direction(margin, 10px 0 0 0);
        }
      }
    }
    &.right_content {
      @media #{$small-only} {
        @include swap_direction(padding, 10px 10px);
        @include swap_direction(margin, 0 0 0 5px);
      }
      @include swap_direction(padding, 16px 16px);
      li {
        @media #{$small-only} {
          list-style-type: disc;
          @include swap_direction(padding, 2px 0);
        }
        list-style-type: disc;
        @include swap_direction(padding, 5px 0);
      }
    }
    &.button {
      @media #{$small-only} {
        @include swap_direction(margin, 0 0 10px 0);
        @include swap_direction(padding, 0);
        font-size: 18px;
      }
      height: auto;
      font-size: 21px;
      @include swap_direction(padding, 5px 30px 5px 30px);
    }
    &.join_loyalty_btn {
      display: block;
      font-size: 11px;
      @include swap_direction(padding, 15px 0);
      @media #{$small-only} {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        font-size: 14px;
      }
    }
    .terms_conditions {
      font-size: 11px;
    }
  }
  #loyalty--popup__gdpr--section {
    width: fit-content;
    margin-top: 20px;
    font-size: 12px;
    color: $color-black;
    .loyalty--popup__gdpr--title {
      display: flex;
      gap: 10px;
      align-items: center;
      padding-bottom: 5px;
      .loyalty--popup__gdpr--icon {
        cursor: pointer;
        background: $color-black;
        color: $color-white;
        border-radius: 20px;
        width: 20px;
        height: 20px;
        display: block;
        text-transform: lowercase;
        @include swap_direction(padding, 4px 0 0 9px);
        line-height: 1em;
      }
      button {
        text-decoration: underline;
        background: none;
        border: none;
        &:focus {
          overflow: unset;
        }
      }
    }
    .loyalty--popup__gdpr--content {
      position: absolute;
      background-color: $color-white;
      border-radius: 5px;
      border: 1px solid $color-black;
      padding: 15px;
      margin: 0 20px;
    }
  }
}
//loyalty tab menu
.account-section {
  &.loyalty-page {
    #loyalty__page__about {
      #loyalty_market_sticky_nav {
        display: none;
      }
    }
    .loyalty_market_smart_right {
      .loyality_sign_off,
      .loyalty_join_button,
      .loyalty_signin {
        display: none;
      }
    }
    .loyalty-loading {
      text-align: center;
      @include swap_direction(padding, 20% 0);
    }
    #loyalty__panel__best-sellers_list,
    #loyalty__panel__offers__sku {
      @include swap_direction(padding, 0 5%);
      @media (min-width: 768px) {
        @include swap_direction(padding, 0);
        @include background(linear-gradient($color-white, $color-light-white));
        @include box-shadow(inset 0 -12px 12px -12px rgba(50, 50, 50, 0.2));
      }
      .loyalty__panel__best-sellers__list-container,
      .loyalty__panel__offers__sku__list-container {
        border-top: 0;
        @include swap_direction(padding, 15px 0 10px 0);
        .mobile_mpp {
          background: transparent;
        }
        .loyalty__panel__best-seller-info_tittle-1 {
          font-family: $helvetica-light-font-family;
          font-size: 2.8em;
          line-height: 42px;
          width: auto;
          @include swap_direction(margin, 15px 0 12px 0);
          @include breakpoint(767px) {
            font-size: 36px;
            @include swap_direction(margin, 0);
          }
        }
        div.loyalty__panel__subtitle {
          font-family: $helvetica-light-font-family;
          font-size: 22px;
          @include swap_direction(margin, 0 0 15px 0);
        }
        .product-image {
          @include breakpoint(767px) {
            width: 50%;
          }
          float: #{$ldirection};
          img {
            width: 100%;
          }
        }
        .product-info {
          @include swap_direction(padding, 0 0 20px 0);
          min-height: 280px;
          @include breakpoint(767px) {
            width: 50%;
            min-height: 150px;
          }
          .product-name {
            a {
              color: $color-cl-soft-black;
            }
          }
          .price {
            color: $color-darker-grey;
          }
          .reviews img {
            width: 60px;
            display: inline;
          }
        }
        .loyalty_earn_terms {
          width: 100%;
          float: #{$ldirection};
          @include swap_direction(padding, 20px 0 30px);
          a {
            font-size: 12px;
            color: $color-black;
            text-decoration: $text-decoration-underline;
            line-height: 2em;
          }
        }
      }
      .loyalty__panel__offers__sku__list-container {
        border-top: 0;
        @include swap_direction(padding, 0);
        .offer_sku_flexslider {
          ol.flex-control-paging {
            bottom: -6%;
            line-height: 1.7;
          }
        }
      }
    }
    @include swap_direction(padding, 0 0 70px 0);
    @include breakpoint(767px) {
      @include swap_direction(padding, 0);
      .outer-wrap {
        @include swap_direction(padding, 0);
      }
      #loyalty__navigation {
        display: none;
      }
    }
    .breadcrumb {
      display: none;
    }
    .loyalty_tab_menu {
      width: 100%;
      display: inline-block;
      @media (min-width: 768px) {
        @include background(linear-gradient($color-white, $color-light-white));
        @include box-shadow(inset 0 -12px 12px -12px rgba(50, 50, 50, 0.2));
      }
      .loyalty_tab {
        @include breakpoint(767px) {
          display: none;
        }
        background: image-url('toolbar-bg.png') top left repeat-x;
        height: 58px;
        border-top: 1px solid $color-lighter-grey;
        border-bottom: 1px solid $color-lighter-grey;
        box-shadow: 6px 6px 14px $color-shade-grey;
        .tab_section {
          display: inline;
          li {
            display: inline;
            float: #{$ldirection};
            white-space: nowrap;
            border-#{$rdirection}: 1px solid $color-btn-gray;
            .active {
              background-color: $color-white;
              border-bottom: none;
              box-shadow: 0 15px 5px $color-white;
            }
          }
          li:first-child {
            border-#{$ldirection}: 1px solid $color-btn-gray;
          }
          a {
            @include swap_direction(padding, 20px 35px);
            float: #{$ldirection};
            color: $product-color-price;
            font: bold 14px $helvetica-regular-font-family;
            text-transform: uppercase;
            letter-spacing: 0.5px;
          }
        }
        .tab_container {
          max-width: 960px;
          @include swap_direction(margin, 0 auto);
          clear: both;
          .row {
            padding-top: 10px;
          }
        }
      }
      .loyalty__panel_tier-benifits-rows {
        #loyalty__panel__transactions {
          @include breakpoint(767px) {
            border-bottom: 0px;
          }
          .loyalty__panel__transactions__title {
            font-family: $helvetica-light-font-family;
            font-size: 27px;
            font-weight: $font-weight-bold;
            line-height: 38px;
            margin-bottom: 20px;
            width: auto;
          }
          .loyalty__panel__transactions__list,
          .loyalty__panel__transactions__faqs {
            margin-#{$ldirection}: 17px;
          }
          .loyalty__panel__subtitle {
            font-size: 25px;
            font-family: $helvetica-light-font-family;
          }
          .loyalty__panel__transactions__subtitle,
          .loyalty__panel__transactions__faqs {
            font-family: $helvetica-regular-font-family;
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 10px;
            width: 90%;
          }
          .loyalty__panel__transactions__subtitle {
            list-style-type: disc;
          }
          .loyalty__panel__transactions__faqs {
            margin-top: 20px;
          }
          #loyalty__panel__transactions_table {
            .loyalty__panel__transactions__paginate {
              text-align: right;
              @include swap_direction(padding, 15px 0 0 0);
            }
            @include breakpoint(767px) {
              @include swap_direction(margin, 0);
              #loyalty__panel__transactions {
                @include swap_direction(padding, 0);
              }
            }
            table.loyalty__panel__transactions__table {
              width: 100%;
              float: #{$ldirection};
              @include breakpoint(767px) {
                float: none;
              }
              thead {
                border-bottom: 1px solid $color-light-grey;
                .loyalty__panel__transactions__list__column-date {
                  @include swap_direction(padding, 0 0 12px 0);
                  width: 16%;
                  font-size: 1.8em;
                  font-weight: normal;
                  font-family: $helvetica-light-font-family;
                }
                .loyalty__panel__transactions__list__column-activity {
                  width: 36%;
                  font-size: 1.8em;
                  font-weight: normal;
                  font-family: $helvetica-light-font-family;
                }
                .loyalty__panel__transactions__list__column-points {
                  width: 20%;
                  font-size: 1.8em;
                  font-weight: normal;
                  font-family: $helvetica-light-font-family;
                  text-align: #{$rdirection};
                  @include breakpoint(767px) {
                    font-size: 1.2em;
                    @include swap_direction(padding, 7px 0);
                  }
                }
              }
              tbody {
                .loyalty__panel__transactions__transaction {
                  border-bottom: 1px solid $color-light-grey;
                  line-height: 3.5;
                  @media #{$small-only} {
                    line-height: 2em;
                  }
                  td {
                    vertical-align: middle;
                    .loyalty__panel__transactions__list__column-earned__points-earned {
                      display: inline-block;
                      @include swap_direction(margin, 0 0 0 5%);
                    }
                    .loyalty__panel__transactions__list__column-activity__transactions {
                      @media #{$small-only} {
                        display: block;
                      }
                    }
                  }
                  td.loyalty__panel__transactions__list__column-activity--level {
                    font-family: $helvetica-bold-font-family;
                    font-size: 1.3em;
                    @include breakpoint(767px) {
                      font-size: 1em;
                      .loyalty__panel__transactions__list__column-date {
                        font-weight: normal;
                      }
                    }
                  }
                  .loyalty__panel__transactions__sub-heading {
                    font-size: 14px;
                    display: block;
                    font-family: $helvetica-regular-font-family;
                    @media #{$medium-up} {
                      font-size: 13px;
                      line-height: normal;
                      margin-top: -1em;
                      margin-bottom: 1.2em;
                    }
                  }
                  .last_td {
                    vertical-align: middle;
                    text-align: #{$rdirection};
                    @media #{$small-only} {
                      vertical-align: top;
                      @include swap_direction(padding, 25px 0 0 0);
                    }
                    .loyalty__panel__transactions__list__column-earned__points-earned {
                      display: inline-block;
                      @include swap_direction(margin, 0 0 0 5%);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .loyalty__panel_tier-benifits-rows {
        .row {
          &.status_rewards .loyalty__panel__points__button .button {
            display: none;
          }
          @include breakpoint(767px) {
            @include swap_direction(padding, 0);
            section:first-child {
              @include swap_direction(padding, 5%);
              border-bottom: 1px solid $color-shade-grey;
              width: 100%;
              .account-loyalty {
                @include swap_direction(padding, 0);
                border-bottom: 0px;
                .section-content {
                  .tier-point-msg {
                    padding: 25px 0 0;
                  }
                }
              }
            }
          }
          width: 100%;
          float: #{$ldirection};
          clear: #{$ldirection};
          @include swap_direction(padding, 35px 0 30px 0);
          .loyalty__panel__points {
            float: #{$ldirection};
            width: 42%;
            @include breakpoint(767px) {
              width: 100%;
            }
            .section-content {
              padding-top: 13px;
            }
            .section-loyalty_rewards__header {
              color: $color-black;
              font-size: 38px;
              @include breakpoint(767px) {
                font-size: 32px;
                @include swap_direction(margin, 0);
              }
            }
          }
          .loyalty__panel__tier_offers {
            float: #{$rdirection};
            width: 58%;
            position: relative;
            @include breakpoint(767px) {
              width: 100%;
              @include swap_direction(padding, 5%);
              border-bottom: 1px solid $color-shade-grey;
            }
            .flexslider {
              ul li {
                margin-right: 0px;
              }
              @include swap_direction(padding, 75px 8% 0 24%);
              @include breakpoint(767px) {
                @include swap_direction(padding, 0);
              }
              ul.slides {
                li.tier_1 {
                  h2 {
                    color: $color-ly-darker-green;
                  }
                }
                li.tier_2 {
                  h2 {
                    color: $color-ly-dark-purple;
                  }
                }
                li.tier_3 {
                  h2 {
                    color: $color-ly-darker-orange;
                  }
                }
                ul li {
                  list-style-type: disc;
                  @include swap_direction(margin, 0 0 0 17px);
                  font-size: 15px;
                  line-height: 20px;
                }
              }
            }
            .flex-direction-nav {
              display: none;
              @include breakpoint(767px) {
                display: none;
              }
            }
            .flex-next {
              right: -26px;
              top: 145px;
            }
            .flex-next.flex-disabled {
              display: none;
            }
            .flex-prev {
              left: 50px;
              top: 145px;
            }
            .flex-control-paging {
              display: none;
              @include breakpoint(767px) {
                text-align: center;
                display: block;
              }
            }
          }
          .loyalty__panel__points__history {
            width: 100%;
            float: #{$ldirection};
            @include swap_direction(padding, 0 0 30px 0);
            @include breakpoint(767px) {
              float: none;
              border: 0px;
              @include swap_direction(padding, 0);
              @include swap_direction(margin, 0);
            }
            @include swap_direction(margin, 0 0 15px 0);
            border-bottom: 1px solid $color-light-grey;
            .loyalty__panel__points__points__details {
              @include swap_direction(margin, 70px 0 0 20px);
              float: #{$rdirection};
              width: 50%;
              div.loyalty__panel__transactions__subtitle {
                width: 100% !important;
                @include swap_direction(padding, 15px 0 15px 0);
              }
              @include breakpoint(768px) {
                float: none;
                width: 100%;
              }
              div.loyalty__panel__transactions__annvdate {
                font-size: 35px;
                font-family: $helvetica-regular-font-family;
                display: none;
              }
            }
            .loyalty__panel__points__progessbar {
              float: #{$ldirection};
              width: 35%;
              @include breakpoint(768px) {
                float: none;
                width: 100%;
              }
              @include breakpoint(767px) {
                section:first-child {
                  @include swap_direction(padding, 0);
                }
              }
              .loyalty__panel__points {
                width: 100%;
                .section-loyalty_rewards__header {
                  display: none;
                }
              }
              .loyalty__panel__transactions__transactions-info {
                .loyalty-panel-transactions-info {
                  font-size: 12px;
                  @include swap_direction(margin, 12px 0 10px 0);
                  display: inline-block;
                }
              }
            }
          }
          &.item-2 {
            .flexslider {
              padding: 0px;
              li {
                width: 230px !important;
                img {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
    .loyalty-page__content {
      .loyalty__panel__title {
        color: $color-sharp-black;
        font-size: 27px;
        font-weight: $font-weight-bold;
        margin-bottom: 12px;
        padding: 0;
        @media #{$cr19-medium-up} {
          padding-top: 30px;
        }
      }
      .loyalty__panel__subtitle {
        color: $color-black;
        font-size: 18px;
        @media #{$cr19-medium-up} {
          font-size: 22px;
        }
      }
      h3.loyalty__panel__points__title {
        font-size: 20px;
        color: $color-sharp-black;
        @include swap_direction(padding, 10px 0);
      }
      #loyalty__panel__offers,
      #loyalty__panel__offers__sku,
      #loyalty__panel__how-to-earn,
      #loyalty__panel__perks,
      #loyalty__panel__offers__earnpoints {
        @media (min-width: 768px) {
          @include background(linear-gradient($color-white, $color-light-white));
          @include box-shadow(inset 0 -12px 12px -12px rgba(50, 50, 50, 0.2));
          @include swap_direction(padding, 0 0 40px 0);
        }
        position: relative;
        display: inline-block;
        width: 100%;
        @include breakpoint(767px) {
          @include swap_direction(padding, 5%);
          border-bottom: 1px solid $color-shade-grey;
        }
        .flex-direction-nav {
          .flex-next {
            opacity: 1;
          }
          .flex-prev {
            opacity: 1;
          }
          .flex-disabled {
            opacity: 0;
          }
          @include breakpoint(767px) {
            display: none;
          }
        }
        .loyalty__panel__offers__list {
          ol.flex-control-paging {
            display: none;
            @include breakpoint(767px) {
              position: absolute;
              bottom: 29%;
              width: 90%;
              text-align: center;
              display: block;
            }
          }
        }
        .loyalty__panel__offers__offer-wrapper,
        .loyalty__panel__offers__sku__product-wrapper,
        #loyalty__panel__offers__earnpoints {
          font-size: 15px;
          text-transform: capitalize;
        }
        .loyalty__panel__offers__offer-wrapper,
        .loyalty__panel__offers__earnpoints__offer-wrapper {
          @include swap_direction(margin, 0 5% 5% 5%);
          .loyalty__panel__offers__offer-data-container {
            min-height: 50px;
          }
          .loyalty__panel__offers__offer-image-container,
          .loyalty__panel__offers__earnpoints__offer-image-container {
            float: none;
            padding: 0;
            @media #{$cr19-medium-up} {
              @include swap_direction(padding, 0 0 5px 3px);
            }
          }
          div {
            float: #{$ldirection};
            @include swap_direction(padding, 0 0 5px 3px);
            color: $color-dark-grey;
            font-size: 14px;
          }
          div.loyalty__panel__offers__offer-code-wrap,
          .loyalty__panel__offers__earnpoints__offer-code-wrap {
            width: 100%;
          }
          .loyalty__panel__offers__button-container,
          .loyalty__panel__offers__earnpoints__button-container {
            @include swap_direction(padding, 0 0 5px 0);
            clear: left;
            width: 100%;
          }
          .loyalty__panel__offers__cta,
          .loyalty__panel__offers__earnpoints__cta {
            @include swap_direction(padding, 10px 0 0 0);
            font-size: 15px;
            clear: left;
            .loyalty__panel__offers__earnpoints_offer_description {
              @include swap_direction(padding, 25px 0 0 0);
              font-size: 15px;
              color: $color-black;
            }
            .loyalty__panel__offers__earnpoints_offer_products {
              @include swap_direction(padding, 15px 0 15px 0);
              color: $product-color-price;
              clear: left;
              font-size: 15px;
              text-transform: capitalize;
            }
          }
        }
      }
      #loyalty__panel__offers {
        .loyalty__panel__offers__offer-wrapper {
          text-transform: none;
        }
        .loyalty__panel__offers__list {
          @media #{$small-only} {
            ol.flex-control-paging {
              bottom: 20%;
            }
          }
        }
      }
      #loyalty__panel__offers__sku {
        @media #{$small-only} {
          @include swap_direction(padding, 5% 5% 45px 5%);
        }
      }
      #loyalty__panel__perks {
        .loyalty-grid-formatter {
          margin-top: 30px;
        }
        #points_perks {
          h2 {
            &.landing_perks {
              display: block;
            }
            &.market_perks {
              display: none;
            }
          }
          h2.loyalty__panel__title {
            @include swap_direction(margin, 0 0 0 0);
          }
          h3.loyalty__panel__subtitle {
            color: $color-black;
            font-size: 22px;
          }
        }
        @include breakpoint(767px) {
          display: none;
        }
      }
      #loyalty__panel__how-to-earn {
        min-height: 200px;
        border-bottom: 0px;
        @include breakpoint(767px) {
          min-height: 80px;
        }
        font-size: 16px;
        display: none;
        .loyalty_earn_terms {
          a {
            font-size: 12px;
          }
        }
        .loyalty_panel_earn_desc {
          border-bottom: 1px solid $color-lighter-grey;
          @include swap_direction(padding, 0 0 20px 0);
          @include swap_direction(margin, 0 0 10px 0);
          @include breakpoint(767px) {
            display: none;
          }
          h3 {
            @include swap_direction(margin, 0px);
            @include swap_direction(padding, 0px);
            font-size: 24px;
          }
          .loyalty__panel__how-to-earn__title {
            color: $color-cl-green-2;
            font-size: 24px;
            font-weight: normal;
            padding-bottom: 15px;
            @if $cr19 == true {
              color: $cr19-text-grey;
            }
          }
          .loyalty__panel__how-to-earn__description {
            font-size: 12px;
          }
        }
      }
      #loyalty__panel__offers__sku {
        .loyalty__account__landing__title {
          display: none;
          @include breakpoint(767px) {
            .loyalty__panel__points__title {
              display: none;
            }
            .loyalty__panel__title {
              font-size: 32px;
            }
          }
        }
        .flexslider {
          min-width: 960px;
          padding: 0px;
          @include breakpoint(767px) {
            min-width: 100%;
          }
        }
        ul.slides {
          li {
            margin-right: 0px;
            @include swap_direction(padding, 0 20px);
            @include breakpoint(767px) {
              img {
                width: 80%;
                margin: 0 auto;
              }
              @include swap_direction(padding, 0 20px 0 24px);
            }
            .loyalty__panel__offers__sku__product__data__wrapper {
              min-height: 140px;
              @include breakpoint($ab-small-down) {
                min-height: 130px;
              }
            }
            .loyalty__panel__offers__sku__product__header {
              @include swap_direction(padding, 25px 0 0 0);
              font-size: 15px;
              color: $color-black;
            }
            .loyalty__panel__offers__sku__product__offer-code {
              @include swap_direction(padding, 15px 0 15px 0);
              color: $product-color-price;
            }
            .loyalty__panel__offers__sku__cta {
              position: relative;
              @include swap_direction(padding, 0 0 70px 0);
              .loyalty__panel__offers__sku__loader {
                text-align: center;
                width: 100%;
                position: absolute;
                top: 0;
                background: $color-light-white;
                @include breakpoint(767px) {
                  background: $color-white;
                  @include swap_direction(padding, 8px 0 8px 0);
                }
                img {
                  width: 40px;
                  height: 40px;
                  display: inline-block;
                }
              }
              .button--offers {
                @include swap_direction(padding, 0 0 15px 0);
                font-size: 1em;
                letter-spacing: 0.05em;
                width: 100%;
                @media #{$cr19-medium-up} {
                  padding: 15px 0;
                }
                &.disable_product {
                  background-color: $color-ly-shade-green;
                  cursor: default;
                }
              }
              @media #{$small-only} {
                text-align: center;
              }
            }
            &.offer_not_met {
              .loyalty__panel__offers__sku__product-image-container {
                img {
                  opacity: 0.5;
                }
              }
              .loyalty__panel__offers__sku__cta {
                a {
                  background-color: $color-btn-gray;
                  text-transform: $text-transform-capitalize;
                  &.loyalty__panel__offers__sku__selected,
                  &.loyalty__panel__offers__sku__remove {
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
        .flex-viewport {
          min-width: 100%;
          padding-bottom: 20px;
          @media #{$cr19-medium-up} {
            min-width: 960px;
            padding-bottom: 0;
          }
        }
      }
      #loyalty__panel__offers,
      #loyalty__panel__offers__earnpoints {
        .loyalty__panel__offers__list,
        .loyalty__panel__offers__earnpoints__list {
          .not_meet {
            .loyalty__panel__offers__offer-image-container,
            .loyalty__panel__offers__earnpoints__offer-image-container {
              img {
                opacity: 0.5;
              }
            }
          }
          .flexslider {
            @include swap_direction(padding, 0);
            @include breakpoint(767px) {
              min-width: 100%;
            }
            .flex-viewport {
              @include breakpoint(767px) {
                min-width: 100%;
              }
            }
          }
          .flex-control-paging {
            bottom: 67%;
            @include breakpoint(767px) {
              bottom: 28%;
            }
          }
        }
        .loyalty__panel__offers__offer,
        .loyalty__panel__offers__earnpoints__offer {
          .loyalty__panel__offers__button,
          .loyalty__panel__offers__earnpoints__button,
          .loyalty__panel__offers__remove,
          .loyalty__panel__offers__redeemed {
            &.not_meet {
              background: $color-dark-grey;
              cursor: default;
            }
            display: block;
            text-transform: $text-transform-capitalize;
            @include breakpoint(767px) {
              @include swap_direction(padding, 0 30px);
            }
          }
        }
        .loyalty__panel__offers__offer--in-cart {
          .loyalty__panel__offers__remove {
            display: block;
          }
          .loyalty__panel__offers__button {
            display: none;
          }
        }
        .loyalty__panel__offers__offer--used {
          .loyalty__panel__offers__redeemed {
            display: block;
          }
          .loyalty__panel__offers__button {
            display: none;
          }
        }
        .loyalty__panel__offers__error {
          color: $color-notice-pink;
        }
      }
      #loyalty__panel__offers__earnpoints {
        .loyalty__panel__offers__earnpoints__offer-wrapper {
          @include breakpoint($ab-small-down) {
            .loyalty__panel__offers__earnpoints__cta {
              float: none;
              text-align: center;
            }
            .loyalty__panel__offers__earnpoints__button-container {
              float: none;
              display: inline-block;
            }
          }
        }
        .loyalty__panel__offers__earnpoints__list-container {
          .offers_flexslider {
            ol.flex-control-paging {
              bottom: 10px;
              @media #{$cr19-medium-up} {
                bottom: -5%;
              }
            }
            ul {
              li {
                @include breakpoint($ab-small-down) {
                  @include swap_direction(margin, 0 5px 0 7px);
                }
              }
            }
          }
        }
      }
      #loyalty__panel__offers {
        .loyalty__panel__offers__offer-wrapper {
          .loyalty__panel__offers__cta {
            float: none;
            text-align: center;
            @media #{$cr19-medium-up} {
              float: #{$ldirection};
              width: 100%;
            }
            .loyalty__panel__offers__button-container {
              display: inline-block;
              float: none;
              @media #{$cr19-medium-up} {
                display: block;
                float: #{$ldirection};
              }
              .button--offers {
                @media #{$cr19-medium-up} {
                  padding: 15px 0;
                }
              }
            }
          }
        }
      }
    }
    @include breakpoint(767px) {
      #loyalty__panel__benefits__content {
        @include swap_direction(padding, 20px 0);
        border-bottom: 1px solid $color-lighter-grey;
        .benefits_flexslider {
          .flex-control-nav {
            text-align: center;
            position: relative;
            bottom: 0;
            display: none;
          }
          ul.slides {
            li {
              margin-#{$rdirection}: 0px;
            }
            h2 {
              color: $color-white;
              @include swap_direction(padding, 20px 0 20px 15px);
              font-size: 25px;
              @include swap_direction(margin, 0 5px 20px 5px);
            }
            li.tier_1 {
              h2 {
                background-color: $color-ly-dark-green;
              }
            }
            li.tier_2 {
              h2 {
                background-color: $color-ly-dark-purple;
              }
            }
            li.tier_3 {
              h2 {
                background-color: $color-ly-dark-orange;
              }
            }
            ul li {
              list-style-type: none;
              @include swap_direction(margin, 10px 10px 0 10px);
              font-size: 15px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}

#loyalty__panel__perks,
#loyalty_marketing_points_sections {
  h2,
  .submitted {
    display: none;
  }
  #points_perks {
    width: 960px;
    @include swap_direction(margin, 0 auto);
    h2 {
      display: block;
      color: $color-sharp-black;
      @include swap_direction(padding, 50px 0 10px 0);
    }
    h2.market_perks {
      padding-top: 5px;
      font-size: 18px;
      font-weight: bold;
    }
    .pointsbar {
      float: #{$ldirection};
      @include swap_direction(margin, 0 0 10px 0);
      @include swap_direction(padding, 0 0 20px 0);
      #smartbar,
      #brilliantbar,
      #geniusbar {
        width: 32.3%;
        float: #{$ldirection};
        @include swap_direction(margin, 0 1% 0 0);
        .pointsbar_bg {
          height: 25px;
          background: $color-ly-dark-green;
        }
        span {
          color: $color-ly-darker-green;
          font-size: 35px;
          @include swap_direction(margin, 35px 0 20px 0);
          float: #{$ldirection};
          width: 100%;
          font-family: $helvetica-light-font-family;
        }
        .smartbar_pts,
        .brilliantbar_pts,
        .geniusbar_pts {
          @include swap_direction(padding, 0 25px 0 0);
          p {
            font-family: $helvetica-regular-font-family;
            line-height: 24px;
            font-size: 15px;
            @include swap_direction(padding, 7px 0);
            color: $color-cl-soft-black;
          }
        }
      }
      #brilliantbar {
        .pointsbar_bg {
          background: $color-ly-dark-purple;
        }
        span {
          color: $color-ly-dark-purple;
        }
      }
      #geniusbar {
        .pointsbar_bg {
          background: $color-ly-dark-orange;
        }
        span {
          color: $color-ly-darker-orange;
        }
      }
    }
    .loyalty_earn_terms {
      font-size: 12px;
      a.overlay-link {
        color: $color-black;
        text-decoration: underline;
      }
    }
    .next_level {
      .next_heading {
        font-size: 24px;
      }
      span {
        color: $color-cl-green;
        font-size: 26px;
      }
      .next_border {
        height: 1px;
        border-top: 1px solid $color-light-grey;
        margin-top: 22px;
      }
    }
  }
}

#loyalty_marketing_points_sections {
  #points_perks {
    .pointsbar {
      border: none;
    }
    h2.market_perks {
      font-weight: normal;
      font-family: $helvetica-regular-font-family;
    }
  }
}

#loyalty__panel__receipt {
  .loyalty__panel__receipt__scanning {
    height: 340px;
    @media #{$small-only} {
      height: 360px;
    }
  }
  .loyalty__panel__receipt_section {
    @media #{$small-only} {
      width: 86%;
      @include swap_direction(margin, 0 auto);
      @include swap_direction(padding, 20px 0 0 0);
    }
    .loyalty__panel__receipt__header {
      color: $color-black;
      font-size: 38px;
      @media #{$small-only} {
        font-size: 31px;
      }
    }
    .loyalty__panel__receipt__introduction {
      font-size: 22px;
      font-family: $helvetica-light-font-family;
      line-height: 2px;
      @include swap_direction(padding, 0 0 10px 0);
      p {
        @include swap_direction(padding, 10px 0 10px 0);
      }
      @media #{$small-only} {
        font-size: 15px;
      }
    }
    .loyalty__panel__receipt__clsr_msg {
      @include swap_direction(margin, 25px 0 0 10px);
      @include swap_direction(padding, 0 0 50px 0);
      font-size: 12px;
      @media #{$small-only} {
        border-bottom: none;
      }
    }
  }
  .loyalty__panel__receipt__submit {
    @include swap_direction(padding, 30px 0 30px 0px);
    @media #{$small-only} {
      @include swap_direction(padding, 0 0 30px 0);
    }
    .loyalty__panel__receipt__submit__option1,
    .loyalty__panel__receipt__submit__option2 {
      float: #{$ldirection};
      width: 33%;
      @include swap_direction(margin, 0);
      @media #{$small-only} {
        float: none;
        width: 100%;
        @include swap_direction(margin, 20px 0);
      }
    }
    .loyalty__panel__receipt__subheader {
      color: $color-black;
      font-size: 38px;
      @include swap_direction(padding, 30px 0 20px 0);
      @media #{$small-only} {
        font-size: 21px;
        @include swap_direction(padding, 0);
      }
    }
    .loyalty__panel__receipt__submit__method {
      display: none;
      @media #{$small-only} {
        display: block;
        line-height: 20px;
        span {
          font-weight: bold;
        }
      }
    }
    .loyalty__panel__receipt__submit__option1 {
      @include swap_direction(margin, 0 0 0 1%);
    }
    .loyalty__panel__receipt__submit__option3 {
      float: #{$ldirection};
      width: 50%;
      @include swap_direction(margin, 0 0 0 0);
      @media #{$small-only} {
        float: none;
        width: 100%;
        @include swap_direction(margin-#{$rdirection}, 0);
      }
    }
    .loyalty__panel__receipt__submit__method__container
      .loyalty__panel__receipt__submit__method__title {
      font-size: 23px;
      font-family: $helvetica-light-font-family;
      @include swap_direction(padding-bottom, 14px);
      @media #{$small-only} {
        display: none;
      }
    }
    .loyalty__panel__receipt__submit__method__content {
      font-size: 14px;
      @media #{$small-only} {
        display: none;
      }
    }
  }
  .loyalty__panel__receipt_content_section {
    @include swap_direction(padding, 35px 0 30px 0px);
    @include background(linear-gradient(white, $color-light-white));
    @include box-shadow(inset 0 -12px 12px -12px rgba(50, 50, 50, 0.2));
    @media #{$small-only} {
      box-shadow: none;
      background: none;
      @include swap_direction(padding, 0);
    }
    .loyalty__panel__receipt__terms__condition {
      max-width: 960px;
      @include swap_direction(margin, 0 auto);
      color: $color-cl-green;
      font-size: 12px;
      @include swap_direction(padding, 0 0 0 10px);
      a.overlay-link {
        text-decoration: underline;
        color: $color-black;
      }
      @media #{$small-only} {
        display: none;
      }
    }
  }
}

.loyalty_mrkt_ldng {
  &.is_loyalty_member {
    #loyalty_market_sticky_nav,
    .btn_sign_in,
    .btn_join,
    .loyality_sign_off {
      display: none !important;
    }
  }
  &.not_loyalty_member {
    .loyalty_signin,
    .loyality_sign_off {
      display: none !important;
    }
  }
  h2 {
    margin: 0;
    display: block;
    color: $color-black;
    @include swap_direction(padding, 50px 0 20px 0);
  }
  #loyalty_market_sticky_nav.fixed {
    top: 45px;
    width: 100%;
    border-bottom: 1px solid $color-light-grey;
    z-index: 999;
    background: $color-white;
    position: fixed;
  }
  .loyalty_market_sticky_nav {
    border-bottom: 1px solid $color-light-grey;
    @include swap_direction(padding, 30px 0 30px 0);
    overflow: hidden;
    .loyalty_join_now {
      @include swap_direction(padding, 0 0 0 100px);

      button.btn_join {
        @include elc-button--dark;
      }
    }
    .loyalty_signin {
      @include swap_direction(padding, 0 0 0 100px);
      button.btn_sign_in {
        @include elc-button--light;
      }
    }
    .loyalty_join_now,
    .loyalty_signin {
      float: #{$ldirection};
      width: 35%;
      @media #{$ipad-portrait-only} {
        width: 45%;
      }
      font-size: 17px;
      button.btn_join,
      button.btn_sign_in {
        @include swap_direction(margin, 15px 0 0 0);
        padding: 0;
        max-width: 12.5rem;
      }
    }
    .loyality_sign_off {
      clear: #{$ldirection};
      font-size: 15px;
      @media #{$cr19-medium-up} {
        @include swap_direction(padding, 20px 0 0 100px);
        font-size: 20px;
      }
    }
  }
  .loyalty_market_smart_left {
    border-bottom: 1px solid $color-light-grey;
    background: url('/media/export/cms/loyalty_market_left_bg.png') top left no-repeat;
    background-size: contain;
    @media #{$small-only} {
      overflow: hidden;
      background: url('/media/export/cms/marking_landing_left.png') top left no-repeat;
    }
    .loyalty_market_smart_right {
      background: url('/media/export/cms/loyalty_market_right_bg.png') top right no-repeat;
      min-height: 630px;
      @media #{$small-only} {
        background: url('/media/export/cms/marking_landing_right.png') top right no-repeat;
        min-height: 610px;
      }
      background-size: contain;
      .outer-wrap {
        @include swap_direction(padding, 120px 0 0 100px);
        @media #{$small-only} {
          @include swap_direction(padding, 60px 5% 20px 5%);
          overflow: hidden;
        }
        .clinique_logo {
          @include swap_direction(padding, 0 0 30px 0);
        }
        .loyalty_earn_terms {
          clear: both;
          @include swap_direction(padding, 150px 0 20px);
          a.overlay-link,
          a.colorbox-link {
            color: $color-black;
            font-weight: bold;
            font-family: $helvetica-regular-font-family;
            text-decoration: underline;
          }
          @media #{$small-only} {
            @include swap_direction(padding, 100px 0 0 0);
          }
        }
        h2 {
          color: $color-black;
          font-size: 38px;
          font-family: $helvetica-bold-font-family;
          margin: 0px;
          @include swap_direction(padding, 8px 0 0 0);
        }
        p {
          @include swap_direction(padding, 20px 0);
          @include swap_direction(margin, 0);
          line-height: 27px;
          font-size: 18px;
          &.loyalty_market_rewards {
            padding-top: 0px;
          }
        }
        .loyality_sign_off {
          font-size: 19px;
          @include swap_direction(padding, 0 0 30px 0);
        }
        .loyalty_join_button,
        .loyalty_signin {
          float: #{$ldirection};
          .btn_join {
            display: block;
            background: $color-black;
            @include swap_direction(padding, 12px 50px);
            @include swap_direction(margin, 0 35px 0 0);
            font-size: 18px;
            font-weight: normal;
            border: 0px;
            color: $color-white;
          }
          .btn_sign_in {
            display: block;
            background: $color-white;
            border: 2px solid $color-black;
            @include swap_direction(padding, 10px 50px);
            @include swap_direction(margin, 0);
            font-size: 18px;
            font-weight: bold;
          }
          @media #{$small-only} {
            width: 50%;
            button {
              margin: 0;
              font-size: 17px;
            }
            .btn_join {
              padding: 7% 18% 8%;
              @include swap_direction(margin, 0 5% 0 0);
            }
            .btn_sign_in {
              @include swap_direction(padding, 6% 22%);
            }
          }
        }
      }
    }
  }
  #loyalty_marketing_points_sections {
    border-bottom: 1px solid $color-light-grey;
    @include swap_direction(padding, 0 0 20px 0);
    .loyalty_earn_terms {
      display: none;
    }
    @media #{$small-only} {
      @include swap_direction(padding, 20px 5%);
      #points_perks {
        width: 100%;
        .market_perks {
          @include swap_direction(padding, 0 0 10px 0);
          font-size: 18px;
          span {
            font-size: 16px;
          }
        }
        .pointsbar {
          width: 100%;
          span {
            margin-top: 5px !important;
          }
          .pointsbar_bg {
            height: 15px !important;
            span {
              font-size: 26px;
            }
          }
        }
        .loyalty_marketing_points_slider {
          padding: 0px;
          .slides li {
            .points_slider_inner {
              @include swap_direction(margin, 0 10px);
              .points_slider_border {
                @include swap_direction(padding, 20px);
                @include swap_direction(margin, 0 0 20px 0);
                min-height: 340px;
              }
            }
            &.smartbar_pts {
              .points_slider_border {
                border: 1px solid $color-ly-dark-green;
                span {
                  color: $color-ly-dark-green;
                  font-size: 26px;
                }
              }
            }
            &.brilliantbar_pts {
              .points_slider_border {
                border: 1px solid $color-ly-dark-purple;
                span {
                  color: $color-ly-dark-purple;
                  font-size: 26px;
                }
              }
            }
            &.geniusbar_pts {
              .points_slider_border {
                border: 1px solid $color-ly-dark-orange;
                span {
                  color: $color-ly-dark-orange;
                  font-size: 26px;
                }
              }
            }
          }
          .flex-control-nav {
            position: relative;
            bottom: 0px;
          }
        }
      }
    }
    #points_perks {
      h2 {
        &.market_perks {
          display: block;
          span {
            font-size: 24px;
          }
        }
      }
    }
  }
  .loyalty_market_points {
    @include swap_direction(padding, 50px 0 60px 0);
    border-bottom: 1px solid $color-light-grey;
    h2 {
      @include swap_direction(padding, 0 0 0 70px);
    }
    .outer-wrap {
      @include breakpoint(767px) {
        @include swap_direction(padding, 0 5% 0 5%);
      }
      h3 {
        font-size: 18px;
        margin-top: 10px;
        color: $color-black;
        @include swap_direction(margin, 10px 0 0 70px);
        @include breakpoint(767px) {
          @include swap_direction(margin, 10px 0 0 0px);
        }
        font-family: $helvetica-regular-font-family;
        a.earning_points {
          color: $color-cl-green-2;
          @if $cr19 == true {
            color: $cr19-text-grey;
          }
        }
        @media #{$small-only} {
          @include swap_direction(margin, 10px 0 0 0);
        }
      }
    }
    .outer-wrap {
      max-width: 1100px;
    }
    table {
      width: 100%;
      font-size: 18px;
      td {
        padding: 15px 0 15px 70px;
        width: 50%;
        &.points_even {
          background-color: $color-lightest-grey-2;
        }
        p {
          font-size: 30px;
          font-family: $helvetica-light-font-family;
        }
      }
    }
    @media #{$small-only} {
      @include swap_direction(padding, 20px 0 20px 0);
      h2 {
        @include swap_direction(padding, 0);
        font-size: 32px;
      }
      table {
        font-size: 16px;
        td {
          @include swap_direction(padding, 4% 0 4% 5%);
          p {
            font-size: 21px;
          }
        }
      }
    }
  }
  .loyalty_market_questions {
    .outer-wrap {
      @include breakpoint(767px) {
        @include swap_direction(padding, 0 5% 0 5%);
        h2 {
          @include swap_direction(padding, 20px 0 10px 0);
          font-size: 32px;
        }
      }
    }
    h3 {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      display: block;
      color: $color-black;
      font-size: 19px;
      font-weight: bold;
    }
    p {
      font-size: 18px;
      font-family: $helvetica-regular-font-family;
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 3px 0 25px 0);
    }
    .qualifying_retailers_section {
      width: 30%;
      float: #{$ldirection};
      @include swap_direction(padding, 0 1% 0 0);
      @media #{$small-only} {
        width: 90%;
      }
    }
  }
}

.loyalty-marketing-page {
  .nav-promo {
    display: none;
  }
  &.toolbar {
    #loyalty_market_sticky_nav.fixed {
      top: 105px;
    }
  }
}

.loyalty_marketing_sticky_nav {
  .non_loyalty_user_sticky_nav,
  .loyalty_user_sticky_nav {
    display: flex;
    flex-direction: column;
    margin: 0 1.5em 1.5em;
    @media #{$medium-up} {
      row-gap: 1em;
      margin-bottom: 1.5em;
      margin-left: 5em;
    }
    a.button {
      width: 100%;
      margin: 12px 0 0;
      height: auto;
      font-size: 18px;
      line-height: 18px;
      padding: 12px;
      background-color: $color-black;
      &:hover {
        background-color: $color-cl-soft-black;
      }
      @media #{$medium-up} {
        padding: 15px 50px;
        margin: 0;
        max-width: max-content;
      }
    }
  }
  .loyalty_join_signin__header {
    padding: 10px 0 10px !important;
    font-size: 15px;
  }
  .loyalty_join_signin__footer {
    font-size: 20px;
  }
}

.review-panel__loyalty-points {
  @include swap_direction(padding, 15px 0 15px 0);
  @media #{$small-only} {
    @include swap_direction(padding, 15px 0 15px 10px);
  }
  border-bottom: 1px solid $color-light-grey;
  h3 {
    display: inline-block;
    @include header(
      $font-size: 13px,
      $font-family: $helvetica-regular-font-family,
      $font-weight: $font-weight-bold,
      $color: $color-cl-soft-black,
      $text-transform: none
    );
    @include swap_direction(padding, 0 0 0 10px);
    @include swap_direction(margin, 0);
    @media #{$small-only} {
      @include swap_direction(padding, 0 0 0 0);
    }
  }
  .review-panel__loyalty-points__description {
    float: #{$rdirection};
    font-weight: $font-weight-bold;
    width: 70%;
    @include swap_direction(padding, 0 20px 0 0);
    @media #{$small-only} {
      float: none;
      width: 100%;
    }
  }
}

#loyalty-status__content {
  #loyalty__panel__offers__sku {
    ul.slides {
      li.offer_not_met {
        .loyalty__panel__offers__sku__cta {
          a.loyalty__panel__product__button {
            background: $color-black;
            text-transform: $text-transform-capitalize;
            &.button--disabled {
              background: $color-btn-gray;
            }
          }
        }
      }
    }
  }
}

#navigation {
  .menu-item-blocks {
    .mm_4col_v1 {
      .col.last {
        width: 31%;
        .button {
          width: 70%;
        }
        .body {
          width: 73%;
        }
      }
    }
    .tout-image {
      .tout-info {
        .body {
          width: 44%;
        }
      }
    }
  }
}

.customer-care-general-info {
  .cs-page-section {
    text-align: justify;
    color: $color-darker-grey;
  }
}

.terms-condition-txt {
  .horizontal-rule {
    span {
      &.heading-4 {
        display: block;
        @include swap_direction(padding, 10px 0);
        font-weight: bold;
        color: $color-darkest-grey;
        @include breakpoint($ab-small-down) {
          font-size: 22px;
          @include swap_direction(padding, 0 0 30px 0);
          font-weight: normal;
        }
      }
      &.terms-heading {
        @include breakpoint($ab-small-down) {
          @include swap_direction(padding, 0 0 10px 0);
        }
      }
    }
    p {
      @include swap_direction(marging, 0);
    }
  }
}

.customer-care-loyalty {
  .qualifying_retailers_section {
    width: 30%;
    float: #{$ldirection};
    @include swap_direction(padding, 0 1% 0 0);
    @media #{$small-only} {
      width: 90%;
    }
  }
}

.account_landing_wrapper {
  #main {
    .most-recent-purchases {
      header {
        @include swap_direction(margin, 45px 0 0 0);
      }
    }
  }
}

#shipping {
  #main.multi {
    #content {
      @include swap_direction(margin, 0px 0px 20px 0);
    }
  }
}

// Live Chat Offer pop up
.live_chatinfo_popup_overlay {
  width: 505px;
  border: 1px solid $color-dark-grey;
  @include swap_direction(padding, 20px 55px 25px);
  .close-container {
    top: 20px;
    right: 20px;
  }
  .live_chatinfo_popup__title {
    @include swap_direction(margin, 40px 0 20px);
    color: $color-black;
    font-size: 36px;
    @include breakpoint($ab-small-down) {
      font-size: 33px;
    }
  }
  .live_chatinfo_popup__header,
  .live_chatinfo_popup__sub-header {
    font-size: 24px;
    color: $color-black;
    @include swap_direction(margin, 0);
    @include breakpoint($ab-small-down) {
      @include swap_direction(padding, 0 20px 0 0);
    }
  }
  .live_chatinfo_popup__buttons {
    @include swap_direction(margin, 20px 0);
    a {
      @include swap_direction(padding, 5px 20px);
      @include swap_direction(margin, 0 10px 0 0);
      @include breakpoint($ab-small-down) {
        height: inherit;
        width: 190px;
      }
    }
    @include breakpoint($ab-small-down) {
      a:first-child {
        display: block;
        @include swap_direction(margin, 0 0 10px);
        width: 220px;
      }
    }
  }
}

.section-customer-care {
  .cs-menu-mobile-v1 {
    a {
      &#qualifying_retailers {
        color: $color-cl-soft-black;
        font-size: 12px;
        font-weight: $font-weight-bold;
        &:hover {
          color: $color-cl-soft-black;
        }
      }
    }
  }
}
// potentially, we can move these out to their own file
// this works for now
.device-mobile {
  &.loyalty-marketing-page {
    #loyalty_market_sticky_nav.fixed {
      top: 0;
    }
    &.toolbar {
      #loyalty_market_sticky_nav.fixed {
        top: 0;
      }
    }
  }
}

.checkout_signin {
  #signin-block {
    .checkout_signin_loyalty {
      @include breakpoint($ab-small-down) {
        margin-top: 10px;
        .section-header {
          font-size: 14px;
          color: $color-cl-green;
          @if $cr19 == true {
            color: $cr19-text-grey;
          }
        }
        .loyalty_form_container {
          @include swap_direction(margin, 0 0 10px);
          input[type='checkbox'] {
            float: #{$ldirection};
            @include swap_direction(margin, 3px 8px 12px 0);
          }
          .loyalty_terms_condition {
            label {
              font-family: $helvetica-light-font-family;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.checkout-table {
  .loyalty_samples_deduction_points {
    @include swap_direction(margin, 20px 0 0);
    @include swap_direction(float, left);
    @include breakpoint($ab-small-down) {
      @include swap_direction(margin, 0 0 10px 0);
    }
  }
}

.account-past-purchases {
  .loyalty-points-deduction {
    @include swap_direction(margin, 20px 0 0);
    @include swap_direction(float, left);
    @include breakpoint($ab-small-down) {
      @include swap_direction(margin, 10px 0);
    }
  }
}

// Refer A Friend Page Styles
.refer-friend {
  .refer-friend__header {
    @include swap_direction(padding, 30px 0 35px 0px);
    @include background(linear-gradient(white, $color-light-white));
    @include box-shadow(inset 0 -12px 12px -12px rgba(50, 50, 50, 0.2));
    margin-bottom: 40px;
    @include breakpoint($ab-small-down) {
      @include swap_direction(padding, 0);
      box-shadow: none;
      background: none;
      margin-bottom: 0px;
    }
    .refer-friend__header--large {
      @include breakpoint($ab-small-down) {
        display: none;
      }
    }
    .refer-friend__header--small {
      display: none;
      @include breakpoint($ab-small-down) {
        display: block;
      }
    }
    .refer-friend__header--content {
      @include swap_direction(margin, 0 auto);
      max-width: 960px;
      position: relative;
    }
    .refer-friend__header--description {
      float: #{$ldirection};
      width: 50%;
      @include breakpoint($ab-small-down) {
        #{$ldirection}: 26px;
        position: absolute;
        top: 50%;
        transform: translate(0px, -50%);
        width: 58%;
      }
      .refer-friend__header--description-title {
        font-size: 36px;
        color: $color-black;
        @include breakpoint($ab-small-down) {
          @include swap_direction(margin, 0 0 15px);
          font-size: 24px;
          line-height: 25px;
        }
      }
      .refer-friend__header--description-copy {
        font-size: 18px;
        @include breakpoint($ab-small-down) {
          font-size: 12px;
          line-height: 14px;
          width: 95%;
        }
      }
    }
    .refer-friend__header--image {
      float: #{$ldirection};
      width: 50%;
      text-align: #{$rdirection};
      @include breakpoint($ab-small-down) {
        width: 100%;
        float: none;
      }
      img {
        max-width: 260px;
        @include breakpoint($ab-small-down) {
          width: 100%;
          max-width: 100%;
          border-bottom: 1px solid $color-light-grey;
        }
      }
    }
  }
  .refer-friend__points {
    margin-bottom: 20px;
    @include breakpoint($ab-small-down) {
      @include swap_direction(padding, 25px 25px 0 25px);
      margin-bottom: 14px;
    }
    .refer-friend__points--title {
      font-size: 36px;
      color: $color-black;
      margin-bottom: 23px;
      max-width: 580px;
      @include breakpoint($ab-small-down) {
        @include swap_direction(margin, 0 0 17px 0);
        font-size: 24px;
        line-height: 25px;
      }
    }
    .refer-friend__points--copy {
      font-size: 14px;
      max-width: 700px;
      @include breakpoint($ab-small-down) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .refer-friend__social-share {
    @include breakpoint($ab-small-down) {
      @include swap_direction(padding, 0 25px);
      border-bottom: 1px solid $color-light-grey;
    }
    .refer-friend__social-share--header {
      font-size: 22px;
      color: $color-black;
      margin-bottom: 20px;
      @include breakpoint($ab-small-down) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
    .refer-friend__link {
      width: 330px;
      height: 33px;
      font-size: 13px;
      font-family: $base-italic-font-family;
      padding-#{$ldirection}: 15px;
      font-weight: bold;
      margin-bottom: 15px;
      @include breakpoint($ab-small-down) {
        width: 100%;
      }
    }
    .refer-friend__social-share--form {
      margin-bottom: 20px;
      @include breakpoint($ab-small-down) {
        margin-bottom: 17px;
      }
    }
    .refer-friend__social-share--links {
      display: inline-block;
      margin-bottom: 35px;
      @include breakpoint($ab-small-down) {
        margin-bottom: 30px;
      }
    }
    .refer-friend__social-share--links-header {
      color: $color-black;
      font-size: 18px;
      float: #{$ldirection};
      margin-#{$rdirection}: 15px;
      @include breakpoint($ab-small-down) {
        float: none;
      }
    }
    .refer-friend__social-share--link {
      margin-#{$rdirection}: 10px;
      @include breakpoint($ab-small-down) {
        margin-#{$rdirection}: 15px;
      }
    }
    .refer-friend__social-share--copy-link {
      background: $color-black;
      color: $color-white;
      display: inline-block;
      font-size: 14px;
      height: 27px;
      line-height: 25px;
      text-transform: uppercase;
      text-align: center;
      width: 140px;
      &.small-link-copy,
      &.link-copy:hover {
        background: $color-white;
        border: 1px solid $color-black;
        color: $color-black;
      }
      @include breakpoint($ab-small-down) {
        font-family: $helvetica-bold-font-family;
        width: 140px;
        height: 25px;
        font-size: 11px;
        line-height: 23px;
      }
    }
  }
  .refer-friend__points-panel {
    margin-bottom: 20px;
    width: 80%;
    @include breakpoint($ab-small-down) {
      @include swap_direction(padding, 25px 25px 0 25px);
      width: 100%;
    }
    .refer-friend__points-panel--header {
      color: $color-black;
      font-size: 22px;
      margin-bottom: 30px;
      @include breakpoint($ab-small-down) {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 24px;
      }
    }
    .refer_status,
    .refer_points,
    .refer_name {
      @include swap_direction(padding, 13px 0);
      word-wrap: break-word;
      float: #{$ldirection};
      width: 33%;
      overflow: hidden;
      @include breakpoint($ab-small-down) {
        @include swap_direction(padding, 15px 0);
      }
    }
    .refer_status {
      text-align: center;
    }
    .refer_points {
      text-align: #{$rdirection};
    }
    @include breakpoint($ab-small-down) {
      .refer_status {
        width: 25%;
      }
      .refer_points {
        width: 40%;
      }
      .refer_name {
        width: 35%;
      }
    }
    ul {
      border-top: 1px solid $color-light-grey;
      display: inline-block;
      width: 100%;
      &:last-child {
        border-bottom: 1px solid $color-light-grey;
      }
      @include breakpoint($ab-small-down) {
        font-size: 10px;
      }
    }
  }
  .refer-friend__referals {
    font-size: 12px;
    font-family: $helvetica-bold-font-family;
    @include breakpoint($ab-small-down) {
      @include swap_direction(padding, 0 25px 25px);
    }
  }
}

// RAF Email Styles
.email-overlay {
  @include swap_direction(padding, 7px 20px);
  @include breakpoint($ab-small-down) {
    @include swap_direction(padding, 7px 10px);
  }
  .email-overlay__container--header {
    color: $color-black;
    font-size: 28px;
    margin-bottom: 17px;
    @include breakpoint($ab-small-down) {
      font-size: 34px;
      margin-bottom: 20px;
    }
  }
  .share-email__buttons > .share-email-loading {
    @include swap_direction(padding, 0 40px);
    @include swap_direction(margin, 0);
    bottom: 0;
    display: none;
    height: 30px;
    vertical-align: middle;
    &.active {
      display: inline-block;
    }
    @include breakpoint($ab-small-down) {
      @include swap_direction(margin, 0 auto 20px);
    }
  }
  .email-overlay__container {
    @include swap_direction(padding, 0 0 20px);
    margin-bottom: 20px;
  }
  .email-preview__container--header {
    @include swap_direction(margin, 0 -40px);
    @include swap_direction(padding, 19px 40px);
    background-image: linear-gradient(to #{$rdirection}, $color-black 70%, transparent 70%);
    background-position: #{$ldirection} top;
    background-repeat: repeat-x;
    background-size: 16px 1px;
    font-size: 18px;
    cursor: pointer;
    .header-icon {
      float: #{$rdirection};
      font-size: 21px;
    }
    .icon-angle-up {
      display: none;
    }
    &.active {
      .icon-angle-up {
        display: block;
      }
      .icon-angle-down {
        display: none;
      }
    }
  }
  .email-preview__container--header-text {
    border-bottom: 1px solid $color-light-grey;
    padding-bottom: 5px;
  }
  .email-overlay__container--copy {
    @include breakpoint($ab-small-down) {
      font-family: $helvetica-bold-font-family;
      font-size: 14px;
      letter-spacing: -0.3px;
    }
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 22px;
    width: 100%;
    max-width: 400px;
  }
  .email-overlay__container--emails {
    font-size: 16px;
    height: 36px;
    padding-#{$ldirection}: 14px;
    width: 100%;
    margin-bottom: 15px;
    @include breakpoint($ab-small-down) {
      height: 75px;
      margin-bottom: 22px;
    }
  }
  .email-preview__container {
    display: none;
    &.active {
      display: block;
      height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
      @include breakpoint($ab-small-down) {
        height: auto;
      }
    }
  }
  .share-email__buttons {
    float: #{$rdirection};
    width: 51%;
    text-align: #{$rdirection};
    @include breakpoint($ab-small-down) {
      width: 100%;
      float: none;
      text-align: center;
    }
  }
  .email-overlay__container--error {
    color: $color-notice-pink;
    margin-bottom: 10px;
  }
  .email-overlay__message--more,
  .email-overlay__message--back,
  .email-overlay__container--send,
  .email-overlay__container--preview {
    @include swap_direction(padding, 3px 0);
    background: $color-white;
    border: 2px solid $color-black;
    color: $color-black;
    display: inline-block;
    font-family: $helvetica-bold-font-family;
    text-align: center;
    width: 140px;
    @include breakpoint($ab-small-down) {
      @include swap_direction(padding, 12px 0);
      width: 100%;
    }
  }
  .email-overlay__container--preview {
    float: #{$ldirection};
  }
  .email-overlay__message--back {
    float: #{$ldirection};
    margin-#{$rdirection}: 5px;
    width: 180px;
    @include breakpoint($ab-small-down) {
      float: none;
      margin-bottom: 15px;
      margin-#{$rdirection}: 0;
      width: 100%;
    }
  }
  .email-overlay__message--more,
  .email-overlay__container--send {
    background: $color-black;
    color: $color-white;
    @include breakpoint($ab-small-down) {
      margin-#{$ldirection}: 0;
      margin-bottom: 15px;
    }
  }
  .email-overlay__message--more {
    width: 180px;
    @include breakpoint($ab-small-down) {
      width: 100%;
    }
  }
  .email-overlay__message--success {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 15px;
    .large {
      font-size: 30px;
    }
  }
}

.back-to-invite {
  float: #{$ldirection};
  color: $color-cl-green;
  font-family: $helvetica-bold-font-family;
  @if $cr19 == true {
    color: $cr19-text-grey;
  }
}

.email-preview {
  .email-preview__branding {
    @include swap_direction(margin, 0 auto);
    @include swap_direction(padding, 20px 15px);
    border: 2px solid $color-light-grey;
    position: relative;
    text-align: center;
    width: 280px;
    img {
      margin-bottom: 10px;
      max-width: 230px;
    }
  }
  .email-preview__description {
    font-size: 12px;
  }
  .email-preview__header {
    color: $color-black;
    font-size: 24px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .email-preview__sub-header {
    color: $color-black;
    font-family: $helvetica-bold-font-family;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 15px;
  }
  .email-preview__join {
    bottom: 20px;
    color: $color-black;
    #{$ldirection}: 40%;
    position: absolute;
    font-family: $helvetica-bold-font-family;
  }
}

//RAF Menu
.account-section.loyalty-page .loyalty_tab_menu {
  &.loyalty_tab_menu_raf {
    .tab_section a {
      @include swap_direction(padding, 20px 30px);
      @include breakpoint($ab-ipad-landscape) {
        @include swap_direction(padding, 20px 10px);
      }
    }
  }
}
