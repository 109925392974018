.enhanced-viewcart-page {
  .paypal-smart-button {
    column-gap: 8px;
    display: flex;
    height: 50px;
    margin-bottom: 10px;
    width: 100%;
    &.paypal-loading {
      background: $color-white url(/media/images/global/ajax-loading.gif) no-repeat center center;
      position: relative;
      text-align: center;
    }
    .paypal-payment-spinner {
      align-items: center;
      background: $color-white;
      display: flex;
      height: 100%;
      justify-content: center;
      &__icon {
        background: url(/media/images/global/ajax-loading.gif) no-repeat left center;
        display: inline-block;
        height: 31px;
        margin-#{$rdirection}: 8px;
        width: 31px;
      }
    }
  }
}
