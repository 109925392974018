.wishlist-header {
  border-bottom: 1px solid $color-light-grey;
  padding-bottom: 25px;
  margin-bottom: 10px;
  .wishlist-header-content {
    display: inline-block;
    width: 100%;
    .share-wishlist {
      float: #{$ldirection};
      height: 32px;
      a {
        border-#{$ldirection}: 1px solid $color-light-grey;
        color: $product-color-price;
        float: #{$ldirection};
        font-size: 22px;
        height: 32px;
        line-height: 32px;
        @include swap_direction(margin, 0 0 0 20px);
        @include swap_direction(padding, 0 0 0 20px);
        &.first,
        &:first-child {
          border: none;
          margin: 0;
          padding: 0;
        }
      }
      .share-button {
        background: transparent url(/media/images/css/icons-share-buttons.png) 0 0 no-repeat;
        display: inline-block;
        height: 20px;
        @include swap_direction(margin, 0 10px 0 0);
      }
      .print {
        background-position: 0 0;
        width: 26px;
      }
      .email {
        background-position: -35px 0;
        width: 25px;
      }
      .facebook {
        background-position: -68px 0;
        width: 11px;
      }
      .twitter {
        background-position: -86px 0;
        width: 19px;
      }
      .pin-it-button {
        background-position: -111px 0;
        width: 19px;
      }
    }
  }
  .add-all-to-bag {
    float: #{$rdirection};
  }
}

.account-favorites-wishlist .favorite-products-grid .favorite {
  width: 100%;
  margin: 0;
  padding: 15px 10px;
  min-height: 0px;
  height: auto;
  border-bottom: 1px solid $color-light-grey;
  .favorite-product {
    display: inline-block;
    position: relative;
    width: 100%;
    vertical-align: top;
    .my-account-sku {
      display: inline-block;
      margin: 0 1% 0 0;
      vertical-align: top;
      width: 88%;
    }
    .product-image {
      display: inline-block;
      margin: 0 5% 0 0;
      vertical-align: top;
      width: 12%;
      img {
        display: block;
        height: auto;
        width: 100%;
      }
    }
    .product-info {
      display: inline-block;
      @include swap_direction(margin, 0 20px 0 0);
      vertical-align: top;
      width: 65%;
      .product-name {
        float: #{$ldirection};
        margin: 0 5% 0 0;
        width: 50%;
        a {
          color: $color-cl-soft-black;
          font-size: 14px;
        }
      }
      .price {
        float: #{$rdirection};
        margin: 0 5% 0 0;
      }
      .reviews {
        clear: both;
      }
    }
    .shades,
    .sizes {
      display: inline-block;
      margin: 0 2% 0 0;
      vertical-align: top;
      width: 30%;
      .search-swatch-container {
        margin-bottom: 0;
        vertical-align: top;
      }
      .shade-name {
        display: inline-block;
        font-size: 11px;
        line-height: 1;
        white-space: normal;
        width: 80%;
      }
    }
    .add-to-bag {
      display: inline-block;
      margin: 0 2% 0 0;
      vertical-align: top;
    }
    .favorite-actions {
      display: inline-block;
      margin: 0;
      vertical-align: top;
      width: 10%;
      .remove,
      .add-to-list {
        font-size: 14px;
      }
      .remove {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.wishlist-header .share-wishlist > span.last {
  border: none;
  padding: 0;
  margin: 0;
}

.account-favorites-wishlist {
  .favorite-products-grid .favorite .favorite-product .favorite-actions .add-to-list,
  .edit-box {
    display: none;
  }
}
/* Mobile */

.device-mobile {
  .favorite-products {
    &.wishlist {
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
      padding-bottom: 0;
      padding-top: 10px;
    }
    header h2 {
      @include swap_direction(margin, 0 0 15px 10px);
    }
  }
  .account-favorites-wishlist .favorite-products-grid .favorite .favorite-product {
    .my-account-sku {
      width: 100%;
    }
    .product-image {
      display: inline-block;
      margin: 0;
      overflow: hidden;
      @include swap_direction(padding, 0 10px 0 0);
      vertical-align: top;
      width: 38%;
      img {
        width: 100%;
      }
    }
    .product-info {
      margin: 0;
      padding-#{$ldirection}: 10px;
      width: 60%;
      .product-name {
        float: none;
        margin: 0;
        width: auto;
      }
    }
    .shades,
    .sizes {
      margin: 5px 0;
      width: 100%;
    }
    .product-info .price {
      float: none;
      margin: 0;
    }
    .add-to-bag {
      border-radius: 0;
      float: #{$rdirection};
      margin: 10px 0;
      padding-top: 0;
      width: 55%;
    }
  }
  .wishlist-header {
    .wishlist-header-content .share-wishlist {
      float: none;
      height: auto;
      margin: 0;
      overflow: hidden;
      padding: 0 0 5px;
      width: 100%;
      .share-button {
        vertical-align: middle;
      }
      a {
        font-size: 15px;
        border: medium none;
        margin: 0;
        @include swap_direction(padding, 0 0 0 10px);
        &.first {
          display: none;
        }
      }
      .print {
        display: none;
      }
    }
    .add-all-to-bag {
      border-#{$ldirection}: 10px solid $color-white;
      border-#{$rdirection}: 10px solid $color-white;
      line-height: 36px;
    }
  }
}
