.ebc-grid__wrapper,
.ebc-grid-item__date {
  font-family: $helvetica-light-font-family;
}

.ebc-grid__wrapper {
  max-width: 1024px;
  width: 95%;
  margin: 20px auto 0 auto;
}

.ebc-grid-item {
  float: left;
  clear: none;
  position: relative;
  width: 24%;
  cursor: pointer;
  margin: 0 0.8% 0.8% 0;
  @include breakpoint(767px) {
    width: 49%;
    margin: 0 1% 1% 0;
  }
  .ebc-grid-item__thumbnail {
    width: 99%;
    height: 99%;
  }
}

.ebc-last-in-row-pc {
  margin-right: 0;
}

.ebc-last-in-row-mobile {
  @include breakpoint(767px) {
    margin-right: 0;
  }
}

.ebc-grid-item__date {
  position: absolute;
  top: 5%;
  left: 5%;
  font-size: 16px;
  font-weight: bold;
}

.ebc-grid-item__title {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 18px;
  text-align: center;
  width: 100%;
  transform: translate(0, -50%);
}

.ebc-grid-item.week_header .ebc-grid-item__title {
  color: $color-white;
  font-size: 40px;
  font-family: HelveticaNeueLTStd35Thin, Arial, sans-serif;
  text-align: left;
  margin: 20px 0 0 20px;
  top: 0;
  transform: translateY(0);
}

.ebc-grid-item__expando {
  display: none;
}

.ebc-grid-item__expando-content {
  margin: 30px;
}

.ebc-grid-item__expanded {
  float: left;
  clear: none;
  width: 98.2%;
  display: none;
  position: relative;
  border: solid $color-cl-green 1px;
  margin-bottom: 1%;
  .ebc-grid-item__close {
    cursor: pointer;
    background: url('../../cl_base/img/icon-close.png') no-repeat 0 0;
    background-size: cover;
    width: 25px;
    height: 25px;
    top: 15px;
    right: 15px;
    @media #{$medium-up} {
      top: 30px;
      right: 30px;
    }
    position: absolute;
    color: transparent;
    text-indent: -999em;
    z-index: 1000;
  }
}

.ebc-grid-item__expanded .animated_gif {
  width: 220px;
  height: 220px;
  background-size: contain;
  position: relative;
  margin: 0 auto 20px auto;
  @media #{$medium-up} {
    margin: 0;
    float: left;
    clear: none;
    width: 360px;
    height: 360px;
  }
  .ebc-grid-item__expando-ucg_question {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 10px;
    font-size: 24px;
    width: 100%;
  }
}

.ebc-grid-item__expando-text {
  text-align: center;
  position: relative;
  @media #{$medium-up} {
    padding-right: 120px;
    left: 70px;
  }
  .ebc-grid-item__expando-headline,
  .ebc-grid-item__expando-date {
    font-size: 30px;
    text-transform: uppercase;
    @media #{$medium-up} {
      font-size: 27px;
    }
  }
  .ebc-grid-item__expando-description {
    font-size: 18px;
    @media #{$medium-up} {
      font-size: 13px;
    }
  }
}

.ebc-grid-item__expando-share {
  float: left;
  clear: both;
  text-align: center;
  width: 100%;
  margin-top: 2em;
  @media #{$medium-up} {
    padding-left: 360px;
    margin-top: 0;
  }
}

.ebc-grid-item__share {
  margin-right: 35px;
}

ebc-grid-item__share:last-child {
  margin-right: 0;
}

.ebc-grid-item__expando-interstitial {
  padding: 1em 0 1em 0;
  border-top: $color-cl-green solid 1px;
  border-bottom: $color-cl-green solid 1px;
  text-align: center;
  font-size: 18px;
}

.ebc-grid-item__extendo-wrapper {
  text-align: center;
  padding: 60px 0;
  .btn-quickbuy {
    background-color: $color-white;
    color: $color-black;
    text-transform: none;
    border: $color-light-grey solid 1px;
    margin-top: 16px;
  }
  .btn-quickbuy:hover {
    background-color: $color-white;
    border-color: $color-cl-green;
    color: $color-cl-green;
  }
}

.ebc-grid-item__extendo-content {
  display: inline-block;
}

.ebc-grid-item__expando-product,
.ebc-grid-item__ucg-box {
  width: 200px;
  margin-bottom: 30px;
  @media #{$medium-up} {
    float: left;
    clear: none;
    margin: 0 10px 0 0;
  }
}

.ebc-grid-item__expando-product-img {
  background-image: url('/media/export/cms/ebc/green_bkg.gif');
  background-repeat: no-repeat;
  background-position: center;
  width: 200px;
  height: 200px;
  background-size: 65%;
}

.ebc-grid-item__expando-product-name,
.ebc-grid-item__expando-product-price {
  text-align: center;
  color: $color-darkest-grey;
}

.ebc-grid-item__expando-product-name {
  height: 3.3em;
}

.ebc-grid-item__ucg-box {
  height: 270px;
  width: 270px;
  background-image: url('/media/export/cms/ebc/ugc_placeholder.gif');
  background-size: cover;
  position: relative;
  margin: 0 0 28px 0;
  color: $color-white;
  @media #{$medium-up} {
    margin: 0 47px 0 0;
    height: 228px;
    width: 228px;
  }
  .ebc-grid-item__ucg-answer-text {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 2em;
    font-size: 16px;
    padding: 20px;
    .ebc-grid-item__ucg-user-text {
      font-weight: bold;
    }
  }
}

.ebc-grid-item__ucg-answer {
  background-image: url('/media/export/cms/ebc/ucg_answer.gif');
}

.ebc-grid-item__ucg-box:last-child {
  margin-right: 0;
}
