.article-image-flexslider {
  position: relative;
  .flex-direction-nav {
    .flex-next,
    .flex-prev {
      position: absolute;
      top: 250px;
      height: 90px;
      width: 30px;
      background-image: url(/media/export/cms/editorial_hub/nav-arrows.png);
      text-indent: -9999px;
      outline: none;
    }
  }
  .flex-viewport {
    max-height: 500px;
    .slides {
      margin: 0;
      padding: 0;
      left: -300px;
      position: relative;
      li .imageContainer {
        position: relative;
        height: 415px;
        width: auto;
        max-width: 643px;
        margin-right: 60px;
        width: 100%;
        height: 100%;
        .eh_hero_article_images_v2 {
          padding-bottom: 0;
          margin-bottom: 0;
        }
        .sliderImage {
          width: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }
      .appendClone {
        float: left;
        list-style: none;
      }
    }
  }
  .flex-direction-nav {
    padding: 0;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 850px;
    li {
      list-style: none;
      a {
        top: 40% !important;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }
    .flex-next {
      right: 50px;
      background-position: right center;
      padding: 0;
    }
    .flex-prev {
      left: 0 !important;
      background-position: left center;
      padding: 0;
    }
    .flex-disabled {
      pointer-events: none;
    }
  }
  .flex-control-nav.flex-control-paging {
    padding: 0;
    text-align: center;
    li {
      display: none;
    }
  }
}

.ie9 .article-image-flexslider .flex-direction-nav .flex-disabled {
  display: none;
}

.ie8 .article-image-flexslider .flex-viewport .slides,
.ie9 .article-image-flexslider .flex-viewport .slides {
  margin-left: 0 !important;
}
/* Device Styles */

@media only screen and (max-width: 800px) and (min-width: 768px) {
  .article-image-flexslider .flex-viewport .slides li .imageContainer .sliderImage {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .article-image-flexslider {
    .flex-viewport .slides {
      left: -130px;
      li .imageContainer {
        margin-right: 0px;
        .eh_hero_article_images_v2 {
          margin: 0;
          .sliderImage {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .flex-direction-nav {
      width: auto;
      li a {
        padding: 0 !important;
      }
      .flex-next {
        right: 0;
      }
    }
    .flex-control-nav.flex-control-paging {
      margin-top: 0;
    }
  }
}
