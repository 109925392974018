.footer-top-v1 {
  margin: 0 auto;
  max-width: 960px;
  overflow: visible;
  padding: 10px 0;
  &.footer-top-messenger {
    max-width: 100%;
    .messenger {
      float: left;
      width: 17%;
      a {
        display: block;
        line-height: 20px;
        margin-left: 5%;
        font-family: $helvetica-regular-font-family;
        color: black;
        text-decoration: underline;
        img {
          width: 21px;
          margin: 0 5px 0 0;
          vertical-align: top;
        }
      }
    }
    .tel {
      width: 13%;
    }
    .social-links {
      width: 10%;
    }
    .signup-forms {
      width: 43%;
    }
  }
}

.footer-top-v1 .tel {
  color: $color-cl-soft-black;
  display: inline-block;
  font-size: 13px;
  line-height: 21px;
  vertical-align: top;
  width: 19%;
}

.footer-top-v1 .tel a {
  @include cl-standard-link($color-cl-soft-black, 50%);
}

.footer-top-v1 .social-links {
  display: inline-block;
  vertical-align: top;
  width: 13%;
}

.footer-top-v1 .social-links a {
  float: #{$ldirection};
  @include swap_direction(margin, 0 0 0 4px);
  &.instagram {
    margin-#{$ldirection}: 6px;
  }
}

.footer-top-v1 .social-links {
  img,
  .svg-icon.twitter {
    height: 21px;
    vertical-align: middle;
    width: 21px;
  }
}

.footer-top-v1 .facebook {
  margin: 0;
}

.footer-top-v1 .facebook-like {
  float: #{$ldirection};
  @include swap_direction(margin, 0 0 0 4px);
}

.footer-top-v1 .signup-forms {
  display: inline-block;
  vertical-align: top;
  width: 67%;
}

.footer-top-v1 .newsletter-signup,
.footer-top-v1 .mobile-signup {
  display: block;
  margin: 0;
  width: auto;
}

.footer-top-v1 form {
  position: relative;
}

.footer-top-v1 label.lbl-sms-promotions p:first-child {
  margin: 0 0 10px 0;
}

.footer-top-v1 form .extra {
  background-color: #fff;
  clear: both;
  display: none;
  margin: 10px 0;
  width: 100%;
}

.footer-top-v1 form .extra .field {
  font-size: 11px;
  font-family: $helvetica-regular-font-family;
  line-height: normal;
  @include swap_direction(margin, 10px 0 0 0);
  padding: 0 13% 0 48%;
  input {
    display: inline-block;
    background: #fff;
    border: 1px solid gray;
  }
  label {
    color: gray;
    display: inline-block;
    font-size: 11px;
    font-family: $helvetica-regular-font-family;
    line-height: normal;
    width: 90%;
  }
}

.footer-top-v1 form label {
  display: inline-block;
  font-family: $helvetica-regular-font-family;
  font-size: 13px;
  line-height: 21px;
  padding: 0;
  text-transform: none;
  vertical-align: top;
  width: 47%;
}

.footer-top-v1 .form-text {
  border: 0 none;
  border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset;
  display: inline-block;
  line-height: normal;
  margin: 0 1% 0 0;
  padding: 8px 10px 6px;
  vertical-align: top;
  width: 39%;
  background: transparent;
  -webkit-appearance: none;
}

.footer-top-v1 input,
.footer-top-v1 select {
  font-size: 12px;
}

.footer-top-v1 .form-text:focus {
  -webkit-box-shadow: 0 1px 3px black inset;
  box-shadow: 0 1px 3px black inset;
}

.footer-top-v1 .form-submit {
  border: 0 none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 29px;
  letter-spacing: 1px;
  line-height: 31px;
  margin: 0;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top;
  width: 11.5%;
}

.footer-top-v1 input.form-submit,
.footer-top-v1 input.button[type='submit'] {
  background-color: $color-btn-gray;
}

.footer-top-v1 input.form-submit:hover,
.footer-top-v1 input.button[type='submit']:hover {
  background-color: $color-btn-hover;
}

.footer-top-v1 .form-messages .progress {
  font-weight: bold;
  margin: 0 0 0 48%;
}

.footer-top-v1 .form-messages .email-error,
.footer-top-v1 .form-messages .error {
  line-height: normal;
  margin: 0 0 5px 48%;
  min-height: 25px;
  color: $color-notice-pink;
}

.footer-top-v1 form.success {
  color: $color-cl-green-2;
  font-size: 22px;
  margin: 0 0 0 48%;
}

.footer-top-v1 form.error {
  input[type='text'] {
    border: 1px solid $color-red;
  }
}

.footer-top-v1 input.error {
  color: $color-notice-pink;
}
/* IE Specific Styles */
.ie7 .footer-top-v1 .form-text,
.ie8 .footer-top-v1 .form-text {
  border: 1px solid $color-btn-gray;
}

.ie7 .footer-top-v1 .form-text:focus,
.ie8 .footer-top-v1 .form-text:focus {
  border: 1px solid $color-black;
}
