.panel {
  @include clearfix;
}

.prod-shelf-row {
  // background: transparent url($template_base_path + '/three-step_row_v1/bg-three-step-row.png') center 170px no-repeat;
  @include clearfix;
  .info {
    float: #{$ldirection};
    ul {
      @include swap_direction(margin, 1em 0 0 0);
      padding: 0;
      li {
        font-size: 1.1em;
        font-family: $helvetica-light-font-family;
        em {
          font-style: normal;
          color: #aaaaaa;
        }
      }
    }
  }
  .products {
    float: #{$ldirection};
  }
  &.three-products {
    .products {
      width: 60%;
    }
    .info {
      width: 40%;
    }
    .product {
      width: 33%;
    }
  }
  &.four-products {
    .products {
      width: 75%;
    }
    .info {
      width: 25%;
    }
    .product {
      width: 25%;
    }
  }
  &.five-products {
    .product {
      width: 20%;
    }
  }
  .product {
    float: #{$ldirection};
    min-height: 390px;
    padding: 0 3% 0 0;
    .button {
      @include swap_direction(margin, 15px 0 0 0);
    }
  }
}
