//font
$gt_sectra: $wink-font-family-3;

//colors
$color_robins_egg_blue_approx: #00d0c1;
$color_log_cabin_approx: #222222;
$gray_24: rgba(128, 128, 128, 0.24);
$gray_34: rgba(128, 128, 128, 0.34);

$gray_c: #cccccc;
$gray_2: #222222;

//@extend-elements//original selectors//#index-page-wrapper .indexed-loading, #index-page-wrapper .indexed-loadmore
%index-common-style-1 {
  float: #{$ldirection};
  margin-bottom: 80px;
  margin-top: 50px;
  text-align: center;
  width: 100%;
}

%index-common-style-2 {
  height: 228px;
  width: 23.25%;
}
/* page overall width */
#index-page {
  max-width: 1024px;
}
/* index page wrapper container */
#index-page-wrapper {
  //Instead of the line below you could use @includeborder-radius($radius,$vertical-radius)   border-radius: 5px;
  float: #{$ldirection};
  height: auto;
  margin-bottom: 30px;
  margin-top: 0;
  width: 100%;
  font-family: $wink-font-family-3;
  #page-headline {
    color: $color_log_cabin_approx;
    float: #{$ldirection};
    font-size: 46px;
    text-align: center;
    width: 100%;
    font-family: $wink-font-family-1;
  }
  #select-index-filter {
    display: none;
  }
  #index-filter {
    color: $gray_24;
    float: #{$ldirection};
    font-size: 14px;
    margin: 30px 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    a {
      color: $gray_c;
      letter-spacing: 16px;
      text-decoration: none;
      font-family: $wink-font-family-1;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        letter-spacing: 12px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        letter-spacing: 12px;
      }
      &.active {
        color: $gray_2;
      }
    }
  }
  .indexpage-details {
    display: none;
  }
  .index-page-term .index-page-term-title {
    @extend %index-common-style-2;
    color: $color_robins_egg_blue_approx;
    display: table-cell;
    font-size: 80px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .term-page {
    float: #{$ldirection};
    padding: 0 20px;
    width: 100%;
    .anchor {
      display: block;
      height: 40px;
      margin-top: -40px;
      visibility: hidden;
      float: #{$ldirection};
      width: 10px;
    }
    .index-page-grid {
      @extend %index-common-style-2;
      float: #{$ldirection};
      @include swap_direction(margin, 0 2.33% 20px 0);
      .indexpage-content .indexpage-image {
        text-align: center;
        position: relative;
        a.term-slide {
          span {
            font-family: inherit;
            display: block;
            position: relative;
          }
        }
        p {
          position: absolute;
          margin: auto;
          top: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          bottom: 0;
          font-size: 26px;
          vertical-align: middle;
          display: table;
          padding: 15px;
          color: #222222;
          width: 100%;
          cursor: pointer;
          text-transform: capitalize;
          font-family: $wink-font-family-1;
        }
      }
      &.index-term-4 {
        margin-#{$rdirection}: 0;
      }
    }
  }
  .animated_gif {
    height: 100%;
    width: 100%;
  }
  .term-slide > .animated_gif {
    position: absolute;
    #{$ldirection}: 0;
    top: 0;
  }
  .indexed-loading {
    @extend %index-common-style-1;
  }
  .indexed-loadmore {
    @extend %index-common-style-1;
    button {
      background-color: $color-white;
      border: 1px solid $gray_34;
    }
  }
  .indexpage-color {
    a {
      @extend %index-common-style-2;
      color: $color_log_cabin_approx;
      display: table-cell;
      font-size: 26px;
      line-height: 30px;
      padding: 15px;
      text-align: center;
      text-decoration: none;
      text-transform: capitalize;
      vertical-align: middle;
      font-family: $wink-font-family-1;
    }
  }
  .index-page-selcted-article {
    border: 1px solid $color_white_ice_approx;
    display: none;
    float: #{$ldirection};
    height: 100%;
    @include swap_direction(margin, 0 0 20px);
    position: relative;
    width: 100%;
  }
}

.indexpage-content.index_active {
  .indexpage-image {
    background-color: $color-white !important;
    border: 1px solid $color_robins_egg_blue_approx;
  }
  .indexpage-color {
    background-color: $color-white !important;
    border: 1px solid $color_robins_egg_blue_approx;
  }
}

.indexpage-term-share-icons a {
  padding: 0 5px;
}

.indexpage-featured-article .eh-flip-on-hover-v1 {
  margin: 0 50px;
  padding: 0;
  width: auto;
  .title {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
  .front_content {
    font-size: 14px;
    margin: 10px auto;
    padding: 0;
    text-align: center;
    width: 50%;
  }
  img.flipimage {
    text-align: center;
  }
}

#index-page-wrapper .indexpage-featured-product .eh-product .product {
  color: $color_log_cabin_approx;
  font-size: 14px;
  .product-title a {
    height: auto;
    font-family: $wink-font-family-1;
  }
  .product-price {
    font-family: $gt_sectra !important;
  }
}
/* Slidedown design */
#index-page-wrapper {
  .index-page-selcted-article {
    clear: both;
    .indexpage-contents {
      padding: 10px;
      float: #{$ldirection};
      width: 100%;
    }
    &.device-mobile {
      padding: 0;
      height: 100%;
      width: 100%;
      &.overlay-container {
        height: 100% !important;
      }
      &.setMaxHeight {
        height: 100%;
      }
      .indexpage-details-image {
        float: none;
        width: auto;
        margin-#{$ldirection}: 0;
        margin-#{$rdirection}: 0;
        .indexpage-image {
          text-align: center;
          img {
            width: auto;
          }
        }
      }
      .indexpage-details-content {
        width: auto;
        margin-#{$ldirection}: 0;
      }
      .indexpage-term-title {
        font-size: 25px;
        line-height: 23px;
        text-transform: capitalize;
      }
      .indexpage-term-description {
        font-size: 15px;
        line-height: 15px;
      }
      .eh-product .product .product-image a {
        height: 160px !important;
        padding-bottom: 0 !important;
        width: 160px !important;
      }
      .indexpage-featured-description.feature-row-2:after {
        border: none;
      }
      .indexpage-featured-description .indexpage-featured-content1 {
        width: 100%;
      }
      .indexpage-featured-description {
        .indexpage-featured-content2 {
          float: none;
          width: 100%;
        }
        .indexpage-featured-article {
          width: 100%;
        }
      }
      .indexpage-contents {
        .indexpage-details-content-container {
          width: 100%;
        }
        .indexpage-close {
          display: none;
        }
      }
    }
    .indexpage-close {
      color: $color-black;
      position: absolute;
      top: 0;
      #{$rdirection}: 0;
      text-decoration: none;
      img {
        height: 18px;
        margin-#{$rdirection}: 10px;
        margin-top: 10px;
        width: 17px;
      }
    }
    .indexpage-details-image {
      float: #{$ldirection};
      height: auto;
      @include swap_direction(margin, 15px 30px 15px 50px);
      width: 360px;
      img {
        width: 100%;
      }
    }
    .indexpage-details-content {
      color: $color_log_cabin_approx;
      float: #{$ldirection};
      font-size: 20px;
      height: auto;
      @include swap_direction(margin, 40px 0 45px 30px);
      width: 420px;
      &.indexpage-details-noimage {
        width: 100%;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
    }
    .indexpage-details-content-container {
      margin: 0 auto;
      width: 420px;
    }
    .indexpage-term-title {
      color: $gray_2;
      font-size: 46px;
      text-align: center;
      text-transform: capitalize;
      line-height: 46px;
      font-family: $wink-font-family-1;
    }
    .indexpage-term-description {
      color: $gray_2;
      font-size: 22px;
      font-family: $gt_sectra !important;
      line-height: 30px;
      margin: 40px 0;
      text-align: center;
      p {
        line-height: inherit;
      }
    }
    .indexpage-related-term {
      font-size: 22px;
      font-family: $gt_sectra;
      text-align: center;
    }
    .indexpage-term-share-icons {
      margin-top: 40px;
      text-align: center;
      width: 100%;
    }
    .indexpage-term-link a {
      color: $gray_2;
      margin-#{$rdirection}: 5px;
      padding: 2px;
      text-decoration: underline;
    }
  }
  .indexpage-featured-title {
    border-bottom: 1px solid $color_white_ice_approx;
    border-top: 1px solid $color_white_ice_approx;
    color: $gray_2;
    float: #{$ldirection};
    height: 56px;
    text-align: center;
    width: 100%;
    p {
      color: $gray_2;
      float: #{$ldirection};
      font-size: 18px;
      line-height: 54px;
      margin: 0;
      width: 100%;
      font-family: $wink-font-family-1;
    }
  }
  .indexpage-featured-description {
    float: #{$ldirection};
    font-size: 14px;
    height: auto;
    position: relative;
    width: 100%;
    .indexpage-featured-content1 {
      height: auto;
      @include swap_direction(margin, 40px auto 45px);
      width: 50%;
      .product-info {
        float: none;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
    }
    .indexpage-featured-content2 {
      float: #{$ldirection};
      height: auto;
      @include swap_direction(margin, 40px 0 45px);
      width: 50%;
    }
    &.feature-row-2:after {
      border-#{$ldirection}: 1px solid $color_white_ice_approx;
      bottom: 0;
      content: '';
      #{$ldirection}: 50%;
      position: absolute;
      top: 0;
      z-index: -1;
    }
    .indexpage-featured-product.indexpage-featured-content2 {
      float: #{$rdirection};
    }
  }
}

@media screen and (max-width: 767px) {
  body {
    &.device-mobile {
      .indexpage-contents .indexpage-close {
        display: none;
      }
    }
    #foreground-node {
      width: 100%;
      overflow: auto;
      .close-container {
        position: fixed;
      }
      &.mobile-sign-in-overlay {
        .close-container {
          position: absolute;
        }
      }
      .indexpage-featured-title {
        border-bottom: 1px solid $color_white_ice_approx;
        border-top: 1px solid $color_white_ice_approx;
        color: $gray_2;
        height: 40px;
        text-align: center;
        width: 100%;
        p {
          color: $gray_2;
          font-size: 18px;
          line-height: 40px;
          margin: 0;
          width: 100%;
          font-family: $wink-font-family-1;
        }
      }
      .indexpage-featured-description {
        margin-bottom: 30px;
        .indexpage-featured-article {
          margin-top: 25px;
        }
        .indexpage-featured-product {
          margin-top: 30px;
          .product-image {
            width: 181px;
            margin-#{$ldirection}: auto;
            margin-#{$rdirection}: auto;
          }
        }
      }
      .indexpage-contents {
        margin-top: 30px;
        margin-bottom: 30px;
        .indexpage-image {
          text-align: center;
          .animated_gif {
            width: 141px;
          }
        }
        .indexpage-details-content {
          width: 275px;
          margin: auto;
          .indexpage-details-content-container {
            @include swap_direction(margin, 25px auto 0);
            .indexpage-term-title {
              font-family: $wink-font-family-1;
              font-size: 25px;
              text-align: center;
            }
            .indexpage-term-description {
              text-align: center;
              margin-top: 27px;
              p {
                margin: 0;
              }
            }
            .indexpage-related-term {
              text-align: center;
              margin-top: 20px;
              .indexpage-term-link {
                margin-bottom: 50px;
                a {
                  color: $gray_2;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
  #index-page-wrapper {
    .term-page {
      .index-page-grid {
        @include swap_direction(margin, 0 4% 4% 0);
        &.index-term-2,
        &.index-term-4 {
          margin-#{$rdirection}: 0;
        }
      }
    }
    .eh-social-share {
      .social_share_links {
        height: auto;
      }
    }
    #index-filter {
      display: none;
    }
    #select-index-filter {
      display: block;
      margin: 0 auto;
      width: 180px;
      select {
        margin: 20px auto;
        text-transform: uppercase;
        text-indent: 44%;
        height: 40px;
        width: 180px;
      }
    }
    .hub-index-page-v1 {
      height: inherit;
      overflow: hidden;
      .indexpage-content {
        height: inherit;
        .indexpage-image {
          text-align: center;
          position: relative;
          p {
            position: absolute;
            margin: auto;
            top: 0;
            #{$ldirection}: 0;
            #{$rdirection}: 0;
            bottom: 0;
            font-size: 26px;
            vertical-align: middle;
            display: table;
            padding: 15px;
            color: #222222;
            width: 100%;
            cursor: pointer;
            text-transform: capitalize;
          }
        }
        .indexpage-color {
          height: 100%;
          width: 100%;
          display: table;
          a {
            height: auto;
            font-size: 25px;
            line-height: 28px;
          }
        }
      }
    }
    .term-page .index-page-grid,
    .indexpage-color a {
      width: 48%;
    }
  }
}
/* Max-width 980px */
@media screen and (min-width: 768px) and (max-width: 980px) {
  #index-page-wrapper {
    #page-headline {
      font-size: 70px;
    }
    .term-page {
      padding: 0;
    }
    .index-page-term-title {
      color: #00d7cc;
      font-size: 90px;
    }
    .index-page-term .index-page-term-title,
    .term-page .index-page-grid,
    .indexpage-color a,
    .animated_gif {
      font-size: 22px;
      height: 169px;
    }
    .index-page-selcted-article {
      margin: 10px auto;
      .indexpage-contents {
        padding: 30px;
      }
      .indexpage-details-content {
        margin: 0 auto;
        padding: 30px 0;
        float: none;
        width: 550px;
      }
      .indexpage-details-content-container {
        width: 100%;
      }
      .indexpage-details-image {
        margin: 0 auto;
        padding: 30px 0;
        float: none;
      }
    }
  }
}
/* Max-width 767px */
@media screen and (min-width: 561px) and (max-width: 767px) {
  #index-page-wrapper {
    #page-headline {
      font-size: 35px;
    }
    .index-page-term-title {
      color: #00d7cc;
      font-size: 90px;
    }
    .index-page-term .index-page-term-title,
    .term-page .index-page-grid {
      height: 240px;
    }
  }
}
/* Max-width 560px */
@media screen and (min-width: 461px) and (max-width: 560px) {
  %index-common-560-style {
    height: 170px;
    font-size: 20px;
    line-height: 25px;
  }
  #index-page-wrapper {
    .index-page-term .index-page-term-title,
    .term-page .index-page-grid {
      @extend %index-common-560-style;
    }
  }
}
/* Max-width 460px */
@media screen and (max-width: 460px) {
  %index-common-460-style {
    height: 120px;
    font-size: 15px;
    line-height: 20px;
  }
  #index-page-wrapper {
    .index-page-term .index-page-term-title,
    .term-page .index-page-grid {
      @extend %index-common-460-style;
    }
    .indexpage-color a {
      padding: 5px;
    }
    .term-page {
      .index-page-grid {
        @include swap_direction(margin, 0 4% 4% 0);
      }
    }
    .hub-index-page-v1 {
      .indexpage-content {
        .indexpage-color {
          a {
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
/* weibo and line style */
.indexpage-weibo img {
  width: 28px;
}
//Important used here as "Line" social icon sets its iframe width
//and height to 0 when it's loaded dynamically or via popup
.indexpage-term-share-icons {
  iframe.line-it-button {
    width: 28px !important;
    height: 28px !important;
  }
}
