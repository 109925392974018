.multi {
  #loyalty-enrollment {
    border-top: 1px solid $color-shade-grey;
    margin-top: 30px;
    background: url('/media/export/cms/Clinique_Loyalty_NA/order-confirmation.png') right 30px
      no-repeat;
    @media screen and (max-width: 768px) {
      background: none;
      a {
        color: $color-notice-pink;
      }
    }
  }
  .h_loyalty {
    color: $color-notice-pink;
    font-size: 25px;
    font-weight: $font-weight-bold;
    width: 54%;
    @include swap_direction(margin, 20px 0);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .bold {
    font-weight: $font-weight-bold;
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-weight: $font-weight-normal;
    }
  }
  .loyalty_list {
    font-size: 18px;
    font-family: $helvetica-light-font-family;
    color: $color-black;
    width: 55%;
    @include swap_direction(padding, 15px 0 25px 0px);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .error_messages {
      width: 55%;
      @include swap_direction(padding, 15px 0 25px 0px);
    }
  }
  #learn-more {
    display: none;
  }
  .js-loyalty-join-now-button {
    &--disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
      pointer-events: none;
    }
  }
  .submit_btn {
    .form-submit {
      display: inline-block;
      background: $color-dark-pink;
      font-weight: $font-weight-bold;
      font-size: 13px;
      @include swap_direction(padding, 10px 34px);
      &:hover {
        background: $color-dark-pink;
      }
    }
    p {
      margin-top: 15px;
      a {
        color: $color-black;
        font-size: 14px;
      }
    }
  }
  .loyalty-promo {
    margin-top: 60px;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
    font-size: 12px;
    h4 {
      display: none;
    }
  }
  .guest-checkout {
    #return-user {
      border-top: 1px solid $color-shade-grey;
      margin-top: 30px;
      background: url('/media/export/cms/Clinique_Loyalty_NA/order-confirmation.png') right 80px
        no-repeat;
      @media screen and (max-width: 768px) {
        background: none;
      }
      .why {
        font-size: 23px;
        font-weight: $font-weight-bold;
        width: 77%;
        @include swap_direction(margin, 20px 0);
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        h3 {
          color: $color-notice-pink;
        }
      }
      .guest_checkout_wrapper {
        h3 {
          font-weight: $font-weight-bold;
          font-size: 16px;
          @media screen and (max-width: 768px) {
            font-weight: $font-weight-normal;
          }
        }
        .loyalty_list {
          font-size: 16px;
          font-family: $helvetica-light-font-family;
          color: $color-black;
          width: 57%;
          @include swap_direction(padding, 15px 0 25px 15px);
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
        .guest_email_text {
          font-weight: $font-weight-bold;
        }
        .email-address {
          label {
            color: $color-darker-grey;
          }
        }
        .guest_pass1_wrapper {
          .guest_pass1_label {
            font-weight: $font-weight-bold;
          }
        }
        .show {
          label {
            font-weight: $font-weight-bold;
          }
        }
      }
      .accept-loyalty-entollemnt {
        color: $color-darker-grey;
        margin-bottom: 10px;
      }
      .green_bg {
        display: inline-block;
        background: $color-dark-pink;
        font-weight: $font-weight-bold;
        font-size: 13px;
        @include swap_direction(padding, 10px 34px);
        margin-top: 30px;
      }
      .loyalty-terms-and-conditions {
        margin-top: 20px;
        font-size: 12px;
      }
    }
  }
}

#confirm {
  #main {
    @media screen and (max-width: 768px) {
      #loyalty-enrollment {
        .h_loyalty {
          font-size: 23px;
          font-weight: $font-weight-normal;
        }
        .submit_btn {
          .form-submit {
            background: $color-black;
            font-size: 18px;
            margin-top: 20px;
            margin-left: 0;
          }
          p {
            color: $color-notice-pink;
          }
        }
      }
      #return-user {
        .gc_create_acc_title {
          color: $color-notice-pink;
          margin-bottom: 20px;
        }
        .guest_create_an_acc_title {
          font-weight: $font-weight-normal;
        }
        #completed_checkout_registration {
          margin-top: 15px;
          .gc_email_addr {
            margin-top: 10px;
            color: $color-darker-grey;
          }
          #password_conf {
            & + fieldset {
              margin: 0;
            }
            margin: 0;
            .password-field {
              @include swap_direction(margin, 8px 5px 10px 0);
              input[type='password'] {
                margin: 0;
                width: 100%;
              }
            }
          }
          .form-submit {
            @include swap_direction(margin, 20px 15px 10px 15px);
            width: 90%;
          }
          .accept-loyalty-entollemnt {
            color: $color-darker-grey;
            text-align: #{$ldirection};
            @include swap_direction(padding, 10px 15px);
          }
          .show {
            label {
              color: $color-darker-grey;
            }
            #show-passwd {
              @include swap_direction(margin, 0px 3px 0px 0px);
            }
          }
        }
        .loyalty-terms-and-conditions {
          font-size: 12px;
          margin-bottom: 20px;
          @include swap_direction(padding, 5px 15px);
        }
        .required_mark {
          color: $color-black;
        }
      }
    }
  }
  .accept-loyalty-enrollemnt {
    &.logged-in.loyalty-user {
      width: 100%;
    }
    @media #{$medium-up} {
      padding: 15px 0 25px;
      width: 55%;
    }
    label {
      font-family: inherit;
    }
  }
}
