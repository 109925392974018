.terms-tooltip {
  #{$ldirection}: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  width: 100%;
  &__info-icon {
    #{$rdirection}: 90px;
    border-radius: 50%;
    color: $color-white;
    cursor: pointer;
    height: 15px;
    line-height: 1.5;
    position: absolute;
    text-align: center;
    top: 0;
    width: 15px;
    &::before {
      @include svg-icon-inline-mask('information.svg');
      content: '';
      #{$rdirection}: 0;
      display: inline-block;
      height: 15px;
      position: absolute;
      width: 15px;
      background: $color-black;
    }
  }
  &__text {
    #{$rdirection}: 80px;
    background-color: $color-white;
    border: 1px solid $color-cl-grey-dark;
    border-radius: 0;
    box-sizing: border-box;
    color: $color-cl-grey-darker;
    display: none;
    font-family: $base-font-family-regular;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 1.4;
    padding: 10px;
    position: absolute;
    text-align: center;
    top: 24px;
    width: 100%;
    z-index: 9999;
  }
  &:hover {
    .terms-tooltip__text {
      display: block;
    }
  }
  &__up-arrow-icon {
    #{$rdirection}: 9px;
    bottom: 100%;
    height: 12px;
    overflow: hidden;
    position: absolute;
    width: 15px;
    &::after {
      #{$ldirection}: 50%;
      background-color: $color-white;
      border: 1px solid $color-cl-grey-dark;
      content: '';
      height: 12px;
      position: absolute;
      transform: translate(-50%, 50%) rotate(45deg);
      width: 12px;
    }
  }
}
