#booking-step3 {
  .book-appt-container {
    .mobile-phone-fieldset {
      float: right;
      padding: 0;
      border: 0;
      .appt-book-mobile-prefix {
        width: 30%;
        display: inline-block;
      }
      .appt-book-mobile {
        width: 70%;
      }
    }
  }
}
