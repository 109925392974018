.eh_article_prev_social_share {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  p:first-child {
    padding-left: 0px;
  }
  .social_icons {
    float: left;
    padding-left: 10px;
  }
}
