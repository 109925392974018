.product-tout {
  .product {
    color: gray;
    font-size: 13px;
    font-family: $helvetica-regular-font-family;
    line-height: 1.2;
    .product-image {
      text-align: center;
      &.align-left {
        float: none;
      }
      img {
        display: block;
        height: auto;
        max-width: 100%;
        width: auto;
      }
    }
    .product-info {
      overflow: hidden;
    }
    .product-title,
    .product-subline,
    .product-subheading {
      color: $product-color-title;
      a {
        color: $color-cl-soft-black;
        font-weight: 300;
        font-size: 13px;
        font-family: $helvetica-regular-font-family;
        line-height: 1.2;
      }
    }
    .product-price {
    }
    .reviews {
      color: $color-cl-green;
      font-size: 11px;
      overflow: hidden;
      padding: 7px 0;
      img {
        display: inline;
        float: #{$ldirection};
        height: auto;
        width: 55px;
      }
      span {
        display: inline;
        margin-#{$ldirection}: 5px;
      }
    }
    .button {
      @include swap_direction(margin, 10px 0 0 0);
      a {
        background: #5eb997;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        line-height: 24px;
        @include swap_direction(padding, 7px 10px 4px 10px);
        text-transform: uppercase;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
}

.topnav-pane {
  .product-image {
    float: #{$ldirection};
    width: 50%;
  }
}
