.module-may-we-suggest {
  @include cl-related-content-item;
  /* border-bottom: 1px solid #DBDBDB; */
  /* padding: 55px 0 25px; */

  // also need to account for its placement within the .spp-extra section,
  // outside of the related content items container, in the SPP markup
  .spp-extra & {
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 40px;
    .wrap {
      @include outer-wrap;
    }
  }
  h2 {
    color: $color-cl-green-2;
    margin: 0 0 15px;
  }
  .grid {
    margin-bottom: 0;
    ul.product-grid {
      font-size: 0.1px;
      margin-#{$rdirection}: -24px;
      position: relative;
      text-align: #{$ldirection};
    }
    ul.product-grid:before,
    ul.product-grid:after {
      content: ' ';
      display: table;
    }
    ul.product-grid:after {
      clear: both;
    }
    ul.product-grid.view-small {
      margin-#{$rdirection}: -12px;
      padding: 0;
    }
    ul.product-grid li.box {
      display: inline-block;
      font-size: 13px;
      margin-bottom: 50px;
      position: relative;
      text-align: #{$ldirection};
      vertical-align: top;
      &.w_19 {
        width: 19%;
      }
      &.w_24 {
        width: 24%;
      }
      &.w_32 {
        width: 32%;
      }
      .badge {
        position: absolute;
        top: 30px;
        #{$ldirection}: 45px;
        display: table;
        width: 80px;
        height: 80px;
        text-align: center;
        @include border-radius(50%);
        color: #fff;
        z-index: 1;
        padding: 0;
        line-height: 16px;
        vertical-align: middle;
        margin: 0;
        font-size: 14px;
        font-family: $helvetica-light-font-family;
        text-transform: uppercase;
        text-align: center;
        &.badge_1,
        &.badge_2,
        &.badge_3,
        &.badge_4,
        &.badge_5,
        &.badge_17,
        &.badge_92,
        &.badge_94,
        &.badge_96,
        &.badge_97 {
          background: #fe6cb5;
        }
        /* for longer badge names */
        &.badge_96 {
          font-size: 12px;
        }
        &.badge_30 {
          background: $color-cl-green-2;
        }
        html.ie7 & {
          text-align: center;
        }
        .badge-inner {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          html.ie7 & {
            position: relative;
            margin-top: inherit;
            *clear: expression(
              style.marginTop = '' +
                (
                  offsetHeight < parentNode.offsetHeight ?
                    parseInt((parentNode.offsetHeight - offsetHeight) / 2) + 'px': '0'
                ),
              style.clear = 'none',
              0
            );
          }
        }
      }
    }
    ul.product-grid li.box .reviews {
      color: #000000;
      font-size: 12px;
      padding: 3px 50px 0;
    }
    ul.product-grid li.box .reviews img {
      vertical-align: middle;
    }
    ul.product-grid li.box .tags {
      font-size: 13px;
      @include swap_direction(padding, 12px 20px 0 50px);
    }
    ul.product-grid li.box .tags span {
      color: #000000;
      display: block;
      margin: 0 0 1px;
    }
    ul.product-grid li.box .tags span em {
      color: #808080;
      font-style: normal;
    }
    ul.product-grid li.box .options {
      padding: 4px 50px 20px;
    }
    ul.product-grid li.box .options .btn-primary {
      color: #ffffff !important;
    }
    ul.product-grid li.box .product {
      font-size: 16px;
      font-family: $helvetica-light-font-family;
      position: relative;
    }
    ul.product-grid li.box .product a {
      color: #808080;
    }
    ul.product-grid li.box .product a .product-image {
      display: inline-block;
      margin: 0 auto;
      width: auto;
    }
    ul.product-grid li.box .product a .tag {
      border-radius: 50%;
      color: #ffffff;
      display: block;
      font-size: 14px;
      font-family: $helvetica-light-font-family;
      font-weight: bold;
      height: 80px;
      #{$ldirection}: 45px;
      line-height: 16px;
      margin: 0;
      padding: 24px 0 0;
      position: absolute;
      text-align: center;
      text-rendering: optimizelegibility;
      text-transform: uppercase;
      top: 70px;
      vertical-align: middle;
      width: 80px;
      z-index: 1;
    }
    ul.product-grid li.box .product a .tag.new {
      background: none repeat scroll 0 0 #fe6cb5;
      padding-top: 34px;
    }
    ul.product-grid li.box .product a .tag.top-seller {
      background: none repeat scroll 0 0 $color-cl-green-2;
    }
    ul.product-grid li.box .product a h3 {
      color: #1f1f1f;
      font-size: 13px;
      font-family: $helvetica-regular-font-family;
      line-height: 21px;
      padding: 0 50px;
      text-decoration: none;
      transition: color 0.2s ease 0s;
    }
    ul.product-grid li.box .product a span {
      display: block;
      margin: 0 0 1px;
      padding: 0 50px;
    }
    ul.product-grid li.box .product a:hover h3 {
      color: $color-cl-green-2;
    }
    ul.product-grid.view-large li.box {
      margin-#{$rdirection}: 25px;
      min-height: 420px;
      width: 303px;
    }
    ul.product-grid.view-small li.box {
      margin: 10px 0 0;
    }
    ul.product-grid.view-small li.box .reviews {
      color: #000000;
      padding: 3px 0 0;
    }
    ul.product-grid.view-small li.box .product {
      font-size: 13px;
      font-family: $helvetica-regular-font-family;
    }
    ul.product-grid.view-small li.box .product a h3 {
      line-height: 18px;
      margin-top: -5px;
      padding: 0;
      width: 95%;
    }
    ul.product-grid.view-small li.box .product a span {
      margin-bottom: 2px;
      padding: 0;
      /* width: 95%; */
    }
    ul.product-grid.view-small li.box .product a .tag {
      font-size: 11px;
      font-family: $helvetica-regular-font-family;
      height: 60px;
      #{$ldirection}: 18px;
      line-height: 11px;
      padding: 19px 0 0;
      top: 35px;
      width: 60px;
    }
    ul.product-grid.view-small li.box .product a .tag.new {
      padding-top: 26px;
    }
    ul.product-grid.view-small li.box .tags {
      padding-#{$ldirection}: 0;
    }
    ul.product-grid.view-small li.box .options {
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
    }
  }
}

@media screen and (max-width: 960px) {
  /* .module-may-we-suggest .grid ul.product-grid.view-small li.box.end_items { display: none; } */
}

@media screen and (max-width: 768px) {
  .module-may-we-suggest h2 {
    margin-bottom: 10px;
  }
  .module-may-we-suggest .grid ul.product-grid.view-small li.box.end_items {
    display: none;
  }
  .module-may-we-suggest .grid ul.product-grid li.box.w_19 {
    width: 32%;
  }
  .module-may-we-suggest .grid ul.product-grid li.box.w_24 {
    width: 32%;
  }
}

@media screen and (max-width: 640px) {
  .module-may-we-suggest h2 {
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 5px;
  }
}

@media screen and (max-width: 640px) {
  .module-may-we-suggest .grid ul.product-grid {
    margin-#{$rdirection}: -10px;
  }
}

@media screen and (max-width: 640px) {
  .module-may-we-suggest .grid ul.product-grid.view-small {
    margin-#{$rdirection}: -10px;
  }
}
