.sbHolder {
  background-color: #f1f4f5;
  border: solid 1px #dddcdc;
  font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
  font-size: 14px;
  font-weight: normal;
  height: 25px;
  position: relative;
  width: 200px;
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: -webkit-linear-gradient(left, #cccccc, #808080);
    border: 1px solid #808080;
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  }
}

.sbHolder:focus .sbSelector {
}

.sbSelector {
  display: block;
  height: 23px;
  line-height: 23px;
  outline: none;
  overflow: hidden;
  text-indent: 10px;
  @include swap_direction(margin, 0 0 0 24px);
  background-color: #fff;
  font-size: 11px;
  outline: none;
}

.sbSelector:link,
.sbSelector:visited,
.sbSelector:hover,
.sbSelector:focus {
  color: #000;
  text-decoration: none;
  outline: none;
}

.sbToggle {
  background: url('/media/export/images/mobile_sku_arrow_dn.png') 8px 8px no-repeat;
  display: block;
  height: 23px;
  outline: none;
  position: absolute;
  #{$rdirection}: 0;
  top: 0;
  opacity: 0.25;
  width: 23px;
}
/* .sbToggle:hover{
	background: url('/media/export/images/mobile_sku_arrow_up.png') 10px 10px no-repeat;
} */
.sbToggleOpen {
  background: url('/media/export/images/mobile_sku_arrow_up.png') 8px 8px no-repeat;
}
/* .sbToggleOpen:hover{
	background: url('/media/export/images/mobile_sku_arrow_up.png') 10px 10px no-repeat;
} */
.sbHolderDisabled {
  background-color: #3c3c3c;
  border: solid 1px #515151;
}

.sbHolderDisabled .sbHolder {
}

.sbHolderDisabled .sbToggle {
}

.sbOptions {
  background-color: #fff;
  border: solid 1px #dddcdc;
  border-bottom: none;
  list-style: none;
  #{$ldirection}: -1px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 23px;
  width: 101%;
  z-index: 1;
  overflow-y: auto;
  max-height: 250px;
}

.sbOptions li {
  border-bottom: 1px solid lightgray;
  @include swap_direction(padding, 0 0 0 24px);
}

.sbOptions a {
  /* border-bottom: dotted 1px #515151; */
  display: block;
  font-size: 11px;
  outline: none;
  @include swap_direction(padding, 4px 10px 4px 10px);
}

.sbOptions a:link,
.sbOptions a:visited {
  color: #808080;
  text-decoration: none;
  display: block;
  min-height: 23px;
  background-color: #fff;
}

.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus {
  color: #000;
  background-color: #eee;
}

.sbOptions li.last a {
  border-bottom: none;
}

.sbOptions .sbDisabled {
  border-bottom: dotted 1px #515151;
  color: #999;
  display: block;
  @include swap_direction(padding, 3px 10px 3px 10px);
}

.sbOptions .sbGroup {
  border-bottom: dotted 1px #515151;
  color: #ebb52d;
  display: block;
  font-weight: bold;
  @include swap_direction(padding, 3px 10px 3px 10px);
}

.sbOptions .sbSub {
  padding-#{$ldirection}: 17px;
}
