.ise_container {
  max-width: 1024px;
  margin: 0 auto;
  font-size: 15px;
  padding-top: 3em;
  div {
    margin-bottom: 0.5em;
  }
  h2 {
    font-size: 2em;
    margin: 0;
    line-height: 100%;
  }
  h3 {
    font-size: 1.5em;
    margin: 0;
    line-height: 100%;
  }
  p {
    margin: 0;
  }
  .ise_indent {
    padding-left: 2em;
  }
  .ise_title_sep {
    margin-bottom: 1.5em;
  }
  .ise_section {
    width: 50%;
    padding-right: 3em;
    .mobile_hidden {
      display: inline-block;
    }
    .pc_hidden {
      display: none;
    }
    @media (max-width: 600px) {
      float: none;
      width: 100%;
      .mobile_hidden {
        display: none;
      }
      .pc_hidden {
        display: inline-block;
      }
    }
  }
  .align__image_left {
    float: right;
  }
  .align__image_right {
    float: left;
  }
  .ise_store_logo {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 0.5em;
  }
  .ise_border_bottom {
    border-bottom: 1px solid #e4e4e4;
  }
  .ise_detail {
    color: #5dba98;
    .ise_location {
      padding-bottom: 0.5em;
    }
    .ise_date {
      padding-bottom: 0.5em;
    }
    .ise_location_icon {
      background: url(/media/export/cms/events/icon_locator.png) no-repeat;
    }
    .ise_date_icon {
      background: url(/media/export/cms/events/icon_calendar.png) no-repeat;
    }
  }
  .ise_image {
    text-align: center;
    max-width: 100%;
  }
  .ise_cta {
    padding-top: 1em;
    text-transform: uppercase;
    font-family: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif;
    &.mobile_hidden {
      a {
        padding: 0.5em 1em 0.5em;
        border: 1px solid rgb(51, 51, 51);
        line-height: 1.5;
        font-size: 0.9em;
        letter-spacing: 0px;
        color: rgb(0, 0, 0);
      }
    }
    &.pc_hidden {
      margin-top: 1.5em;
      width: 100%;
      padding-top: 2em;
      padding: 0.5em 1em 0.5em;
      background-color: #5dba98;
      text-align: center;
      line-height: 2;
      font-size: 1.2em;
      letter-spacing: 0px;
      a {
        color: white;
      }
    }
  }
}

.bt_pt_content {
  z-index: 1;
  cursor: pointer;
  margin-bottom: 1em;
  &.mob_clicked {
    .flipper {
      &.mob_hide {
        width: 0px;
        margin: 0;
        .flip_front {
          .bt_show_more {
            width: 0px;
          }
          .bt_pt_prod_image {
            width: 0px;
            max-width: 0px;
            display: none;
          }
        }
      }
      &.mob_expand {
        .flip_front {
          .bt_show_more {
            width: 0px;
          }
          .bt_pt_prod_image {
            width: 0px;
            max-width: 0px;
            display: none;
          }
        }
      }
    }
  }
  .flipper {
    float: left;
    width: 14.6em;
    height: 14.6em;
    margin-right: 1em;
    position: relative;
    -webkit-transition: all 500ms cubic-bezier(0.45, 0.75, 0.27, 0.905);
    -moz-transition: all 500ms cubic-bezier(0.45, 0.75, 0.27, 0.905);
    -o-transition: all 500ms cubic-bezier(0.45, 0.75, 0.27, 0.905);
    transition: all 500ms cubic-bezier(0.45, 0.75, 0.27, 0.905);
    &.touch {
      max-width: 14.6em;
      width: 48%;
      max-height: 14.6em;
      margin: 1%;
      &.mob_expand {
        max-width: 29.2em;
        width: 98%;
        .flip_back {
          &.touch {
            display: inline-block;
            max-width: 29.2em;
            width: 100%;
            &.prod_2 {
              .bt_pt_prod_image {
                float: right;
              }
              .bt_pt_prod_detail {
                left: 0%;
              }
              .bt_pt_bg_filter {
                width: 98%;
              }
            }
            .bt_pt_prod_image {
              width: 50%;
            }
            .bt_pt_bg_filter {
              width: 98%;
            }
            .bt_pt_prod_detail {
              left: 40%;
            }
          }
        }
        .flip_front {
          &.touch {
            max-width: 48%;
          }
        }
      }
    }
    .bt_pt_prod {
      text-align: center;
    }
    .bt_pt_prod_image {
      max-width: 100%;
      padding: 0.5em;
      img {
        max-height: 13.6em;
        max-width: 13.6em;
      }
    }
    .flip_front {
      position: static;
      top: 0;
      max-width: 14.6em;
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
      &.not_touch {
        z-index: 1;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: perspective(1000) rotateY(0deg);
        -ms-transform: perspective(1000) rotateY(0deg);
        transform: perspective(1000) rotateY(0deg);
      }
      .bt_show_more {
        position: absolute;
        background: url('/media/export/cms/events/corner.jpg') no-repeat;
        width: 24px;
        height: 25px;
        top: 0px;
        right: 0px;
      }
    }
    .flip_back {
      position: absolute;
      top: 0;
      max-width: 14.6em;
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
      &.not_touch {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: perspective(1000) rotateY(180deg);
        -ms-transform: perspective(1000) rotateY(180deg);
        transform: perspective(7001000px) rotateY(180deg);
      }
      &.touch {
        display: none;
      }
      .bt_pt_bg_filter {
        height: 14em;
        width: 14em; /* h/w + top+left = overall width (14.6 currently) */
        position: absolute;
        top: 0.3em;
        left: 0.3em;
        background-color: white;
        opacity: 0.7;
        filter: alpha(opacity=70);
      }
      .bt_pt_prod_detail {
        color: black;
        /* z-index: inherit; */
        position: absolute;
        top: 0em;
        padding: 1em;
        text-align: left;
        .bt_pt_prod_name {
          font-weight: bold;
          font-size: 1.1em;
        }
        .bt_pt_prod_des {
          font-size: 0.9em;
          padding-top: 0.5em;
        }
      }
    }
    .flip {
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
    }
    .flip_front.fliped {
      -webkit-transform: perspective(1000) rotateY(-179.9deg);
      -ms-transform: perspective(1000) rotateY(-179.9deg);
      transform: perspective(1000) rotateY(-179.9deg);
    }
    .flip_back.fliped {
      -webkit-transform: perspective(1000) rotateY(0deg);
      -ms-transform: perspective(1000) rotateY(0deg);
      transform: perspective(1000) rotateY(0deg);
    }
  }
}

.bt_event_banner {
  padding-top: 1em;
  margin-bottom: 3em;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  .ise_main_event_content {
    width: 70%;
    padding: 0;
    text-align: left;
    padding-left: 1.2em;
    padding-right: 0.9em;
  }
  .ise_main_image_content {
    width: 30%;
    padding: 0;
    .ise_image {
      text-align: right;
    }
  }
  .ise_indent {
    padding: 0;
  }
}

.eh-carousel-v1 {
  &.crash-carousel {
    &.bt_event_video {
      background-color: #eeeef0;
      margin: 0 auto 1.92em;
      h3 {
        border: 0;
        font-size: 3em;
        font-weight: normal;
        padding-top: 1.1em;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
}
/** Bonus time gift page **/

#bonus_time_gift_uk {
  max-width: 960px;
  margin: 0 auto;
  overflow: hidden;
  .details_container {
    float: left;
    width: 54%;
    padding: 30px;
    @media (max-width: 950px) {
      float: none;
      width: 100%;
      padding: 10px;
    }
    h1 {
      margin: 0;
    }
    h2 {
      color: #4f4f4f;
      font-size: 23px;
      line-height: 23px;
      margin: 0;
    }
    p {
      font-family: 'HelveticaNeueLTStd45Light', Arial, Sans-Serif;
      font-size: 15px;
    }
    .gift_image {
      padding: 20px 0;
      max-width: 100%;
    }
    .store_logo {
      display: block;
      margin-left: 2.2em;
      padding-bottom: 0.5em;
      padding-top: 1.92em;
      float: left;
      @media (max-width: 650px) {
        display: block;
        margin: 0 auto;
        padding: 20px 0;
        float: none;
      }
    }
    .gift_description {
      text-align: left;
    }
    .bt_cta {
      display: block;
      text-align: right;
      padding: 5.1em 1.92em 0 0;
      float: right;
      a {
        text-transform: uppercase;
        font-family: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif;
        padding: 0.4em 1em 0.4em;
        border: 1px solid #333333;
        line-height: 1.5;
        font-size: 0.9em;
        letter-spacing: 0px;
        color: black;
        display: inline-block;
        min-width: 190px;
      }
      @media (max-width: 650px) {
        margin-top: 1.5em;
        width: 100%;
        padding-top: 2em;
        padding: 0.5em 1em 0.5em;
        background-color: #5dba98;
        text-align: center;
        line-height: 2;
        font-size: 1.2em;
        letter-spacing: 0px;
        text-align: center;
        a {
          color: white;
          padding: 0;
          border: 0;
        }
      }
    }
    .tandcs p {
      font-size: 9px;
      text-align: center;
    }
  }
  .products_container {
    float: left;
    width: 46%;
    padding: 30px 30px 30px 0px;
    @media (max-width: 950px) {
      padding: 10px;
      clear: both;
      width: 35.5em;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

/** End Bonus time gift page **/

/** Offer Promo Banner **/

#offer_promo_banner_uk {
  border-top: 1px solid #e3e4e5;
  border-bottom: 1px solid #e3e4e5;
  max-width: 960px;
  margin: 0 auto 30px auto;
  overflow: visible;
  padding: 0 30px;
  .offer_product {
    float: left;
  }
  .offer_detail {
    padding: 20px 0;
    .offer_title {
      font-family: 'HelveticaNeueLTStd45Light', Arial, Sans-Serif;
      font-size: 28px;
      text-rendering: optimizelegibility;
      -webkit-font-smoothing: antialiased;
      line-height: 28px;
      margin: 0 0 15px 0;
      display: block;
    }
    .offer_surtitle {
      display: block;
      font-size: 18px;
    }
    p {
      font-family: 'HelveticaNeueLTStd45Light', Arial, Sans-Serif;
      font-size: 15px;
    }
    .tandcs p {
      font-size: 9px;
    }
  }
}

/** End Offer Promo Banner **/
