.flexslider {
  background: transparent;
  border: none;
  -webkit-border-radius: none;
  -moz-border-radius: none;
  -o-border-radius: none;
  border-radius: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 5%;
}

.flex-viewport {
  margin: 0 auto;
  max-height: 627px;
  max-width: 960px;
  width: 100%;
  .slides img {
    display: block;
    height: auto;
    max-height: 546px;
    max-width: 100%;
    width: 100%;
  }
}

.flex-control-paging {
  li {
    cursor: pointer;
    display: inline-block;
    height: 13px;
    margin: 0 5px;
    position: relative;
    width: 14px;
    a {
      background-color: rgba(0, 0, 0, 0);
      -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
      -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
      -o-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
      border-radius: 50%;
      cursor: pointer;
      height: 100%;
      #{$ldirection}: 0;
      position: absolute;
      text-indent: -9999px;
      top: 0;
      transition: -webkit-box-shadow 0.3s ease 0s;
      transition: -moz-box-shadow 0.3s ease 0s;
      transition: -o-box-shadow 0.3s ease 0s;
      transition: box-shadow 0.3s ease 0s;
      width: 100%;
      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0);
        -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4) inset;
        -moz-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4) inset;
        -o-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4) inset;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4) inset;
      }
      &.flex-active {
        background-color: rgba(0, 0, 0, 0);
        -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
        -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
        -o-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.5) inset;
      }
    }
  }
}

.flexslider-vertical {
  .flex-control-paging {
    bottom: auto;
    #{$rdirection}: -32px;
    top: 40%;
    width: auto;
    li {
      display: block;
      margin: 5px 0;
    }
  }
}

.flex-direction-nav {
  a {
    background-color: rgba(0, 0, 0, 0);
    background-image: url('/media/export/cms/nav-arrows.png');
    background-repeat: no-repeat;
    height: 55px;
    margin: -27px 0 0 0;
    opacity: 0.3;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    width: 27px;
    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }
  .flex-prev {
    background-position: #{$ldirection} 10px;
    #{$ldirection}: 10px;
  }
  .flex-next {
    background-position: #{$rdirection} 10px;
    #{$rdirection}: 10px;
    text-align: #{$ldirection} !important;
  }
}

.flexslider-vertical,
.flexslider-vertical:hover {
  .flex-direction-nav {
    a {
      background-color: rgba(0, 0, 0, 0);
      background-image: url('/media/export/cms/nav-arrows-vertical.png');
      background-repeat: no-repeat;
      height: 27px;
      #{$ldirection}: 50%;
      margin: 0 0 0 -14px;
      opacity: 0.3;
      position: absolute;
      text-indent: -9999px;
      top: auto;
      -webkit-transition: none;
      -moz-transition: none;
      transition: none;
      width: 55px;
      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }
    .flex-prev {
      background-position: 0 top;
      top: -50px;
      &:hover {
        #{$ldirection}: 50%;
      }
    }
    .flex-next {
      background-position: 0 bottom;
      bottom: 5px;
      #{$rdirection}: auto;
      text-align: #{$ldirection};
      &:hover {
        #{$ldirection}: 50%;
      }
    }
  }
}
