#product-page-livechat-hitarea {
  height: 25px;
  overflow: hidden;
  width: 114px;
  z-index: 99;
  position: fixed;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
}

#product-page-livechat-hitarea .product-page-livechat-hitarea-mask {
  position: relative;
  top: -1px;
  left: 0px;
  color: red;
  width: 350px;
}
