.page-wrapper--offer__kit {
  margin: 0;
}

.offer__kit__multi_sku_formatter {
  //@include swap_direction(margin, 0 auto 40px);
  @include swap_direction(margin, 0 auto 0);
  //max-width: 1366px;
  max-width: 1076px;
  min-width: 320px;
  overflow: hidden;
  //padding-bottom: 5px;
}

.offer__kit__container {
  max-width: none;
  margin: 0 auto;
  //margin: 0;
}
/* steps */

.offer__kit__multi_sku_step {
  margin: 0;
  &.offer__kit__step--alt {
    margin: 0;
    .offer__kit__step__content,
    .offer__kit__image__container {
      margin: 0;
    }
  }
}

.offer__kit__option,
.offer__kit__image__container,
.offer__kit__image__small__wrapper {
  position: relative;
}

.offer__kit__multi_sku_formatter {
  .offer__kit__container {
    .offer__kit__multi_sku_step {
      background-image: url(/media/export/cms/GWP/cyber2017/step_1_bg.png);
      background-size: cover;
      overflow: hidden;
      padding: 50px 0;
      background-position: bottom center;
      &.disable-select-buttons {
        .offer__kit__option__container {
          .offer__kit__option {
            .offer__kit__option__select,
            .offer__kit__option__submit {
              background-color: #ccc;
              cursor: not-allowed;
            }
          }
        }
      }
      &.offer__kit__step--alt {
        .offer__kit__step__content,
        .offer__kit__image__container {
          margin: 0;
        }
      }
      .offer__kit__step__content {
        position: relative;
      }
      .offer__kit__image__container {
        position: relative;
        margin: 0;
        .no-longer-available-txt {
          display: block;
          font-size: 20px;
          text-align: center;
          position: relative;
          &.hidden {
            display: none;
          }
        }
        .offer__kit__image--01__container,
        .offer__kit__image--05__container,
        .offer__kit__image--02__container,
        .offer__kit__image--06__container,
        .offer__kit__image--03__container,
        .offer__kit__image--07__container,
        .offer__kit__image--04__container,
        .offer__kit__image--08__container {
          position: relative;
        }
        .offer__kit__image--01__container,
        .offer__kit__image--05__container,
        .offer__kit__image--02__container,
        .offer__kit__image--06__container,
        .offer__kit__image--03__container,
        .offer__kit__image--07__container,
        .offer__kit__image--04__container,
        .offer__kit__image--08__container {
          .no-longer-available-txt {
            text-align: center;
          }
        }
      }
      .offer__kit__image--01,
      .offer__kit__image--02,
      .offer__kit__image--03,
      .offer__kit__image--04,
      .offer__kit__image--05,
      .offer__kit__image--06,
      .offer__kit__image--07,
      .offer__kit__image--08 {
        display: block;
      }
      .offer__kit__image__small--01,
      .offer__kit__image__small--02 {
        display: inline;
      }
      // CX-5551 - hide checkbox and enable submit button
      .offer__kit__option__container {
        .offer__kit__option {
          @include swap_direction(margin, 20px 0 0 0);
          text-align: #{$ldirection}; // left align content
          margin: 10px;
          width: 23.1%;
          font-size: 22px;
          label {
            cursor: default;
            font-family: $helvetica-light-font-family;
            margin-#{$ldirection}: 0;
            & strong {
              font-weight: bold;
            }
          }
          input[type='submit'] {
            background-color: $color-white;
            border: 1px solid $color-black;
            color: $color-black;
            display: block;
            font-family: $helvetica-bold-font-family;
            font-size: 14px;
            @include swap_direction(margin, 10px 0 0 100px); // center align CTA
            min-width: 110px; // center align CTA
            padding: 5px 15px;
            text-transform: capitalize;
            width: auto; // center align CTA
            display: inline-block;
            height: 37px;
          }
          .offer__kit__items {
            display: inline-block;
            margin-#{$ldirection}: 10px;
            position: relative;
            top: 10px;
          }
          input[type='checkbox'] {
            display: none;
          }
          .offer__kit__option__select,
          .offer__kit__option__submit {
            &.disabled {
              background-color: $color-light-grey !important;
              border: 1px solid $color-light-grey !important;
              cursor: not-allowed;
            }
          }
          .regular-checkbox + label.styled-checkbox {
            border: none;
            display: none;
            &:checked + label::after {
              border: none;
              content: '';
            }
          }
          // New PC imgs position
          .offer__kit__image {
            cursor: pointer;
            height: auto;
            margin-bottom: 30px;
            margin-#{$rdirection}: 0;
            max-width: none;
            //width: 24.4%;
            width: 25%;
            &.offer__kit__image--01,
            &.offer__kit__image--02,
            &.offer__kit__image--03,
            &.offer__kit__image--04,
            &.offer__kit__image--05,
            &.offer__kit__image--06,
            &.offer__kit__image--07,
            &.offer__kit__image--08 {
              float: none;
              height: auto;
              margin: 0;
              padding: 0;
              width: 100%;
            }
          }
        }
      }
      &.two-gwps {
        .offer__kit__option--01,
        .offer__kit__option--02 {
          width: 47.7%;
        }
        .offer__kit__step__content {
          background: $color-white;
          overflow: hidden;
          margin: 0 auto;
          padding-bottom: 20px;
          width: 916px;
        }
      }
      &.three-gwps {
        .offer__kit__image--01,
        .offer__kit__image--02,
        .offer__kit__image--03,
        .offer__kit__option--01,
        .offer__kit__option--02,
        .offer__kit__option--03 {
          width: 33.3%;
        }
      }
      &.two-gwps {
        label.for-styled-checkbox {
          font-size: 18px;
          line-height: 18px;
          p {
            margin: 0px;
          }
        }
      }
      .offer__kit__details__close {
        font-size: 15px;
        color: $color-darker-grey;
        position: absolute;
        #{$rdirection}: 15px;
        top: 10px;
        cursor: pointer;
      }
      .offer__kit__details__link__mobile {
        position: absolute;
        top: 4.5%;
        #{$ldirection}: 3.5%;
        width: 8%;
        img {
          width: 100%;
        }
      }
    }
  }
}
/* Mobile small images */

.device-mobile {
  .offer__kit__multi_sku_formatter .offer__kit__container {
    .offer__kit__multi_sku_step {
      padding: 65px 0 50px;
    }
  }
  .offer__kit__container {
    .offer__kit__image__small {
      width: 100%;
    }
    .offer__kit__image__small__container {
      float: none;
      margin: 15px 0 0;
      position: relative;
      width: 100%;
      .no-longer-available-txt {
        font-size: 20px;
        position: relative;
        text-align: center;
      }
      .offer__kit__details__text {
        overflow-y: scroll;
        height: 100%;
      }
    }
    .offer__kit__option {
      @include swap_direction(margin, 20px 0 0 0);
      text-align: center;
      width: auto;
      label {
        font-family: $helvetica-light-font-family;
        font-size: 100%;
      }
      input[type='submit'] {
        background-color: $color-white;
        border: 1px solid $color-black;
        color: $color-black;
        font-family: $helvetica-bold-font-family;
        font-size: 17px;
        @include swap_direction(margin, -3px auto 10px);
        padding: 8px;
        text-transform: capitalize;
        width: 100%;
        letter-spacing: 0.5px;
      }
      .offer__kit__option__submit {
        &.disabled {
          background-color: $color-light-grey !important;
          border: 1px solid $color-light-grey !important;
          cursor: not-allowed;
          display: none;
        }
      }
      input[type='checkbox'] {
        display: none;
      }
      .regular-checkbox + label.styled-checkbox {
        border: none;
        display: none;
        &:checked + label::after {
          border: none;
          content: '';
        }
      }
    }
    .offer__kit__image__small--01,
    .offer__kit__image__small--02,
    .offer__kit__image__small--03,
    .offer__kit__image__small--04,
    .offer__kit__image__small--05,
    .offer__kit__image__small--06,
    .offer__kit__image__small--07,
    .offer__kit__image__small--08 {
      display: inline;
      margin-#{$ldirection}: 0;
    }
  }
  .offer__kit__details__overlay {
    #{$ldirection}: 10%;
    #{$rdirection}: 10%;
    width: 180%;
    height: 120%;
    margin-#{$ldirection}: 0;
    &__odd {
      margin-#{$ldirection}: -100%;
    }
    .offer__kit__details__text {
      padding: 10px;
      h3 {
        line-height: 2;
      }
    }
  }
}

.offer__kit__option__container {
  .offer__kit__option {
    float: #{$ldirection};
    font-family: $base-thin-font-family;
    @include swap_direction(margin, 1% 0 0 14%);
    //width: 36%;
    width: 50%;
    label,
    input[type='checkbox'] {
      margin: 0;
    }
    &.offer__kit__option--01 {
      margin-#{$ldirection}: 0;
    }
    &.offer__kit__option--02 {
      margin-#{$ldirection}: 0;
    }
    .prod-subheading {
      color: $color-sharp-black;
      font-size: 23px;
      line-height: 23px;
      overflow-y: auto;
      height: 150px;
    }
    .prod-name {
      color: $color-sharp-black;
      font-size: 21px;
      line-height: 25px;
      margin-#{$ldirection}: 45px;
      margin-top: 10px;
    }
  }
}
/* Hide checkboxes on mobile */

.device-mobile {
  .offer__kit__option__container {
    display: none;
    .offer__kit__option {
      @include swap_direction(margin, 20px 0 0 0);
      text-align: center;
      width: 50%;
      label,
      input[type='checkbox'],
      input[type='submit'] {
        font-size: 20px;
      }
      input[type='submit'] {
        margin: 0;
      }
      input[type='checkbox'] {
        display: none;
      }
      .regular-checkbox + label.styled-checkbox {
        border: none;
        display: none;
        &:checked + label::after {
          border: none;
          content: '';
        }
      }
    }
  }
}
/* option 2 */

.offer__kit__container .offer__kit__option__image__alt {
  margin: 0;
  .offer__kit__image--01,
  .offer__kit__image--02,
  .offer__kit__image__small--01,
  .offer__kit__image__small--02 {
    display: block;
  }
}

.offer__kit__header__container {
  .offer__kit__number,
  .offer__kit__header {
    float: none;
    position: relative;
    font-family: $base-thin-font-family;
    text-align: center;
  }
  .offer__kit__number {
    font-size: 80px;
  }
  .offer__kit__header {
    font-size: 45px;
    margin-bottom: 30px;
    p {
      font-size: 20px;
      margin: 7px 0 0;
    }
  }
}
/* Mobile header */

.device-mobile {
  .offer__kit__header__container {
    .offer__kit__number {
      font-size: 43px;
    }
    .offer__kit__header {
      font-size: 150%;
    }
  }
}

.offer__kit__cta {
  margin: 0;
  &.gwp2-offer-step-area {
    overflow: hidden;
    .offer__kit__header__container {
      //display: none;
    }
    .offer__kit__button__add {
      //color: $color-light-white;
      display: block;
      cursor: pointer;
      max-width: 12%;
      text-align: center;
      font-family: $helvetica-bold-font-family;
      font-size: 15px;
      @include swap_direction(margin, 0px auto 50px);
      min-width: 150px; // center align CTA
      padding: 5px 15px;
      text-transform: uppercase;
      width: auto; // center align CTA
      font-weight: normal;
      &.disabled {
        background-color: $color-light-grey !important;
        border: 1px solid $color-light-grey !important;
        cursor: not-allowed;
      }
    }
    &.offer__kit__is__loading {
      .offer__kit__loading {
        &.offer-1,
        &.offer-2,
        &.offer-3,
        &.offer-4,
        &.offer-5,
        &.offer-6,
        &.offer-7,
        &.offer-8 {
          display: block;
        }
        //&.offer-1 {
        //float: #{$ldirection};
        //margin-#{$ldirection}: 10%;
        //}
        //&.offer-2 {
        //float: #{$rdirection};
        //margin-#{$rdirection}: 34%;
        //}
      }
      .offer__kit__button__add,
      .offer__kit__button__oos {
        background-color: $color-light-grey;
        color: $color-light-white;
        cursor: not-allowed;
      }
      .offer__kit__button__oos {
        &.offer-1,
        &.offer-2 {
          display: block;
        }
        &.offer-1 {
          float: #{$ldirection};
          margin-#{$ldirection}: 10%;
        }
        &.offer-2 {
          float: #{$rdirection};
          margin-#{$rdirection}: 34%;
        }
      }
    }
    &.offer__kit__loaded {
      .offer__kit__header__container {
        display: block;
      }
      &.offer__kit__is__loading {
        .offer__kit__loading {
          &.offer-1,
          &.offer-2,
          &.offer-3,
          &.offer-4,
          &.offer-5,
          &.offer-6,
          &.offer-7,
          &.offer-8 {
            display: block;
          }
        }
        //.offer__kit__header__container {
        //display: none;
        //}
        .offer__kit__button__add {
          cursor: not-allowed;
          //&.offer-1, &.offer-2 {
          //display: none;
          //}
        }
      }
      .offer__kit__loading {
        display: none;
        //&.offer-1, &.offer-2 {
        //display: none;
        //}
      }
      .offer__kit__button__add {
        background-color: $color-black;
        color: $color-white;
        //cursor: pointer;
        display: inline-block;
        //&.offer-1 {
        //display: inline-block;
        //float: #{$ldirection};
        //margin-#{$ldirection}: 10%;
        //}
        //&.offer-2 {
        //display: inline-block;
        //float: #{$rdirection};
        //margin-#{$rdirection}: 25%;
        //}
      }
    }
  }
  .offer__kit__button__add,
  .offer__kit__button__oos {
    //background-color: $color-light-grey;
    color: $color-light-white;
    //cursor: not-allowed;
    display: none;
    font-weight: bold;
    @include swap_direction(margin, 40px 0 0);
    padding: 10px 20px;
    text-transform: uppercase;
    width: auto;
  }
  .offer__kit__button__oos {
    background-color: $color-light-grey;
    color: $color-light-white;
    cursor: not-allowed;
    &.offer-1,
    &.offer-2 {
      display: none;
    }
    &.offer-1 {
      float: #{$ldirection};
      margin-#{$ldirection}: 10%;
    }
    &.offer-2 {
      float: #{$rdirection};
      margin-#{$rdirection}: 34%;
    }
  }
  &.offer__kit__is__loading {
    .offer__kit__loading {
      &.offer-1,
      &.offer-2 {
        display: block;
      }
      &.offer-1 {
        float: #{$ldirection};
        margin-#{$ldirection}: 10%;
      }
      &.offer-2 {
        float: #{$rdirection};
        margin-#{$rdirection}: 34%;
      }
    }
    .offer__kit__button__add,
    .offer__kit__button__oos {
      background-color: $color-light-grey;
      color: $color-light-white;
      cursor: not-allowed;
    }
    .offer__kit__button__oos {
      &.offer-1,
      &.offer-2 {
        display: block;
      }
      &.offer-1 {
        float: #{$ldirection};
        margin-#{$ldirection}: 10%;
      }
      &.offer-2 {
        float: #{$rdirection};
        margin-#{$rdirection}: 34%;
      }
    }
  }
  &.offer__kit__loaded {
    .offer__kit__header__container {
      display: block;
    }
    &.offer__kit__is__loading {
      .offer__kit__loading {
        &.offer-1,
        &.offer-2 {
          display: block;
        }
      }
      .offer__kit__header__container {
        display: none;
      }
      .offer__kit__button__add {
        &.offer-1,
        &.offer-2 {
          display: none;
        }
      }
    }
    .offer__kit__loading {
      &.offer-1,
      &.offer-2 {
        display: none;
      }
    }
    .offer__kit__button__add {
      background-color: $color-black;
      color: $color-white;
      cursor: pointer;
      &.offer-1 {
        display: inline-block;
        float: #{$ldirection};
        margin-#{$ldirection}: 10%;
      }
      &.offer-2 {
        display: inline-block;
        float: #{$rdirection};
        margin-#{$rdirection}: 25%;
      }
    }
  }
}

.offer__kit__button__oos {
  width: auto;
}

.offer__kit__loading {
  background: url('/media/export/cms/479-01 Clinique_LoadingGif_R22.gif') no-repeat 0 0 $color-white;
  background-size: 35px;
  cursor: pointer;
  display: none;
  float: #{$ldirection};
  font-size: 12px;
  font-weight: bold;
  height: 35px;
  letter-spacing: 0.15em;
  line-height: 45px;
  margin-#{$ldirection}: 10%;
  margin-top: 40px;
  text-transform: uppercase;
  width: 35px;
  //.offer__kit__is__loading {
  //display: block;
  //}
}

.offer__kit__oos {
  display: none;
  font-size: 12px;
  text-transform: uppercase;
  color: red;
  .offer__kit__is__sold__out {
    display: block;
  }
}

.offer__kit__button__oos,
.offer__kit__products__data {
  display: none;
}
/* OOS mobile */

.device-mobile .offer__kit__container {
  position: relative;
  .offer__kit__cta {
    &.gwp2-offer-step-area {
      .offer__kit__button__add {
        max-width: 90%;
      }
    }
    .offer__kit__button__oos {
      margin: 0;
    }
    &.offer__kit__is__loading {
      .offer__kit__loading {
        &.offer-1,
        &.offer-2 {
          display: block;
        }
        &.offer-1 {
          float: #{$ldirection};
          margin-#{$ldirection}: 10%;
        }
        &.offer-2 {
          float: #{$rdirection};
          margin-#{$rdirection}: 34%;
        }
      }
      .offer__kit__button__oos {
        background-color: $color-white;
        color: $color-light-grey;
        cursor: not-allowed;
        position: absolute;
        top: 160px;
      }
      .offer__kit__button__oos {
        &.offer-1,
        &.offer-2 {
          display: none;
        }
        &.offer-1 {
          float: #{$ldirection};
          margin-#{$ldirection}: 10%;
        }
        &.offer-2 {
          float: #{$rdirection};
          margin-#{$rdirection}: 34%;
        }
      }
    }
  }
}
/* success overlay */

.offer__kit__success__overlay {
  &#cboxOverlay {
    display: none;
  }
  #cboxContent {
    background: $color-white;
    border: 1px solid $color-white;
  }
}

.offer__kit__success__overlay__container {
  display: none;
}

.offer__kit__success__overlay__content {
  background: $color-white none repeat scroll 0 0;
  color: $color-black;
  padding-bottom: 50px;
  text-align: center;
}

.offer__kit__success__overlay__header {
  font-family: $base-thin-font-family;
  font-size: 60px;
  line-height: 30px;
  margin-bottom: 35px;
  margin-top: 50px;
  width: 100%;
}

.offer__kit__success__overlay__copy {
  margin: 15px auto;
  text-align: #{$ldirection};
  width: 90%;
  h2 {
    color: $color-black;
    font-size: 40px;
  }
}

.offer__kit__success__overlay__button__container {
  margin-top: 30px;
}
/* Success overlay mobile */

.device-mobile #foreground-node.offer__kit__success__overlay {
  .close-container {
    #{$rdirection}: 15px;
    top: 15px;
    position: absolute;
  }
  .offer__kit__success__overlay__copy {
    h2 {
      font-size: 30px;
    }
  }
}
/* Checkboxes */

.offer__kit__option {
  .regular-checkbox {
    display: none;
    + label.styled-checkbox {
      display: inline-block;
      position: relative;
      vertical-align: bottom;
      width: 21px;
      height: 21px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(204, 204, 204);
      border-image: initial;
    }
    &:checked + label::after {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(0, 0, 0);
      content: '✓';
      font-size: 22px;
      height: 21px;
      #{$ldirection}: -2px;
      line-height: 22px;
      position: absolute;
      top: -2px;
      width: 21px;
    }
  }
  label.for-styled-checkbox {
    display: inline;
    font-size: 20px;
    line-height: 20px;
    margin-#{$ldirection}: 20px;
    width: 89%;
  }
}

.offer__kit__details {
  font-size: 12px;
  margin: 5px 0;
  a {
    font-size: 12px;
    color: $color-black;
    font-family: $helvetica-bold-font-family;
  }
}

.offer__kit__details__overlay {
  background: $color-lightest-grey-2 none repeat scroll 0 0;
  display: none;
  height: 116%;
  padding: 30px 0 0 45px;
  text-align: left;
  position: absolute;
  top: 0;
  width: 90%;
  opacity: 0.8;
  z-index: 1;
  margin-#{$ldirection}: 22px;
  h3 {
    font-weight: bold;
    margin: 15px 0 15px 0;
  }
  .prod-name {
    color: $color-sharp-black;
    font-size: 30px;
    line-height: 33px;
    margin: 0;
  }
}

.offer__kit__option--01 .offer__kit__details .offer__kit__details__overlay {
  #{$ldirection}: 0;
}

.offer__kit__option--02 .offer__kit__details .offer__kit__details__overlay {
  #{$rdirection}: 0;
}
/* Tablets */

/* ----------- iPad mini, 1 and 2 ----------- */ /* Portrait */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .offer__kit__container {
    .offer__kit__image {
      height: 270px;
    }
  }
}
/* ----------- iPad 3 and 4 ----------- */ /* Portrait */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .offer__kit__container {
    .offer__kit__image {
      height: 270px;
    }
  }
}
