#gnav_signin {
  .my-account-lists-and-favorites-v1 {
    .user_results {
      .product-col3 {
        .product-tout {
          .product {
            .spp_product_status {
              overflow: hidden;
            }
            .btn-comingsoon {
              width: 130px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

#footer {
  .footer-top-v1 {
    margin-top: 35px;
    .tel {
      width: 17%;
    }
    .social-links {
      width: 15%;
    }
    .tooltip {
      text-align: right;
      .tooltiptext-over::before {
        left: 90%;
      }
    }
  }
}

p.error {
  color: #ef6ea8;
}
/* Christmas opening hours contact times need to be taller */
.cs-3-col-row-v1 {
  .customer-care-list {
    .customer-care-list-item {
      min-height: 380px;
    }
  }
}
/* Video meta data is not functional, global issue, hiding as global have done */
.domain-uk .video-category-row-v1 .item-video .item-video-meta {
  display: none !important;
}
/* donations */
.donations.panel {
  margin: 20px 0px 0px 0px;
  h2#charity-header {
    padding: 0.3em 0.8em;
  }
  .content {
    padding: 0 !important;
    .donation_info_panel {
      width: 100%;
      padding: 5px 20px 20px 20px;
      .donation_logo {
        float: left;
        height: 100%;
        padding: 0px 20px 0px 0px;
        img {
          margin: 0;
          max-width: 100%;
          height: auto;
          vertical-align: bottom;
        }
      }
      .donation_logo_mobile {
        display: none;
        float: left;
        height: 100%;
      }
      .donation_message {
        font-size: 14px;
        line-height: 20px;
      }
      .donation_message_mobile {
        display: none;
        margin: 0% 0% 0% 25%;
        font-size: 14px;
      }
    }
    .donation_amounts_panel {
      float: left;
      width: 100%;
      h2.donation-panel__title {
        font-size: 14px;
        text-align: right;
        border-bottom: none;
        margin-bottom: 10px;
        background-color: #eff0f0 !important;
        letter-spacing: 0.2em;
        width: 100%;
        padding: 1% 1% 1% 0%;
        float: left;
        #select-amount {
          font-size: 14px;
          padding: 0px 0px 0px 20px;
          float: left;
          text-transform: uppercase;
          font-weight: bold;
        }
        #select-amount-mobile {
          display: none;
        }
        span.donation-group {
          input.field {
            height: 0.9em !important;
          }
          label {
            display: inline-block !important;
            text-transform: uppercase !important;
          }
        }
      }
    }
  }
}
/* Phone Order App */
#csr_header {
  a {
    display: block;
  }
  #csr_info_box {
    border-right: 1px solid $color-red;
  }
  #csr_registered_user_info,
  #cust_cart {
    border-right: 1px solid $color-red;
  }
  .csr_table {
    td {
      vertical-align: middle;
      padding: 0 20px;
    }
    #csr_dragger {
      cursor: move;
    }
  }
}

#main.single {
  #offer-code-panel {
    #offer_code {
      input[type='text'] {
        height: 2.2em !important;
      }
    }
  }
}

.right {
  .ship-method-group {
    .form-item {
      label {
        float: left;
        margin: 0 10px 0 0 !important;
      }
      .label-content {
        display: inline-block;
        float: left;
      }
      .date-list {
        display: block;
      }
      float: right;
      margin: 10px 0 0 0 !important;
    }
    .delivery-note {
      display: block;
      float: left;
    }
  }
}

.product-name {
  .donation-items {
    font-family: $base-font-family;
    color: $color-cl-soft-black;
  }
}

#viewcart #main.single #viewcart-panel .content .product {
  a.remove-donation {
    left: 125px;
    bottom: 10px;
    position: absolute;
  }
  &.donation {
    font-family: $base-font-family;
    color: $color-cl-soft-black;
  }
  #giftcard_balance {
    .checkbox {
      .label-content {
        display: inline-block;
      }
    }
  }
  .form-item.checkbox {
    span.label-content {
      display: inline !important;
    }
  }
}

.panel select.error,
.panel select.checkedEmpty,
.panel select.touched {
  border-color: $color-notice-pink !important;
}

.left {
  .ship-method-home-group {
    div {
      float: right;
      clear: right;
    }
    .form-item {
      margin: 0.3em 0px !important;
      label {
        display: inline-block;
        float: left;
        margin: 0 10px 0 0 !important;
      }
      .label-content {
        display: inline-block;
        padding: 5px 0 0 0;
      }
    }
    .delivery-note {
      float: right;
      margin: 10px 0 0 0;
    }
    select {
      direction: rtl;
    }
  }
}

div #waitlist_christmas {
  text-align: center;
  padding-top: 25px;
  h2 {
    font-size: 35px;
    color: #000000;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 23px;
    color: #000000;
  }
  input.form-text {
    width: 300px;
    border: 1px solid black;
  }
  input.form-submit.button {
    height: 35px;
  }
  .thankyou {
    margin: auto;
    position: absolute;
    top: 40%;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.spp_page_wrap {
  .order_arrival {
    .loading-status {
      float: left;
    }
  }
  .icon-heart-message {
    float: left !important;
  }
}

body[id*='quiksearch'] #endeca-results {
  margin-bottom: 70px;
}
/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  width: 100%;
  font-weight: bold;
  a {
    color: #fff;
    text-decoration: underline;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 5px;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: normal;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 10%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 10%;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
}

#appointment-book-container {
  #appointment-book-sections {
    .service {
      min-height: 397px;
    }
  }
}
