/* VARIABLES */

$subnav-bg: #f7f7f7;
$border-color: #bdbdbd;
/* END OF VARIABLES */

.careers {
  margin: 0 auto;
  width: 965px;
  line-height: 1.5;
  margin-bottom: 35px;
  padding-top: 0 !important;
  /* SUB NAV */
  .cms_layer.career_sub_nav {
    width: 965px;
    height: 228px;
    background-color: $subnav-bg;
    border: 1px solid $border-color;
    position: relative;
    #{$rdirection}: 32px;
    .career_subnav_menus {
      margin-#{$ldirection}: 45px;
      list-style: none;
      li {
        float: #{$ldirection};
        margin: 5px 0;
        list-style: none;
        &.educationmanager,
        &.countermanager {
          width: 37%;
        }
        &.accountexec,
        &.acctcoordinator {
          width: 29%;
        }
        &.consultant,
        &.regionaleducationexec {
          width: 33%;
        }
        a {
          font-size: 19px;
          font-family: $helvetica-light-font-family;
        }
      }
      h2 {
        margin: 60px 0 20px;
      }
    }
  }
  .profile-form-container .cms_layer.career_sub_nav {
    @include swap_direction(margin, 60px 0 0 0);
    position: relative;
    #{$rdirection}: 45px;
  }
  /* END OF SUB NAV */

  &.career_request {
    margin-bottom: 60px;
  }
  input.active,
  textarea.active {
    color: $color-black;
  }
  .cms_layer {
    &.career_content {
      @include swap_direction(margin, 60px 0 0 13px);
      padding-#{$rdirection}: 30px;
      .career_head {
        font-size: 22px;
      }
      .submit_resume {
        float: right;
      }
    }
  }
  .horizontal-rule {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: $border-color -moz-use-text-color -moz-use-text-color;
    border-image: none;
    border-#{$rdirection}: 0 none;
    border-style: solid none none;
    border-width: 1px 0 0;
    height: 1px;
    margin: 0 0 15px;
    padding: 0;
  }
  .small_header {
    font-size: 22px;
    color: $color-cl-green;
    @if $cr19 == true {
      color: $cr19-text-black;
    }
  }
  hr {
    margin: 8px auto;
  }
  ul {
    list-style: disc outside none;
    @include swap_direction(margin, 0 0 20px 15px);
    padding: 0;
    li {
      margin-bottom: 3px;
      list-style: disc;
    }
  }
  ol {
    list-style: decimal outside none;
    @include swap_direction(margin, 0 0 30px 45px);
    padding: 0;
    li {
      margin-bottom: 5px;
      list-style: decimal;
    }
  }
  p {
    @include swap_direction(margin, 0 0 20px 0);
  }
  .interested {
    width: 70%;
  }
  .apply {
    text-align: #{$rdirection};
  }
  #careers_right_container {
    width: 100%;
    @include swap_direction(margin, 60px 0 0 13px);
    float: none;
  }
  .career_heading {
    font-size: 22px;
    @include swap_direction(margin, 0 0 15px 12px);
  }
  fieldset.fs {
    border: 0;
    width: 100%;
  }
  form label {
    text-transform: none;
  }
  .user_info {
    border-bottom: 1px solid $border-color;
    padding-bottom: 41px;
    fieldset.fs {
      margin: 0;
      padding: 0;
      width: 100%;
    }
    .form_element input {
      width: 215px;
      border: 1px solid $border-color;
      height: 36px;
      line-height: 36px;
      @include swap_direction(padding, 0px 0 0 14px);
    }
  }
  fieldset.fs .country_container select {
    width: 215px;
    border: 1px solid $border-color;
    height: 36px;
    line-height: 36px;
    @include swap_direction(padding, 7px 0 6px 14px);
  }
  .user_info .form_element {
    @include swap_direction(margin, 10px 50px 1px 1px);
  }
  .fields_required {
    font-size: 14.5px;
  }
  .career_screening {
    .screening_left {
      float: #{$ldirection};
      width: 70%;
    }
    .screening_container span.lastchild {
      float: #{$rdirection};
    }
  }
  .optional_container span.lastchild,
  .position_container .lastchild {
    float: #{$rdirection};
  }
  .career_screening fieldset.fs {
    background: #ffffff;
    margin: 0;
    @include swap_direction(padding, 13px 24px 13px 17px);
    &:nth-child(even) {
      background: #f5f5f6;
    }
  }
  .profile-form-container .even {
    background: #f5f5f6 !important;
  }
  .career_screening fieldset.fs {
    &:nth-child(16) {
      background: #fff;
      border-bottom: 1px solid $border-color;
      padding-botttom: 0px;
      margin-bottom: 22px;
    }
    &:nth-child(18) {
      background: #fff;
    }
  }
  #employment_landing hr {
    margin: 8px auto;
  }
  .career_applications {
    #privacy_statement,
    #esignature_statement {
      background-color: $color-white;
      display: none;
      height: 300px;
      #{$ldirection}: 332px;
      position: absolute;
      text-align: #{$ldirection};
      top: 0;
      width: 630px;
      z-index: 10001;
    }
    fieldset.fs div span {
      &.direct_reports_count input {
        width: 190px;
        height: 36px;
        border: 1px solid $border-color;
        color: $product-color-price;
      }
      input {
        border: 0 none;
        @include swap_direction(margin, 0 0 0 37px);
        vertical-align: middle;
        width: 16px;
      }
    }
  }
  fieldset.fs .optional_container {
    width: 100%;
  }
  .avail_weekdays {
    @include swap_direction(margin, 25px 0 32px 0);
    width: 100%;
    height: 150px;
  }
  .weekdays_timing {
    float: #{$ldirection};
    width: 80px;
    border-#{$rdirection}: 1px solid $border-color;
    div {
      border-bottom: 1px solid $border-color;
      text-align: #{$ldirection};
      height: 37px;
      line-height: 37px;
      padding-#{$ldirection}: 15px;
    }
  }
  .weekdays_right {
    float: #{$ldirection};
    width: 75%;
  }
  .weekdays_days {
    float: #{$ldirection};
    padding: 6px 0;
    text-align: center;
    width: 90px;
    height: 37px;
    line-height: 37px;
    border-bottom: 1px solid $border-color;
    border-#{$rdirection}: 1px solid $border-color;
  }
  .weekdays_checkbox {
    color: $color-white;
    float: #{$ldirection};
    font-size: 0;
    height: 37px;
    line-height: 37px;
    padding-top: 6px;
    text-align: center;
    width: 90px;
    border-#{$rdirection}: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }
  .optional_works {
    label {
      margin-top: 7px;
    }
    input {
      display: block;
      height: 36px;
      line-height: 36px;
      @include swap_direction(margin, 12px 0 0 23px);
      width: 216px;
      border: 1px solid $border-color;
      @include swap_direction(padding, 0 0 0 14px);
    }
  }
  fieldset .position_container select {
    float: #{$rdirection};
    width: 190px;
  }
  .optional_container {
    select {
      float: #{$rdirection};
      width: 190px;
    }
    .screening_left {
      label {
        float: #{$ldirection};
        width: 63%;
      }
      span.qno {
        float: #{$ldirection};
        margin-#{$rdirection}: 13px;
      }
    }
  }
  .optional_licenses {
    display: block;
    margin-bottom: 10px;
  }
  fieldset.fs .qno {
    margin-#{$rdirection}: 10px;
  }
  .plaining_budget .optional_container input {
    @include swap_direction(margin, 3px 7px 3px 25px);
    vertical-align: middle;
  }
  .position_container input {
    vertical-align: middle;
    @include swap_direction(margin, 3px 7px 3px 32px);
  }
  .career_coverletter {
    label {
      font-size: 22px;
      font-weight: normal;
    }
    textarea {
      display: block;
      margin-top: 17px;
      width: 76.5%;
      height: 267px;
      margin-bottom: 5px;
      padding-top: 15px;
    }
  }
  .career_coverletter {
    padding-bottom: 25px;
  }
  .esignature input {
    height: 36px;
    line-height: 36px;
    border: 1px solid $border-color;
    margin-top: 34px;
    width: 215px;
    @include swap_direction(padding, 0 0 0 14px);
  }
  input.form-submit:hover {
    background: none repeat scroll 0 0 $color-btn-green;
    @if $cr19 == true {
      background-color: $cr19-bg-black;
    }
  }
  .upload_resume {
    input {
      @include swap_direction(margin, 22px 0 0 0);
      width: 250px;
    }
    .career_heading {
      @include swap_direction(margin, 0 0 10px 0);
    }
  }
  .career_screening {
    border-bottom: 1px solid $border-color;
    margin: 41px 0;
    padding-bottom: 41px;
  }
  .position_block {
    border-bottom: 1px solid $border-color;
    margin-bottom: 27px;
    padding-bottom: 47px;
  }
  fieldset.fs {
    .weekdays .weekdays_days:last-child {
      border-#{$rdirection}: 0;
    }
    .week_checkbox .weekdays_checkbox {
      &:nth-child(7),
      &:nth-child(14),
      &:nth-child(21) {
        border-#{$rdirection}: 0;
      }
      &:nth-child(15),
      &:nth-child(16),
      &:nth-child(17),
      &:nth-child(18),
      &:nth-child(19),
      &:nth-child(20),
      &:nth-child(21) {
        border-bottom: 0;
      }
    }
    .weekdays_timing div:last-child {
      border-bottom: 0;
    }
  }
  .screening_container {
    label {
      float: #{$ldirection};
      width: 95%;
      &.label.error {
        color: $color-notice-pink;
      }
    }
    span {
      float: #{$ldirection};
    }
  }
  .sign_statement {
    width: 65%;
    input {
      float: #{$ldirection};
      @include swap_direction(margin, 3px 3px 0 0);
    }
    label {
      display: block;
      padding-#{$ldirection}: 20px;
    }
    .sign_statement_required {
      span {
        float: #{$ldirection};
        margin-#{$rdirection}: 1px;
        font-size: 14px;
      }
    }
  }
  &.job_request {
    @include swap_direction(padding, 0 0 0 13px);
    .request_content {
      padding-top: 150px;
    }
    .request_status {
      margin-bottom: 30px;
    }
  }
}

.removetop {
  padding-top: 0 !important;
}
/* HEADER MODULE */
#careers_header {
  background-image: url('/media/images/pc/career/career_header_bg.jpg');
  padding-top: 115px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 100% 100%;
  margin-bottom: 60px;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/pc/career/career_header_bg.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/media/images/pc/career/career_header_bg.jpg', sizingMethod='scale')";
  line-height: 1.5;
  > #careers_header_container {
    margin: 0 auto;
    @include swap_direction(padding, 35px 0 0 13px);
    width: 965px;
    overflow: hidden;
  }
  &.career_form {
    padding-top: 0;
  }
  #header_module {
    height: 380px;
    .cms_layer.header_content {
      width: 50%;
      float: #{$ldirection};
      padding-top: 95px;
      .career_head {
        margin-bottom: 5px;
      }
    }
  }
  .cms_layer.header_image {
    width: 50%;
    float: #{$ldirection};
  }
  .breadcrumbs {
    padding-bottom: 10px;
  }
}
/* END OF HEADER MODULE */

/* Overlay container in Customer Careers page */
#customer_service_career .overlay-container {
  overflow: hidden;
  h3.header {
    margin-bottom: 10px;
  }
  .pop-up-control {
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #bdbdbd;
    width: 644px;
    height: 246px;
    padding: 0 10px 10px;
    &.privacy-statement {
      min-height: 300px;
      height: auto;
      overflow: hidden;
      padding-top: 10px;
    }
    &.esignature-statement {
      height: 300px;
    }
  }
}

.content-block.basic-tout {
  #beauty_chat,
  #chat_cta_tout_pc,
  #cs_chat {
    .chat_now {
      text-decoration: none !important;
      border-bottom: 1px solid rgba($cr19-text, 0.45);
      display: inline-block;
      line-height: 1.5;
      &:hover {
        border-bottom: 1px solid $cr19-text;
      }
    }
  }
}
