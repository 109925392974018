.mm-formatter-v1,
#sticky-bar {
  .mm-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .mm-menu li {
    float: #{$ldirection};
    height: 43px;
    white-space: nowrap;
    line-height: 43px;
    border-#{$ldirection}: 1px solid #fff;
    border-#{$rdirection}: 1px solid #fff;
  }
  .mm-menu li.active {
    border-left-color: #dbdbdb;
    border-right-color: #dbdbdb;
    height: 43px;
  }
  .mm-menu li span.title {
    display: block;
    position: relative;
    z-index: 1000; /* This needs to be 1 greater than '#navigation .menu-item-blocks' */
    padding: 0 10px;
    line-height: 47px;
    cursor: pointer;
    margin-top: 0;
  }
  .mm-menu li.first.register span.title,
  .mm-menu li.first.signin span.title {
    padding-right: 0;
  }
  .mm-menu li.first.register span.title span,
  .mm-menu li.first.signin span.title span {
    border-right: 1px solid transparent;
    padding-right: 10px;
  }
  .mm-menu li.first.register span.title span.borderRight,
  .mm-menu li.first.signin span.title span.borderRight {
    border-right: 1px solid $color-black;
  }
  .mm-menu li.first.active span.title span {
    border-right: 1px solid transparent;
  }
  .mm-menu li.first.signin.active span.title {
    padding-right: 10px;
  }
  .mm-menu li.first.signin.active span.title span.borderRight {
    border-right: 1px solid transparent;
    padding-right: 0;
  }
  .mm-menu li span.title img {
    width: 23px;
    max-height: 23px;
    margin-#{$rdirection}: 7px;
    vertical-align: middle;
  }
  .mm-menu li.active span.title {
    background-color: #fff;
  }
  .mm-menu li a {
    text-decoration: none;
    color: #808080;
  }
  .mm-menu li.active a {
    background-color: #fff;
    color: #000;
  }
}

.elc-user-state-anonymous .mm-menu li a.signin,
.elc-user-state-logged-in .mm-menu li a.signOut {
  color: $color-cl-green-2;
  @if $cr19 == true {
    color: $cr19-text-black;
  }
}

.elc-user-state-anonymous .mm-menu li .avatar {
  display: none;
}
/* Region-specific CSS */

#navigation .menu-item-blocks,
#sticky-bar .menu-item-blocks {
  /* ClearType bold text bug in IE8 and Chrome/XP */
  -webkit-backface-visibility: hidden;
  opacity: 0.99;
  filter: alpha(opacity=99);
  position: absolute;
  top: 43px;
  #{$ldirection}: 0;
  z-index: 1; /* I'm not sure why this needs to be set to "1" for it to work */
}

#navigation .menu-item-blocks,
#main .menu-item-blocks,
#sticky-bar .menu-item-blocks {
  display: none;
}

#main .menu-item-blocks {
  visibility: hidden;
  width: 100%;
  .mm_3col_1tout_v1,
  .mm_4col_v1 {
    width: 100%;
  }
}

#navigation .menu-item-blocks,
#sticky-bar .menu-item-blocks {
  width: 100%;
  background-attachment: fixed;
  background: #fff url($template_base_path + '/mm_formatter_v1/newt.png') 0 bottom repeat-x;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}

#navigation .menu-item-blocks .menu-item-block,
#sticky-bar .menu-item-blocks .menu-item-block,
#main .menu-item-blocks .menu-item-block {
  display: none;
  min-height: 290px;
}

#navigation .menu-item-blocks .menu-item-block.active,
#sticky-bar .menu-item-blocks .menu-item-block.active,
#main .menu-item-blocks .menu-item-block.active {
  display: block;
}

#sticky-bar {
  #sticky_bar_content {
    .mm-menu .last {
      max-width: 10%;
    }
    .mm-menu .item-more .more-content {
      padding: 4px;
      #{$rdirection}: -1px;
    }
  }
}
/* "More" menu */
#navigation,
#sticky-bar {
  .mm-menu .item-more {
    display: none;
    position: relative;
  }
  .mm-menu .item-more > .title a {
    color: $color-cl-green-2;
    @if $cr19 == true {
      color: $cr19-text-black;
    }
  }
  .mm-menu .item-more li.active {
    border-color: #fff;
  }
  .mm-menu .item-more .more-content {
    background: #fff;
    border: 1px solid #ddd;
    border-top: none;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.3);
    display: none;
    padding: 15px;
    position: absolute;
    #{$rdirection}: -15px;
    top: 44px;
    z-index: 9999;
    li {
      clear: both;
    }
  }
  .mm-menu .item-more .more-content a:hover {
    text-decoration: underline;
  }
  .mm-menu .item-more.open {
    border-left-color: #dbdbdb;
    border-right-color: #dbdbdb;
  }
  .mm-menu .item-more.open > .title {
    background: #fff;
  }
  .mm-menu .item-more.open .more-content {
    display: block;
  }
}

#sticky-bar .mm-menu {
  padding: 0 10px;
  li {
    padding: 0 5px;
    height: 47px;
    &.active {
      height: 47px;
    }
    span.title {
      line-height: 50px;
      height: 47px;
      z-index: auto;
    }
    a {
      font-size: 15px;
    }
  }
}

@if $loyalty == true {
  .mm-formatter-v1 {
    .mm-menu {
      li {
        span.title {
          @include swap_direction(padding, 0 7px);
        }
      }
      li:first-child {
        span.title {
          span.borderRight {
            border-right: 1px solid black;
          }
        }
      }
    }
  }
}
