.my-account-auth-user-panel-v1 {
  .flip-loading {
    display: none;
    height: 315px;
    max-width: 877px;
    position: absolute;
    width: 100%;
    z-index: 100;
    img {
      display: block;
      #{$ldirection}: 50%;
      margin: -30px 0 0 -30px;
      position: absolute;
      top: 50%;
    }
  }
  .header {
    *zoom: 1;
    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    &:after {
      clear: both;
    }
    h2 {
      font-size: 36px;
      text-transform: none;
      margin: 0;
      vertical-align: bottom;
      float: #{$ldirection};
      a {
        color: $color-cl-green-2;
        @if $cr19 == true {
          color: $cr19-text-black;
        }
      }
      span {
        font-size: 14px;
        a {
          font-family: $helvetica-bold-font-family;
        }
      }
    }
  }
  .twocol {
    display: block;
    float: #{$ldirection};
    width: 30%;
    margin-#{$ldirection}: 1.5%;
    &:first-child {
      margin-#{$ldirection}: 0;
    }
    .avatar {
      *zoom: 1;
      font-size: 13px;
      margin-bottom: 20px;
      &:before,
      &:after {
        content: ' ';
        display: table;
      }
      &:after {
        clear: both;
      }
      .thumb {
        float: #{$ldirection};
        margin-#{$rdirection}: 10px;
        width: 92px;
      }
      .bio {
        overflow: hidden;
        color: #111;
        span {
          display: block;
          margin-bottom: 3px;
        }
      }
    }
  }
  .meter {
    @include swap_direction(margin, 0 0 10px 2px);
    border-radius: 15px;
    background: #eaeaea;
    display: block;
    height: 18px;
    width: 85%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    span {
      background: $color-cl-green-2;
      display: block;
      height: 18px;
      border-radius: 15px 0 0 15px;
      @if $cr19 == true {
        background-color: $cr19-bg-black;
      }
    }
  }
  .twocol .offer-link {
    clear: both;
    font-size: 18px;
    font-family: $helvetica-light-font-family;
    padding: 10px 0;
  }
  .twocol .avatar .bio span.name {
    font-family: $helvetica-bold-font-family;
  }
  .twocol p {
    line-height: 1.35;
    font-size: 13px;
    @include swap_direction(margin, 0 0 12px 0);
  }
  .fourcol {
    padding-#{$ldirection}: 20px;
    width: 68%;
    display: block;
    float: #{$ldirection};
    margin-#{$rdirection}: 1.5%;
    border-#{$ldirection}: 1px solid #dadada;
    &:last-child {
      margin-#{$rdirection}: 0;
    }
    h4 {
      color: $color-cl-soft-black;
      font-size: 22px;
      height: 40px;
      line-height: 1;
      margin: 0;
    }
  }
  .shortcuts {
    *zoom: 1;
    width: 535px;
    margin: 0;
    padding: 0;
    &.no-header {
      margin: 20px 0;
    }
    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    &:after {
      clear: both;
    }
    li {
      display: block;
      text-align: center;
      width: 120px;
      @include swap_direction(margin, 0 15px 15px 0);
      @include swap_direction(padding, 3px 0 0 0);
      height: 100px;
      float: #{$ldirection};
      a {
        display: block;
        height: 100px;
        border-radius: 10px;
        @include box-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
        background: #fff;
        @include swap_direction(padding, 26px 15px 0 15px);
        color: #111;
        font-size: 12px;
        font-family: $helvetica-regular-font-family;
        img {
          margin-bottom: 6px;
        }
        &.account {
          background: $color-cl-green;
          color: #fff;
          @include box-shadow(none);
          font-family: $helvetica-light-font-family;
          font-size: 22px;
          line-height: 23px;
          padding: 26px 0;
          @if $cr19 == true {
            background-color: $cr19-bg-black;
            color: $cr19-text-white;
          }
        }
      }
    }
    .seefull_page {
      margin-right: 0px;
    }
  }
  .main-container {
    position: relative;
  }
  .flip-container {
    position: relative;
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    -o-perspective: 800px;
    perspective: 800px;
    z-index: 1000;
  }
  .flip-card {
    width: 100%;
    height: 100%;
    position: absolute;
    .flip-front,
    .flip-back {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
    }
    .flip-back {
      display: none;
    }
  }
  .flip-back .unflip {
    position: absolute;
    text-align: #{$rdirection};
    #{$rdirection}: 20px;
    top: 12px;
    z-index: 9999;
  }
  .card {
    @include swap_direction(padding, 0 0 15px 0);
    margin: 0;
    height: 315px;
    position: relative;
    z-index: 50;
    h3 {
      font-size: 36px;
      text-transform: none;
      @include swap_direction(margin, 0 0 20px 0);
      line-height: 38px;
      vertical-align: bottom;
    }
  }
}
/* Media queries */

@media screen and (max-width: 960px) {
  .my-account-auth-user-panel-v1 .fourcol {
    width: 80%;
    padding-#{$ldirection}: 0;
  }
}

@media screen and (max-width: 960px) {
  .my-account-auth-user-panel-v1 .twocol {
    display: none;
  }
  .my-account-auth-user-panel-v1 .fourcol {
    border-#{$ldirection}: 0px;
  }
}

@media screen and (max-width: 768px) {
  .my-account-auth-user-panel-v1 .card h3 {
    font-size: 17px;
  }
}
