@import 'power-review-common';
/* Mixin function for pr-button display */
@mixin pr-button-display {
  display: inline-block;
  border-width: 0;
  width: auto;
  padding: 6px 15px;
  overflow: visible;
  vertical-align: top;
  cursor: pointer;
  background-color: $color-black;
  color: $color-white;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  line-height: 18px;
  &:hover {
    background-color: $color-btn-grey;
  }
}
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-review-display,
.spp_page_wrap,
.spp_reviews,
#pr-reviewsnippet {
  .p-w-r {
    & :focus {
      outline: 0;
      box-shadow: 0 0 0;
      -webkit-box-shadow: 0 0 0;
    }
    .product-grid-wrapper & {
      @include swap_direction(margin, 0 0 15px 0);
      float: $ldirection;
    }
    span,
    p {
      color: $color-black;
    }
    .pr-textinput,
    .pr-textarea {
      font-size: 14px;
    }
    #pr-warning,
    .pr-header-product-img,
    .pr-header-required,
    .pr-logo-container,
    .pr-header-product-name,
    .pr-snippet-rating-decimal {
      display: none;
    }
    .pr-review-snapshot-header-intro {
      .pr-headline {
        @include text-title--small;
        color: $color-black;
        display: block;
        max-width: 1008px;
        padding: 25px 0 0;
        text-align: center;
        @include breakpoint($ab-small-down) {
          @include swap_direction(padding, 25px 0 20px 0);
        }
      }
      .pr-subheadline {
        display: none;
      }
    }
    .pr-table-cell {
      padding: 0;
    }
    .pr-accessible-btn {
      @include breakpoint($ab-small-down) {
        @include swap_direction(padding, 5% 2%);
      }
      @include pr-button-display();
      @include swap_direction(padding, 3% 2%);
      @include text-body-text;
      border: 1px solid $color-black;
      border-radius: 2px;
      background-color: $color-black;
      color: $color-white;
      &:hover {
        background-color: $color-black;
      }
    }
    .pr-header-table {
      .pr-header-title {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    .pr-snippet-stars-reco {
      .pr-inline {
        .pr-snippet-read-and-write {
          .spp__container & {
            display: inline-block;
            a {
              text-transform: uppercase;
              text-decoration: underline;
              color: $color-black;
              font-weight: bold;
            }
          }
          .pr-snippet-write-review-link {
            .spp-cross-sells__products &,
            .product-grid-wrapper &,
            .search-wrapper & {
              display: none;
            }
          }
        }
        &.pr-snippet-minimal {
          .pr-snippet-stars-container {
            display: inline-block;
          }
        }
      }
    }
    /* To display a Review description posted by user */
    .pr-review-display {
      dt,
      dd,
      dl {
        color: $color-black;
      }
      &.pr-rd-display-tablet {
        .pr-review {
          border-bottom: 1px dotted $color-grey;
        }
        .pr-rd-flag-review-container {
          vertical-align: bottom;
        }
      }
      .pr-rd-description-text {
        color: $color-black;
      }
      .pr-rd-footer {
        @include swap_direction(padding, 0 0 10px 0);
      }
      .pr-rd-main-header {
        background: none;
        border-top: 1px solid $color-light-grey;
        border-bottom: 1px solid $color-light-grey;
        margin-bottom: 80px;
        padding-bottom: 20px;
        h1 {
          display: none;
        }
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        @include text-body-text--large;
        text-transform: uppercase;
        color: $color-black;
      }
      #smartrewards2,
      #loyaltymember2 {
        display: none;
      }
      .pr-rating {
        &.number {
          border: 1px solid $color-darkest-grey;
        }
        &.date {
          border: none;
        }
      }
      .title {
        color: $color-black;
        text-transform: capitalize;
      }
      .pr-dark {
        color: $color-black;
        font-weight: bold;
      }
    }
  }
}

.mpp-grid,
.mpp-product {
  .p-w-r {
    margin: 0;
    .pr-snippet {
      .pr-snippet-stars-png {
        @include pr-background-image();
        .pr-snippet-rating-decimal {
          display: none;
        }
      }
      div {
        vertical-align: top;
      }
    }
    .pr-category-snippet {
      margin: 0;
    }
    .pr-category-snippet__total {
      @include text-body-text--fine;
      color: $color-black;
      padding-top: 1px;
      &:before {
        content: '(';
        visibility: visible;
      }
      &:after {
        content: ')';
        visibility: visible;
      }
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-stars-container {
          display: table-cell;
        }
        a.pr-snippet-write-review-link {
          display: none;
        }
        .pr-snippet-read-and-write {
          display: table-cell;
          @include breakpoint($ab-small-down) {
            display: table;
            padding-#{$ldirection}: 5px;
          }
          .pr-snippet-review-count {
            @include text-body-text--fine;
            color: $color-black;
            position: relative;
            left: 2px;
            top: 1px;
            &:before {
              content: '(';
            }
            &:after {
              content: ')';
            }
            @include breakpoint($ab-small-down) {
              border: 0;
            }
          }
        }
      }
    }
    .pr-no-reviews {
      .pr-snippet {
        display: none;
      }
    }
  }
}
/* SPP page */
.spp_view {
  .p-w-r {
    @include breakpoint($ab-ipad-landscape) {
      text-align: center;
    }
  }
}

#pr-spp-snippet {
  .p-w-r {
    margin: 0;
    .pr-snippet {
      @include swap_direction(margin, 5px 0 0 0);
      font-size: 12px;
      line-height: 16px;
      height: auto;
      text-align: center;
      @include breakpoint($ab-small-down) {
        display: inline;
      }
      .pr-snippet-stars-png {
        @include pr-background-image();
        .pr-snippet-rating-decimal {
          display: none;
        }
        .pr-rating-stars {
          top: 2px;
        }
      }
      .pr-category-snippet__total {
        padding-top: 3px;
      }
    }
    .pr-no-reviews {
      .pr-category-snippet__total,
      .pr-snippet-review-count {
        display: none;
      }
      .pr-snippet-write-review-link {
        border-#{$ldirection}: 1px solid $color-shade-grey;
        @if $cr19 == true {
          @include text-body-text--no-xl;
          padding: 0 15px;
          margin-#{$ldirection}: 15px;
          color: $cr19-text-grey;
          font-size: 15px !important;
          @media #{$cr19-medium-up} {
            font-size: 17px !important;
          }
        }
      }
      .pr-snippet-stars-container {
        @include swap_direction(padding, 0 7px 0 0);
      }
    }
    .pr-snippet-reco-to-friend {
      display: none;
    }
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          @include swap_direction(padding, 0 7.5px 0 3.5px);
          border-#{$rdirection}: 1px solid $color-shade-grey;
          vertical-align: text-bottom;
          &:before {
            content: '(';
          }
          &:after {
            content: ')';
          }
        }
        a,
        span {
          @include swap_direction(margin, 0 2px 0 0);
          @include text-body-text;
          color: $color-black;
        }
      }
      &.pr-snippet-compact {
        .pr-snippet-stars-container {
          @include breakpoint($ab-small-down) {
            display: block;
          }
        }
      }
      &.pr-snippet-standard {
        .pr-snippet-stars-reco-stars {
          float: none;
        }
        .pr-snippet-stars-container {
          display: block;
        }
        .pr-snippet-read-and-write {
          @include swap_direction(margin, 10px 0 0 0);
        }
      }
    }
  }
}

.reviews {
  .promo_product_row & {
    .pr-review-snippet-container {
      .pr-snippet-stars-reco-reco {
        display: none;
      }
    }
  }
  .p-w-r {
    margin: 0;
    @include breakpoint($ab-ipad-landscape) {
      text-align: center;
    }
    .pr-snippet {
      @include swap_direction(margin, 5px 0 0 0);
      font-size: 12px;
      line-height: 16px;
      height: auto;
      @include breakpoint($ab-small-down) {
        display: inline;
      }
      .pr-snippet-stars-png {
        @include pr-background-image();
        .pr-snippet-rating-decimal {
          display: none;
        }
        .pr-rating-stars {
          top: 2px;
        }
      }
    }
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          vertical-align: text-bottom;
          @include breakpoint($ab-small-down) {
            @include swap_direction(padding, 0 7.5px 0 3.5px);
            border-#{$rdirection}: 1px solid $color-shade-grey;
          }
          &:before {
            content: '(';
          }
          &:after {
            content: ')';
          }
        }
        a,
        span {
          @include swap_direction(margin, 0 2px 0 0);
          @include text-body-text;
          color: $color-black;
        }
        a.pr-snippet-write-review-link {
          @include swap_direction(padding, 0 0 0 5px);
          display: none;
        }
        span {
          font-size: 12px !important;
          padding: 0 3px;
        }
      }
      .pr-snippet-stars-container {
        float: $ldirection;
      }
    }
    .pr-no-reviews {
      .pr-category-snippet__total,
      .pr-snippet-review-count {
        display: none;
      }
    }
  }
}
/* Spp Page Review Container */
.spp__container {
  .spp_customer_reviews {
    border: 1px solid $color-grey;
    padding: 20px;
    @include breakpoint($ab-small-down) {
      border: none;
      padding: 0;
    }
  }
  .spp_reviews {
    @include swap_direction(padding, 0 0 18px 0);
    @include swap_direction(margin, 0 0 10px 0);
    border-bottom: 1px dotted $color-grey;
    &._heading {
      float: $ldirection;
      h2 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
      }
    }
    &._button {
      text-align: #{$rdirection};
      input[type='submit'] {
        letter-spacing: 0px;
      }
    }
  }
  .product-full__details {
    .pr-review-snippet-container {
      .pr-snippet-stars-reco-reco {
        display: none;
      }
      .pr-snippet-stars-reco-inline {
        @include swap_direction(margin, 0 0 10px 0);
      }
    }
  }
}

.spp-product {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-stars-reco-reco {
        display: none;
      }
      .pr-snippet-stars-container {
        @include breakpoint($ab-small-down) {
          float: none;
          display: block !important;
          margin-bottom: 2px;
        }
      }
      .pr-snippet-stars-reco-inline {
        float: none;
      }
      .pr-snippet-stars-reco-stars {
        @include breakpoint($ab-small-down) {
          float: none;
        }
      }
      .pr-snippet-read-and-write,
      a.pr-snippet-write-review-link {
        @include breakpoint($ab-small-down) {
          display: inline-block !important;
        }
      }
    }
  }
}
/* To display a Review description posted by user */
.pwr_title,
#BVRRPullquoteHighlightContainer {
  @include text-title--med;
  display: block;
  margin: 0 auto;
  padding: 10px 0;
  line-height: 41px;
  max-width: 1008px;
  color: $color-black;
  text-align: center;
  @include breakpoint($ab-small-down) {
    @include swap_direction(padding, 0 0 0 15px);
    max-width: 100%;
  }
}

#pr-review-display {
  .p-w-r {
    margin: 0 auto;
    max-width: 1008px;
    @if $cr19 == true {
      @include container-max-width-x-large;
      padding: 0;
    }
    position: relative;
    @include breakpoint($ab-small-down) {
      max-width: 100%;
      padding: 10px 15px;
    }
    a,
    span,
    select {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .pr-review-snapshot-block-container,
    .pr-review-snapshot-faceoff,
    .pr-review-snapshot-msq-container {
      display: none;
    }
    .pr-review-snapshot-simple {
      @include swap_direction(margin, 10px 0);
      display: block;
      position: relative;
      .pr-review-snapshot-block {
        @include swap_direction(padding, 10px 5px);
        width: 24%;
        border: none;
        min-height: 190px;
        @include breakpoint($ab-small-down) {
          width: 100%;
          margin-top: 5px;
          min-height: 30%;
        }
        &.pr-review-snapshot-block-snippet {
          width: 17%;
          @include breakpoint($ab-small-down) {
            width: 100%;
            margin-bottom: 0;
          }
        }
        &.pr-review-snapshot-block-recommend {
          width: 21%;
          @include breakpoint($ab-small-down) {
            width: 100%;
            margin-bottom: 1%;
          }
        }
        &.pr-review-snapshot-block-histogram {
          width: 33%;
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
          .pr-review-snapshot-histogram {
            @include breakpoint($ab-small-down) {
              margin-bottom: 15%;
            }
          }
          .pr-snippet-write-review-link {
            @include breakpoint($ab-small-down) {
              @include swap_direction(padding, 4%);
              @include text-body-text;
              border-radius: 2px;
              color: $color-black;
              border: 1px solid $color-black;
              display: inline-block;
              text-align: center;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .pr-snippet-reco-to-friend-green {
        background-color: $color-white;
        @include breakpoint($ab-small-down) {
          width: 100%;
        }
      }
      .pr-histogram-count {
        text-align: $rdirection;
        font-weight: normal;
      }
      .pr-snippet-read-and-write {
        @include breakpoint($ab-small-down) {
          text-align: center;
        }
        a.pr-snippet-write-review-link {
          @include elc-button--light;
          [data-component] & {
            @include elc-button--light;
            @include elc-button-component-overrides;
          }
          &.elc-button--disabled,
          &:disabled {
            @include elc-button--light-disabled;
          }
        }
        .pr-snippet-review-count {
          &:before {
            content: '(';
            visibility: visible;
          }
          &:after {
            content: ')';
            visibility: visible;
          }
        }
      }
      .pr-review-snapshot-snippets-headline {
        margin-bottom: 0;
        @include breakpoint($ab-small-down) {
          display: block;
        }
      }
      .pr-review-snapshot-snippets {
        .pr-snippet-review-count {
          @include text-body-text--fine;
          color: $color-black;
        }
      }
      .pr-snippet-reco-to-friend-red,
      .pr-snippet-reco-to-friend-yellow {
        background: none;
      }
    }
    .pr-snippet {
      @include breakpoint($ab-small-down) {
        padding: 10px 0;
        text-align: center;
      }
    }
    .pr-review-snapshot {
      @include pr-background-medium-image();
      .pr-review-snapshot-header {
        text-align: center;
        margin: 0;
        padding: 0;
        @include breakpoint($ab-small-down) {
          text-align: $ldirection;
          margin-bottom: 10px;
        }
        .pr-review-snapshot-snippets {
          .pr-snippet-stars-reco-stars {
            float: none;
            .pr-snippet-read-and-write {
              .pr-snippet-write-review-link {
                @include text-body-text($font-family: $helvetica-bold-font-family);
                text-transform: uppercase;
                background: $color-cl-green-2;
                width: auto;
                padding: 6px 15px;
                overflow: visible;
                vertical-align: top;
                color: $color-white;
                cursor: pointer;
                text-align: center;
                border-width: 0;
                &:hover {
                  background: $color-cl-green-2-darker;
                  text-decoration: none;
                }
                @include breakpoint($ab-small-down) {
                  width: 100%;
                  display: block;
                  float: $ldirection;
                  margin: 15px 0;
                }
              }
            }
          }
        }
        .pr-review-snapshot-header-intro {
          border: none;
          margin: 0;
          padding: 0;
        }
      }
      .pr-snippet-stars-reco-inline {
        .pr-snippet-stars-container {
          @include swap_direction(padding, 18px 0 0 0);
          float: $ldirection;
          @include breakpoint($ab-small-down) {
            width: 100%;
            padding: 0;
            display: inline;
            float: none;
          }
        }
        .pr-snippet-read-and-write {
          @include swap_direction(padding, 25px 20px 25px 5px);
          float: $ldirection;
          border-#{$rdirection}: 1px solid $color-shade-grey;
          @include breakpoint($ab-small-down) {
            width: 100%;
            border: 0;
            float: none;
            display: inline;
            padding: 0;
          }
          .pr-snippet-review-count {
            @include swap_direction(padding, 2px 10px 0px 5px);
            @include text-body-text;
            float: $ldirection;
            color: $color-black;
            @include breakpoint($ab-small-down) {
              float: none;
            }
          }
        }
        .pr-snippet-stars-reco-reco {
          @include swap_direction(padding, 17px 3px 21px 11px);
          float: $ldirection;
          border-#{$rdirection}: 1px solid $color-shade-grey;
          @include breakpoint($ab-small-down) {
            float: none;
            padding: 0;
            border-#{$rdirection}: 0;
          }
        }
      }
      .pr-ratings-histogram {
        .pr-ratings-histogram-bar {
          height: 19px;
          background-color: $color-grey;
        }
        .pr-ratings-histogram-barValue {
          height: 17px;
          background-color: $color-darker-grey;
          border-radius: 0;
          &:hover {
            background-color: $color-black;
          }
        }
        .pr-histogram-stars {
          &:hover {
            background: none;
          }
          &:focus,
          &:active {
            .pr-ratings-histogram-bar {
              border: none;
              background-color: $color-grey;
              .pr-ratings-histogram-barValue {
                background-color: $color-black;
                border-radius: 0;
                height: 18px;
              }
            }
          }
        }
        .pr-histogram-5Stars {
          .pr-histogram-label {
            background-color: $color-white;
            color: transparent;
            background-image: url('/media/images/stars/star-5.jpg');
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .pr-histogram-4Stars {
          .pr-histogram-label {
            background-color: $color-white;
            color: transparent;
            background-image: url('/media/images/stars/star-4.jpg');
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .pr-histogram-3Stars {
          .pr-histogram-label {
            background-color: $color-white;
            color: transparent;
            background-image: url('/media/images/stars/star-3.jpg');
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .pr-histogram-2Stars {
          .pr-histogram-label {
            background-color: $color-white;
            color: transparent;
            background-image: url('/media/images/stars/star-2.jpg');
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .pr-histogram-1Stars {
          .pr-histogram-label {
            background-color: $color-white;
            color: transparent;
            background-image: url('/media/images/stars/star-1.jpg');
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
    .pr-review-display {
      @include pr-large-background-image();
      .pr-rd-main-header {
        .pr-clearfix {
          clear: none;
        }
        .pr-rd-review-total {
          @include swap_direction(padding, 15px 0 0 0);
          @include text-body-text;
          display: inline-block;
          color: $color-black;
        }
        .pr-rd-sort {
          @include text-body-text;
          @include dropdown-opt;
          border: 0;
          cursor: pointer;
          height: 45px;
          padding: 0 15px;
          text-align: $ldirection;
          text-transform: capitalize;
        }
        .pr-rd-main-header-search-sort {
          padding-top: 0;
          float: $rdirection;
          width: 45%;
          @include breakpoint($ab-small-down) {
            float: $ldirection;
            width: 100%;
          }
        }
        .pr-rd-main-header-search {
          height: auto;
          width: auto;
          margin-top: 5px;
          @include breakpoint($ab-small-down) {
            float: $ldirection;
            margin-top: 0;
            width: 100%;
          }
        }
        .pr-rd-review-header-sorts {
          float: $rdirection;
          padding: calc(1em + 5px) 0;
          width: 59%;
          @include breakpoint($ab-small-down) {
            @include swap_direction(padding, 0 0 15px 0);
            border: none;
            float: $rdirection;
            width: 100%;
          }
        }
        .pr-multiselect-options {
          width: auto;
          @include breakpoint($ab-small-down) {
            padding: 5px;
            max-height: initial;
          }
        }
        .pr-rd-review-header-contents {
          @include swap_direction(padding, 5px 0);
          width: 55%;
          float: $ldirection;
          background: none;
          @include breakpoint($ab-small-down) {
            border: none;
            width: 100%;
            text-align: $ldirection;
          }
          .pr-multiselect {
            padding: 12px 0;
            @media #{$cr19-typography-large-up} {
              padding: calc(1em + 5px) 0;
            }
            &.pr-multiselect-button-pros,
            &.pr-multiselect-button-describeyourself,
            &.pr-multiselect-button-cliniquecustomerfor,
            &.pr-multiselect-button-cons,
            &.pr-multiselect-button-wasthisagift {
              display: none;
            }
            .pr-multiselect-button {
              @include swap_direction(padding, 10px 10px 14px 0);
            }
            &.pr-multiselect-button-age {
              .pr-multiselect-button {
                @include breakpoint($ab-small-down) {
                  @include swap_direction(margin, 5px 4px 0 0);
                }
              }
            }
          }
          &:before {
            @include text-title--small;
            content: 'Filter by:';
            display: inline;
            @include breakpoint($ab-small-down) {
              content: 'Filter:';
              vertical-align: sub;
            }
          }
        }
        @include breakpoint($ab-small-down) {
          padding: 10px 0;
          margin: 0;
        }
      }
      .pr-review {
        @include swap_direction(margin, 0 0 70px 0);
        border-bottom: 1px solid $color-shade-grey;
        @include breakpoint($ab-small-down) {
          @include swap_direction(margin, 0 0 45px 0);
        }
        .pr-rd-header {
          @include breakpoint($ab-small-down) {
            @include swap_direction(margin, 40px 0 0 0);
          }
          .pr-snippet-rating-decimal {
            display: none;
          }
          .pr-rd-review-headline {
            @include swap_direction(padding, 15px 0 10px 0);
            @include text-body-text--large;
            width: 100%;
            display: block;
            color: $color-black;
            margin: 0;
            @include breakpoint($ab-small-down) {
              @include swap_direction(padding, 5px 0 10px 0);
            }
          }
          .pr-snippet-stars-png {
            .pr-snippet-rating-decimal {
              display: none;
            }
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            @include text-body-text;
            em {
              font-weight: bold;
            }
          }
          .pr-rd-right {
            .pr-rd-reviewer-type {
              display: none;
            }
            .pr-rd-reviewer-details {
              p,
              time {
                @include swap_direction(margin, 0 0 5px 0);
                @include text-body-text($font-family: $helvetica-bold-font-family);
                text-transform: capitalize;
                color: $color-black;
              }
              p {
                .pr-rd-bold {
                  @include text-body-text($font-family: $helvetica-bold-font-family);
                  text-transform: capitalize;
                  margin-#{$rdirection}: 4px;
                }
                .pr-rd-author-location {
                  @include text-body-text;
                }
              }
            }
          }
        }
        .pr-rd-merchant-response {
          .pr-rd-description-text {
            @include text-body-text;
          }
          .pr-rd-merchant-response-headline {
            strong {
              @include text-body-text;
            }
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            outline: none;
            box-shadow: none;
            span {
              @include text-body-text($font-family: $helvetica-bold-font-family);
              color: $color-black;
            }
            .pr-caret-icon__line {
              stroke: $color-black;
            }
          }
          .pr-accordion-content {
            dl {
              dt,
              dd {
                @include text-body-text;
                @include breakpoint($ab-small-down) {
                  font-size: 13px;
                }
              }
              dt {
                @include text-body-text($font-family: $helvetica-bold-font-family);
                background-color: $color-white;
              }
              &.pr-rd-review-tag dt {
                @include swap_direction(padding, 10px 0 0 0);
              }
            }
            dd {
              @include breakpoint($ab-small-down) {
                line-height: 20px;
              }
            }
            .pr-rd-subratings {
              dl[data-reactid-powerreviews$='smartrewards'] {
                display: block;
                dt {
                  display: none;
                }
                dd {
                  padding-top: 10px;
                  width: 510px;
                  @include breakpoint($ab-small-down) {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .pr-rd-footer {
          .pr-rd-bottomline {
            span {
              @include text-body-text;
            }
            .pr-rd-bold {
              display: none;
            }
          }
          .pr-rd-helpful-action {
            @include swap_direction(padding, 0 0 35px 0);
            margin-#{$rdirection}: 0;
            @include breakpoint($ab-small-down) {
              @include swap_direction(padding, 25px 0 0 0);
              @include swap_direction(margin, 35px 0 0 0);
              line-height: 20px;
              border-top: 1px solid $color-shade-grey;
            }
            .pr-rd-helpful-text {
              @include text-body-text($font-family: $helvetica-bold-font-family);
              @include breakpoint($ab-small-down) {
                @include swap_direction(padding, 10px 0 15px 0);
              }
            }
          }
          .pr-helpful-count {
            @include swap_direction(padding, 0 30px 0 0);
            @include text-special-body($font-family: $nitti-medium-font-family);
            font-weight: 500;
            @include breakpoint($ab-small-down) {
              @include swap_direction(padding, 0 7px 0 0);
            }
          }
          .pr-helpful-btn {
            border: 0;
            margin: 0 1px;
            padding: 0 1px;
            background: none !important;
            text-transform: capitalize;
            vertical-align: middle;
            font-weight: bold;
            @include breakpoint($ab-small-down) {
              float: $ldirection;
              margin: 0;
            }
            @include breakpoint($medium-up) {
              margin-bottom: 10px;
            }
            .pr-thumbs-icon {
              display: none;
            }
          }
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                @include swap_direction(padding, 8px 20px);
                content: 'YES';
                visibility: visible;
                margin-#{$rdirection}: 10px;
                border: 1px solid $color-black;
                border-radius: 2px;
                vertical-align: middle;
                @include breakpoint($ab-small-down) {
                  @include swap_direction(padding, 8px 12px);
                  margin-#{$rdirection}: 7px;
                }
                @include breakpoint($medium-up) {
                  display: inline-block;
                  min-width: 70px;
                }
              }
              &:after {
                @include swap_direction(margin, 0 8px 0 8px);
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                @include swap_direction(padding, 8px 20px);
                content: 'NO';
                visibility: visible;
                margin-#{$rdirection}: 10px;
                border: 1px solid $color-black;
                border-radius: 2px;
                vertical-align: middle;
                @include breakpoint($ab-small-down) {
                  @include swap_direction(padding, 8px 12px);
                  margin-#{$rdirection}: 7px;
                }
                @include breakpoint($medium-up) {
                  display: inline-block;
                  min-width: 70px;
                }
              }
              &:after {
                @include swap_direction(margin, 0 0 0 8px);
              }
            }
          }
          .pr-helpful-active {
            .pr-helpful-count {
              &:before {
                background-color: $color-darker-grey;
              }
            }
          }
          .pr-rd-flag-review-container {
            @include swap_direction(padding, 0 0 27px 0);
            @include text-body-text;
            vertical-align: initial;
            width: 35%;
            a {
              font-size: 15px;
              color: $color-black;
              @include breakpoint($ab-small-down) {
                padding-#{$ldirection}: 11px;
              }
              @media screen and (max-width: 320px) {
                padding-#{$ldirection}: 0;
                padding-top: 5px;
                float: $ldirection;
              }
            }
            @include breakpoint($ab-small-down) {
              @include swap_direction(padding, 10px 0 20px 0);
              width: auto;
            }
            @media screen and (max-width: 320px) {
              @include swap_direction(padding, 10px 0 35px 0);
              clear: both;
            }
            p.pr-flag-review-thankyou {
              font-size: 15px;
              @include breakpoint($ab-small-down) {
                font-size: 14px;
              }
            }
          }
          .pr-modal-content {
            #pr-flag-reviews {
              input[type='radio'] {
                left: auto;
              }
              .pr-flagging-radio {
                span {
                  @include text-body-text;
                }
              }
            }
            .pr-flag-review-btn {
              @include text-body-text($font-family: $helvetica-bold-font-family);
              display: inline-block;
              background: $color-black;
              cursor: pointer;
              color: $color-white;
              text-transform: uppercase;
              &:hover {
                background-color: $color-btn-grey;
              }
            }
            .pr-flag-review-btn-cancel {
              background-color: $color-btn-hover;
              color: $color-white;
              &:hover {
                background-color: $color-dark-grey;
              }
            }
            .pr-control-label,
            .pr-flag-review-label {
              @include text-body-text;
              color: $color-black;
            }
          }
          .spp_reviews & {
            .pr-helpful-yes,
            .pr-helpful-no {
              .pr-helpful-count {
                @include text-special-body($font-family: $nitti-medium-font-family);
                font-weight: 500;
                display: block;
                margin-bottom: 5px;
                &:before {
                  padding: 8px 2px;
                  margin-#{$ldirection}: 0;
                  margin-#{$rdirection}: 10px;
                  border: 1px solid $color-black;
                  top: 0;
                  display: inline-block;
                  min-width: 50px;
                }
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
      .pr-rd-main-footer {
        padding-top: 15px;
        margin-top: 20px;
        margin-bottom: 30px;
        border-top: 1px solid $color-light-grey;
        .pr-rd-content-block {
          width: 100%;
          border: 0 !important;
          padding-bottom: 0 !important;
          .pr-rd-to-top {
            @include text-body-text;
            padding-#{$ldirection}: 30%;
            text-align: center;
            color: $color-black;
            @include breakpoint($ab-small-down) {
              padding-#{$ldirection}: 0;
            }
          }
          .pr-rd-review-position span {
            @include text-body-text;
            &.pr-rd-bold {
              @include text-body-text($font-family: $helvetica-bold-font-family);
            }
          }
          .pr-rd-pagination-btn {
            @include elc-button--light;
            text-decoration: none;
            [data-component] & {
              @include elc-button--light;
              @include elc-button-component-overrides;
            }
            &.elc-button--disabled,
            &:disabled {
              @include elc-button--light-disabled;
            }
          }
        }
      }
      &.pr-rd-display-tablet {
        .pr-rd-side-content-block {
          right: -38%;
        }
      }
      .pr-rd-main-header-with-filters {
        .pr-rd-search-container {
          .pr-rd-search-reviews-input {
            input {
              height: 35px !important;
              background-color: $color-lightest-grey-2;
              border: 0;
              padding: 10px;
              &:active {
                box-shadow: none;
              }
              @include breakpoint($ab-small-down) {
                padding: 5px;
                font-size: 12px;
                height: 30px !important;
              }
            }
            input + button {
              span {
                svg {
                  path {
                    fill: $color-light-grey;
                  }
                }
              }
            }
            .pr-rd-search-reviews-icon-button {
              height: 34px;
              background-color: $color-lightest-grey-2;
              border: 0;
              svg {
                transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
              }
              @include breakpoint($ab-small-down) {
                height: 29px;
              }
            }
            .pr-rd-search-reviews-clear-button {
              width: 23px;
              right: 25px;
              &:active {
                border: none;
                box-shadow: none;
              }
              .pr-cross-icon__line {
                stroke: $color-black;
              }
            }
          }
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-content-block {
          padding-bottom: 15px;
          margin: 0;
          &.pr-accordion {
            padding-bottom: 7px;
          }
          &.pr-rd-footer {
            padding-bottom: 0;
          }
          &.pr-rd-images {
            margin: 0;
            padding: 0;
          }
          &.pr-rd-tags,
          &.pr-rd-subratings {
            border: 0;
            width: auto;
          }
        }
        .pr-rd-side-content-block {
          right: -30%;
        }
        .pr-rd-bottomline {
          @include swap_direction(margin, 0 25px 35px 0);
          padding-bottom: 70px;
          border-bottom: 1px solid $color-shade-grey;
          width: 1010px;
        }
        .pr-rd-sort-group {
          &:last-child {
            padding-top: 10px;
          }
          &:before {
            @include text-body-text($font-family: $helvetica-bold-font-family);
            content: 'Sort by:';
            display: inline-block;
            padding-#{$rdirection}: 10px;
            @include breakpoint($ab-small-down) {
              font-size: 12px;
              display: inline;
            }
          }
        }
      }
      .pr-rd-no-reviews {
        @include swap_direction(padding, 35px 0);
      }
      .pr-rd-sort-group {
        @include breakpoint($ab-small-down) {
          float: $rdirection;
        }
        &:before {
          @include text-body-text($font-family: $helvetica-bold-font-family);
          content: 'Sort by:';
          display: inline-block;
          padding-#{$rdirection}: 10px;
          @include breakpoint($ab-small-down) {
            display: inline;
            font-size: 12px;
          }
        }
      }
      .pr-rd-tags {
        dl.pr-rd-review-tag {
          width: 100%;
          dd {
            @include swap_direction(padding, 0 5px 0 0);
            display: inline;
            margin: 0;
            &:after {
              content: ',';
            }
            &:last-child {
              &:after {
                content: ' ';
              }
            }
          }
        }
      }
      .pr-rd-display-search-no-results {
        clear: both;
      }
      .pr-rd-pagination {
        @include breakpoint($ab-small-down) {
          border-top: none;
        }
      }
    }
    .pr-snippet-reco-to-friend {
      @include breakpoint($ab-small-down) {
        max-width: 100%;
        text-align: center;
      }
      .pr-checkbox-icon svg {
        display: none;
      }
      .pr-reco-to-friend-message {
        @include text-body-text--large;
        max-width: 260px;
        color: $color-black;
        padding: 0;
        @include breakpoint($ab-small-down) {
          max-width: 100%;
          margin-top: 15px;
          text-align: center;
        }
        .elc-spp-reviews & {
          @include swap_direction(padding, 0 20px 0 0);
        }
      }
      .pr-reco {
        span {
          @include text-title--xl;
        }
      }
    }
    .pr-modal-shown {
      z-index: 9999;
    }
    .pr-multiselect {
      .pr-caret-icon {
        width: 21px;
        @include breakpoint($ab-small-down) {
          right: 0;
        }
        @media screen and (max-width: 320px) {
          right: 2px;
        }
      }
      .pr-multiselect-button {
        min-width: auto;
        border: none;
        vertical-align: middle;
        display: inline-flex;
        @include breakpoint($ab-small-down) {
          @include swap_direction(padding, 0 9px 0 0);
          @include swap_direction(margin, 5px 2px 0 0);
        }
        @media screen and (max-width: 320px) {
          @include swap_direction(padding, 0 10px 0 0);
          @include swap_direction(margin, 5px 26px 0 0);
        }
        .pr-multiselect-count {
          background: $color-black;
          color: $color-white;
          @include breakpoint($ab-small-down) {
            @include swap_direction(padding, 1px 5px);
            @include swap_direction(margin, 0 2px 0 0);
            width: 13px;
            display: none;
          }
        }
        span {
          @include breakpoint($ab-small-down) {
            font-size: 12px;
          }
        }
      }
      .pr-multiselect-options {
        ul {
          width: 200px;
          @include breakpoint($ab-small-down) {
            width: 160px;
          }
          li label {
            @include breakpoint($ab-small-down) {
              @include swap_direction(padding, 5px 0 0px 15px);
            }
          }
        }
        span {
          @include breakpoint($ab-small-down) {
            margin-#{$ldirection}: 2%;
          }
        }
      }
    }
  }
}
/* Write a Review Form */
#power_review_container {
  max-width: 1008px;
  margin: 0 auto;
  @include breakpoint($ab-small-down) {
    max-width: 100%;
    margin: 0 10px;
  }
  .product-full__image {
    float: $ldirection;
    width: 25%;
    @include breakpoint($ab-small-down) {
      width: 100%;
      text-align: center;
    }
    img {
      @include swap_direction(margin, 59px 0);
      width: 250px;
      height: 250px;
    }
  }
  .product-full__details {
    @include swap_direction(padding, 55px 0 0 0);
    float: $ldirection;
    width: 75%;
    @include breakpoint($ab-small-down) {
      @include swap_direction(padding, 20px 0 0 0);
      width: 100%;
    }
  }
  .product-full__name {
    @include swap_direction(margin, 15px 0 15px);
    @include text-title--med;
    color: $color-cl-green-2;
  }
  .pr-sub-line {
    @include swap_direction(margin, 10px 0);
    @include text-body-text;
    color: $color-black;
  }
}

#pr-war-form {
  .pr-cons-form-group,
  .pr-pros-form-group,
  .pr-describeyourself-form-group,
  .pr-bestuses-form-group,
  .pr-smartrewards2-form-group,
  .pr-loyaltymember2-form-group,
  .pr-wasthisagift-form-group {
    display: none;
  }
}

.write_a_review__container {
  #pr-write {
    @include swap_direction(padding, 10px 0 0 0);
    .p-w-r {
      @include pr-large-background-image();
      .pr-header {
        clear: both;
        .pr-header-title {
          @include text-body-text--large;
          text-transform: none;
          color: $color-black;
        }
      }
      span {
        font-size: 12px;
      }
      #pr-war-form {
        .pr-btn-default {
          border-bottom-width: 1px;
          &.active {
            background-color: $color-black;
            color: $color-white;
          }
          &.pr-btn-fileinput {
            padding: 0px;
            @include breakpoint($ab-small-down) {
              width: 100% !important;
            }
          }
        }
        fieldset {
          width: 75%;
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
        }
        .pr-rating-form-group {
          label {
            @include swap_direction(margin, 5px 20px 0 0);
            float: $ldirection !important;
          }
        }
      }
      .pr-clear-all-radios {
        color: $color-black;
      }
      .pr-btn-add-tag {
        a {
          @include swap_direction(padding, 2% 2%);
          @include pr-button-display();
          @include text-body-text;
          border: 1px solid $color-black;
          border-radius: 2px;
          background-color: $color-black;
          color: $color-white;
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
          &:after {
            display: none;
          }
          &:hover {
            background-color: $color-black;
            @include breakpoint($ab-small-down) {
              width: 100%;
            }
          }
        }
      }
      .pr-btn-fileinput {
        span {
          @include pr-button-display();
          width: 200px;
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
          &:after {
            color: $color-white;
          }
        }
      }
      .pr-submit {
        a {
          font-size: 12px;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
      .form-control {
        @include text-body-text;
        color: $color-black;
        background-color: $color-lighter-white;
        border-radius: 0;
        box-shadow: none;
        &::placeholder {
          color: $color-black;
          opacity: 0.4;
        }
      }
      .pr-footer {
        margin-bottom: 60px;
        .pr-subscript {
          @include text-body-text;
          color: $color-black;
        }
      }
      .form-group {
        .pr-label-control {
          &.checked span {
            color: $color-white;
          }
        }
      }
      .tag-group {
        .pr-label-control {
          border-width: 1px;
          &.checked {
            background-color: $color-black;
            .pr-tag-label {
              color: $color-white;
            }
          }
        }
        .input-add-tag {
          width: 51%;
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
        }
      }
      .thank-you-page {
        margin-bottom: 70px;
        .header {
          .title,
          .subtitle {
            @include text-body-text--large;
            color: $color-black;
          }
          h5 {
            @include swap_direction(margin, 30px 0);
            a {
              @include swap_direction(padding, 2% 2%);
              @include text-body-text;
              border-radius: 2px;
              background-color: $color-black;
              color: $color-white;
            }
          }
        }
        .pr-attribute {
          @include text-body-text;
          color: $color-black;
        }
        .pr-attribute-header {
          @include text-body-text;
          background-color: $color-white;
          color: $color-black;
        }
        .pr-dark {
          @include text-body-text;
          margin-#{$rdirection}: 3px;
          display: inline-block;
          width: 42%;
          @include breakpoint($ab-small-down) {
            width: 56%;
          }
        }
        .pr-text {
          @include text-body-text;
          span {
            @include text-body-text;
            display: inline-block;
            width: 56%;
            @include breakpoint($ab-small-down) {
              width: initial;
            }
          }
        }
        .pr-rating-number,
        .pr-rating-date,
        #war-ty-cons,
        #war-ty-best-uses,
        #email_collection {
          display: none;
        }
        h2.headline {
          font-weight: normal;
        }
        #smartrewards {
          .pr-dark {
            display: none;
          }
        }
      }
      .pr-has-error {
        .form-control {
          border-color: $color-red;
          &:focus {
            border-color: $color-red;
          }
        }
        .pr-control-label {
          span {
            color: $color-red;
          }
        }
      }
      .pr-media_image-form-group,
      .pr-media_videourl-form-group,
      .pr-loyaltymember-form-group,
      .pr-email_collection-form-group {
        display: none;
      }
      .pr-alert-container {
        display: block;
        h4 {
          font-family: $helvetica-regular-font-family;
        }
        p {
          @include text-body-text;
        }
      }
    }
    .form-group {
      margin-bottom: 20px;
      span,
      label,
      .pr-helper-text {
        @include text-body-text;
        width: auto;
        color: $color-black;
        margin-#{$ldirection}: 0px;
      }
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('/media/images/stars/down-arrow.png') no-repeat scroll 99% 5px transparent;
      }
    }
    .pr-media_image-form-group,
    .pr-media_videourl-form-group {
      span {
        color: $color-black;
        float: none;
      }
      .pr-icon-delete {
        span {
          float: none;
        }
        fill: $color-white;
      }
      .form-group {
        input {
          display: none;
        }
      }
      .pr-media-preview {
        .pr-loading-indicator {
          margin: 8%;
        }
      }
      .pr-btn-danger {
        background-color: $color-black;
        border: 1px solid $color-lighter-grey;
        padding: 4px 10px;
        &:hover {
          background-color: $color-cl-green-2;
          border: 1px solid $color-lighter-grey;
        }
      }
    }
    .pr-smartrewards-form-group {
      display: none;
    }
  }
}

#pwr_signin {
  .pr_prod_img {
    float: $ldirection;
    width: 50%;
    @include breakpoint($ab-small-down) {
      width: 100%;
      text-align: center;
    }
  }
  .email_check,
  .full_sign_in {
    @include swap_direction(margin, 50px 0);
    float: $ldirection;
    width: 50%;
    @include breakpoint($ab-small-down) {
      @include swap_direction(margin, 10px 0);
      width: 100%;
    }
    h3 {
      color: $color-black;
      font-size: 15px;
    }
    .row {
      @include swap_direction(margin, 15px 0);
      .btn-pr {
        background-color: $color-black;
        padding: 2% 5%;
        border-radius: 2px;
        text-transform: uppercase;
        text-align: center;
      }
    }
    input[type='email'],
    input[type='password'] {
      height: 36px;
      padding-#{$ldirection}: 7px;
      width: 235px;
      line-height: 15px;
      @include breakpoint($ab-small-down) {
        width: 100%;
      }
    }
    .forgot-pw {
      display: inline-block;
      margin-#{$ldirection}: 10px;
      padding-top: 2px;
      vertical-align: middle;
      @include breakpoint($ab-small-down) {
        margin-#{$ldirection}: 0;
        padding-top: 4px;
      }
    }
  }
}

.pc_spp_view {
  .page-spp.bv-widgets {
    background: none;
  }
}

.flex-viewport {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          display: none;
        }
      }
    }
  }
}

.product-tout {
  .product {
    .reviews {
      padding-top: 0;
      span {
        color: $color-black;
      }
    }
  }
}
/* Ask & Answer tab section */

.spp__container {
  .product-full {
    #pr-spp-snippet {
      display: inline-block;
      .p-w-r {
        .pr-review-snippet-container {
          .pr-snippet {
            @include swap_direction(padding, 0 0 10px);
            height: auto;
            .pr-snippet-read-and-write {
              @include swap_direction(margin, 0);
            }
            .pr-snippet-stars-container {
              @media screen and (min-width: $medium-min) {
                @include swap_direction(margin, 0);
                display: inline-block;
                padding-#{$rdirection}: 0px;
              }
              @include swap_direction(margin, 10px 0);
              display: block;
              text-align: #{$rdirection};
              padding-#{$rdirection}: 10px;
              div {
                vertical-align: top;
              }
              @if $cr19 == true {
                @media #{$cr19-medium-up} {
                  display: inline-block;
                }
              }
            }
          }
        }
        .pr-snippet-read-and-write {
          .pr-snippet-review-count,
          .pr-snippet-write-review-link {
            @include swap_direction(padding, 0 7px);
            @if $cr19 == true {
              @include text-body-text--no-xl;
              color: $cr19-text-grey;
              @media #{$cr19-medium-up} {
                font-size: 17px !important;
              }
            }
          }
          .pr-snippet-write-review-link {
            border-right: 0px;
            @if $cr19 == true {
              @media #{$cr19-medium-up} {
                margin-#{$ldirection}: 5px;
              }
            }
          }
        }
        .pr-snippet-stars-reco-stars {
          .pr-snippet {
            &-read-and-write {
              margin-top: 0;
            }
            &-stars-container {
              @include swap_direction(margin, 10px 0 15px);
              display: flex;
            }
          }
        }
      }
    }
    &__media {
      .product-full_review-mobile {
        @include swap_direction(padding, 0);
        #pr-spp-snippet {
          .p-w-r {
            .pr-review-snippet-container {
              .pr-snippet {
                .pr-snippet-stars-container {
                  @media screen and (min-width: $medium-min) {
                    @include swap_direction(margin, 10px 0);
                    display: block;
                    padding-right: 10px;
                  }
                  @if $cr19 == true {
                    margin-top: 3px;
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
        #pr-questionsnippet {
          display: inline-block;
        }
      }
    }
    .product-full__content {
      .product-full_review--pc {
        #pr-questionsnippet {
          .pr-snippet-qa-metrics {
            font-family: $helvetica-regular-font-family;
            color: $color-black;
          }
        }
      }
    }
    &_review-mobile {
      text-align: center;
      @if $cr19 == true {
        text-align: #{$ldirection};
      }
      margin-bottom: 15px;
      .pr-review-snippet-container {
        text-align: center;
      }
    }
  }
}

.product-full {
  &.module-spp-detail {
    .product-full__content {
      h2 {
        &.product-full__description--short {
          @include swap_direction(padding, 0 20px);
          @if $cr19 == true {
            padding: 0;
          }
          margin-top: 10px;
          @media screen and (min-width: $medium-min) {
            @include swap_direction(margin, 0 0 15px);
            padding: 0;
          }
        }
      }
    }
  }
}

.spp_page_wrap {
  .product-full__image {
    .spp-share-container {
      text-align: center;
      #pr-spp-snippet {
        display: inline-block;
        .p-w-r {
          .pr-review-snippet-container {
            .pr-snippet {
              .pr-snippet-stars-container {
                @include swap_direction(margin, 10px 0);
                display: block;
                text-align: #{$rdirection};
                padding-#{$rdirection}: 10px;
                @if $cr19 == true {
                  display: inline-block;
                }
              }
              .pr-snippet-read-and-write {
                margin-top: 0px;
              }
            }
          }
          .pr-snippet-read-and-write {
            margin-top: 0px;
            .pr-snippet-review-count,
            .pr-snippet-write-review-link {
              @include swap_direction(padding, 0 7px);
            }
          }
        }
      }
    }
  }
}

.reviews-processed {
  text-align: center;
  .p-w-r {
    display: inline-block !important;
    .pr-review-snippet-container {
      .pr-snippet {
        .pr-snippet-stars-container {
          @include swap_direction(margin, 10px 0);
          display: block;
          text-align: #{$rdirection};
          padding-#{$rdirection}: 10px;
        }
        .pr-snippet-read-and-write {
          margin-top: 0px;
          .pr-snippet-review-count,
          .pr-snippet-write-review-link {
            @include swap_direction(padding, 0 7px);
            @include swap_direction(margin, 0);
            border-#{$rdirection}: 1px solid $color-shade-grey;
          }
        }
      }
    }
  }
}

.product-full_review--pc {
  .spp-share-container {
    @include swap_direction(padding, 0 20px);
    text-align: center;
    margin-bottom: 10px;
    @media screen and (min-width: $medium-min) {
      padding: 0;
      display: flex;
    }
  }
}

#pr-questionsnippet {
  display: inline-block;
  vertical-align: middle;
  .p-w-r {
    @include swap_direction(margin, 0);
    .pr-snippet-qa {
      border-#{$ldirection}: 1px solid $color-shade-grey;
      .pr-ask-question-link,
      .pr-snippet-qa-answer-link {
        color: $color-black;
        font-family: $helvetica-regular-font-family;
        margin-top: 0;
        padding-#{$ldirection}: 10px;
      }
      .pr-ask-question-cta,
      .pr-ask-question-subtext {
        display: none;
      }
      .pr-snippet-qa-metrics {
        .pr-qa-large {
          display: none;
        }
        .pr-answered-question-circle {
          display: none;
        }
      }
    }
  }
}

.spp_reviews {
  &.page-spp {
    &.tabs {
      ul {
        &.tabs-nav {
          @media screen and (min-width: $medium-m) {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid $color-light-grey;
          }
          @include swap_direction(padding, 10px 0 0);
          border-bottom: 0;
          display: block;
          &.stick {
            position: fixed;
            top: 0;
            width: 100%;
            background: $color-white;
            z-index: 9999;
          }
          li {
            @include swap_direction(padding, 15px);
            @include text-title--small;
            text-align: center;
            border-bottom: 1px solid $color-black;
            position: relative;
            color: $color-darker-grey;
            cursor: pointer;
            @media screen and (min-width: $medium-m) {
              border-width: 1px 1px 0px 1px;
              border-color: $color-light-grey;
              border-style: solid;
              width: 250px;
              position: relative;
              top: 1px;
            }
            &:nth-child(1) {
              border-#{$rdirection}: 0px;
            }
            &:after {
              content: '\203A';
              position: absolute;
              width: 14px;
              height: 14px;
              #{$rdirection}: 30px;
              top: 22px;
              transition: all 0.4s ease;
              display: flex;
              justify-content: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              -webkit-box-align: center;
              align-items: center;
              @media screen and (min-width: $medium-m) {
                display: none;
              }
            }
            &.tab-active {
              color: $color-black;
              @media screen and (min-width: $medium-m) {
                position: relative;
                border-bottom: 1px solid $color-white;
              }
              &:after {
                transform: rotateZ(90deg);
                top: 25px;
                #{$rdirection}: 25px;
              }
            }
          }
        }
      }
    }
  }
  .p-w-r {
    .pr-submit {
      float: #{$rdirection};
      width: 100%;
      @media screen and (min-width: $small-max) {
        width: auto;
      }
      .pr-accessible-btn {
        @include swap_direction(padding, 20px 50px);
        width: 100%;
        @media screen and (min-width: $small-max) {
          width: auto;
        }
        &:hover {
          color: $color-white;
        }
      }
    }
  }
}

#pr-questiondisplay {
  .p-w-r {
    @include swap_direction(padding, 0);
    max-width: 1008px;
    margin: 0 auto;
    label {
      font-family: $helvetica-regular-font-family;
      padding-bottom: 5px;
      cursor: auto;
    }
    .pr-waqwaa {
      .pr-helper-text,
      .pr-waq-legal-text {
        @include text-body-text;
        padding-bottom: 5px;
        color: $color-black;
      }
      .pr-waq-legal-text {
        span {
          color: $color-black;
        }
      }
      .pr-question-form {
        .pr-control-label {
          display: block;
        }
        .btn-group {
          display: block;
        }
      }
      @media screen and (min-width: $medium-m) {
        max-width: 100%;
      }
      .form-group {
        #questionCategory {
          .pr-btn {
            border-color: $color-dark-grey;
            font-family: $helvetica-regular-font-family;
            @media screen and (min-width: $medium-m) {
              width: 50%;
            }
            &.active {
              background-color: $color-black;
            }
          }
          .pr-control-label {
            color: $color-sharp-black;
          }
        }
        .form-control {
          background: $color-white;
          color: $color-darker-grey;
          border-radius: 0;
          border-color: $color-dark-grey;
          font-family: $helvetica-regular-font-family;
          &::placeholder {
            color: $color-darker-grey;
            font-family: $helvetica-regular-font-family;
          }
          @media screen and (min-width: $medium-m) {
            width: 40% !important;
            &.pr-textarea {
              width: 75% !important;
            }
          }
        }
        .pr-control-label {
          span {
            color: $color-black;
          }
        }
        &.pr-has-error {
          label {
            span {
              color: $color-red;
            }
          }
        }
      }
    }
    .pr-qa-display-ask-question {
      @media screen and (min-width: $medium-m) {
        @include swap_direction(padding, 10px 0);
        float: #{$rdirection};
        margin-bottom: 20px;
        width: 100%;
      }
      @include swap_direction(padding, 10px 0);
      margin-bottom: 0px;
      .pr-loading-indicator {
        @media screen and (min-width: $medium-m) {
          @include swap_direction(margin, 0 0 50px);
        }
      }
      button {
        @include swap_direction(padding, 15px 50px);
        background-color: $color-white;
        border: 1px solid $color-black;
        border-radius: 3px;
        color: $color-black;
        font-weight: 400;
        float: $rdirection;
        text-transform: initial;
        width: 100% !important;
        @media screen and (min-width: $small-max) {
          @include swap_direction(padding, 20px 75px);
          width: auto !important;
        }
        .pr-cross-icon {
          display: none;
        }
        .pr-qa-display-text {
          color: $color-black;
          font-size: 15px;
        }
      }
    }
    .pr-qa-display {
      @include swap_direction(padding, 15px 20px);
      .pr-qa-display-headline {
        @media screen and (min-width: $medium-m) {
          display: block;
        }
        margin: 0px;
        .pr-qa-display-ask-question {
          .pr-submit {
            .pr-accessible-btn {
              float: $rdirection !important;
              margin-#{$rdirection}: 240px;
            }
          }
        }
        .pr-qa-display-search {
          background-color: $color-white;
          padding: 0;
          position: relative;
          float: #{$ldirection};
          clear: unset;
          form {
            width: 100%;
            label {
              display: none;
            }
            .pr-qa-display-searchbar {
              &-input,
              .pr-qa-search-btn {
                @include swap_direction(padding, 0 15px);
                background-color: $color-lightest-grey-2;
                border-radius: 0;
                border: 0;
                color: $color-black;
                font-family: $helvetica-regular-font-family;
                &::placeholder {
                  font-family: $helvetica-regular-font-family;
                  color: $color-darker-grey;
                }
              }
              .pr-qa-search-btn {
                span {
                  path {
                    fill: $color-darker-grey;
                  }
                }
              }
            }
          }
        }
        .pr-qa-display-sort {
          @include swap_direction(padding, 20px 0 15px);
          clear: both;
          .pr-qa-sort-headline {
            @include text-body-text--large($font-family: $helvetica-bold-font-family);
          }
          .pr-qa-sort-group {
            @media screen and (min-width: $medium-m) {
              display: block;
            }
            display: none;
            label {
              &:after {
                content: ':';
                display: inline-block;
              }
            }
            select {
              &.pr-qa-sort {
                @include text-body-text;
                background: url('#{$base-theme-path}img/icons/src/arrow-8.svg') no-repeat center;
                background-size: 10px;
                background-position: $rdirection 0.2em top 50%, 0 0;
                border: 0;
                cursor: pointer;
                padding: 0 15px;
                text-align: $ldirection;
                text-transform: capitalize;
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
              &::-ms-expand {
                display: none;
              }
            }
          }
        }
        .pr-h1 {
          display: none;
        }
      }
      .pr-qa-display-question {
        @media screen and (min-width: $medium-m) {
          width: 100%;
        }
        &list {
          @media screen and (min-width: $medium-m) {
            padding-#{$ldirection}: 25px;
          }
          @include swap_direction(padding, 0 0 25px);
          @include swap_direction(margin, 35px 0 0);
          overflow: hidden;
          border-bottom: 1px solid $color-light-grey;
          border-#{$ldirection}: unset;
          .pr-qa-display-item {
            @media screen and (min-width: $medium-m) {
              margin-bottom: 35px;
              padding-#{$ldirection}: 35px;
            }
            display: flex;
            .pr-qa-display-item-icon {
              @media screen and (min-width: $medium-m) {
                #{$ldirection}: -2px;
              }
              background-color: $color-black;
              font-size: 14px;
              padding-top: 3px;
              #{$ldirection}: 0px;
              top: 0px;
            }
            .pr-qa-display-item-text {
              @media screen and (min-width: $medium-m) {
                @include swap_direction(padding, 3px 0 0 5px);
              }
              @include swap_direction(padding, 3px 0 0 35px);
              @include text-body-text--large($font-family: $helvetica-bold-font-family);
            }
          }
          .pr-qa-display-item-details {
            @media screen and (min-width: $medium-m) {
              padding-#{$ldirection}: 5px;
              padding-top: 0px;
              float: $ldirection;
              position: static;
              width: 18%;
            }
            @include swap_direction(padding, 10px 0 0 38px);
            font-family: $helvetica-bold-font-family;
            sub,
            time {
              @include swap_direction(margin, 0);
              font-size: 14px;
              color: $color-black;
              display: block;
              line-height: 26px;
            }
          }
          .pr-qa-display-answer-link-container {
            @media screen and (min-width: $medium-m) {
              display: inline-block;
              float: $ldirection;
            }
            .pr-btn-answer {
              @include swap_direction(padding, 15px 70px);
              width: 100% !important;
              border: 1px solid $color-black;
              border-radius: 3px;
              text-transform: capitalize;
              font-family: $helvetica-regular-font-family;
              span {
                color: $color-black;
                &.pr-cross-icon {
                  display: none;
                }
              }
            }
          }
          .pr-qa-display-add-answer {
            .p-w-r {
              .pr-waqwaa {
                max-width: 100%;
                .form-group {
                  .form-control {
                    &.pr-textarea,
                    &.pr-textarea {
                      width: 100% !important;
                    }
                  }
                }
                .pr-answer-form {
                  .pr-submit {
                    @media screen and (min-width: $medium-m) {
                      position: relative;
                      #{$rdirection}: 0px;
                      margin-bottom: 20px;
                    }
                  }
                }
              }
            }
            @media screen and (min-width: $medium-m) {
              clear: both;
              width: 81%;
              float: $rdirection;
              padding-#{$ldirection}: 10px;
            }
          }
          .pr-qa-display-answer {
            padding-#{$ldirection}: 35px;
            @media screen and (min-width: $medium-m) {
              display: inline-block;
              width: 81%;
              float: $rdirection;
              padding-#{$ldirection}: 0px;
            }
            &-by-container {
              @media screen and (min-width: $medium-m) {
                border-#{$ldirection}: 0px;
                padding-#{$ldirection}: 10px;
              }
              padding-#{ldirection}: 38px;
            }
            &-details {
              @include swap_direction(padding, 10px 0 10px 30px);
              font-family: $helvetica-regular-font-family;
              border-#{$ldirection}: 1px solid $color-black;
              span {
                color: $color-black;
                font-size: 14px;
              }
              .pr-helpful-voting {
                padding-top: 15px;
                .pr-helpful-btn {
                  @include swap_direction(padding, 0 5px);
                  background-color: $color-lightest-grey-2;
                  margin-#{$rdirection}: 15px;
                  border: 0;
                  border-radius: 2px;
                  &.pr-helpful-active {
                    background: $color-black !important;
                    .pr-thumbs-icon {
                      path {
                        fill: $color-white;
                      }
                    }
                    .pr-helpful-count {
                      color: $color-white;
                    }
                  }
                  .pr-thumbs-icon {
                    path {
                      fill: $color-darker-grey;
                    }
                  }
                }
              }
            }
            &-icon {
              display: none;
            }
            &-text {
              padding-top: 10px;
            }
          }
        }
      }
    }
    .pr-qa-display-thankyou-headline {
      @media screen and (min-width: $medium-m) {
        @include swap_direction(padding, 45px 25px 25px);
      }
      @include swap_direction(margin, 0 0 30px);
      @include swap_direction(padding, 25px 25px 25px);
      @include text-body-text--large;
      text-align: center;
      float: none;
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .table-cell {
        @include swap_direction(padding, 0 0 10px);
      }
      .pr-question-form {
        @include swap_direction(padding, 0 0 25px);
        #questionCategory {
          font-family: $helvetica-regular-font-family;
        }
        .form-group {
          span,
          .pr-helper-text {
            font-family: $helvetica-regular-font-family;
          }
          .pr-helper-text,
          .form-control {
            @include swap_direction(padding, 5px 0 10px);
          }
          .form-control {
            background: $color-white;
          }
          #questionCategory {
            .pr-btn {
              &.active {
                background-color: $color-black;
              }
            }
          }
        }
      }
    }
  }
}
