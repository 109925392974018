.eh-product {
  .product {
    color: gray;
    font-size: 13px;
    font-family: $helvetica-regular-font-family;
    line-height: 1.2;
    text-align: center;
    .product-image {
      text-align: center;
      margin-bottom: 20px;
      &.align-left {
        float: none;
      }
      img {
        display: block;
        height: auto;
        max-width: 100%;
        width: auto;
        margin-bottom: -22px;
        padding-top: 6px;
      }
      a {
        background: #add8e6;
        border-radius: 80px;
        display: inline-block;
        padding: 0;
        margin-bottom: 20px;
        width: 160px;
        height: 160px;
      }
    }
    .product-info {
      overflow: hidden;
      margin-bottom: 7px;
      min-height: 45px;
      float: none;
      margin: auto;
    }
    .product-title {
      color: $product-color-title;
      a {
        color: $color-btn-dark-gray;
        font-size: 14px;
        font-family: $wink-font-family-1;
        line-height: 18px;
        height: 52px;
        overflow: hidden;
        display: block;
      }
    }
    .product-price {
    }
    .reviews {
      color: $color-cl-green;
      font-size: 11px;
      overflow: hidden;
      padding: 7px 0;
      img {
        display: inline;
        float: #{$ldirection};
        height: auto;
        width: 55px;
      }
      span {
        display: inline;
        margin-#{$ldirection}: 5px;
      }
    }
    a.button {
      @include swap_direction(margin, 10px 0 0 0);
      background: transparent;
      border: 1px solid $color-lightest-grey;
      color: $color-btn-dark-gray;
      text-align: center;
      text-rendering: inherit;
      text-transform: capitalize;
      font-family: $wink-font-family-3;
      font-style: italic;
      width: 120px;
      height: 35px;
      line-height: 35px;
      padding: 0;
      font-size: 14px;
      &:hover {
        border: 1px solid $color-btn-dark-gray;
      }
    }
    @include breakpoint(767px) {
      .product-info {
        width: 100%;
      }
      .product-image a {
        width: 100%;
        height: 0;
        border-radius: 50%;
        padding-bottom: 100%;
      }
    }
  }
}
