/* Social info section on Account preferences page */
.registration_content {
  .social-info {
    .social-info__content {
      p {
        margin-bottom: 25px;
        margin-top: 10px;
      }
      input.form-submit {
        padding: 0 10px;
      }
      .fb-overlay-social-info {
        margin-bottom: 10px;
      }
      .tooltip {
        margin-top: 10px;
        .tooltiptext-over {
          font-family: $base-font-family;
          text-align: justify;
        }
      }
    }
  }
}

#registration.device-mobile {
  .registration_content {
    .photo-tip {
      .tooltiptext-over {
        color: $color-white;
        text-align: justify;
      }
    }
    .social-info {
      label {
        font-weight: normal;
        font-family: $base-font-family;
      }
      .tooltip {
        .tooltiptext-over {
          color: $color-white;
        }
      }
    }
  }
}
/* Order confirmation page */
.single,
.main_container {
  #confirm,
  #confirmation-page {
    .social-login {
      border-top: 1px solid $color-shade-grey;
      border-bottom: 1px solid $color-shade-grey;
      padding-bottom: 10px;
      @media #{$large-up} {
        padding-#{$ldirection}: 10px;
        border-bottom: none;
      }
      .social-login__container {
        text-align: #{$ldirection};
        margin-top: 20px;
        padding: 0;
      }
      .social-login__terms,
      .social-login__divider {
        display: none;
      }
      .social-login__title {
        display: block;
        font-size: 24px;
        color: $color-cl-soft-black;
      }
      .social-login__info {
        margin-bottom: 10px;
        display: block;
      }
      .social-login__email-opt-in {
        display: block;
        @media #{$medium-up} {
          display: flex;
        }
        input {
          vertical-align: bottom;
        }
        .social-login__opt-in-label {
          font-size: 14px;
          font-weight: normal;
          display: inline;
          @media #{$large-up} {
            line-height: 30px;
          }
        }
      }
    }
  }
}

.pg_wrapper .social-login__container .disclaimer {
  display: inline-flex;
}

body#index {
  #main.single {
    .panel {
      .edit {
        h2.social-login__divider {
          background-color: $color-white;
        }
      }
    }
  }
}

.social-login {
  form {
    label,
    .email-optin {
      font-family: $base-font-family;
      text-align: #{$ldirection};
      .social-login__opt-in-label {
        padding-top: 5px;
        @media #{$medium-up} {
          display: inline-flex;
        }
      }
    }
  }
  .social-login__terms {
    text-align: #{$ldirection};
    padding-#{$ldirection}: 0;
  }
  /* Tooltip */
  .tooltip {
    text-align: #{$ldirection};
    margin-top: 10px;
    .tooltiptext-over {
      text-align: justify;
    }
  }
}
/* Connected accounts */
.account-profile__connected-accounts {
  color: $color-dark-grey;
  padding: 5px 8px;
}
/* Appointment Page */
.appt-book {
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-form-container {
          .social-login {
            .social-login__opt-in-checkbox {
              width: auto;
              @media #{$medium-up} {
                display: inline;
                height: auto;
                vertical-align: middle;
              }
            }
            .social-login__opt-in-label {
              padding-top: 0;
              @media #{$medium-up} {
                margin-top: 0;
              }
            }
            .social-login__terms {
              margin-top: 10px;
            }
            .fb-disclaimer-container {
              margin-top: 20px;
            }
          }
          input {
            width: auto;
            margin-bottom: 0;
            display: inline;
            height: auto;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
/* Appointment Page */
@media #{$small-up} {
  .appointments-container {
    .social-login {
      .social-login__email-opt-in .social-login__opt-in-label {
        padding-#{$ldirection}: 4px;
      }
      .fb-disclaimer-container {
        margin-top: 20px;
      }
    }
    .disclaimer {
      margin-top: 10px;
    }
    .sign-in-container {
      .sign-in-form-container {
        .social-login .social-login__email-opt-in {
          .social-login__opt-in-label {
            margin-top: 0;
          }
        }
      }
    }
  }
  .social-login {
    .social-login__container {
      text-align: #{$ldirection};
    }
  }
}

#confirmation-page {
  .social-login {
    .social-login__terms {
      .tooltip {
        display: none;
      }
    }
  }
}
/* GNAV */
.signin_container .gnav .fb-disclaimer-container {
  text-align: #{$ldirection};
  margin-top: 10px;
}

.gnav-my-account-v1 .social-login.gnav .social-login__email-opt-in {
  margin-top: 20px;
}

#gnav_signin .signin_container form label {
  padding-bottom: 0;
  font-family: 'HelveticaNeueLTStd45Light', Arial, sans-serif;
}

#signin .signin_container form label {
  font-family: 'HelveticaNeueLTStd45Light', Arial, sans-serif;
}

@media #{$large-only} {
  .wrapper .gnav-my-account-v1 {
    overflow-y: auto;
    height: 450px;
  }
}
/* Account signin page */
@media #{$small-up} {
  .outer-wrap .social-login__container .disclaimer {
    text-align: #{$ldirection};
    margin-top: 20px;
  }
  .social-login .social-login__email-opt-in {
    display: block;
  }
  #signin {
    #signin-block {
      .social-login .social-login__email-opt-in label {
        font-size: 13px;
        display: inline;
        margin-#{$ldirection}: 0;
        color: $color-darkest-grey;
      }
    }
  }
}
/* Checkout */
@media #{$small-up} {
  #main.single {
    .sign-in-panel {
      .social-login {
        label:before,
        label:after {
          display: none;
        }
        .disclaimer {
          margin-top: 20px;
          display: inline-flex;
          margin-bottom: 15px;
        }
      }
      input {
        display: inline !important;
        width: auto;
        vertical-align: middle;
      }
      .social-login__email-opt-in {
        input {
          width: auto;
        }
        label {
          @media #{$small-only} {
            padding-top: 0;
          }
          display: inline-flex !important;
          vertical-align: top;
          padding-#{$ldirection}: 0;
        }
      }
    }
    .panel label {
      display: inline;
    }
    .select-address {
      .new-address-container {
        #manual-fields,
        #manual-address-fields {
          width: 100%;
        }
      }
    }
    .new-address {
      label {
        display: block;
      }
      #manual-fields {
        width: 90%;
      }
      #manual-address-fields {
        width: 90%;
        select {
          width: 60%;
        }
      }
    }
  }
  div#main.single section.panel label {
    padding: 0 4px;
    @media #{$medium-up} {
      margin-top: 5px;
    }
    color: $color-black;
  }
  body#index #main.single .panel.edit h2 {
    background-color: $color-white;
  }
}

.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  width: 164px;
  height: 22px;
  position: relative;
  display: inline-flex;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}
/* Index Page */
#sign-in-panel {
  .social-login {
    .social-login__container {
      padding: 0 15px;
    }
  }
}

div#main.single {
  section.panel {
    .social-login {
      label {
        font-size: 16px;
        @media #{$medium-up} {
          font-size: 14px;
        }
      }
      .tooltip,
      span {
        font-size: 14px;
      }
      #fb-field-disclaimer,
      &__opt-in-checkbox {
        margin-top: 4px;
        @media #{$medium-up} {
          margin-top: 0;
        }
      }
      &__email-opt-in {
        display: flex;
      }
      &__email-opt-in-label {
        padding: 0 4px;
      }
    }
  }
  #confirmation-page {
    .social-login {
      .tooltip,
      span,
      label {
        font-size: 14px;
      }
    }
  }
}
