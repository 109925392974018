.ingredients_container {
  list-style-type: none;
  margin: 20px 0;
  .ingredients {
    &__toggle {
      display: inline-block;
      height: 10px;
      width: 10px;
    }
    &__disclaimer {
      margin-bottom: 15px;
      margin-top: 18px;
    }
    &__less {
      background-image: url('/media/export/cms/images/minus_icon.png');
      margin-bottom: 4px;
    }
    &__more {
      background-image: url('/media/export/cms/images/product_details_open.png');
    }
    &__header {
      font-weight: normal;
      font-family: $helvetica-bold-font-family;
      cursor: pointer;
    }
  }
}

.device-mobile {
  .ingredients_container {
    list-style: none;
    margin: 20px 15px;
  }
  .ingredients {
    &__header {
      font-size: 15px;
      color: $color-cl-grey-5;
      font-family: $helvetica-bold-font-family;
      font-weight: bold;
    }
  }
  &__disclaimer {
    margin-top: 15px;
  }
}
