.gnav-search-v1 {
  text-align: #{$rdirection};
}

.gnav-search-v1 form {
  @include swap_direction(padding, 20px 0 0 0);
}

.gnav-search-v1 .form-type-textfield {
  background-color: $color-white;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.4) inset;
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -ms-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.gnav-search-v1 form div {
  width: 100%;
  text-align: #{$ldirection};
}

.gnav-search-v1 form input {
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
}

.gnav-search-v1 form .form-text {
  width: 100%;
  @include swap_direction(padding, 0 28px 0 10px);
  height: 35px;
  background-color: transparent;
  color: gray;
  font-size: 15px;
  font-family: $helvetica-light-font-family;
  line-height: 1.2;
  word-spacing: -0.3mm;
}

.ie8 .gnav-search-v1 form .form-text {
  padding-top: 10px;
}

.gnav-search-v1 form .form-text.active {
  color: #000;
}

.gnav-search-v1 form .form-submit {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  width: 27px;
  height: 35px;
  text-indent: 1000px;
  overflow: hidden;
  float: #{$rdirection};
  position: relative;
  z-index: 999;
}

.gnav-search-v1 .form-tablet {
  display: none;
  @include swap_direction(padding, 15px 0 0 0);
  position: relative;
}

.gnav-search-v1 .form-tablet .tablet-input {
  height: 71px;
  overflow: hidden;
  position: absolute;
  #{$rdirection}: 40px;
  top: -20px;
  width: 0;
  z-index: 9999;
}

.gnav-search-v1 .form-tablet .tablet-input .wrap {
  background: #f2f2f2;
  border-#{$ldirection}: 1px solid #ddd;
  height: 71px;
  @include swap_direction(padding, 20px 8px 10px 25px);
}

.gnav-search-v1 .form-tablet .tablet-input .form-text {
  background-color: $color-white;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.4) inset;
  display: none;
  padding: 12px 10px 6px;
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -ms-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.gnav-search-v1 .form-tablet.open .tablet-input .form-text {
  display: block;
}
/* Endeca predictive search custom styles */
.gnav-search-v1 #typeahead-wrapper {
  background: none repeat scroll 0 0 #f7f7f7;
  border-bottom: 1px solid #cccccc;
  border-#{$ldirection}: 1px solid #cccccc;
  border-#{$rdirection}: 1px solid #cccccc;
  position: absolute;
  padding: 0;
  text-align: #{$ldirection};
  z-index: 1000;
  width: 398px;
}

.gnav-search-v1 #typeahead-wrapper .result {
  border-top: 1px solid #cccccc;
  cursor: pointer;
  overflow: hidden;
  /* padding: 10px 30px; */
}

.gnav-search-v1 #typeahead-wrapper .result.last {
  border-bottom: 1px solid #cccccc;
}

.gnav-search-v1 #typeahead-wrapper .image {
  display: inline-block;
  @include swap_direction(margin, 0 15px 0 25px);
  width: 30px;
  vertical-align: middle;
}

.gnav-search-v1 #typeahead-wrapper .image img {
  display: block;
  height: auto;
  width: 30px;
}

.gnav-search-v1 #typeahead-wrapper .result a.link {
  display: inline-block;
  margin: 10px 0;
  @include swap_direction(padding, 0 30px 0 0);
  vertical-align: middle;
  color: #1f1f1f;
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
  white-space: normal;
  max-width: 250px;
}

.gnav-search-v1 #typeahead-wrapper .result:hover,
.gnav-search-v1 #typeahead-wrapper .result:hover a:hover {
  background: none repeat scroll 0 0 $color-white;
  text-decoration: none;
}

.gnav-search-v1 #typeahead-wrapper .result a span.term {
  display: inline;
  font-weight: bold;
  padding: 0;
}

.gnav-search-v1 #typeahead-wrapper .term-results:first-child .result.last {
  border-bottom: none;
}

.gnav-search-v1 #typeahead-wrapper .term-results:first-child a.link {
  font-family: $helvetica-bold-font-family;
  @include swap_direction(padding, 0 30px 0 25px);
  text-transform: uppercase;
}

.gnav-search-v1 #typeahead-wrapper .see-results .result {
  border: none;
  display: block;
}

.gnav-search-v1 #typeahead-wrapper .see-results .result a {
  display: inline-block;
  background: none repeat scroll 0 0 $color-cl-green;
  color: $color-white;
  font-family: $helvetica-bold-font-family;
  @include swap_direction(margin, 15px 30px 15px 25px);
  @include swap_direction(padding, 5px 15px 7px 15px);
  text-align: center;
  text-transform: uppercase;
  &:hover {
    background: none repeat scroll 0 0 $color-cl-green;
  }
}

.gnav-search-v1 #typeahead-wrapper .see-results .result a.link span.search-results {
  display: inline-block;
  vertical-align: middle;
}

.gnav-search-v1 #typeahead-wrapper .see-results .result a.link span.search-count {
  display: inline-block;
  vertical-align: middle;
}

@media screen and (min-width: 961px) {
  .gnav-search-v1 {
    min-width: 210px;
  }
}

@media screen and (max-width: 960px) {
  .gnav-search-v1 .form-type-textfield {
    display: none;
  }
  .gnav-search-v1 {
    .form-tablet {
      display: block;
    }
    #typeahead-wrapper {
      top: 71px;
      #{$rdirection}: 200px;
      width: 316px;
    }
  }
  .block-template-gnav-search-v1 {
    .clearfix {
      overflow: visible;
    }
  }
}

#typeahead-wrapper.hidden {
  display: none;
  visibility: hidden;
}

#typeahead-wrapper.typeahead-sticky {
  margin-top: 116px;
  #{$rdirection}: 0;
}
