.page-wrapper--offer__kit {
  margin: 0;
}

.offer__kit__formatter {
  @include swap_direction(margin, 0 auto 40px);
  max-width: 964px;
  min-width: 320px;
  overflow: hidden;
  padding-bottom: 5px;
}

.offer__kit__container {
  max-width: 1124px;
  margin: 0 auto;
  margin: 0;
}
/* steps */

.offer__kit__step {
  margin: 0;
  &.offer__kit__step--alt {
    margin: 0;
    .offer__kit__step__content,
    .offer__kit__image__container {
      margin: 0;
    }
  }
}

.offer__kit__container {
  .offer__kit__step.offer__kit__step--alt {
    .offer__kit__step__content,
    .offer__kit__image__container {
      margin: 0;
    }
  }
  .offer__kit__step__content {
    position: relative;
  }
  .offer__kit__image__container {
    position: relative;
    margin: 0;
    .no-longer-available-txt {
      display: block;
      font-size: 36px;
      position: relative;
      &.hidden {
        display: none;
      }
    }
  }
  .offer__kit__image {
    cursor: pointer;
    //height: 311px;
    height: auto;
    margin-bottom: 30px;
    max-width: 48%;
    width: auto;
    &.offer__kit__image--01 {
      float: #{$ldirection};
    }
    &.offer__kit__image--02 {
      float: #{$rdirection};
      margin-#{$rdirection}: 30px;
    }
  }
  .offer__kit__image__small {
    width: 48%;
  }
  .offer__kit__image--01,
  .offer__kit__image--02 {
    display: block;
  }
  .offer__kit__image__small--01,
  .offer__kit__image__small--02 {
    display: inline;
  }
  // CX-5551 - hide checkbox and enable submit button
  .offer__kit__option__container {
    .offer__kit__option {
      @include swap_direction(margin, 20px 0 0 0);
      //text-align: #{$ldirection}; // left align content
      text-align: center; // center align content
      //width: auto;
      label {
        cursor: default;
        font-family: $helvetica-light-font-family;
        margin-#{$ldirection}: 0;
      }
      input[type='submit'] {
        background-color: $color-black;
        border: none;
        color: $color-white;
        display: block;
        font-family: $helvetica-bold-font-family;
        font-size: 15px;
        //@include swap_direction(margin, 30px 0 0); // left align CTA
        @include swap_direction(margin, 30px auto 0); // center align CTA
        min-width: 150px; // center align CTA
        padding: 5px 15px;
        text-transform: uppercase;
        width: auto; // center align CTA
      }
      input[type='checkbox'] {
        display: none;
      }
      &.offer__kit__option--01,
      &.offer__kit__option--02 {
        margin-#{$ldirection}: 0;
      }
      &.offer__kit__option--02 {
        label,
        input[type='submit'] {
          //margin-#{$ldirection}: 7%; // left align label & CTA
        }
      }
      .regular-checkbox + label.styled-checkbox {
        border: none;
        display: none;
        &:checked + label::after {
          border: none;
          content: '';
        }
      }
    }
  }
}
/* Mobile small images */

.device-mobile {
  .offer__kit__container {
    .offer__kit__image__small {
      width: 95%;
    }
    .offer__kit__image__small__container {
      float: #{$ldirection};
      margin: 0 2.5%;
      width: 45%;
      .no-longer-available-txt {
        //display: block;
        font-size: 25px;
        //#{$ldirection}: 20px;
        position: relative;
        text-align: center;
      }
      .offer__kit__option {
        @include swap_direction(margin, 20px 0 0 0);
        text-align: center;
        width: auto;
        label {
          font-family: $helvetica-light-font-family;
          font-size: 18px;
        }
        input[type='submit'] {
          //background-color: $color-white;
          background-color: $color-black;
          border: none;
          color: $color-white;
          font-family: $helvetica-bold-font-family;
          font-size: 15px;
          //@include swap_direction(margin, 10px 0 0);
          @include swap_direction(margin, 10px auto 0);
          padding: 5px 15px;
          text-transform: uppercase;
          width: 90%;
        }
        input[type='checkbox'] {
          display: none;
        }
        .regular-checkbox + label.styled-checkbox {
          border: none;
          display: none;
          &:checked + label::after {
            border: none;
            content: '';
          }
        }
      }
    }
    .offer__kit__image__small--01__container,
    .offer__kit__image__small--02__container {
      float: #{$ldirection};
      margin: 0 2.5%;
      width: 45%;
    }
    .offer__kit__image__small--01,
    .offer__kit__image__small--02 {
      display: inline;
    }
    .offer__kit__image__small--01,
    .offer__kit__image__small--02 {
      margin-#{$ldirection}: 2%;
    }
  }
}

.offer__kit__option__container {
  .offer__kit__option {
    float: #{$ldirection};
    font-family: $base-thin-font-family;
    @include swap_direction(margin, 1% 0 0 14%);
    width: 50%;
    label,
    input[type='checkbox'] {
      margin: 0;
    }
    &.offer__kit__option--01 {
      //margin-#{$ldirection}: 5%;
    }
    &.offer__kit__option--02 {
      //margin-#{$ldirection}: 17%;
    }
    .prod-subheading {
      color: $color-sharp-black;
      font-size: 23px;
      line-height: 23px;
      overflow-y: auto;
      height: 150px;
    }
    .prod-name {
      color: $color-sharp-black;
      font-size: 21px;
      line-height: 25px;
      margin-#{$ldirection}: 45px;
      margin-top: 10px;
    }
  }
}
/* Hide checkboxes on mobile */

.device-mobile {
  .offer__kit__option__container {
    display: none;
    .offer__kit__option {
      @include swap_direction(margin, 20px 0 0 0);
      text-align: center;
      width: 50%;
      label,
      input[type='checkbox'],
      input[type='submit'] {
        font-size: 20px;
      }
      input[type='submit'] {
        margin: 0;
      }
      input[type='checkbox'] {
        display: none;
      }
      .regular-checkbox + label.styled-checkbox {
        border: none;
        display: none;
        &:checked + label::after {
          border: none;
          content: '';
        }
      }
    }
  }
}
/* option 2 */

.offer__kit__container .offer__kit__option__image__alt {
  margin: 0;
  .offer__kit__image--01,
  .offer__kit__image--02,
  .offer__kit__image__small--01,
  .offer__kit__image__small--02 {
    display: block;
  }
}

.offer__kit__header__container {
  .offer__kit__number,
  .offer__kit__header {
    float: none;
    font-family: $base-thin-font-family;
    text-align: center;
  }
  .offer__kit__number {
    font-size: 80px;
  }
  .offer__kit__header {
    font-size: 45px;
    margin-bottom: 30px;
  }
}
/* Mobile header */

.device-mobile {
  .offer__kit__header__container {
    .offer__kit__number {
      font-size: 50px;
    }
    .offer__kit__header {
      font-size: 33px;
    }
  }
}

.offer__kit__cta {
  margin: 0;
  .offer__kit__button__add,
  .offer__kit__button__oos {
    //background-color: $color-light-grey;
    //color: $color-light-white;
    //cursor: not-allowed;
    display: none;
    font-weight: bold;
    @include swap_direction(margin, 40px 0 0);
    padding: 10px 20px;
    text-transform: uppercase;
    width: auto;
  }
  .offer__kit__button__oos {
    background-color: $color-light-grey;
    color: $color-light-white;
    cursor: not-allowed;
    &.offer-1,
    &.offer-2 {
      display: none;
    }
    &.offer-1 {
      float: #{$ldirection};
      margin-#{$ldirection}: 10%;
    }
    &.offer-2 {
      float: #{$rdirection};
      margin-#{$rdirection}: 34%;
    }
  }
  &.offer__kit__is__loading {
    .offer__kit__loading {
      &.offer-1,
      &.offer-2 {
        display: block;
      }
      &.offer-1 {
        float: #{$ldirection};
        margin-#{$ldirection}: 10%;
      }
      &.offer-2 {
        float: #{$rdirection};
        margin-#{$rdirection}: 34%;
      }
    }
    .offer__kit__button__add {
      background-color: $color-light-grey;
      color: $color-light-white;
      cursor: not-allowed;
    }
    /* .offer__kit__button__oos {
      &.offer-1, &.offer-2 {
        display: block;
      }
      &.offer-1 {
        float: #{$ldirection};
        margin-#{$ldirection}: 10%;
      }
      &.offer-2 {
        float: #{$rdirection};
        margin-#{$rdirection}: 34%;
      }
    } */
  }
  &.offer__kit__loaded {
    &.offer__kit__is__loading {
      .offer__kit__loading {
        &.offer-1,
        &.offer-2 {
          display: block;
        }
      }
      .offer__kit__button__add {
        &.offer-1,
        &.offer-2 {
          display: none;
        }
      }
    }
    .offer__kit__loading {
      &.offer-1,
      &.offer-2 {
        display: none;
      }
    }
    .offer__kit__button__add {
      background-color: $color-black;
      color: $color-white;
      cursor: pointer;
      &.offer-1 {
        display: inline-block;
        float: #{$ldirection};
        margin-#{$ldirection}: 10%;
      }
      &.offer-2 {
        display: inline-block;
        float: #{$rdirection};
        margin-#{$rdirection}: 25%;
      }
    }
  }
}

.offer__kit__button__oos {
  width: auto;
}

.offer__kit__loading {
  background: url('/media/export/cms/479-01 Clinique_LoadingGif_R22.gif') no-repeat 0 0 $color-white;
  background-size: 35px;
  cursor: pointer;
  display: none;
  float: #{$ldirection};
  font-size: 12px;
  font-weight: bold;
  height: 35px;
  letter-spacing: 0.15em;
  line-height: 45px;
  margin-#{$ldirection}: 10%;
  margin-top: 40px;
  text-transform: uppercase;
  width: 35px;
  //.offer__kit__is__loading {
  //display: block;
  //}
}

.offer__kit__oos {
  display: none;
  font-size: 12px;
  text-transform: uppercase;
  color: red;
  .offer__kit__is__sold__out {
    display: block;
  }
}

.offer__kit__button__oos,
.offer__kit__products__data {
  display: none;
}
/* OOS mobile */

.device-mobile .offer__kit__container {
  position: relative;
  .offer__kit__cta {
    .offer__kit__button__oos {
      margin: 0;
    }
    &.offer__kit__is__loading {
      .offer__kit__loading {
        &.offer-1,
        &.offer-2 {
          display: block;
        }
        &.offer-1 {
          float: #{$ldirection};
          margin-#{$ldirection}: 10%;
        }
        &.offer-2 {
          float: #{$rdirection};
          margin-#{$rdirection}: 34%;
        }
      }
      .offer__kit__button__oos {
        background-color: $color-white;
        color: $color-light-grey;
        cursor: not-allowed;
        position: absolute;
        top: 160px;
      }
      .offer__kit__button__oos {
        &.offer-1,
        &.offer-2 {
          display: none;
        }
        &.offer-1 {
          float: #{$ldirection};
          margin-#{$ldirection}: 10%;
        }
        &.offer-2 {
          float: #{$rdirection};
          margin-#{$rdirection}: 34%;
        }
      }
    }
  }
}
/* success overlay */

.offer__kit__success__overlay {
  &#cboxOverlay {
    display: none;
  }
  #cboxContent {
    background: $color-white;
    border: 1px solid $color-white;
  }
}

.offer__kit__success__overlay__container {
  display: none;
}

.offer__kit__success__overlay__content {
  background: $color-white none repeat scroll 0 0;
  color: $color-black;
  padding-bottom: 50px;
  text-align: center;
}

.offer__kit__success__overlay__header {
  font-family: $base-thin-font-family;
  font-size: 60px;
  line-height: 30px;
  margin-bottom: 35px;
  margin-top: 50px;
  width: 100%;
}

.offer__kit__success__overlay__copy {
  margin: 15px auto;
  text-align: #{$ldirection};
  width: 90%;
  h2 {
    color: $color-black;
    font-size: 40px;
  }
}

.offer__kit__success__overlay__button__container {
  margin-top: 30px;
}
/* Success overlay mobile */

.device-mobile #foreground-node.offer__kit__success__overlay {
  .close-container {
    #{$rdirection}: 15px;
    top: 15px;
    position: absolute;
  }
  .offer__kit__success__overlay__copy {
    h2 {
      font-size: 30px;
    }
  }
}
/* Checkboxes */

.offer__kit__option {
  .regular-checkbox {
    display: none;
    + label.styled-checkbox {
      display: inline-block;
      position: relative;
      vertical-align: bottom;
      width: 21px;
      height: 21px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(204, 204, 204);
      border-image: initial;
    }
    &:checked + label::after {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(0, 0, 0);
      content: '✓';
      font-size: 22px;
      height: 21px;
      #{$ldirection}: -2px;
      line-height: 22px;
      position: absolute;
      top: -2px;
      width: 21px;
    }
  }
  label.for-styled-checkbox {
    display: inline;
    font-size: 25px;
    line-height: 12px;
    margin-#{$ldirection}: 20px;
    width: 89%;
  }
}

.offer__kit__details {
  margin-#{$ldirection}: 45px;
  margin-top: 10px;
  a {
    color: $color-black;
    font-family: $helvetica-bold-font-family;
  }
  .offer__kit__details__overlay {
    background: $color-white none repeat scroll 0 0;
    border: 1px solid $color-light-grey;
    display: none;
    height: 225px;
    padding: 20px;
    position: absolute;
    top: 175%;
    width: 35%;
    .prod-name {
      color: $color-sharp-black;
      font-size: 30px;
      line-height: 33px;
      margin: 0;
    }
  }
}

.offer__kit__option--01 .offer__kit__details .offer__kit__details__overlay {
  #{$ldirection}: 0;
}

.offer__kit__option--02 .offer__kit__details .offer__kit__details__overlay {
  #{$rdirection}: 0;
}
/* Tablets */

/* ----------- iPad mini, 1 and 2 ----------- */ /* Portrait */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .offer__kit__container {
    .offer__kit__image {
      height: 270px;
    }
  }
}
/* ----------- iPad 3 and 4 ----------- */ /* Portrait */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .offer__kit__container {
    .offer__kit__image {
      height: 270px;
    }
  }
}
