.eh-image-share {
  position: absolute;
  top: 0;
  z-index: 1001;
  margin: 0 auto;
  left: 0;
  right: 0;
  .share_link {
    position: absolute;
    border: medium none;
    top: 10px;
    right: 10px;
    height: auto !important;
    width: auto !important;
  }
  &.shrink {
    width: 35px !important;
    height: 35px !important;
  }
  .share {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 215, 204, 0.5);
    top: 0px;
    &.hideIcons {
      display: none;
      visibility: initial;
    }
    &.showIconsInDOM {
      display: block;
      visibility: hidden;
    }
    .share_close {
      float: #{$rdirection};
      width: 24px;
      @include swap_direction(margin, 10px 10px 0 0);
      border: medium none;
      height: 24px !important;
    }
    img {
      margin-top: 30px;
      border: medium none;
      height: auto;
      width: auto !important;
    }
    .share_social_links {
      position: relative;
      width: 30px;
      margin: 0 auto;
      a {
        display: block;
        margin-bottom: 13px;
        text-align: center;
        &.quote_links {
          height: 30px;
          width: 30px;
          line-height: 30px;
          > img {
            margin-top: 0;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.image .eh-image-share {
  .share .share_social_links a {
    margin-bottom: 20px;
  }
}

// IE specific fix bg trasparency

// IE specific fix bg trasparency
.ie8 .eh-image-share .share {
  background-color: rgb(0, 215, 204);
  /* IE 8 */
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
  /* IE 5-7 */
  filter: alpha(opacity=50);
}

@include breakpoint(767px) {
  .eh-image-share {
    left: 0;
    right: 0;
    margin: auto;
    .share {
      .share_social_links {
        left: 0;
        right: 0;
        margin: auto;
        a {
          width: 40px;
          height: 40px;
          display: block;
          line-height: 40px;
          margin-bottom: 20px;
          img {
            margin-top: 0;
          }
        }
      }
    }
  }
  .quote .eh-image-share {
    .share_social_links {
      height: 25px;
      vertical-align: top;
      width: auto;
      left: 0;
      position: absolute;
      right: 0;
      a {
        font-size: 25px;
        vertical-align: top;
        display: inline-block;
        img {
          margin-top: 0;
          vertical-align: text-top;
        }
      }
    }
  }
  .article_overlay .article-prev-content .eh-image-share {
    right: 0;
  }
}

@include breakpoint(375px) {
  .eh-image-share {
    .share {
      .share_social_links {
        a {
          margin-bottom: 10px;
        }
      }
    }
  }
}
