.eh-social-share {
  width: 274px;
  height: auto;
  position: fixed;
  top: 260px;
  #{$rdirection}: -1px;
  z-index: 9997;
  transition: all 0.5s ease-in 0s;
  background-color: white;
  &.expanded {
    display: block;
    border: 1px solid #c5c5c5;
  }
  &.closed {
    #{$rdirection}: -274px;
  }
  .eh-social-share-wrapper {
    position: relative;
    min-height: 232px;
    overflow: auto;
  }
  .article {
    overflow: hidden;
    border-#{$rdirection}: none;
    height: auto;
    float: #{$rdirection};
    padding: 26px 30px 25px 40px;
    background-color: white;
    font-family: $wink-font-family-1;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    #{$rdirection}: 58px;
  }
  .social_share_links {
    border-#{$ldirection}: none;
    border-#{$rdirection}: none;
    //padding: 30px 30px 30px 0;
    @include swap_direction(padding, 30px 30px 30px 0);
    width: 58px;
    float: #{$rdirection};
    background-color: white;
    position: absolute;
    #{$rdirection}: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 232px;
  }
  .share {
    border: 1px solid #c5c5c5;
    border-#{$rdirection}: none;
    position: absolute;
    text-align: center;
    top: 50px;
    #{$ldirection}: -75px;
    width: 75px;
    height: 30px;
    line-height: 30px;
    background-color: white;
    cursor: pointer;
    font-family: $wink-font-family-3 !important;
    font-style: italic;
    font-size: 15px;
  }
  .article {
    p {
      line-height: 25px;
      margin: 0;
      padding: 0;
      width: 144px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      max-width: 144px;
      height: 100%;
    }
    .artist_name {
      margin-bottom: 10px;
      width: 144px;
    }
    .artist-img-wrapper {
      height: 87px;
      overflow: hidden;
    }
  }
  .social_share_links {
    .social_icons {
      text-align: center;
      margin: 0;
      padding-top: 0;
      padding-bottom: 20px;
      a {
        display: block;
        height: 28px;
      }
      &.last-icon {
        padding-bottom: 0;
      }
    }
    .social_icons_imgs {
      margin: 0;
      max-width: 20px; //FE-1235
    }
  }
}

body.smooth-scroll {
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
}
/* Device Styles */

@include breakpoint(767px) {
  .eh-social-share {
    display: block;
    bottom: 0;
    #{$rdirection}: 0 !important;
    top: auto;
    height: auto;
    width: 100%;
    .eh-social-share-wrapper {
      position: static;
      min-height: 0;
    }
    .social_share_links {
      position: static;
      float: none;
      padding: 0;
      text-align: center;
      margin: 0;
      height: 100%;
      width: 100%;
      .social_icons {
        display: inline-block;
        padding: 12px 0;
        text-align: center;
        width: 19%;
      }
    }
    .article,
    .eh-social-share__tooltip-content {
      display: none;
    }
    &.line-icon {
      .social_share_links {
        .social_icons {
          &.last-icon {
            padding-top: 12px;
          }
        }
      }
    }
  }
  //Show Global footer when Social share icons displayed in Hub Pages
  .body_container.show-global-footer {
    margin-bottom: 40px;
  }
}

// Font can be accessed with these classes
.georgia-italic {
  font-family: $wink-font-family-3;
  font-style: 'italic';
}

.georgia-regular {
  font-family: $wink-font-family-3;
  font-style: 'normal';
}

.neuzeit-office-regular {
  font-family: $wink-font-family-1;
}

.neuzeit-office-bold {
  font-family: $wink-font-family-2;
}
