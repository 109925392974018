.pc_spp_view {
  .wrap.page-spp {
    margin: 0 auto;
    max-width: 1008px;
    padding: 0 24px;
    &.spp-extra {
      margin-bottom: 30px;
    }
  }
  .page-spp {
    &.related_content_item {
      margin-bottom: 40px;
      border-bottom: 1px solid #dbdbdb;
    }
    &.bv-widgets {
      clear: both;
      margin-bottom: 0;
      margin-top: 40px;
      background-color: #fff;
      background-image: linear-gradient(bottom, rgb(247, 247, 247) 18%, rgb(255, 255, 255) 59%);
      background-image: -o-linear-gradient(bottom, rgb(247, 247, 247) 18%, rgb(255, 255, 255) 59%);
      background-image: -moz-linear-gradient(bottom, rgb(247, 247, 247) 18%, rgb(255, 255, 255) 59%);
      background-image: -webkit-linear-gradient(bottom, rgb(247, 247, 247) 18%, rgb(255, 255, 255) 59%);
      background-image: -ms-linear-gradient(bottom, rgb(247, 247, 247) 18%, rgb(255, 255, 255) 59%);
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.18, rgb(247, 247, 247)),
        color-stop(0.59, rgb(255, 255, 255))
      );
      border-bottom: 1px solid #dbdbdb;
    }
  }
  .btn-secondary {
    background: #e8e9e8;
    text-transform: uppercase;
    color: gray;
    text-align: center;
    height: 24px;
    line-height: 27px;
    padding: 0 10px;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    letter-spacing: 1px;
    border: 0;
    &:hover {
      background: gray;
      color: #fff;
    }
  }
  .options.price2 {
    border-top: 1px solid #dbdbdb;
    padding-top: 13px;
    .strike-thru-price {
      color: #dbdbdb;
      display: inline;
      font-size: 18px;
      padding-top: 13px;
      text-decoration: line-through;
      & ~ .strike-thru-price {
        display: none; // only show the first one
      }
    }
    .price {
      border: none;
      color: $color-discount-price;
      display: inline;
      & ~ .price {
        display: none; // only show the first one
      }
    }
  }
  .options .price {
    font-size: 18px;
    font-family: $base-font-family;
    display: block;
    margin-bottom: 5px;
    & ~ .price {
      display: none; // only show the first one
    }
  }
  ul {
    &.read-reviews,
    &.social-share {
      padding: 0;
    }
  }
  [data-productid='PROD35510'] .module-spp-detail .sizes ul li a {
    background-image: none;
  }
  .node-spp-related-content-item-set {
    .field-item-set-type {
      display: none;
    }
  }
  .how_to_use {
    h3 {
      font-size: 36px;
      line-height: 38px;
      letter-spacing: -0.3mm;
    }
    ul {
      list-style: initial;
      padding: 0 15px;
      li {
        list-style-type: disc;
        font-size: 15px;
        font-family: $base-font-family;
        color: #000000;
        line-height: 1.2;
      }
    }
  }
  .sticky_content {
    .sbHolder {
      margin-top: 10px;
    }
    .selected_sku {
      display: inline-block;
      vertical-align: middle;
      &.sized {
        background: url('/media/export/images/ddml_pc.png') no-repeat -130px -280px;
        padding-#{$ldirection}: 25px;
        height: 25px;
        &.Tube {
          background-image: url('/media/export/images/tube_pc.png');
        }
        &.Fragrance {
          background-image: url('/media/export/images/fragrance_pc.png');
        }
        &.Square {
          background-image: url('/media/export/images/square_bottle_pc.png');
        }
        &.Tall {
          background-image: url('/media/export/images/tall_bottle_pc.png');
        }
        &.Jar {
          background-image: url('/media/export/images/jar_pc.png');
        }
      }
      img {
        vertical-align: middle;
        margin-#{$rdirection}: 5px;
      }
      span {
        display: block;
        text-align: center;
        width: 17px;
        height: 17px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased;
        color: #fff;
        font-size: 12px;
        font-family: $base-bold-font-family;
        border-radius: 50%;
        @include swap_direction(margin, 0 0 6px 0);
        float: #{$ldirection};
        &.type-1 {
          background: #90ccba;
        }
        &.type-2 {
          background: #dbadcc;
        }
        &.type-3 {
          background: #f69f9f;
        }
        &.type-4 {
          background: #94cfd7;
        }
      }
    }
    .dropdown {
      @include swap_direction(padding, 8px 30px 6px 15px);
      text-align: #{$ldirection};
      cursor: pointer;
      background: #efefef url('/media/export/images/dd-arrow.png') no-repeat right -123px;
      float: #{$ldirection};
      height: 40px;
      @include swap_direction(margin, 3px 3px 3px 0);
      max-width: 220px;
    }
    .dropdown.active {
      background-position: right 12px;
    }
    .dropdown-menu {
      background: #efefef;
      padding: 10px 15px;
      text-align: #{$ldirection};
      position: absolute;
      top: 42px;
      z-index: 9001;
      border: none;
      border-width: 0;
      h4 {
        color: #000000;
        font-size: 15px;
        padding: 10px 0;
      }
      &.sizes {
        ul {
          @include swap_direction(margin, 0 0 10px 0);
          padding: 0;
          li {
            display: inline-block;
            margin: 0;
            float: #{$ldirection};
            a {
              display: block;
              width: 72px;
              height: 72px;
              /* IE8 hack */
              background: #ffffff url('/media/export/images/ddml_pc.png') no-repeat 0 -155px;
              border: 1px solid #dbdbdb;
              border-radius: 3px;
              color: gray;
              font-size: 12px;
              padding: 30px 4px 4px;
              line-height: 12px;
              @include swap_direction(margin, 0 5px 5px 0);
              &.Tube {
                background: #ffffff url('/media/export/images/tube_pc.png') no-repeat 0 -155px;
              }
              &.Fragrance {
                background: #ffffff url('/media/export/images/fragrance_pc.png') no-repeat 0 -155px;
              }
              &.Square {
                background: #ffffff url('/media/export/images/square_bottle_pc.png') no-repeat 0 -155px;
              }
              &.Tall {
                background: #ffffff url('/media/export/images/tall_bottle_pc.png') no-repeat 0 -155px;
              }
              &.Jar {
                background: #ffffff url('/media/export/images/jar_pc.png') no-repeat 0 -155px;
              }
              &.active {
                background-position: 0 -5px;
                border: 2px solid #000;
                color: #000;
              }
              &:hover {
                text-decoration: none;
                color: $color-cl-green-2;
              }
            }
          }
        }
      }
    }
    .spp_product_status {
      clear: both;
      text-align: center;
      margin-left: 60px;
      .temp_out_of_stock_msg,
      .coming_soon_stock_msg,
      .inactive_stock_msg,
      .sold_out_stock_msg,
      .pre_order_active_msg,
      .pre_order_sold_msg {
        @include swap_direction(padding, 10px);
      }
    }
    .spp_product_status_msg {
      float: #{$ldirection};
      .coming_soon_stock_msg {
        margin: 11px 10px;
      }
      .inactive_stock_msg,
      .sold_out_stock_msg,
      .pre_order_active_msg,
      .pre_order_sold_msg {
        margin: 16px 10px;
      }
    }
  }
  .bv_hide {
    display: none;
  }
  .module-spp-detail .col2.bvflow_show {
    h2 {
      font-size: 24px;
      line-height: 1.5em;
      color: $color-cl-green-2;
    }
    h3 {
      color: #000000;
    }
    .bv_email {
      background: white;
      border: 1px solid #dbdbdb;
      vertical-align: middle;
      color: #111111;
      line-height: 14px;
      padding: 0px 13px;
    }
    .btn-bv {
      height: 37px;
      line-height: 41px;
      font-size: 17px;
      font-size: $base-bold-font-family;
      padding: 0 15px;
      background: #5cba98;
      text-transform: uppercase;
      color: white;
      text-align: center;
      display: inline-block;
      letter-spacing: 1px;
      border: 0;
      font-family: $base-bold-font-family;
      cursor: pointer;
    }
    .row {
      margin: 10px 0;
      span {
        color: #ff3b89;
      }
    }
  }
}

.device-pc {
  .spp_page_wrap {
    margin: 0 auto;
    max-width: 1008px;
    padding: 0px;
    .page-spp {
      .breadcrumbs {
        @include swap_direction(margin, 0 0 30px 0);
        @include swap_direction(padding, 15px 0 0 0);
        li {
          display: inline-block;
          vertical-align: middle;
          @include swap_direction(margin, 0 1px 0 0);
          padding: 0;
          color: black;
          @include swap_direction(padding, 0 5px 0 0);
          a {
            @include cl-standard-link($color-text-black, false, $color-cl-green-2-darker);
            @include swap_direction(padding, 0 5px 0 0);
          }
        }
      }
    }
    .module-spp-detail {
      @include swap_direction(margin, 0 30px 0 0);
      padding: 0 0 42px; // TODO: related value (M9ohX3); to keep 65px separation from SPPs' descriptions and the modules it has, this value has to be calculated as the difference between 65 and this value's counterpart
      .icon-message {
        a:hover {
          text-decoration: none;
        }
      }
      &.clinique-product-spp-v1 {
        .container {
          .col1.product-full__image {
            .badge {
              &.badge--medium,
              &.badge--large,
              &.badge--small {
                top: 0px;
                #{$ldirection}: 0px;
              }
            }
          }
        }
      }
      .container {
        &:before {
          content: ' ';
          display: table;
        }
        &:after {
          content: ' ';
          display: table;
          clear: both;
        }
        .col1 {
          float: #{$ldirection};
          margin: 0 2% 0 0;
          width: 42%;
          position: relative;
          .product-img {
            /* display: block; */
            height: auto;
            margin: 0 auto;
            width: 402px;
            height: 464px;
          }
          .spp-share-container {
            margin-top: -40px;
            .alternate-views {
              color: #808080;
              font-size: 10px;
              line-height: 10px;
              margin: 0 0 10px;
              text-transform: uppercase;
              a {
                color: #000000;
              }
              i {
                display: block;
                font-size: 16px;
                line-height: 22px;
                margin: 0 0 2px;
              }
            }
            .read-reviews {
              display: inline-block;
              margin-#{$ldirection}: 5px;
              vertical-align: middle;
              li {
                display: inline-block;
                @include swap_direction(margin, 0 1px 0 0);
                padding: 0;
                vertical-align: middle;
                a {
                  color: #000000;
                  @include swap_direction(padding, 0 5px 0 0);
                  &:hover {
                    color: $color-cl-green-2;
                  }
                }
              }
            }
            .social-share {
              display: inline-block;
              margin-#{$ldirection}: 0;
              vertical-align: middle;
              li {
                display: inline-block;
                font-size: 23px;
                margin: 0;
                vertical-align: middle;
                a {
                  color: #bfbfbf;
                  @include swap_direction(padding, 0 5px 0 0);
                  &:hover {
                    color: #000000;
                  }
                }
              }
            }
          }
          .badge {
            position: absolute;
            top: 50px;
            #{$ldirection}: 50px;
            display: table;
            width: 65px;
            height: 65px;
            text-align: center;
            @include border-radius(50%);
            color: #fff;
            z-index: 1;
            padding: 0;
            line-height: 14px;
            font-family: $base-font-family;
            vertical-align: middle;
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            &.badge_1,
            &.badge_2,
            &.badge_3,
            &.badge_4,
            &.badge_5,
            &.badge_17,
            &.badge_92,
            &.badge_94,
            &.badge_96,
            &.badge_97 {
              background: #fe6cb5;
            }
            &.badge_96 {
              font-size: 12px;
            }
            &.badge_30 {
              background: $color-cl-green-2;
            }
            &.badge_101 {
              background: $color-cl-purple-2;
            }
            html.ie7 & {
              text-align: center;
            }
            .badge-inner {
              display: table-cell;
              vertical-align: middle;
              text-align: center;
              html.ie7 & {
                position: relative;
                margin-top: expression(
                  offsetHeight < parentNode.offsetHeight ? parseInt((parentNode.offsetHeight - offsetHeight) / 2) + 'px':
                    '0'
                );
                clear: 'none';
              }
            }
            &.badge--medium,
            &.badge--large,
            &.badge--small {
              top: 0px;
              #{$ldirection}: 0px;
            }
          }
        }
        .col2 {
          color: #000000;
          float: #{$ldirection};
          width: 56%;
          font-size: 15px;
          font-family: $base-font-family;
          .shades .shade-dd .col1,
          .sbHolder .sbSelector {
            font-size: inherit;
            font-family: inherit;
          }
          h1 {
            font-size: 36px;
            line-height: 1;
            margin: 0 0 15px;
            letter-spacing: 0;
          }
          h2 {
            font-size: 15px;
            line-height: 17px;
            margin: 0;
            strong {
              font-family: $base-bold-font-family;
              font-weight: normal;
            }
          }
          p {
            line-height: 19px;
            margin: 0 0 10px;
          }
          .abstract {
            margin: 10px 0;
            p {
              display: inline;
            }
          }
          .support {
            margin: 25px 0 0;
            font-size: 15px;
            font-family: $base-font-family;
            #lpbuttonproduct,
            #lpbuttonvideo {
              display: inline-block;
              cursor: pointer;
              img {
                vertical-align: middle; // TODO: candidate for globalization (alignment styles for all buttons - even those generated by 3rd parties)
              }
            }
            p {
              margin-#{$rdirection}: 10px;
            }
            .btn-secondary {
              background: none repeat scroll 0 0 #808080;
              color: #ffffff;
              @include swap_direction(margin, 15px 0 0 0);
              &:hover {
                // TODO: candidate for globalization (button styles)
                background-color: darken(#808080, 15%);
              }
            }
          }
          .product-subline,
          .product-subheading {
            padding-bottom: 15px;
            color: $color-cl-green;
            font-weight: 400;
            font-size: 1.17em;
          }
        }
      }
    }
  }
}

.spp-mobile--green_copy {
  padding: 15px 15px 0 15px;
  h2 {
    font-size: 16px;
    line-height: 14px;
    margin: 0px;
  }
}

.spp-mobile--atff_module {
  .outer-wrap {
    min-height: 200px;
    position: relative;
    background-size: 1000px;
    background-repeat: no-repeat;
    background-position: 60%;
    max-width: 550px;
    @include breakpoint(414px) {
      background-position: 55%;
    }
    @include breakpoint(360px) {
      background-position: 50%;
    }
    .top {
      h2 {
        font-size: 20px;
        line-height: 18px;
      }
      position: relative;
      float: right;
      margin-top: 10%;
      @include breakpoint(414px) {
        margin-left: 110px;
        position: absolute;
        float: none;
      }
      .cta {
        display: none;
      }
    }
  }
}

.spp-pc--atff_module {
  .outer-wrap {
    min-height: 400px;
    background-position: center;
    position: relative;
    .top {
      margin-top: 80px;
      margin-left: 430px;
      position: absolute;
      .subheading {
        h2 {
          font-size: 26px;
          line-height: 26px;
        }
      }
    }
  }
}

.product-social {
  text-align: right;
  font-size: 20px;
  position: relative;
  top: -20px;
  float: right;
  i {
    color: $color-btn-gray;
  }
}
