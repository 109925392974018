.supercat-columns-v1 {
  overflow: hidden;
  @include swap_direction(padding, 45px 0 42px 0);
}

.supercat-columns-v1 .column {
  border-#{$ldirection}: 1px solid #bdbdbd;
  float: #{$ldirection};
  min-height: 205px;
  padding: 0 0 0 3%;
}

.supercat-columns-v1 .column:first-child {
  border: none;
  padding-#{$ldirection}: 0;
}
