.block.block-template-site-logo-responsive-v1,
.block.block-template-site-logo-responsive-v1 div {
  height: 100%;
}

.site-logo-responsive-v1 a {
  display: block;
  height: $responsive-logo-height;
  line-height: $responsive-logo-height;
  padding-#{$rdirection}: 30px;
}

.site-logo-responsive-v1 a img {
  margin: auto;
  max-width: 160px;
  vertical-align: middle;
  width: auto;
}
/* mobile */
@media screen and (max-width: 767px) {
  #top #header .site-logo-responsive-v1 a img {
    @include swap_direction(margin, -20px 0 0);
    padding: 0;
  }
}

.device-mobile .site-logo-responsive-v1 a {
  height: $responsive-logo-height-mobile;
  line-height: $responsive-logo-height-mobile;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1180px) {
  .site-logo-responsive-v1 a {
    @include swap_direction(padding, 0 20px);
  }
}
