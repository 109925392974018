/**
 * @file base/_typography.scss
 *
 * \brief Fonts, line-heights and re-usable text styles
 *
 * Almost all styles pulled directly from the styleguide PDF
 * A note on pt vs px: http://kyleschaeffer.com/development/css-font-size-em-vs-px-vs-pt-vs/
 * We use a mixin to handle converting these styleguides to web-usable measurements, global/_mixins.scss
 */

/*
 * Global typography
 */

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  font-family: $helvetica-regular-font-family;
  font-size: 13px;
  color: #222222;
  background: white;
}

p {
  @include swap_direction(margin, 0 0 25px 0);
  line-height: 1.3;
}

a {
  @include cl-standard-link;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  transition: color 0.2s ease;
  outline: none;
  &.button {
    @include a-button-base;
    &.button-secondary {
      @include a-button-secondary;
    }
    &.pink {
      background-color: $color-btn-pink;
    }
    &.green {
      background-color: $color-btn-green;
      @if $cr19 == true {
        background-color: $cr19-bg-black;
        color: $cr19-text-white;
      }
    }
    &.purple {
      background-color: $color-btn-purple;
    }
    &.orange {
      background-color: $color-btn-orange;
    }
    &:hover,
    &.hover,
    &.disabled {
      text-decoration: none;
      background: $color-btn-darker-green;
      @if $cr19 == true {
        background-color: $cr19-bg-med;
        color: $cr19-text-white;
      }
    }
    &.cancel {
      background-color: $color-white;
      border: 1px solid $color-btn-green;
      color: $color-btn-green;
      @if $cr19 == true {
        background-color: $color-white;
        border-color: $cr19-border-light;
        color: $cr19-text;
      }
    }
  }
}

input.form-submit,
input.button[type='submit'] {
  @include a-button; // @todo clean this up someday :(
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-family: $helvetica-light-font-family;
  color: $color-text-black;
  font-weight: normal;
  margin: 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  color: $color-cl-green;
  @if $cr19 == true {
    color: $cr19-text-black;
  }
  a {
    color: $color-text-black;
  }
}

h1,
.h1 {
  @include swap_direction(margin, 0 0 30px 0);
  color: $color-cl-green-2;
  font-size: 40px;
  line-height: 45px;
  @if $cr19 == true {
    color: $cr19-text-black;
  }
}

h2,
.h2 {
  font-size: 36px;
  line-height: 38px;
  @include swap_direction(margin, 0 0 20px 0);
}
