$ab-small-down: 767px;
$ab-ipad-portrait: 768px;
$ab-mobile-landscape: 480px;
$ab-ipad-landscape: 1024px;
$ab-mobile: 320px;
$ab-ipad-portrait: 768px;
$ab-max-width: 960px;
$ab-large-up: min-width $ab-small-down;
$color-light-purple: #9e6f9c;
$color-light-purple-alt: #deccde;
$color-light-purple-bg: #f5e8f5;
$color-light-purple-border: #d8bbd8;
$color-btn-gray-disabled: #eecfcf;
$color-btn-gray-disabled-alt: #bdbdbd;
$base-thin-font-family: 'HelveticaNeueLTStd-UltLt', Arial, Sans-Serif !default;
$color-light-black: #e8e7e7;
$ab--color--gray--lighter: #c7c7c7;
$ab--color--gray--black: #1a1a1a;
$ab--color--white: #ffffff;

// Appointment duration & price container on Review Page size adjust
$appt_booking_duration_price_container_adjust: false !default;

@mixin appt_booking {
  // Collection formatter - main container
  @include breakpoint($ab-small-down) {
    abbr,
    abbr[title] {
      border: none;
      cursor: default;
    }
    .section-cancel-appointment .appt-book-page-header {
      display: none;
    }
  }
  .appt-book {
    position: relative;
    padding: 0;
    margin: 0 auto 16px;
    text-align: center;
    letter-spacing: 0;

    //Back To Link On Mobile
    @include breakpoint($ab-small-down) {
      a.back-to-link,
      a.back-to-link:hover {
        display: none;
        width: auto;
        background: url('/media/export/cms/appointment_booking/common/back_to_link_arrow.gif')
          no-repeat 0 3px;
        font-family: $helvetica-light-font-family;
        font-size: 12px;
        color: $color-light-purple;
        text-decoration: none;
        border: none;
        margin: 5px 22px;
        padding-left: 16px;
      }
    }
    &#appointment-book-container {
      #appointment-book-sections {
        .appointments-container {
          .service {
            @media #{$medium-up} {
              min-height: inherit;
            }
          }
        }
      }
    }
    .appt-book-sections-container {
      position: relative;
      max-width: $ab-max-width;
      margin: 0 auto;
      @include breakpoint($ab-small-down) {
        padding: 0 20px;
        text-align: left;
      }
    }
    &.services .appt-book-sections-container,
    &.appointments .appt-book-sections-container {
      @include breakpoint($ab-small-down) {
        padding: 0;
      }
    }
    .appt-book-section {
      position: relative;
      width: 100%;
      .appt-book-section-content {
        position: relative;
        width: 100%;
        margin-bottom: 100px;
        @include breakpoint($ab-small-down) {
          margin-bottom: 20px;
          padding-top: 3px;
        }
      }
    }
    .basic-responsive {
      position: relative;
      display: block;
      font-size: 17px;
      letter-spacing: -0.015em;
      font-family: $helvetica-light-font-family;
      @include breakpoint($ab-small-down) {
        font-size: 15px;
      }
      a {
        margin-top: 2px;
        color: $color-black;
        font-family: $base-pro-roman-font-family;
        border-bottom: 1px solid $color-shade-dark-grey;
        @include breakpoint($ab-small-down) {
          margin-top: 0;
        }
      }
      p {
        font-family: $base-pro-roman-font-family;
      }
    }
    p {
      margin: 18px auto 11px;
      letter-spacing: -0.015em;
      @include breakpoint($ab-small-down) {
        margin-top: 11px;
      }
    }
    .appt-book-link {
      font-family: $helvetica-bold-font-family;
      padding-bottom: 5px;
      text-decoration: none;
      border-bottom: 1px solid $color-light-purple;
      font-size: 14px;
      letter-spacing: 0.23em;
      @include breakpoint($ab-small-down) {
        font-size: 12px;
        padding-bottom: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .button.disabled {
      background-color: $color-btn-gray-disabled;
      cursor: default;
    }

    // Appointment Booking page header
    .appt-book-page-header {
      position: relative;
      color: $color-black;
      text-decoration: none;
      display: block;
      margin: 0 auto;
      @include breakpoint($ab-small-down) {
        margin-bottom: 16px;
      }
      .appt-book-page-header-bg {
        display: block;
        width: 100%;
        height: auto;
      }
      .appt-book-page-header-bg.bg-img-mobile {
        display: none;
      }
      // Don't show the pc background image on mobile
      @include breakpoint($ab-small-down) {
        .appt-book-page-header-bg.bg-img-pc {
          display: none;
        }
        .appt-book-page-header-bg.bg-img-mobile {
          display: inline;
        }
      }
      .appt-book-page-header-content {
        position: absolute;
        text-align: center;
        top: 9%;
        z-index: 1;
        width: 100%;
        @include breakpoint($ab-small-down) {
          position: relative;
          top: 9%;
          height: 100%;
          line-height: 100%;
          width: 100%;
          left: 17px;
          .appt-book-page-header-content__subhead {
            width: 53%;
          }
          p {
            font-size: 15px;
          }
        }
        .inner {
          text-align: left;
          position: relative;
          max-width: $ab-max-width;
          margin: 0 auto;
          @include breakpoint($ab-ipad-portrait) {
            padding-left: 15px;
          }
          @include breakpoint($ab-small-down) {
            padding-left: 2px;
            text-align: left;
          }
          h2 {
            @extend .title--xl !optional;
            font-size: 70px;
            line-height: 72px;
            font-family: $base-pro-bolder-font-family;
          }
          .appt-book-page-header-content__subhead {
            h4,
            p {
              @extend .title--small !optional;
              font-size: 28px;
              line-height: 32px;
              font-family: $base-pro-roman-font-family;
              @include breakpoint($ab-small-down) {
                font-size: 17px;
                line-height: 1.33333;
              }
            }
          }
        }
        h2,
        h3,
        h4 {
          text-align: left;
          margin-bottom: 0;
          font-family: $base-pro-roman-font-family;
          b,
          strong {
            font-family: $base-pro-bolder-font-family;
          }
        }
        h2 {
          font-family: $base-pro-bolder-font-family;
          @include breakpoint($ab-ipad-landscape) {
            font-size: 45px;
          }
          @include breakpoint($ab-ipad-portrait) {
            font-size: 30px;
          }
          @include breakpoint($ab-small-down) {
            font-size: 21px;
            line-height: 1.05em;
            letter-spacing: 0.075em;
            text-align: left;
            font-family: $helvetica-light-font-family;
          }
        }
        h3 {
          font-size: 27px;
          @include breakpoint($ab-small-down) {
            font-size: 34px;
            line-height: 0.9;
            color: $color-black;
            letter-spacing: -0.075em;
            margin: 6px 0 10px;
          }
        }
        h4,
        p {
          color: $color-black;
          margin-#{$ldirection}: 4px;
          padding-top: 10px;
          @include breakpoint($ab-ipad-portrait) {
            font-size: 14px;
          }
          @include breakpoint($ab-small-down) {
            font-size: 16px;
            line-height: 18px;
            text-align: #{$ldirection};
            padding-top: 12px;
          }
        }
        .multi {
          white-space: nowrap;
        }
        .multi--mobile-inline {
          display: inline-block;
          @include breakpoint($ab-small-down) {
            display: block;
          }
        }
        .appt-book-page-header-content__dots {
          color: $color-light-purple;
          font-size: 14px;
          list-style-type: none;
          margin-left: 4px;
          padding-bottom: 6px;
          text-align: left;
          text-transform: uppercase;
          @include breakpoint($ab-small-down) {
            margin-left: 0;
            padding-bottom: 4px;
          }
          li {
            color: #d7c8d6;
            display: inline-block;
            font-size: 14px;
            margin: 0;
            &.active {
              color: $color-light-purple;
              @include breakpoint($ab-small-down) {
                font-size: 14px;
              }
            }
            &:first-child {
              margin-left: 0;
            }
            @include breakpoint($ab-ipad-landscape) {
              font-size: 25px;
            }
            @include breakpoint($ab-ipad-portrait) {
              font-size: 15px;
            }
            @include breakpoint($ab-small-down) {
              font-size: 27px;
            }
          }
          li.disabled {
            display: none;
          }
        }
        .appt-book-page-header-content__title,
        .inner {
          h2 {
            font-weight: bold;
            font-size: 70px;
            line-height: 1.02857;
            @include breakpoint($ab-small-down) {
              font-weight: bold;
              font-size: 26px;
            }
          }
          h4 {
            font-size: 28px;
            line-height: 32px;
            font-family: $base-pro-roman-font-family;
            @include breakpoint($ab-small-down) {
              font-size: 17px;
              line-height: 1.33333;
            }
          }
        }
      }
    }
    .appt-book-content-header {
      margin: 20px;
      p {
        @include breakpoint($ab-small-down) {
          font-size: 18px;
        }
      }
      .section-header {
        margin-top: 3px;
        margin-bottom: 3px;
        @include breakpoint($ab-small-down) {
          width: 100%;
          font-size: 20px;
          font-family: $helvetica-regular-font-family;
          text-align: center;
        }
        .section-header__inner {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .book-now-bar {
        padding-top: 17px;
        @include breakpoint($ab-small-down) {
          display: none;
        }
      }

      @include breakpoint($ab-large-up) {
        margin: 20px auto;
      }
    }
    .book-now-bar {
      @include clearfix;
      letter-spacing: 0.23em;
      font-family: $base-pro-bolder-font-family;
      font-size: 17px;
      line-height: 24px;
      max-width: 400px;
      margin: 0 auto;
      .minutes {
        border: 1px solid $color-light-black;
        border-right: none;
        width: 50%;
        padding: 10px 10px;
        float: left;
        display: inline-block;
        text-align: center;
        color: $color-black;
        letter-spacing: 0;
        font-family: $base-pro-bolder-font-family;
        font-size: 17px;
        line-height: 1.44;
      }
      .button {
        width: 50%;
        float: #{$ldirection};
        display: inline-block;
        padding: 10px;
        color: $color-white;
        letter-spacing: -0.05em;
        border: 1px solid $color-black;
        background-color: $color-black;
        font-family: $base-pro-bolder-font-family;
        font-size: 17px;
        text-transform: none;
        cursor: pointer;
        &.disabled {
          background-color: $color-black;
          color: $color-white;
          font-size: 17px;
          line-height: 1.44;
          font-family: $base-pro-bolder-font-family;
          text-transform: none;
        }
      }
      @include breakpoint($ab-small-down) {
        width: 95%;
        font-size: 17px;
        font-family: $base-pro-bolder-font-family;
        max-width: none;
        .minutes {
          width: 45.5%;
          height: 52px;
          line-height: 45px;
          font-size: 17px;
          font-family: $base-pro-bolder-font-family;
          background-position: 7px center;
          padding: 3px 0;
          &.small {
            font-size: 16px;
          }
        }
        .button {
          width: 54.5%;
          height: 52px;
          padding: 12px 0 !important;
          font-size: 17px;
          letter-spacing: -0.02em;
          text-align: center;
          font-family: $base-pro-bolder-font-family;
          .sub-text {
            font-size: 11px;
            font-family: $helvetica-light-font-family;
            font-style: italic;
            text-transform: none;
            letter-spacing: 0.03em;
          }
        }
      }
    }
    .appt-book-location-selector {
      position: relative;
      text-align: center;
      font-size: 18px;
      padding: 44px 0 3px;
      @include breakpoint($ab-small-down) {
        padding: 0 0 4px 0;
        font-size: 15px;
      }
      .book__virtual__appt__btn {
        @if $virtual-appointment {
          @media #{$medium-up} {
            width: auto;
            padding: 11px 25px 14px;
            letter-spacing: -0.015em;
            margin: 30px 0 10px;
          }
          height: auto;
          font-family: $base-pro-bolder-font-family;
          color: $color-white;
          margin-top: 7px;
          letter-spacing: -0.02em;
          width: 100%;
          padding: 12px 0;
          background-color: $color-black;
          border: 1px solid $color-black;
          &.disabled {
            background-color: $color-btn-gray-disabled;
            &:hover {
              background-color: $color-btn-gray-disabled;
            }
          }
          &:hover {
            background-color: $color-black;
          }
          &:focus {
            outline-color: $color-black;
            outline-style: none;
          }
        }
      }
      .appt-book-location-selector-copy {
        text-align: left;
        display: block;
        letter-spacing: -0.015em;
        font-family: $base-pro-roman-font-family;
        @include breakpoint($ab-small-down) {
          text-align: left;
          font-family: $base-pro-roman-font-family;
        }
        p {
          font-family: $base-pro-bolder-font-family;
          text-align: center;
        }
      }
      .form-container {
        position: relative;
        display: inline-block;
        overflow: hidden;
        margin-top: 10px;
        @include breakpoint($ab-small-down) {
          width: 100%;
          margin-top: 13px;
        }
        p {
          line-height: 32px;
          font-size: 28px;
          font-family: $base-pro-bolder-font-family;
        }
        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
          background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 8 4' width='8' height='8' xmlns='http://www.w3.org/2000/svg'><g><path d='M0 0l4 4 4-4H0z'></path> /></g></svg>")
            no-repeat;
          background-position: 95% center;
          background-repeat: no-repeat;
          outline: none;
          padding-right: 30px;
          border: 1px solid $color-light-black;
        }
      }
      .state-select,
      .location-select,
      .location-submit {
        display: block;
        width: 277px;
        font-family: $base-pro-bolder-font-family;
        text-transform: none;
        border: 1px solid $color-light-black;
        font-weight: bold;
      }
      .state-select,
      .location-select {
        color: $color-black;
        letter-spacing: -0.015em;
        padding: 9px;
        @include breakpoint($ab-small-down) {
          width: 100%;
          margin-bottom: 14px;
        }
      }
      .state-select {
        margin-bottom: 20px;
      }
      .location-submit {
        height: auto;
        line-height: 1;
        padding: 12px 11px 14px;
        margin-top: 20px;
        letter-spacing: -0.015em;
        font-size: 17px;
        background-color: $color-black;
        @include breakpoint($ab-small-down) {
          width: 100%;
          margin-left: 0;
          font-size: 15px;
          margin-top: 13px;
          letter-spacing: -0.02em;
          padding-top: 13px;
        }
        &.disabled {
          background-color: $color-btn-gray-disabled-alt;
          &:hover {
            background-color: $color-btn-gray-disabled-alt;
          }
        }
        &:hover {
          background-color: $color-black;
        }
      }
      @include breakpoint($ab-small-down) {
        .appt-book-location__signin-text {
          text-align: #{$ldirection};
        }
      }
    }
    .location-select-section {
    }
    &.services .appt-book-page-header {
      @include breakpoint($ab-small-down) {
        margin-bottom: 0;
      }
    }
    .service-select {
      width: auto;
      margin: 0 auto 23px;
      overflow: hidden;
      @include breakpoint($ab-small-down) {
        margin-bottom: -16px;
        &.appt-book-section-content {
          padding-top: 0;
          margin-bottom: 0;
        }
      }
      .section-header__inner {
        @include breakpoint($ab-small-down) {
          font-size: 16px;
        }
      }
      .services-container {
        width: 100%;
        max-width: $ab-max-width;
        overflow: hidden;
        margin: 40px auto;
        padding: 0 36px;
        background: transparent
          url('/media/export/cms/appointment_booking/services/vert_line_bg.gif') repeat-y top center;
        @include breakpoint($ab-small-down) {
          background: none;
          border: none;
          margin-top: 0;
          margin-bottom: 0;
          padding: 0;
        }
      }
      .service {
        display: block;
        width: 33.33%;
        padding: 1.9%;
        min-height: 460px;
        overflow: hidden;
        float: left;
        @include breakpoint($ab-small-down) {
          float: none;
          width: 100%;
          min-height: 0;
          padding: 2.5% 1.9%;
          &.expanded .service-head .icon {
            width: 33px;
            height: 13px;
            margin-top: 10px;
          }
          .service-content-container {
            border: 1px solid $color-white;
          }
          &.selected .service-content-container {
            background: $color-white;
            border: 1px solid $color-black;
          }
          .service-head {
            position: relative;
            width: 100%;
            height: auto;
            overflow: hidden;
            cursor: pointer;
            .service-title {
              display: block;
              float: left;
              width: 80%;
              font-size: 17px;
              font-family: $helvetica-light-font-family;
              padding: 0;
              margin-top: 3px;
              margin-left: 15px;
              padding-bottom: 12px;
            }
            .icon {
              display: block;
              width: 28px;
              height: 25px;
              float: right;
              padding: 0;
              margin: 6px 0 0 0;
              background: url('/media/export/cms/appointment_booking/services/serv_arrow_closed.png')
                no-repeat;
            }
          }
        }
        .service-content {
          border: 1px solid $color-white;
        }
        &.selected {
          .service-content {
            background-color: $color-white;
            border: 1px solid $color-black;
            padding: 1px;
          }
        }
        .service-image {
          text-align: center;
          width: 100%;
          padding: 0 10.43%;
          @include breakpoint($ab-small-down) {
            width: 45.35%;
            display: block;
            float: left;
            padding: 0;
          }
        }
        .service-details {
          text-align: #{$ldirection};
          margin-left: 27px;
          @include breakpoint($ab-small-down) {
            display: block;
            float: left;
            text-align: left;
            overflow: hidden;
            width: 50.1%;
            margin-left: 8px;
          }
          h3 {
            margin: 0;
            &.service-title {
              font-family: $base-pro-bolder-font-family;
              font-weight: bold;
              font-size: 17px !important;
              line-height: 24px;
              margin-top: 0;
              color: $color-black;
              @include breakpoint($ab-ipad-portrait) {
                font-size: 30px;
                font-family: $base-pro-bolder-font-family;
              }
              @include breakpoint($ab-small-down) {
                font-size: 17px;
                font-family: $base-pro-bolder-font-family;
                margin-top: -2px;
              }
            }
          }
          .lesson_description {
            line-height: 24px;
            font-size: 17px;
            p {
              margin-top: 0;
              margin-bottom: 0;
              letter-spacing: 0;
              min-height: 50px;
              @include breakpoint($ab-ipad-portrait) {
                font-size: 17px;
                min-height: 75px;
                font-family: $base-pro-roman-font-family;
              }
              @include breakpoint($ab-small-down) {
                font-size: 17px;
                line-height: 1.3em;
                letter-spacing: 0;
                font-family: $base-pro-roman-font-family;
              }
            }
          }
        }
        .selection-bar {
          display: block;
          clear: both;
          overflow: hidden;
          letter-spacing: 0;
          color: $color-black;
          max-width: 300px;
          margin: 0 auto;
          padding: 7px 10px 0;
          @include breakpoint($ab-small-down) {
            margin-bottom: 10px;
            max-width: 75%;
            padding: 7px 5% 0;
          }
          > div {
            display: block;
            float: left;
            text-align: center;
            padding: 12px 0;
            margin: 0;
            font-family: $base-pro-bolder-font-family;
            @include breakpoint($ab-small-down) {
              font-size: 10px;
              letter-spacing: 0.2em;
              padding: 7px 0;
            }
          }
          .selection-data {
            width: 48%;
            border: 1px solid $color-light-black;
            font-size: 17px;
            font-family: $base-pro-bolder-font-family;
            font-size: 17px;
            line-height: 24px;
            span {
              font-family: $base-pro-bolder-font-family;
              font-size: 17px;
              &.title--small {
                line-height: 1.44;
              }
            }
            @include breakpoint($ab-small-down) {
              width: 50%;
              font-size: 17px;
              font-family: $base-pro-bolder-font-family;
              background-size: 21px 21px;
              .selection-time {
                font-family: $base-pro-bolder-font-family;
                font-size: 17px;
                letter-spacing: 0;
              }
              span {
                display: block;
                font-family: $base-pro-bolder-font-family;
                &.selection-price:empty {
                  display: none;
                }
              }
            }
          }
          .selection-selector {
            border: 1px solid $color-black;
            background-color: $color-black;
            color: $color-white;
            width: 52%;
            cursor: pointer;
            font-size: 17px;
            line-height: 24px;
            &.priced {
              height: 50px;
              padding: 16px 0;
              @include breakpoint($ab-small-down) {
                height: 45px;
                padding: 14px 0;
              }
            }
            .icon {
              display: none;
              position: relative;
              top: -2px;
              width: 17px;
              height: 12px;
              background: url('/media/export/cms/appointment_booking/services/service_selected_check.gif')
                no-repeat;
              @include breakpoint($ab-small-down) {
                display: none;
              }
            }
            @include breakpoint($ab-small-down) {
              width: 50%;
              font-size: 17px;
              letter-spacing: -0.02em;
              font-family: $base-pro-bolder-font-family;
              background-size: 8px 30px;
            }
          }
          .button--selection-data {
            cursor: default;
            text-transform: none;
          }
          .button--selection-selector {
            text-transform: none;
          }
          .selection-selector.selected {
            font-family: $base-pro-bolder-font-family;
            color: $color-white;
            .icon {
              display: none;
            }
          }
        }
      }
    }
    .appointment-select {
      width: auto;
      margin: 0 auto;
      overflow: hidden;
      .appointments-container {
        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
          background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 8 4' width='8' height='8' xmlns='http://www.w3.org/2000/svg'><g><path d='M0 0l4 4 4-4H0z'></path> /></g></svg>")
            no-repeat;
          background-position: 95% center;
          background-repeat: no-repeat;
          outline: none;
          padding-right: 35px;
          padding-left: 10px;
          border: 1px solid $color-light-black;
        }
        .booking-step {
          margin-bottom: 22px;
          text-align: left;
          font-size: 17px;
          line-height: 24px;
          @include breakpoint($ab-small-down) {
            font-size: 15px;
          }
          &.step1 {
            margin-bottom: 0;
            @include breakpoint($ab-small-down) {
              margin-bottom: 20px;
              padding: 0 20px;
            }
          }
          &.step2 {
            padding: 0 5px;
            margin-bottom: 15px;
            @include breakpoint($ab-small-down) {
              padding: 0 20px;
              margin-bottom: 20px;
            }
          }
          &.step3 {
            padding: 0 5px;
            @include breakpoint($ab-small-down) {
              margin-bottom: 0px;
              padding: 0;
            }
          }
        }
        .step-header {
          text-align: left;
          padding: 21px 0 6px;
          margin: 0;
          border-bottom: 1px solid $color-light-grey;
          font-family: $base-pro-roman-font-family;
          font-size: 17px;
          line-height: 24px;
          color: $color-black;
          @include breakpoint($ab-small-down) {
            letter-spacing: 0.03em;
            font-size: 15px;
            font-family: $base-pro-roman-font-family;
            padding: 0 0 4px;
          }
        }
        .step1 {
          .step-header {
            margin: 0 5px 2px;
            @include breakpoint($ab-small-down) {
              margin: 0 0 2px 0;
            }
          }
        }
        .step2 {
          .step-header {
            border: none;
            @include breakpoint($ab-small-down) {
              border-bottom: 1px solid $color-light-grey;
              margin-bottom: 10px;
            }
          }
        }
        @include breakpoint($ab-small-down) {
          .step3 {
            .step-header {
              margin-left: 20px;
              margin-right: 20px;
            }
            .error-messages,
            .sign-in-container,
            .book-appt-container {
              padding: 0 20px;
            }
          }
        }
        .services {
          width: 77.6%;
          overflow: hidden;
          padding: 0;
          float: left;
          @include breakpoint($ab-small-down) {
            float: none;
          }
          h4 {
            font-family: $helvetica-bold-font-family;
            font-size: 16px;
            padding-bottom: 18px;
            letter-spacing: 0.2em;
            @include breakpoint($ab-small-down) {
              letter-spacing: 0.02em;
              font-family: $helvetica-light-font-family;
              text-transform: uppercase;
              font-size: 17px;
              padding-bottom: 0;
              margin-bottom: 8px;
            }
          }
        }
        .service {
          display: block;
          width: 100%;
          padding: 1% 0.7%;
          overflow: hidden;
          float: left;
          @include breakpoint($ab-small-down) {
            width: 50%;
            overflow-wrap: break-word;
            float: none;
            padding: 0;
          }
          .service-head {
            color: $color-black;
            font-family: $base-pro-bolder-font-family;
            font-size: 28px;
            line-height: 32px;
            margin: 0;
            min-height: 40px;
            @include breakpoint($ab-ipad-portrait) {
              font-size: 28px;
            }
            @include breakpoint($ab-small-down) {
              text-transform: none;
              font-family: $base-pro-roman-font-family;
              color: $color-black;
              min-height: 0px;
            }
          }
          .selection-bar {
            display: block;
            clear: both;
            padding-top: 6px;
            overflow: hidden;
            color: $color-black;
            letter-spacing: -0.02em;
            max-width: 300px;
            @include breakpoint($ab-small-down) {
              display: none;
            }
            > div {
              display: block;
              float: left;
              text-align: center;
              padding: 15px 0;
              margin: 0;
              font-size: 17px;
              font-family: $base-pro-bolder-font-family;
              @include breakpoint($ab-small-down) {
                font-size: 17px;
                font-family: $base-pro-bolder-font-family;
              }
            }
            .selection-data {
              width: 48%;
              border: 1px solid $color-border-grey;
              .selection-time {
                font-size: 17px;
                font-family: $base-pro-bolder-font-family;
              }
              @include breakpoint($ab-small-down) {
                .selection-time {
                  font-size: 17px;
                  font-family: $base-pro-bolder-font-family;
                }
              }
            }
            .selection-selector {
              width: 52%;
              text-transform: none;
              border: 1px solid $color-black;
              background: $color-black;
              color: $color-white;
              font-family: $base-pro-bolder-font-family;
              font-size: 17px;
              &.priced {
                height: 56px;
                padding: 19px 0;
              }
              .icon {
                display: none;
                position: relative;
                top: -2px;
                margin-right: 2px;
                width: 1px;
                height: 12px;
                background: url('/media/export/cms/appointment_booking/services/service_selected_check.gif')
                  no-repeat;
              }
            }
            .selection-selector.selected {
              color: $color-white;
              .icon {
                display: none;
              }
            }
          }
        }
        #change-services-link,
        #read-all-artist-link,
        #appt-book-sign-in-link,
        .start-over {
          font-family: $base-pro-roman-font-family;
          padding-bottom: 5px;
          text-decoration: none;
          border-bottom: 1px solid $color-light-grey;
          color: $color-black;
          @include breakpoint($ab-small-down) {
            padding-bottom: 0;
            text-transform: none;
          }
        }
        .start-over {
          text-transform: uppercase;
        }
        #change-services-link {
          margin: 1% 0 0;
          float: #{$rdirection};
          @include breakpoint($ab-small-down) {
            position: absolute;
            top: 2%;
            right: 6%;
          }
        }
        #read-all-artist-link {
          margin-left: 51.8%;
        }
        .selects-container {
          overflow: hidden;
          width: 100%;
          margin-top: 18px;
          margin-bottom: 3px;
          padding: 0;
          @include breakpoint($ab-small-down) {
            margin-top: 3px;
          }
          .select-container {
            float: left;
            text-align: left;
            padding: 2.5% 8% 2.5% 0.7%;
            width: 42.3%;
            @include breakpoint($ab-small-down) {
              width: auto;
              float: none;
              padding-right: 20%;
              padding-left: 0;
            }
            &.virtual__location {
              &__counter {
                @if $virtual-appointment {
                  @include breakpoint($ab-small-down) {
                    padding-#{$rdirection}: 13%;
                  }
                  width: 100%;
                  padding-#{$rdirection}: 0;
                  .virtual__appt__content {
                    font-family: $base-pro-bolder-font-family;
                    color: $color-black;
                  }
                }
              }
            }
            h4 {
              font-family: $base-pro-roman-font-family;
              padding-bottom: 6px;
              color: $color-black;
              @include breakpoint($ab-small-down) {
                letter-spacing: 0.02em;
                font-size: 15px;
                font-family: $base-pro-roman-font-family;
                text-transform: none;
                padding-bottom: 0;
                margin-bottom: 5px;
              }
            }
            .artist-info {
              text-decoration: underline;
              cursor: pointer;
            }
            .selectbox {
              height: 42px;
              color: $color-black;
              font-family: $base-pro-bolder-font-family;
              width: 100%;
              font-weight: bold;
              @include breakpoint($ab-small-down) {
                height: 32px;
              }
            }
          }
          .counter-select-container {
            @include breakpoint($ab-small-down) {
            }
          }
        }
        .appointment-select {
          .calendar-head {
            position: relative;
            text-align: center;
            height: 56px;
            margin: 0;
            @include breakpoint($ab-small-down) {
              text-align: left;
              height: auto;
              margin: 0 0 2px 0;
              font-family: $helvetica-bold-font-family;
              font-size: 17px;
              span {
                font-family: $helvetica-light-font-family;
              }
            }
            .cal-head-content {
              position: relative;
              display: inline-block;
              overflow: hidden;
              margin-top: 6px;
              > * {
                float: left;
              }
              .cal-copy {
                position: relative;
                font-family: $base-pro-bolder-font-family;
                padding: 2px 33px;
                @include breakpoint($ab-small-down) {
                  letter-spacing: 1px;
                }
              }
              .cal-controls {
                width: 10px;
                height: 18px;
                cursor: pointer;
                margin-top: 5px;
                overflow: hidden; // for img replacement
                text-indent: 100%; // for img replacement
                white-space: nowrap; // for img replacement
              }
              .cal-controls.next {
                background: transparent
                  url('/media/export/cms/appointment_booking/appointments/cal_arrow_right_black.png')
                  no-repeat;
              }
              .cal-controls.previous {
                background: transparent
                  url('/media/export/cms/appointment_booking/appointments/cal_arrow_left_black.png')
                  no-repeat;
              }
            }
            .date-picker {
              position: absolute;
              right: 12px;
              top: 0;
              background: transparent
                url('/media/export/cms/appointment_booking/appointments/cal_date_picker_icon_black.png')
                no-repeat;
              width: 28px;
              height: 34px;
              cursor: pointer;
              overflow: hidden; // for img replacement
              text-indent: 100%; // for img replacement
              white-space: nowrap; // for img replacement
            }
          }
          @include breakpoint($ab-small-down) {
            .date-picker {
              width: 100%;
              margin-bottom: 13px;
              .pikaday-container {
                padding: 0;
                .pika-single {
                  z-index: 0;
                  font-family: $helvetica-light-font-family;
                  border: none;
                  .pika-lendar {
                    float: none;
                    margin: 0;
                    width: 100%;
                    .pika-title {
                      font-size: 20px;
                      margin-top: 10px;
                      margin-bottom: 20px;
                      .pika-label {
                        z-index: 0;
                        font-size: 20px;
                        padding-top: 0;
                        padding-bottom: 0;
                        &:last-child {
                          display: none;
                        }
                        .pika-select {
                          z-index: 0;
                          display: none;
                        }
                      }
                      .pika-prev,
                      .pika-next {
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                        overflow: hidden; // for img replacement
                        text-indent: 100%; // for img replacement
                        white-space: nowrap; // for img replacement
                      }
                      .pika-prev {
                        background: transparent
                          url('/media/export/cms/appointment_booking/appointments/cal_arrow_left_black.png')
                          no-repeat;
                      }
                      .pika-next {
                        background: transparent
                          url('/media/export/cms/appointment_booking/appointments/cal_arrow_right_black.png')
                          no-repeat;
                      }
                    }
                    table.pika-table {
                      border-collapse: collapse;
                      border-spacing: 0;
                      td,
                      th {
                        border: none;
                        background-color: transparent;
                        height: 35px;
                        padding: 0;
                        color: $color-black;
                      }
                      th {
                        abbr,
                        abbr[title] {
                          border: none;
                          cursor: default;
                        }
                      }
                      td {
                        .pika-button {
                          background-color: $color-white;
                          color: $color-black;
                          border-radius: 0;
                          box-shadow: none;
                          text-align: center;
                          font-size: 16px;
                          font-family: $helvetica-light-font-family;
                          height: 47px;
                          &:hover {
                            color: $color-black;
                            background-color: $color-white;
                          }
                        }
                        &.is-selected .pika-button {
                          background-color: $color-black;
                          color: $color-white;
                          &:hover {
                            background-color: $color-black;
                            color: $color-white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          table {
            text-align: center;
            border: 1px solid $color-grey;
            margin: 0;
            width: 100%;
            font-family: $base-pro-roman-font-family;
            @include breakpoint($ab-small-down) {
              border: none;
              border-collapse: separate;
              border-spacing: 6px;
            }
            th {
              background-color: $color-oab-table-header;
              border: none;
              text-align: center;
              font-size: 17px;
              padding: 0.55em 0;
              letter-spacing: 0.02em;
              &.hilite {
                background-color: $color-black;
                color: $color-white;
              }
            }
            td {
              border: 1px solid #d8d8d8;
              text-align: center;
              color: #d0d0d0;
              cursor: default;
              padding: 0.46em 0;
              @include breakpoint($ab-small-down) {
                padding: 0.6em 0;
              }
            }
            td.enabled {
              font-family: $helvetica-light-font-family;
              background-color: $color-white;
              color: $color-black;
              cursor: pointer;
              &:hover {
                color: $color-white;
                background-color: $color-black;
                @include breakpoint($ab-small-down) {
                  color: $color-black;
                  background-color: $color-white;
                }
              }
            }
            td.selected,
            td.selected:hover {
              font-family: $base-pro-roman-font-family;
              background-color: $color-black;
              color: $color-white;
            }
          }
        }
        .error-messages {
          display: none;
          margin-top: 20px;
          .error-message {
            display: none;
            color: #ef6ea8;
            margin-bottom: 5px;
          }
        }
        .sign-in-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          margin: 37px 0 0 0;
          letter-spacing: 0.03em;
          font-family: $base-pro-roman-font-family;
          @include breakpoint($ab-small-down) {
            letter-spacing: 0.03em;
            margin-top: 17px;
          }
          h4 {
            margin-bottom: 28px;
            color: $color-black;
            @include breakpoint($ab-small-down) {
              margin-bottom: 3px;
              font-size: 15px;
            }
          }
          .sign-in-form-container {
            display: none;
            position: relative;
            overflow: hidden;
            input {
              display: inline-block;
              width: 30%;
              margin-bottom: 20px;
              font-family: $base-pro-roman-font-family;
              &.login__email {
                float: left;
                @include breakpoint($ab-small-down) {
                  width: 100%;
                  float: none;
                  font-size: 12px;
                  height: 29px;
                  margin-bottom: 15px;
                }
              }
              &.login__password {
                float: #{$rdirection};
                margin-#{$rdirection}: 10px;
                @include breakpoint($ab-small-down) {
                  float: #{$ldirection};
                  font-size: 12px;
                  height: 29px;
                  margin-bottom: 15px;
                  width: 63%;
                }
              }
              &.login__submit {
                display: inline-block;
                width: auto;
                clear: unset;
                float: right;
                font-size: 16px;
                font-family: $helvetica-regular-font-family;
                letter-spacing: -0.02em;
                background-color: $color-black;
                &:hover {
                  background-color: $color-black;
                }
                @include breakpoint($ab-small-down) {
                  float: #{$rdirection};
                  font-size: 12px;
                  height: 29px;
                  margin-bottom: 15px;
                  padding: 0 10px;
                  line-height: 1;
                  width: 33%;
                  margin-top: 0;
                }
              }
            }
            .social-login {
              .social-login__email-opt-in,
              .fb-disclaimer-container {
                display: block !important;
                .social-login__opt-in-label {
                  display: inline;
                }
                text-align: left;
                input {
                  width: auto !important;
                }
                .fb-disclaimer {
                  a {
                    text-decoration: underline;
                  }
                }
              }
              .social-login__terms {
                a {
                  text-decoration: underline;
                }
              }
            }
          }
          .tooltip {
            margin-top: 0;
            position: relative;
            display: inline;
            font-weight: 700;
            font-family: $base-pro-bolder-font-family;
            text-decoration: underline;
            color: $color-black;
            width: 100%;
          }
          @include breakpoint($ab-small-down) {
            .tooltiptext-over {
              width: 303px;
            }
          }
        }
        .book-appt-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          margin: 12px 0 23px;
          letter-spacing: 0.03em;
          @include breakpoint($ab-small-down) {
            letter-spacing: 0.03em;
            margin-bottom: 34px;
          }
          a {
            color: $color-black;
            font-family: $base-pro-roman-font-family;
            text-decoration: underline;
          }
          h4 {
            margin-bottom: 17px;
            color: $color-black;
            font-family: $base-pro-roman-font-family;
            @include breakpoint($ab-small-down) {
              letter-spacing: 0.03em;
              font-size: 15px;
              margin-bottom: 12px;
            }
          }
          input,
          fieldset {
            display: block;
            width: 48.2%;
            margin-bottom: 20px;
            font-size: 17px;
            font-family: $base-pro-roman-font-family;
            height: 38px;
            letter-spacing: 0.03em;
            @include breakpoint($ab-small-down) {
              width: 100%;
              float: none;
              font-size: 12px;
              height: 29px;
              margin-bottom: 15px;
            }
            &.appt-book-first-name,
            &.appt-book-email {
              float: left;
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: none;
              }
            }
            &.appt-book-last-name,
            &.appt-book-mobile {
              float: right;
              @include breakpoint($ab-small-down) {
                width: 100%;
                float: none;
              }
            }
            &.appt-placeholder {
              color: #6a6969;
            }
          }
          fieldset {
            @include breakpoint($ab-small-down) {
              margin: 0 0 15px;
              padding: 0;
            }
          }
          .note-head {
            clear: both;
            padding-top: 7px;
            padding-bottom: 3px;
            @include breakpoint($ab-small-down) {
              padding-top: 3px;
              line-height: 1;
              margin-bottom: 4px;
            }
          }
          .appt-book-note {
            width: 100%;
            display: block;
            height: 149px;
            margin-bottom: 16px;
            font-family: $base-pro-roman-font-family;
            font-size: 17px;
            letter-spacing: 0.03em;
            @include breakpoint($ab-small-down) {
              letter-spacing: 0.03em;
              height: 90px;
              margin-bottom: 12px;
              font-size: 12px;
            }
          }
          .registration__terms,
          .registration__terms_commercial,
          .registration__email-list,
          .registration__sms-list {
            font-family: $base-pro-roman-font-family;
            width: 48.2%;
            float: #{$ldirection};
            input {
              width: 20px;
              height: 20px;
              float: left;
              @include breakpoint($ab-small-down) {
                width: 15px;
                height: 15px;
                margin-right: 5px;
                margin-bottom: 17px;
              }
            }
            @include breakpoint($ab-small-down) {
              width: auto;
              float: none;
              display: block;
              margin-bottom: 24px;
              font-size: 12px;
              letter-spacing: 0;
            }
          }
          .registration__sms-list {
            display: none;
            float: right;
            @include breakpoint($ab-small-down) {
              display: none;
              width: auto;
              float: none;
            }
          }
          .appointment_privacy_legal_text {
            clear: both;
            padding-top: 20px;
          }
          .registration__email-list-text {
            display: table;
          }
        }
        .book-appt-container.no-last-name {
          input.appt-book-first-name {
            width: 100%;
          }
          input.appt-book-last-name {
            display: none;
          }
        }
        .confirm-container {
          display: block;
          overflow: visible;
          @if $appt_booking_duration_price_container_adjust {
            width: 70%;
          } @else {
            width: 50%;
          }
          height: auto;
          margin: 18px 0;
          float: right;
          letter-spacing: 0.02em;
          @include breakpoint($ab-small-down) {
            width: 95%;
            margin-left: 2.5%;
            float: none;
            text-align: left;
            font-family: $helvetica-regular-font-family;
            margin-bottom: 0px;
          }
          .total-time {
            border: 1px solid $color-gray;
            border-right: none;
            width: 47%;
            float: #{$ldirection};
            height: 53px;
            color: $color-black;
            padding: 15px 0 0 45px;
            font-family: $base-pro-bolder-font-family;
            &.priced {
              width: 50%;
              @include breakpoint($ab-small-down) {
                @if $appt_booking_duration_price_container_adjust {
                  padding-top: 0;
                } @else {
                  padding-top: 8px;
                }
              }
            }
            @include breakpoint($ab-small-down) {
              padding: 18px 0 0;
              text-align: center;
            }
          }
          .tooltip {
            @include breakpoint($ab-large-up) {
              text-align: $rdirection;
              .tooltiptext-over {
                #{$rdirection}: 0%;
                #{$ldirection}: inherit;
                text-align: center;
              }
              .tooltiptext-over::before {
                #{$rdirection}: 19%;
                #{$ldirection}: inherit;
              }
            }
          }
          .appt-book-book-submit {
            float: #{$rdirection};
            padding: 0 18px;
            width: 53%;
            cursor: pointer;
            border: 1px solid $color-light-grey;
            border-#{$ldirection}: none;
            height: 53px;
            font-size: 17px;
            letter-spacing: -0.02em;
            font-family: $base-pro-bolder-font-family;
            &.priced {
              width: 50%;
            }
            &.disabled {
              background-color: $color-btn-gray-disabled-alt;
              color: $color-white;
              cursor: default;
            }
            @include breakpoint($ab-small-down) {
              margin-top: 0;
              padding: 0;
              font-family: $base-pro-bolder-font-family;
              font-size: 14.5px;
            }
          }
          .start-over {
            text-align: right;
            float: right;
            display: block;
            margin-top: 24px;
            text-transform: uppercase;
            color: $color-black;
            border-color: $color-black;
            @include breakpoint($ab-small-down) {
              width: auto;
              float: none;
              display: block;
              font-size: 15px;
              text-align: center;
              padding-top: 18%;
              margin: 0 auto;
              width: 60%;
            }
          }
        }
      }
    }
    .page-header {
      .page-header__title {
        font-family: $helvetica-bold-font-family;
        font-size: 39px;
        font-weight: bold;
        letter-spacing: 7px;
        padding-left: 90px;
        text-align: left;
        text-transform: uppercase;
      }
      .page-header__subtitle {
        padding-left: 90px;
        text-align: left;
        p {
          font-family: $helvetica-regular-font-family;
          font-size: 24px;
          line-height: 1.3;
        }
      }
    }
    .confirmation {
      width: 100%;
      position: relative;
      .appt-book-content-header {
        margin: 40px 0;
        display: none;
        p {
          width: 56%;
          margin: 18px auto;
        }
      }
      .confirmation-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-bottom: 33px;
        > div {
          float: left;
          width: 33.33%;
          min-height: 220px;
          padding: 18px 0 0;
          text-align: #{$ldirection};
          overflow: hidden;
          font-size: 17px;
          font-family: $helvetica-light-font-family;
          @include breakpoint($ab-small-down) {
            float: none;
            width: 100%;
            min-height: auto;
            text-align: left;
          }
          h4 {
            font-family: $base-pro-bolder-font-family;
            font-size: 17px;
            margin: 10px 0 20px;
            padding-bottom: 10px;
            text-align: #{$ldirection};
            border-bottom: 1px solid $color-dark-grey-bolder;
            color: $color-black;
            font-weight: bold;
            &::first-letter {
              text-transform: uppercase;
            }
            @include breakpoint($ab-small-down) {
              text-align: left;
              font-size: 17px;
              padding-bottom: 5px;
              margin: 7px 0 5px;
            }
          }
          span {
            display: block;
            text-align: #{$ldirection};
            margin: 0;
            color: $color-black;
            font-size: 17px;
            font-family: $base-pro-roman-font-family;
            @include breakpoint($ab-small-down) {
              text-align: left;
              font-size: 17px;
              line-height: 1.3;
              font-family: $base-pro-roman-font-family;
            }
          }
        }
        @include breakpoint($ab-small-down) {
          border-bottom: 0px;
          padding-bottom: 0px;
        }
        .your-lessons {
          h4,
          span {
            text-align: #{$ldirection};
          }
        }
        .date-time {
          .appt-date-cal {
            background-image: none;
            .add-cal-link {
              display: inline-block;
              cursor: pointer;
              font-family: $base-pro-roman-font-family;
              background: $color-black;
              color: $color-white;
              font-size: 15px;
              height: 40px;
              padding: 0 20px;
              margin-top: 5px;
              line-height: 2.8;
              @include breakpoint($ab-small-down) {
                padding-bottom: 0;
              }
            }
            .cals-container {
              width: 150px;
              display: none;
              margin-top: 5px;
              position: absolute;
              border: 1px solid $color-black;
              background-color: $color-white;
              .appt-book-link {
                border: none;
                display: block;
                padding: 8px 15px 0;
                &:first-child {
                  padding-top: 5px;
                }
                &:last-child {
                  padding-bottom: 5px;
                }
              }
              @include breakpoint($ab-small-down) {
                width: 100%;
              }
              &.expanded {
                display: block;
                float: $ldirection;
              }
            }
            .appt-book-link {
              color: $color-black;
              text-transform: uppercase;
              font-size: 12px;
              line-height: 1.3;
              font-family: $base-pro-roman-font-family;
              letter-spacing: 0;
              border-color: 1px solid $color-black;
            }
          }
          h4,
          span {
            text-align: #{$ldirection};
            @include breakpoint($ab-small-down) {
              text-align: #{$ldirection};
            }
          }
          @include breakpoint($ab-small-down) {
            padding-bottom: 33px;
          }
        }
        .artist {
          display: none;
          margin-top: 30px;
          border-top: 1px solid #d0d0d0;
          width: 100%;
          padding-bottom: 33px;
          h4 {
            border: none;
            padding-bottom: 0;
          }
          @include breakpoint($ab-small-down) {
            display: none;
            margin-top: 0;
            border: none;
            h4 {
              border-bottom: 1px solid #d0d0d0;
              padding-bottom: 5px;
            }
          }
        }
        .location {
          a {
            border-bottom: 1px solid $color-cl-grey;
            font-family: $base-pro-roman-font-family;
            padding-bottom: 5px;
            font-size: 17px;
            text-decoration: underline solid $color-cl-grey 2px;
            letter-spacing: 0.02em;
            color: $color-black;
            @include breakpoint($ab-small-down) {
              font-size: 17px !important;
              padding-bottom: 0;
              border-bottom: 1px solid $color-cl-grey;
            }
            &.location-phone {
              color: $color-black;
              border-bottom: none;
              font-size: 17px;
              direction: ltr;
              @include breakpoint($ab-small-down) {
                font-size: 17px;
              }
            }
          }
        }
      }
    }
    .my-appointments {
      width: 100%;
      position: relative;
      #appt-book-my-appointments-no-appts {
        @include breakpoint($ab-small-down) {
          padding: 0 20px;
        }
      }
      .appt-book-content-header {
        margin: 36px 0 28px;
        display: none;
        p {
          width: 56%;
          margin: 18px auto;
        }
      }
      .appointments-container {
        position: relative;
        width: auto;
        overflow: hidden;
        margin: 48px 3.6% 0;
        min-height: 62px;
        @include breakpoint($ab-small-down) {
          margin: 8px 0 0;
          padding: 0 20px;
        }
        .mobile-header {
          display: none;
        }
        &.past {
          background-color: $color-white;
          height: auto;
          overflow: hidden;
          margin-top: 10px;
          .past-appt-head {
            width: 100%;
            position: relative;
            text-align: center;
            a.view-link {
              font-family: $base-pro-bolder-font-family;
              font-size: 17px;
              text-transform: none;
              color: $color-black;
              text-align: center;
              display: inline;
              padding: 10px;
              border: 1px solid $color-light-black;
              height: 62px;
              background-color: $color-white;
              letter-spacing: 0;
              position: relative;
              line-height: 3.5;
              @include breakpoint($ab-small-down) {
                height: 77px;
                font-size: 17px;
                text-align: left;
                line-height: 4.5;
              }
            }
            .section-header {
              margin: 28px 0 29px;
              text-align: center;
              font-size: 17px;
              letter-spacing: 0.1em;
              font-family: $base-pro-bolder-font-family;
              @include breakpoint($ab-small-down) {
                letter-spacing: 0.2em;
                font-size: 17px;
                text-align: left;
              }
              .section-header .inner,
              .section-header__inner {
                color: $color-black;
                padding: 0 14px 0 18px;
                letter-spacing: 0.03em;
                font-size: 17px;
                font-family: $base-pro-bolder-font-family;
                @include breakpoint($ab-small-down) {
                  font-size: 17px;
                  text-align: left;
                  padding: 0;
                }
              }
            }
          }
          .appointment-details {
            margin: 0;
            border-top: 1px solid $color-white;
            h4 {
              border-bottom: 1px solid $color-cl-grey;
            }
            .date-time {
              border: none;
            }
            .artist {
              border-top: 1px solid $color-white;
              border-bottom: none;
              padding-top: 24px;
            }
          }
        }
      }
      .appointment-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-bottom: 0;
        .appointment-details {
          position: relative;
          width: 100%;
          margin: 40px 0;
          overflow: hidden;
          font-size: 17px;
          line-height: 22px;
          font-family: $helvetica-light-font-family;
          border-bottom: 1px solid $color-border-grey;
          padding-bottom: 30px;
          @include breakpoint($ab-small-down) {
            font-size: 17px;
            line-height: 19px;
            margin-top: 45px;
            margin-bottom: 30px;
            border-bottom: 0px;
          }
          > div {
            float: left;
            width: 33.33%;
            padding: 18px 0 0;
            text-align: center;
            overflow: hidden;
            @include breakpoint($ab-small-down) {
              float: none;
              width: 100%;
              min-height: auto;
              text-align: left;
              padding: 10px 0 0;
            }
            h4 {
              font-family: $base-pro-bolder-font-family;
              font-size: 17px;
              font-weight: bold;
              margin: 1px 0 20px;
              padding-bottom: 10px;
              text-transform: none;
              text-align: #{$ldirection};
              border-bottom: 1px solid $color-cl-grey;
              color: $color-black;
              @include breakpoint($ab-small-down) {
                text-align: left;
                font-size: 17px;
                padding-bottom: 5px;
                margin: 7px 0 8px;
                font-family: $base-pro-bolder-font-family;
                border-bottom: 1px solid $color-cl-grey;
                text-transform: none;
              }
            }
            span {
              display: block;
              text-align: #{$ldirection};
              color: $color-black;
              font-size: 17px;
              font-family: $base-pro-roman-font-family;
              @include breakpoint($ab-small-down) {
                text-align: #{$ldirection};
                font-size: 17px;
                font-family: $base-pro-roman-font-family;
                line-height: 1.4;
                margin: 0;
              }
            }
          }
          .your-lessons,
          .location {
          }
          .your-lessons,
          .date-time {
          }
          .artist {
            display: none;
            margin-top: 30px;
            border-top: 1px solid #d0d0d0;
            border-bottom: 1px solid #d0d0d0;
            width: 100%;
            padding-bottom: 33px;
            h4 {
              border: none;
              padding-bottom: 0;
            }
            @include breakpoint($ab-small-down) {
              margin-top: 0;
              border: none;
              h4 {
                border-bottom: 1px solid $color-cl-grey;
                padding-bottom: 5px;
              }
            }
          }
          .location {
            a {
              font-family: $base-pro-roman-font-family;
              padding-bottom: 5px;
              font-size: 17px;
              text-decoration: none;
              color: $color-black;
              letter-spacing: 0.02em;
              @include breakpoint($ab-small-down) {
                font-size: 17px;
                padding-bottom: 0;
                letter-spacing: 0.02em;
                color: $color-black;
              }
            }
            .location-direction-links {
              display: table;
              text-align: #{$ldirection};
              border-bottom: 1px solid $color-shade-dark-grey;
              @include breakpoint($ab-small-down) {
                &.mobile {
                  display: inline-block;
                  text-transform: none;
                }
              }
            }
          }
        }
        &:last-child {
          .appointment-details {
            margin-bottom: 0;
          }
        }
      }
      .appt-booking-links {
        margin: 60px 0 30px 0;
        @include breakpoint($ab-small-down) {
          margin: 0px 20px 20px 20px;
        }
        h4 {
          font-family: $base-pro-bolder-font-family;
          font-size: 17px;
          margin: 0 0 28px;
          letter-spacing: 0;
          color: $color-black;
          a {
            font-size: 17px;
            font-family: $base-pro-bolder-font-family;
            text-decoration: underline;
          }
        }
        a#view-makeup-lessons-btn {
          color: $color-black;
          font-size: 17px;
          letter-spacing: 0.02em;
          padding-bottom: 3px;
          font-family: $base-pro-bolder-font-family;
        }
      }
      .appointments-buttons {
        margin: 10px 0 36px;
        .change-appt-btn {
          margin-right: 36px;
          letter-spacing: -0.02em;
          padding: 14px 0.7em;
          font-size: 17px;
          background-color: $color-black;
          font-family: $base-pro-bolder-font-family;
          text-transform: none;
          &.mobile {
            display: none;
          }
          @include breakpoint($ab-small-down) {
            float: left;
            font-size: 13px;
            height: 33px;
            letter-spacing: -0.02em;
            line-height: 1.4em;
            margin: 0 14px 0 0;
            width: 50%;
            padding: 0.6em 0.7em;
          }
        }
        a.appt-book-btn {
          color: $color-black;
          border: 1px solid $color-light-black;
          letter-spacing: -0.02em;
          padding: 14px 0.7em;
          margin-right: 36px;
          font-size: 17px;
          background-color: $color-white;
          font-family: $base-pro-bolder-font-family;
          text-transform: none;
          @include breakpoint($ab-small-down) {
            &.desktop {
              display: none;
            }
            &.cancel-appt-btn {
              float: left;
              font-size: 13px;
              height: 33px;
              letter-spacing: -0.02em;
              line-height: 1.3em;
              margin-right: 0;
              padding: 0.6em 0.7em;
              border: 1px solid $color-light-black;
              width: 45%;
              text-align: center;
            }
          }
        }
      }
    }
    .appointment-cancellation {
      width: 100%;
      position: relative;
      display: none;
      @include breakpoint($ab-small-down) {
        display: block;
        text-align: left;
      }
      .appt-book-content-header {
        .section-header {
          text-align: center;
        }
        .section-header__inner {
          text-align: center;
          font-family: $helvetica-light-font-family;
          font-size: 24px;
          color: $color-black;
          font-weight: normal;
        }
        @include breakpoint($ab-small-down) {
          .section-header,
          .section-header__inner {
            text-align: left;
            color: $color-light-purple;
          }
        }
      }
      & .cancel-content,
      & .error-content {
        display: none;
        font-size: 16px;
        font-family: $helvetica-light-font-family;
      }
      .button-container {
        display: block;
        margin-top: 40px;
        text-align: center;
        .button {
          font-family: $helvetica-regular-font-family;
          font-size: 18px;
          letter-spacing: -0.02em;
          background-color: $color-light-purple;
          &:hover {
            background-color: $color-light-purple;
          }
        }
        @include breakpoint($ab-small-down) {
          text-align: left;
          margin-top: 10px;
          .button {
            font-size: 14px;
          }
        }
      }
    }
    .no-appts-content {
      font-size: 17px;
      line-height: 1.41176;
      letter-spacing: -0.01em;
      font-family: $base-pro-bolder-font-family;
      @include breakpoint($ab-small-down) {
        font-size: 15px;
        line-height: 1.33333;
      }
    }
    #appt-book-btn {
      margin-top: 20px;
      text-align: center;
      font-family: $base-pro-bolder-font-family;
      font-size: 18px;
      letter-spacing: -0.02em;
      background-color: $color-black;
      &:hover {
        background-color: $color-black;
      }
    }
  }
  .appt-book-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #ffffff; //fallback
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100 !important;
    .overlay-content-container {
      position: absolute;
      display: block;
      padding: 16px 25px;
      text-align: center;
      background-color: $color-white;
      border: 1px solid $color-black;
      z-index: 1;
      left: calc(50% - 250px);
      top: 50%;
      width: 500px;
      @include breakpoint($ab-small-down) {
        width: 90%;
        left: 5%;
      }
      .overlay-close {
        position: absolute;
        display: block;
        right: 9px;
        top: 9px;
        background: url(../../cl_base/img/icon-close.png) no-repeat;
        width: 19px;
        height: 19px;
        cursor: pointer;
        z-index: 3;
        overflow: hidden; // for img replacement
        text-indent: 100%; // for img replacement
        white-space: nowrap; // for img replacement
      }
      .overlay-content {
        display: block;
        position: relative;
        text-align: left;
        h4 {
          font-family: $base-pro-bolder-font-family;
          color: $color-black;
          margin-bottom: 6px;
          margin-top: 7px;
          font-size: 21.72px;
          @include breakpoint($ab-small-down) {
            font-size: 24px;
            letter-spacing: 0.11em;
            line-height: 1.1em;
            margin-bottom: 8px;
            margin-top: 13px;
          }
        }
        p {
          font-family: $base-pro-roman-font-family;
          line-height: 1.2em;
          margin-top: 7px;
          font-size: 19.61px;
          @include breakpoint($ab-small-down) {
            margin: 0 auto;
            line-height: 1.3em;
            font-size: 12px;
            width: 100%;
          }
        }
        a.button {
          background-color: $color-black;
          font-size: 17px;
          height: auto;
          letter-spacing: -0.015em;
          color: $color-white;
          border: 1px solid $color-black;
          font-family: $base-pro-bolder-font-family;
          line-height: 1;
          margin-top: 20px;
          padding: 12px 20px;
        }
      }
    }
    &.appt-book-services-overlay .overlay-content-container {
      width: 490px;
      height: auto;
      top: 50%;
      left: 50%;
      margin-top: -95px;
      margin-left: -240px;
      @include breakpoint($ab-small-down) {
        width: 300px;
        min-width: 300px;
        margin-left: -150px;
      }
    }
    &.appt-book-datepicker-overlay {
      z-index: 1000;
      .overlay-content-container {
        width: 570px;
        top: 100px;
        left: 50%;
        margin-left: -325px;
        background-color: $color-white;
        @include breakpoint($ab-small-down) {
          top: 60px;
          padding-top: 65px;
          padding-bottom: 38px;
        }
        .pika-single {
          color: $color-black;
          border: none;
          .pika-lendar {
            @include breakpoint($ab-small-down) {
              border-right: 1px solid $color-light-grey;
              width: 50%;
              padding-right: 3%;
              padding-left: 0.5%;
              margin: 0;
              &:last-child {
                border: none;
                padding-right: 0.5%;
                padding-left: 3%;
              }
            }
          }
        }
        .pika-title {
          padding: 0 10px;
          button {
            &:hover {
              background-color: transparent;
            }
          }
        }
        .pika-label {
          font-family: $helvetica-light-font-family;
          font-size: 24px;
          letter-spacing: 0.02em;
          @include breakpoint($ab-small-down) {
            font-size: 14px;
            letter-spacing: 1px;
          }
        }
        .pika-table {
          @include breakpoint($ab-small-down) {
            margin-top: 27px;
          }
          th {
            color: $color-black;
            font-family: $helvetica-light-font-family;
            border-bottom: none;
            letter-spacing: 0.02em;
            @include breakpoint($ab-small-down) {
              font-size: 18px;
              padding: 0.7em 0 1em;
            }
          }
          abbr[title],
          abbr {
            border: none;
            text-decoration: none;
            cursor: default;
          }
          td {
            color: $color-black;
            font-family: $helvetica-light-font-family;
            border-bottom: none;
            letter-spacing: 0.02em;
            @include breakpoint($ab-small-down) {
              font-size: 24px;
              padding: 0.47em 0;
            }
          }
        }
        .pika-button {
          background: #ffffff;
          font-family: $helvetica-light-font-family;
          color: $color-black;
          border-radius: 0;
          @include breakpoint($ab-small-down) {
            font-size: 24px;
            padding: 25px 20px;
            line-height: 5px;
          }
          &:hover {
            background-color: $color-black;
            color: $color-white;
            border-radius: 0;
          }
        }
        .pika-prev,
        .is-rtl .pika-next {
          background: transparent
            url('/media/export/cms/appointment_booking/appointments/cal_arrow_left_black.png')
            no-repeat;
        }
        .pika-next,
        .is-rtl .pika-prev {
          background: transparent
            url('/media/export/cms/appointment_booking/appointments/cal_arrow_right_black.png')
            no-repeat;
        }
      }
    }
    &.my-appointments-overlay .overlay-content-container {
      width: 490px;
      min-height: 154px;
      top: 50%;
      left: 50%;
      margin-top: -95px;
      margin-left: -214px;
      background-color: $color-white;
      #confirm-cancel {
        p {
          font-family: $base-pro-roman-font-family;
          letter-spacing: -0.06em;
          font-size: 21.72px;
          margin: 10px 0 18px 0;
          line-height: 1;
        }
        .button {
          width: 69px;
          height: 42px;
          background-color: $color-white;
          border: 1px solid $color-black;
          text-transform: uppercase;
          font-size: 16px;
          letter-spacing: -0.02em;
          text-align: center;
          padding: 11px 0;
          color: $color-black;
          float: #{$ldirection};
          &.yes-btn {
            margin-#{$rdirection}: 36px;
          }
          &:hover {
            background-color: $color-black;
            color: $color-white;
          }
        }
      }
      @include breakpoint($ab-small-down) {
        width: 300px;
        height: auto;
        margin-left: -150px;
        #confirm-cancel {
          p {
            color: $color-black;
            font-size: 17px;
          }
          .button {
            width: 100%;
            height: 32px;
            margin: 4px 0;
            padding: 7px 0;
            font-size: 14px;
          }
        }
      }
      .canceled {
        h4 {
          margin: 10px auto 12px;
          line-height: 24px;
          width: 100%;
          font-family: $helvetica-light-font-family;
          letter-spacing: -0.06em;
          font-size: 21.72px;
          @include breakpoint($ab-small-down) {
            width: auto;
            font-size: 20px;
            color: $color-black;
            line-height: 1;
          }
        }
        p {
          margin-top: 17px;
          margin-bottom: 23px;
          font-family: $helvetica-light-font-family;
          letter-spacing: -0.06em;
          font-size: 16px;
          span {
            display: block;
            text-align: left;
            margin-bottom: 5px;
          }
        }
        .book-new-btn {
          padding: 0.86em 1.1em 0.86em 1.4em;
          letter-spacing: 0.25em;
          margin-bottom: 7px;
          background-color: $color-black;
          font-family: $helvetica-regular-font-family;
          @include breakpoint($ab-small-down) {
            letter-spacing: 1px;
          }
        }
        @include breakpoint($ab-small-down) {
          p {
            margin: 10px 0;
          }
          .book-new-btn {
            font-size: 14px;
            height: 32px;
            line-height: 1;
            margin-top: 10px;
            padding: 9px 5px;
          }
        }
      }
    }
  }
  .services-makeup,
  .services-skincare {
    overflow: hidden;
    margin-bottom: 50px;
    .section-header {
      text-align: center;
    }
    .service {
      width: 33.3%;
    }
  }
  .appointments-page {
    .appt-book-sections-container {
      @include breakpoint($ab-small-down) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .no-rgba .appt-book-overlay {
    background: transparent url(/media/export/cms/appointment_booking/common/overlay_tint.png)
      repeat;
  }
}
//override css for zoom link in confirmation page
.appt-book {
  .confirmation .confirmation-container {
    .location .zoom-meeting {
      a {
        color: $color-black;
        font-size: 17px;
        display: inline-block;
        margin-top: 20px;
        text-decoration: underline;
        text-decoration-color: $color-cl-grey;
        word-break: break-all;
        font-family: $base-pro-roman-font-family;
        border-bottom: none;
      }
    }
  }
  .my-appointments .appointment-container {
    .appointment-details {
      .location .location-name {
        .zoom-meeting {
          margin-top: 22px;
        }
        .zoom-meeting a {
          color: $color-black;
          text-transform: none;
          font-family: $base-pro-roman-font-family;
          font-size: 17px;
          word-break: break-all;
          text-decoration: underline;
          text-decoration-color: $color-underline-grey;
        }
      }
    }
  }
}
#cboxOverlay {
  background: $ab--color--gray--lighter;
}
.appt-artist-overlay {
  #cboxContent {
    padding: 0;
    #cboxClose {
      #{$rdirection}: 10.3px;
      top: 8.3px;
      background: url('../../cl_base/img/icons/src/x-16.svg') no-repeat;
      width: 17.4px;
      height: 17.4px;
      @include breakpoint($ab-small-down) {
        #{$rdirection}: 10px;
        top: 14px;
      }
    }
  }
}
.app-artist-info {
  .slick-slide {
    width: 260px;
  }
  .slick-list {
    margin: 25px 30px 0 35px;
    @include breakpoint($ab-small-down) {
      margin: 10px 30px 0 -10px;
    }
  }
  .slick-track {
    @include breakpoint($ab-small-down) {
      #{$ldirection}: 7px;
      margin-top: 15px;
    }
  }
  .slick-prev {
    #{$ldirection}: 10px;
    &:before {
      content: '\2039';
      color: $ab--color--gray--black;
      font-size: 50px;
    }
  }
  .slick-next {
    #{$rdirection}: 7px;
    &:before {
      content: '\203A';
      color: $ab--color--gray--black;
      font-size: 50px;
      position: static;
    }
  }
  .slick-dots {
    @include breakpoint($ab-small-down) {
      bottom: -35px;
    }
    li {
      @include breakpoint($ab-small-down) {
        display: inline-block;
      }
      button {
        &:before {
          @include breakpoint($ab-small-down) {
            font-size: 30px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.artist-bio {
  margin-#{$ldirection}: 15px;
  @include breakpoint($ab-small-down) {
    margin: 0;
    margin-#{$rdirection}: 20px;
  }
  border-radius: 4px;
  border: 1px solid $ab--color--gray--lighter;
  overflow: hidden;
  .artist-image {
    padding: 0 0 1rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .artist-details {
    padding: 0px 0px 10px 10px;
    text-align: left;
    background-color: $ab--color--white;
    .artist_pre_header {
      text-transform: uppercase;
      font-size: 10px;
    }
    .artist-title {
      word-wrap: break-word;
      .artist-header {
        font-size: 12px;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
    .artist-description {
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 16px;
    }
  }
}
