//
//  Blue Ocean
//    Without this, the menus ofthe quiz are hidden and can't be scrolled to
//
#blue_ocean .main_container {
  overflow-y: scroll;
}

#bo-wrapper section.screen-view.ng-scope {
  height: 100%;
}
