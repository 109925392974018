/* Mixin function for review star small Background image */
@mixin pr-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 12px;
  $pr-width-value: 14px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/stars/rating-small-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
      @include swap_direction(margin, 0 2px 0 0);
      &:focus {
        box-shadow: 0 0 0 $color-white;
        -webkit-box-shadow: 0 0 0 $color-white;
        outline: 0;
      }
    }
  }
}
/* Mixin function for review star Medium Background image */
@mixin pr-background-medium-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 16px;
  $pr-width-value: 17px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/stars/rating-small-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
      margin: 0;
      &:focus {
        box-shadow: 0 0 0 $color-white;
        -webkit-box-shadow: 0 0 0 $color-white;
        outline: 0;
      }
    }
  }
}
/* Mixin function for review star Large Background image */
@mixin pr-large-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 26px;
  $pr-width-value: 28px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      @include swap_direction(margin, 0 2px 0 0);
      background-image: url('/media/images/stars/rating-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
      &:focus {
        box-shadow: 0 0 0 $color-white;
        -webkit-box-shadow: 0 0 0 $color-white;
        outline: 0;
      }
    }
  }
}
