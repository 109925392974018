.create-wishlist-form h2 {
  font-size: 22px;
  line-height: 1;
  @include swap_direction(margin, 0 0 10px 0);
}

.create-wishlist-form div div {
  clear: left;
  @include swap_direction(margin, 0 0 20px 0);
}

.create-wishlist-form label {
  display: block;
  font-weight: normal;
  @include swap_direction(margin, 0 0 4px 0);
  text-transform: none;
}

.create-wishlist-form input[type='text'] {
  background: #fafafa;
  border: 1px solid lightgrey;
  height: 35px;
  padding: 0 8px;
}

.account-favorites-mylists,
.create-wishlist-form {
  .list-icon {
    span {
      display: block;
      height: 28px;
      label {
        background: transparent url(/media/images/css/sprite-favorites-list-icons.png) 0 0 no-repeat;
        cursor: pointer;
        float: #{$ldirection};
        height: 28px;
        @include swap_direction(margin, 0 25px 0 0);
        overflow: hidden;
        text-indent: -9999px;
      }
    }
    input[type='radio'] {
      display: none;
    }
  }
  h3 .icon {
    background: transparent url(/media/images/css/sprite-favorites-list-icons.png) 0 0 no-repeat;
    float: #{$ldirection};
    height: 28px;
    @include swap_direction(margin, 0 15px 0 0);
  }
  #form--alter_list--field--IMAGE_NAME--index--icon_grad_label,
  .form--alter_list--field--IMAGE_NAME--index--icon_grad_label {
    background-position: 0 0;
    width: 30px;
  }
  #form--alter_list--field--IMAGE_NAME--index--icon_grad_label:hover,
  #form--alter_list--field--IMAGE_NAME--index--icon_grad_label.active,
  h3 .form--alter_list--field--IMAGE_NAME--index--icon_grad_label {
    background-position: 0 -28px;
  }
  #form--alter_list--field--IMAGE_NAME--index--icon_ring_label,
  h3 .form--alter_list--field--IMAGE_NAME--index--icon_ring_label {
    background-position: -59px 0;
    width: 19px;
  }
  #form--alter_list--field--IMAGE_NAME--index--icon_ring_label:hover,
  #form--alter_list--field--IMAGE_NAME--index--icon_ring_label.active,
  h3 .form--alter_list--field--IMAGE_NAME--index--icon_ring_label {
    background-position: -59px -28px;
  }
  #form--alter_list--field--IMAGE_NAME--index--icon_shoe_label,
  .form--alter_list--field--IMAGE_NAME--index--icon_shoe_label {
    background-position: -105px 0;
    width: 27px;
  }
  #form--alter_list--field--IMAGE_NAME--index--icon_shoe_label:hover,
  #form--alter_list--field--IMAGE_NAME--index--icon_shoe_label.active,
  h3 .form--alter_list--field--IMAGE_NAME--index--icon_shoe_label {
    background-position: -105px -28px;
  }
  #form--alter_list--field--IMAGE_NAME--index--icon_cake_label,
  .form--alter_list--field--IMAGE_NAME--index--icon_cake_label {
    background-position: -163px 0;
    height: 27px;
    width: 25px;
  }
  #form--alter_list--field--IMAGE_NAME--index--icon_cake_label:hover,
  #form--alter_list--field--IMAGE_NAME--index--icon_cake_label.active,
  h3 .form--alter_list--field--IMAGE_NAME--index--icon_cake_label {
    background-position: -163px -28px;
  }
  #form--alter_list--field--IMAGE_NAME--index--_label,
  .form--alter_list--field--IMAGE_NAME--index--_label {
    background-position: -213px 0;
    margin: 0;
    width: 31px;
  }
  #form--alter_list--field--IMAGE_NAME--index--_label:hover,
  #form--alter_list--field--IMAGE_NAME--index--_label.active,
  h3 .form--alter_list--field--IMAGE_NAME--index--_label {
    background-position: -213px -28px;
  }
  h3 .form--alter_list--field--IMAGE_NAME--index--_label {
    @include swap_direction(margin, 0 15px 0 0);
  }
}

#favorites .overlay-container .close-container {
  margin: 0;
  #{$rdirection}: 15px;
  top: 15px;
}

#favorites .overlay-container .close-container a.close-link {
  background-position: center center;
  height: 11px;
  width: 11px;
}

#favorites .overlay-container .create-wishlist-form {
  border: none;
  display: block;
  padding: 0 5px;
  position: static;
}

#favorites {
  .overlay-container {
    .add-to-list-overlay {
      .create-wishlist-form {
        .errors {
          display: none;
        }
      }
    }
  }
}
