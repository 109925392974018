.account-landing-header-panel {
  .outer-wrap {
    display: grid;
    align-items: center;
    margin-inline: auto;
    max-width: 960px;
    gap: 20px;
    grid-template-columns: 240px 1fr;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 20px;
    header {
      display: grid;
      grid-template-columns: 96px 1fr;
      margin: 0;
      padding: 0;
      padding-block: 20px;
      gap: 10px;
      border-#{$rdirection}: 1px solid $color-shade-grey;
      width: auto;
      float: none;
      #profile_picture-main-container {
        float: none;
      }
      .profile-heading {
        margin: 0;
      }
    }
    ul {
      &.shortcuts {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        width: auto;
        float: none;
        padding: 0;
        .item {
          width: auto;
          float: none;
          margin: 0;
        }
      }
      .item-url {
        display: flex;
        padding: 0;
        margin: 0;
        gap: 10px;
        .item_content {
          margin: 0;
          height: auto;
        }
      }
      &.menu-aside-items {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .account-profile-label {
          .account-profile__connected-accounts {
            padding: 0;
          }
        }
      }
      .icon {
        display: block;
        height: 17px;
        margin: 0;
        opacity: 0.46;
        transition: ease 0.4s all;
      }
    }
  }
  .menu-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
