body.locale_en_gb {
  #main.single section#samples-panel {
    header h2.compact {
      display: none;
    }
    header.promo_pick_full {
      h2.normal {
        display: none;
      }
      h2.compact {
        display: block;
      }
    }
    div.samples {
      p.additional-info {
        display: none;
        &.promo_pick_full {
          display: block;
        }
      }
    }
  }
  .samples-overlay {
    #samples-panel {
      header h2.compact {
        display: none;
      }
      header.promo_pick_full {
        h2.normal {
          display: none;
        }
        h2.compact {
          display: block;
        }
      }
      .samples {
        width: auto;
        padding: 0;
        .offer {
          display: none;
        }
        div > :first-child {
          display: block;
        }
      }
    }
  }
  .single {
    .column.right {
      .tab-switcher {
        .samples {
          width: auto;
          padding: 0;
        }
      }
    }
  }
}

body.locale_en_gb {
  #samples-panel {
    .product-not-orderable {
      background-color: #fcfcfc;
    }
  }
}
/* Styling for treat week day 3 - 5 samples popup */
#viewcart {
  .samples-overlay.samples_treatweek {
    margin-bottom: 0px !important;
    height: 595px !important;
  }
}

body {
  .samples-overlay {
    .product-list {
      .skus {
        position: inherit;
      }
    }
  }
}

body.locale_en_gb {
  .samples-overlay #samples-panel.samples_treatweek,
  .samples-overlay #samples-panel.samples_treatweek_special_day,
  .samples-overlay #samples-panel.samples_treatweek_revert {
    background: url('/media/export/cms/offers/june16_five_treats_popup/popup_background-20160612.jpg') top no-repeat;
    header.samples_treatweek,
    header.samples_treatweek_special_day,
    header.samples_treatweek_revert {
      h2.normal {
        display: none;
      }
    }
    h3,
    .add-button-top {
      display: none;
    }
    h4 {
      padding-top: 10px;
    }
    .product-list {
      padding-top: 185px;
    }
    .product-list .product {
      border: none;
      padding: 0;
      position: relative;
      width: 215px;
      float: left;
      height: 85px;
      margin: 2px;
      background-color: #fff;
    }
    .product-list .details {
      float: none;
      padding: 0 10px 0 0;
      font-size: 12px;
    }
    .product-list .skus {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
    }
    .sample-select-button {
      background-color: transparent;
      color: white;
      width: 100%;
      height: 85px;
      display: block;
      position: absolute;
      top: 0;
      overflow: hidden;
      text-indent: -999px;
    }
    .sample-select-button.selected {
      border: solid 2px #ff4aac;
      background-color: transparent;
    }
    .deactivate .sample-select-button {
      background-color: transparent !important;
    }
    input.form-submit {
      background: url('/media/export/cms/offers/august_2018/bankholiday_checkout_continue.jpg');
      width: 222px;
      height: 85px;
      overflow: hidden;
      text-indent: -999px;
      padding: 0;
      float: right;
    }
    .add-button {
      margin: 2px 0 0 2px;
      width: 437px;
      float: right;
      background: url('/media/export/cms/offers/september_2018/Sept_Gift_checkout_select_6.jpg') 0px 2px #fff no-repeat;
      height: 85px;
      padding: 0;
    }
  }
  .samples-overlay #samples-panel.special_day_gift {
    background: url('/media/export/cms/offers/WOT/popup_background_nov2018.jpg') top no-repeat;
    header.special_day_gift {
      h2.normal {
        display: none;
      }
    }
    h3,
    .add-button-top {
      display: none;
    }
    h4 {
      padding-top: 10px;
    }
    .product-list {
      padding-top: 172px;
      margin-left: 12px;
    }
    .product-list .product {
      border: none;
      padding: 0;
      position: relative;
      width: 224px;
      float: left;
      height: 85px;
      margin: 2px;
      background-color: #fff;
    }
    .product-list .details {
      float: none;
      padding: 0 10px 0 0;
      font-size: 12px;
    }
    .product-list .skus {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
    }
    .sample-select-button {
      background-color: transparent;
      color: white;
      width: 100%;
      height: 85px;
      display: block;
      position: absolute;
      top: 0;
      overflow: hidden;
      text-indent: -999px;
    }
    .sample-select-button.selected {
      border: solid 2px #ff4aac;
      background-color: transparent;
    }
    .deactivate .sample-select-button {
      background-color: transparent !important;
    }
    input.form-submit {
      background: url('/media/export/cms/offers/WOT/btn_continue.jpg');
      width: 224px;
      height: 85px;
      overflow: hidden;
      text-indent: -999px;
      padding: 0;
      float: right;
      margin-top: 2px;
    }
    .add-button {
      margin: 0 0 0 2px;
      width: 452px;
      float: right;
      background: url('/media/export/cms/offers/WOT/txt_select.jpg') 0 2px transparent no-repeat;
      height: 90px;
      padding: 0;
      margin-bottom: 4px;
      margin-right: 14px;
    }
  }
  .samples-overlay #samples-panel.samples_christmas_day1,
  .samples-overlay #samples-panel.samples_christmas_day3_above30 {
    background: url('/media/export/cms/christmas_2016/pop_up/5treat_popup_background.jpg') top no-repeat;
    div.offer {
      ul.samples_christmas_day1,
      ul.samples_christmas_day3_above30 {
        padding-left: 6px;
      }
    }
    form#checkout_samples {
      div.add-button {
        margin-right: 8px;
        margin-bottom: 4px;
      }
    }
    header.samples_christmas_day1,
    header.samples_christmas_day3_above30 {
      h2.normal {
        display: none;
      }
    }
    h3,
    .add-button-top {
      display: none;
    }
    h4 {
      padding-top: 10px;
    }
    .product-list {
      padding-top: 173px;
    }
    .product-list .product {
      border: none;
      padding: 0;
      position: relative;
      width: 224px;
      float: left;
      height: 85px;
      margin: 2px;
      background-color: #fff;
    }
    .product-list .details {
      float: none;
      padding: 0 10px 0 0;
      font-size: 12px;
    }
    .product-list .skus {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
    }
    .sample-select-button {
      background-color: transparent;
      color: white;
      width: 100%;
      height: 85px;
      display: block;
      position: absolute;
      top: 0;
      overflow: hidden;
      text-indent: -999px;
    }
    .sample-select-button.selected {
      border: solid 2px #ff4aac;
      background-color: transparent;
    }
    .deactivate .sample-select-button {
      background-color: transparent !important;
    }
    input.form-submit {
      background: url('/media/export/cms/christmas_2016/pop_up/5treat_btn_continue.jpg');
      width: 225px;
      height: 85px;
      overflow: hidden;
      text-indent: -999px;
      padding: 0;
      float: right;
    }
    .add-button {
      margin: 2px 0 0 2px;
      width: 452px;
      float: right;
      background: url('/media/export/cms/christmas_2016/pop_up/5treat_txt_select.jpg') 0px 2px #fff no-repeat;
      height: 85px;
      padding: 0;
    }
  }
  .samples-overlay #samples-panel.samples_mothersday_above40 {
    background: url('/media/export/cms/offers/march_2017/popup_background.jpg') top no-repeat;
    div.offer {
      ul.samples_mothersday_above40 {
        padding-left: 6px;
      }
    }
    form#checkout_samples {
      div.add-button {
        margin-right: 8px;
        margin-bottom: 4px;
      }
    }
    header.samples_mothersday_above40 {
      h2.normal {
        display: none;
      }
    }
    h3,
    .add-button-top {
      display: none;
    }
    h4 {
      padding-top: 10px;
    }
    .product-list {
      padding-top: 173px;
    }
    .product-list .product {
      border: none;
      padding: 0;
      position: relative;
      width: 224px;
      float: left;
      height: 85px;
      margin: 2px;
      background-color: #fff;
    }
    .product-list .details {
      float: none;
      padding: 0 10px 0 0;
      font-size: 12px;
    }
    .product-list .skus {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
    }
    .sample-select-button {
      background-color: transparent;
      color: white;
      width: 100%;
      height: 85px;
      display: block;
      position: absolute;
      top: 0;
      overflow: hidden;
      text-indent: -999px;
    }
    .sample-select-button.selected {
      border: solid 2px #ff4aac;
      background-color: transparent;
    }
    .deactivate .sample-select-button {
      background-color: transparent !important;
    }
    input.form-submit {
      background: url('/media/export/cms/offers/march_2017/btn_continue.jpg');
      width: 225px;
      height: 85px;
      overflow: hidden;
      text-indent: -999px;
      padding: 0;
      float: right;
    }
    .add-button {
      margin: 2px 0 0 2px;
      width: 452px;
      float: right;
      background: url('/media/export/cms/offers/march_2017/txt_select.jpg') 0px 2px #fff no-repeat;
      height: 85px;
      padding: 0;
    }
  }
  .samples-overlay #samples-panel.samples_above40 {
    background: url('/media/export/cms/offers/june_2017/popup_background.jpg') top no-repeat;
    div.offer {
      ul.samples_above40 {
        padding-left: 6px;
      }
    }
    form#checkout_samples {
      div.add-button {
        margin-right: 8px;
        margin-bottom: 4px;
      }
    }
    header.samples_above40 {
      h2.normal {
        display: none;
      }
    }
    h3,
    .add-button-top {
      display: none;
    }
    h4 {
      padding-top: 10px;
    }
    .product-list {
      padding-top: 173px;
    }
    .product-list .product {
      border: none;
      padding: 0;
      position: relative;
      width: 224px;
      float: left;
      height: 85px;
      margin: 2px;
      background-color: #fff;
    }
    .product-list .details {
      float: none;
      padding: 0 10px 0 0;
      font-size: 12px;
    }
    .product-list .skus {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
    }
    .sample-select-button {
      background-color: transparent;
      color: white;
      width: 100%;
      height: 85px;
      display: block;
      position: absolute;
      top: 0;
      overflow: hidden;
      text-indent: -999px;
    }
    .sample-select-button.selected {
      border: solid 2px #ff4aac;
      background-color: transparent;
    }
    .deactivate .sample-select-button {
      background-color: transparent !important;
    }
    input.form-submit {
      background: url('/media/export/cms/offers/june_2017/btn_continue.jpg');
      width: 225px;
      height: 85px;
      overflow: hidden;
      text-indent: -999px;
      padding: 0;
      float: right;
    }
    .add-button {
      margin: 2px 0 0 2px;
      width: 452px;
      float: right;
      background: url('/media/export/cms/offers/june_2017/txt_select.jpg') 0px 2px #fff no-repeat;
      height: 85px;
      padding: 0;
    }
  }
  .samples-overlay #samples-panel.samples_christmas_day2_above30 {
    background: url('/media/export/cms/christmas_2016/pop_up/day8_popup_background.jpg') top no-repeat;
    div.offer {
      ul.samples_christmas_day2_above30 {
        padding-left: 6px;
      }
    }
    form#checkout_samples {
      div.add-button {
        margin-right: 8px;
        margin-bottom: 4px;
      }
    }
    header.samples_christmas_day2_above30 {
      h2.normal {
        display: none;
      }
    }
    h3,
    .add-button-top {
      display: none;
    }
    h4 {
      padding-top: 10px;
    }
    .product-list {
      padding-top: 173px;
    }
    .product-list .product {
      border: none;
      padding: 0;
      position: relative;
      width: 224px;
      float: left;
      height: 85px;
      margin: 2px;
      background-color: #fff;
    }
    .product-list .details {
      float: none;
      padding: 0 10px 0 0;
      font-size: 12px;
    }
    .product-list .skus {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
    }
    .sample-select-button {
      background-color: transparent;
      color: white;
      width: 100%;
      height: 85px;
      display: block;
      position: absolute;
      top: 0;
      overflow: hidden;
      text-indent: -999px;
    }
    .sample-select-button.selected {
      border: solid 2px #ff4aac;
      background-color: transparent;
    }
    .deactivate .sample-select-button {
      background-color: transparent !important;
    }
    input.form-submit {
      background: url('/media/export/cms/christmas_2016/pop_up/day8_btn_continue.jpg');
      width: 225px;
      height: 85px;
      overflow: hidden;
      text-indent: -999px;
      padding: 0;
      float: right;
    }
    .add-button {
      margin: 2px 0 0 2px;
      width: 452px;
      float: right;
      background: url('/media/export/cms/christmas_2016/pop_up/day8_txt_select.jpg') 0px 2px #fff no-repeat;
      height: 85px;
      padding: 0;
    }
  }
}
