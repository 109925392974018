.elc-product-quick-view-wrapper {
  .elc-service-view-renderer[data-config*='Temporarily'] {
    .elc-add-to-bag-button {
      display: none;
    }
  }
}

.elc-vto-shade-info-container {
  .elc-vto-product-size {
    padding-#{$ldirection}: 40px;
  }
}

body {
  #foreground-node.cboxIframe.learnmore-overlay {
    @media #{$medium-up} {
      overflow-y: hidden;
      top: 0 !important;
    }
    .close-container {
      @media #{$medium-up} {
        top: 5px;
        #{$rdirection}: 5px;
      }
    }
    iframe {
      @media #{$medium-up} {
        max-height: 100%;
      }
    }
  }
}

#main.single {
  .donations + #order-summary-panel {
    .total.clearpay {
      width: 100% !important;
      float: $rdirection;
      text-transform: none;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 1.5;
      margin-top: 10px;
      img {
        vertical-align: middle;
        width: 93px !important;
        @media #{$medium-up} {
          position: relative;
          bottom: 2px;
          height: 27px;
          width: 121px !important;
        }
      }
      .close_link {
        display: none;
      }
      .single-message {
        @media #{$medium-up} {
          text-align: $ldirection;
          padding-#{$ldirection}: 149px;
        }
      }
      .afterpay-learnmore {
        margin-#{$ldirection}: 0;
        @media #{$medium-up} {
          position: relative;
          bottom: 1px;
        }
      }
    }
  }
  .column.right {
    .total.clearpay {
      display: none;
    }
    .order-summary-total {
      .installments {
        display: none;
      }
    }
  }
  #checkout_billing_panel {
    .form-container {
      .form-item {
        width: 100%;
        .label-content {
          font-weight: 100;
          font-size: 13px;
        }
      }
      .payment {
        @media #{$medium-up} {
          border-bottom: 1px solid $color-black;
        }
        > label {
          .label-content {
            @media #{$medium-up} {
              display: none;
            }
          }
        }
      }
      .payment-option {
        height: auto;
        border-top: 1px solid $color-black;
        display: block;
        float: none;
        font-size: 16px;
        font-weight: 700;
        text-align: $ldirection;
        @media #{$medium-up} {
          padding: 20px 0;
          margin-top: 0;
        }
        .related-media {
          display: inline-block;
          font-size: 13px;
          font-weight: 600;
          @media #{$medium-up} {
            display: inline;
          }
          img {
            vertical-align: middle;
          }
        }
        .related-media.cc {
          display: inline-block;
          width: 100%;
          margin-#{$ldirection}: -17px;
          margin-top: 12px;
          img {
            width: 200px;
          }
        }
        .related-media.pp {
          @media #{$medium-up} {
            padding: 5px;
          }
          img {
            width: 59px;
            height: 20px;
            margin-#{$ldirection}: 2px;
            @media #{$medium-up} {
              width: 74px;
              margin-#{$ldirection}: 0;
            }
          }
        }
        .related-media.cp {
          @media #{$medium-up} {
            padding: 9px;
          }
        }
        .sub-section {
          font-size: 13px;
          font-weight: 100;
        }
        #checkout_payment {
          .field {
            font-size: 13px;
          }
          .card-expiry-date {
            .field {
              width: 77px;
            }
          }
        }
        #checkout_saved_payment {
          font-size: 13px;
          font-weight: 100;
        }
        .purchase-agreement {
          padding-#{$ldirection}: 224px;
          font-size: 13px;
          text-decoration: underline;
          color: $color-black;
        }
      }
    }
  }
  .view-address {
    margin-bottom: 10px;
    .billing-edit-panel {
      background: #{$color-white};
      border: none;
      float: #{$ldirection};
      width: 100%;
      .address-header {
        border-width: 0;
        color: #{$color-text-black};
        float: #{$ldirection};
        font-size: 24px;
        margin: 0;
        line-height: 1.2;
      }
      .change-address {
        color: #{$color-text-black};
        float: #{$rdirection};
        text-transform: uppercase;
        &.btn.btn-mini {
          background: #{$color-white};
          border: 1px solid #{$color-lighter-grey};
          color: #{$color-text-black};
          font-family: #{$helvetica-bold-font-family};
          margin: 0 10px;
          padding: 5px 20px;
        }
      }
    }
    .edit-panel-header {
      border-top: 1px solid #{$color-lighter-grey};
      display: inline-block;
      margin-top: 15px;
      padding-top: 5px;
      width: 100%;
    }
  }
  .set-address {
    margin-bottom: 15px;
  }
}

.continue-clearpay {
  width: 100%;
  height: 45px;
  background-color: $color-black;
  color: $color-white;
  margin-top: 10px;
}

.klarna-account {
  align-items: center;
  display: flex;
  font-family: $helvetica-regular-font-family;
  font-size: get-rem(13px);
  gap: 10px;
  &__prepend,
  &__append {
    display: none;
  }
  &__learn-more {
    color: $color-darkest-grey;
    font-family: $helvetica-regular-font-family;
    text-decoration: underline;
    white-space: nowrap;
  }
  &__logo {
    width: 54px;
    height: 30px;
    min-width: 54px;
    background-image: url('/media/images/checkout/icon--klarna.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-inline-start: auto;
    text-indent: -999em;
  }
  &--payment {
    .klarna-account__logo {
      display: none;
    }
  }
}
// Specificity required to override existing styling.
body #main.single .klarna-account__learn-more.loading {
  background: none;
}
.klarna-information {
  margin: auto;
  max-width: 660px;
  @media #{$medium-up} {
    padding: 20px;
  }
  &__header {
    background-image: url('/media/images/checkout/icon--klarna.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 80px;
    margin-bottom: 25px;
    text-indent: -999em;
  }
  &__text {
    margin-bottom: 20px;
  }
  &__list {
    counter-reset: klarna-counter;
    margin-bottom: 25px;
    margin-#{$ldirection}: 30px;
  }
  &__list-item {
    align-items: baseline;
    counter-increment: klarna-counter;
    display: flex;
    margin-bottom: 10px;
    &::before {
      content: counter(klarna-counter) '.';
      font-size: 24px;
      padding-#{$rdirection}: 10px;
    }
  }
  &__sub-text {
    color: $color-darkest-grey;
    margin: 0;
    .klarna-information__link {
      color: $color-darkest-grey;
      text-decoration: underline;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.afterpay {
  &-learnmore {
    border: none;
    margin-#{$ldirection}: 5px;
    span {
      width: 19px;
      height: 19px;
      display: inline-block;
      border-radius: 50%;
      color: $color-black;
      padding-#{$rdirection}: 0;
      font-size: 12px;
      font-family: $helvetica-regular-font-family;
      line-height: 1.4;
      text-transform: lowercase;
      text-align: center;
      border: 2px solid $color-black;
    }
  }
  &-container {
    text-align: $ldirection;
    margin-top: 6px;
    margin-#{$ldirection}: -6px;
  }
  &-image {
    width: 104px !important;
    height: 24px;
    position: relative;
    top: -0.5px;
    @media #{$medium-up} {
      width: 121px !important;
      height: 34px;
      top: -1.5px;
      margin-#{$rdirection}: 5px;
      display: inline-block;
    }
  }
  &-message {
    padding-bottom: 20px;
    line-height: 1.5;
    border-bottom: 1px solid $color-black;
  }
}

#adyen-payment {
  .component-container {
    .adyen-checkout__label {
      display: block !important; // third party override style
    }
  }
}

.chckt-sdk {
  .chckt-pm {
    border: none;
    width: 98%;
    @media #{$medium-up} {
      width: 100%;
    }
    &__details {
      background: #{$color-white} !important; // third party override style
      padding-#{$ldirection}: 0;
      .chckt-input-field--recurring {
        background: #{$color-white};
        border: 1px solid #{$color-black};
        border-radius: 0;
        height: 40px;
        padding: 12px 10px;
        @media #{$medium-up} {
          height: 45px;
          padding: 8px 10px;
        }
      }
    }
    &__header {
      background: #{$color-white} !important; // third party override style
      padding-#{$ldirection}: 0;
      .chckt-pm__radio-button {
        display: none;
      }
    }
    &__image {
      #{$rdirection}: 2px;
      margin: 0;
    }
    &__name {
      color: #{$color-black};
    }
  }
  .chckt-form--max-width {
    color: #{$color-black};
    max-width: 352px;
    min-width: 274px;
    @media #{$medium-up} {
      max-width: 472px;
      min-width: 345px;
    }
    .chckt-checkbox {
      margin-top: 6px;
    }
    .chckt-form-label {
      &__text {
        color: #{$color-black};
        &--dark {
          margin-top: 6px;
          padding: 3px 30px;
          text-transform: initial;
          @media #{$medium-up} {
            #{$rdirection}: 20px;
            margin-top: 0;
            padding: 0 33px;
          }
        }
      }
      &--exp-date {
        width: 49%;
        @media #{$medium-up} {
          min-width: 182px;
          width: 46%;
        }
      }
      &--cvc {
        float: #{$rdirection};
        padding-#{$ldirection}: 12px;
        width: 49%;
        @media #{$medium-up} {
          float: #{$ldirection};
          max-width: 291px;
          padding-#{$ldirection}: 22px;
          width: 54%;
        }
      }
      &__error-text {
        color: #{$color-red};
        text-transform: none;
      }
    }
  }
  .chckt-input-field {
    border-radius: 0;
    border-color: #{$color-black};
    color: #{$color-black};
    height: 40px;
    @media #{$medium-up} {
      height: 45px;
    }
    &--cvc {
      max-width: 100%;
    }
    &--error {
      color: #{$color-red};
      border: 2px solid #{$color-red};
    }
  }
  .chckt-button-container {
    margin-bottom: 10px;
    width: 94%;
    @media #{$medium-up} {
      width: 87%;
    }
    .chckt-more-pm-button {
      border: none;
      padding-#{$ldirection}: 0;
      &__icon {
        display: none;
      }
      &__text {
        color: #{$color-black};
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-#{$ldirection}: 0;
        text-decoration: underline;
      }
    }
    .chckt-button {
      background: #{$color-black};
      margin-top: 0;
      padding: 10px;
      &--disabled {
        opacity: 0.7;
      }
    }
  }
}

body {
  &.braintree-loading {
    .wrapper {
      opacity: 1;
    }
    .paypal-processing {
      .process-content * {
        opacity: 1;
      }
    }
  }
}

.adyen-gift-part {
  input[type='text'] {
    min-height: 40px;
    width: 100%;
  }
  .gift-card-number {
    float: #{$ldirection};
    width: 60%;
    @media #{$medium-up} {
      width: 45%;
    }
  }
  .gift-card-pin {
    float: #{$ldirection};
    padding-#{$ldirection}: 5%;
    width: 35%;
    @media #{$medium-up} {
      padding-#{$ldirection}: 0;
      width: 30%;
    }
    input[type='text'] {
      width: 100%;
      @media #{$medium-up} {
        max-width: 104px;
      }
    }
  }
  .continue-btn {
    width: 95%;
    @media #{$medium-up} {
      width: 100%;
    }
  }
  .form-submit {
    width: 100%;
    min-height: 40px;
    @media #{$medium-up} {
      width: 64%;
    }
  }
}

.paypal-loading {
  background-size: 23px;
  background-position: top;
  display: inline-block;
  height: 30px;
  margin-#{$rdirection}: 10px;
  width: 30px;
}

.paypal-process {
  opacity: 0.2;
}

.paypal-overlay {
  #{$ldirection}: 0;
  background: #{$color-black};
  height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.paypal-processing {
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  margin: 0 auto;
  opacity: 1;
  padding: 10px;
  position: fixed;
  text-align: center;
  top: 25%;
  width: 100%;
  z-index: 9999;
  .process-content {
    background: #{$color-white};
    margin: 0 auto;
    max-width: 335px;
    opacity: 1;
    position: relative;
    padding: 30px 10px;
    z-index: 999;
  }
  .paypal-logo {
    padding-top: 20px;
    width: 90px;
  }
  .cl-logo {
    padding-bottom: 25px;
  }
}

.viewcart-buttons-panel {
  .paypal-smart-button {
    display: inline-block;
    vertical-align: middle;
    .zoid-outlet {
      width: 180px !important; // third party override style
    }
  }
}

.account-payment-form {
  margin: 20px;
  float: #{$ldirection};
}

.checkout_payment_form {
  .payment-container {
    border-top: 1px solid #{$color-black};
    .payment-option {
      border-bottom: 1px solid #{$color-black};
      min-height: 50px;
      width: 100%;
      .payment_options-terms,
      .adyen-payment {
        @media #{$medium-up} {
          margin-#{$ldirection}: 23px;
        }
        display: none;
        margin-#{$ldirection}: 30px;
      }
      .paypal-terms {
        .local-submits {
          display: block;
          .paypal-express-btn {
            background: #{$color-black};
            color: #{$color-white};
            padding: 5px 40px;
          }
        }
      }
      .cl-related-media {
        display: inline-block;
        padding: 15px;
        vertical-align: middle;
        &.cc {
          padding: 5px;
          .card-img {
            margin-#{$ldirection}: 4px;
            width: 34px;
            @media #{$medium-up} {
              margin-#{$ldirection}: 6px;
              width: 40px;
            }
          }
        }
        &.pp {
          @media #{$medium-up} {
            padding: 9px;
          }
          img {
            margin-#{$ldirection}: 2px;
            width: 59px;
            height: 20px;
            @media #{$medium-up} {
              width: 74px;
              margin-#{$ldirection}: 0;
            }
          }
        }
        &.cp {
          @include swap_direction(padding, 0 9px 2px);
          .clearpay-image {
            width: 121px;
            height: 34px;
          }
          &.clearpay-logo {
            padding: 0;
          }
        }
      }
      input[type='radio'] {
        &:checked {
          & ~ label {
            .adyen-payment,
            .payment_options-terms {
              display: block;
            }
          }
        }
      }
      .pay-with-text {
        font-size: 18px;
      }
      .payment-terms {
        width: 90%;
        @media #{$medium-up} {
          width: 86%;
        }
        .terms-overlay-link-cms,
        .overlay-link-cms,
        .clearpay-terms-service {
          color: #{$color-darkest-grey};
        }
      }
      .after_pay-installment {
        .afterpay-learnmore {
          span {
            background: #{$color-black};
            color: #{$color-white};
          }
        }
      }
      .afterpay-button {
        background: #{$color-black};
        border: none;
        color: #{$color-white};
        line-height: 1.6;
        height: 40px;
        width: 100%;
        @media #{$medium-up} {
          width: 70%;
        }
      }
      #adyen-payment-form {
        input[type='checkbox'] {
          & ~ span {
            &::before {
              background: #{$color-white};
              border: 2px solid #{$color-black};
              border-radius: 0;
              content: ' ';
              cursor: pointer;
              display: inline-block;
              height: 16px;
              position: absolute;
              top: 1px;
              width: 16px;
              #{$ldirection}: 0;
            }
          }
          &:checked {
            & ~ span {
              &::after {
                @include swap_direction(border-width, 0 2px 2px 0);
                #{$ldirection}: 6px;
                background: #{$color-white};
                border-color: #{$color-black};
                border-style: solid;
                border-radius: 0;
                content: ' ';
                cursor: pointer;
                display: block;
                height: 8px;
                position: absolute;
                transform: rotate(45deg);
                top: 4px;
                width: 4px;
                -webkit-transform: rotate(45deg);
              }
            }
          }
        }
      }
      .adyen-label-content {
        .klarna-img {
          #{$ldirection}: 3px;
          position: relative;
          top: 8px;
          width: 57px;
        }
        .card-img {
          margin-#{$ldirection}: 6px;
          position: relative;
          top: 8px;
          width: 40px;
        }
        .adyen-label-text {
          font-size: 18px;
          margin-#{$rdirection}: 5px;
        }
        .klarna-placement {
          @media #{$medium-up} {
            bottom: 2px;
            padding-#{$ldirection}: 10px;
            padding-top: 0;
            position: relative;
          }
          display: inline-block;
          padding-#{$ldirection}: 30px;
          padding-top: 10px;
        }
      }
      .adyen-scheme {
        padding-#{$ldirection}: 20px;
        .adyen-terms {
          margin-#{$ldirection}: 0;
        }
        .adyen-checkout {
          &__card__form {
            font-size: 18px;
          }
          &__input {
            border-color: $color-black;
            border-radius: 0;
            box-shadow: none;
            &:focus {
              border-color: $color-black;
              border-radius: 0;
              box-shadow: none;
            }
          }
          &__label__text {
            color: $color-black;
          }
          &__field {
            display: inherit;
            margin-bottom: 0;
          }
        }
      }
      .adyen-klarna,
      .adyen-klarna_account {
        .adyen-checkout__button {
          @media #{$medium-up} {
            margin-#{$ldirection}: 20px;
            max-width: 240px;
          }
          margin-#{$ldirection}: 30px;
          .component-error {
            @media #{$medium-up} {
              padding-#{$ldirection}: 20px;
            }
            padding-#{$ldirection}: 30px;
          }
          &__text {
            &::after {
              content: url(/media/images/checkout/klarna_white.svg);
              padding-inline-start: 3px;
            }
          }
        }
      }
      .adyen-component-container {
        max-width: 484px;
      }
      .adyen-payment-method {
        .adyen-payment-type {
          display: none;
        }
        .adyen-checkout__button {
          border-radius: 0;
          margin-bottom: 5px;
        }
      }
      .adyen-label-scheme {
        .adyen-label-content {
          display: inline-block;
          margin-bottom: 10px;
        }
        .card-img {
          margin-#{$ldirection}: 0;
          margin-#{$rdirection}: 6px;
        }
        &--single {
          .adyen-label-content,
          .adyen-payment-type {
            display: none;
          }
        }
      }
    }
    .scheme {
      .payment-type {
        &:checked {
          & + .adyen-parent-label {
            .adyen-scheme {
              align-items: baseline;
              display: flex;
              .adyen-payment-type {
                display: inherit;
              }
              &--single {
                .adyen-payment-type {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .local-submits {
    display: none;
  }
}

.order-summary-total {
  .installments {
    @media #{$medium-up} {
      align-items: end;
      border: none;
      margin-top: 0;
      padding-top: 20px;
      position: initial;
    }
    border-top: 1px solid #{$color-shade-grey};
    clear: both;
    display: flex;
    flex-direction: column;
    margin-top: 55px;
    padding-top: 30px;
    .messaging {
      align-items: center;
      display: flex;
      font-size: get-rem(13px);
      justify-content: space-between;
      max-width: 415px;
      text-align: #{$ldirection};
      text-transform: none;
      width: 100%;
      &.clearpay-installments {
        padding-top: 20px;
      }
      .single-message {
        .clearpay-logo {
          vertical-align: middle;
          width: 90px;
        }
        .close_link {
          display: none;
        }
      }
    }
    .klarna-logo {
      width: 65px;
    }
  }
}
.clearpay-message {
  @media #{$medium-up} {
    display: inline-block;
    padding-#{$ldirection}: 0;
    padding-top: 0;
  }
  color: #{$color-darkest-grey};
  display: block;
  font-family: #{$helvetica-regular-font-family};
  font-size: get-rem(13px);
  padding-#{$ldirection}: 30px;
  padding-top: 10px;
}
.clearpay-learnmore {
  color: #{$color-darkest-grey};
  font-family: #{$helvetica-regular-font-family};
  margin: 0;
  text-decoration: underline;
  white-space: nowrap;
}

#shipping-panel {
  .panel-group {
    .panel {
      &-left {
        float: #{$ldirection};
        width: 50%;
      }
      &-right {
        float: #{$rdirection};
        width: 50%;
      }
    }
  }
}

.page-footer {
  .cs-sticky-cta {
    #{$rdirection}: 0;
    bottom: 145px;
    position: fixed;
    z-index: 5;
    .LPMoverlay {
      margin: 0 !important; //!important is used to override the inline style from 3rd party content
      @media #{$cr19-large-up} {
        img {
          width: 156px !important; //!important is used to override the inline style from 3rd party content
        }
      }
    }
  }
  .sticky-footer-live-chat {
    .LPMcontainer {
      a {
        #{$rdirection}: 22px;
        background-color: transparent !important; //!important is used to override the inline style from 3rd party content
        border: none !important; //!important is used to override the inline style from 3rd party content
        @media #{$cr19-large-up} {
          #{$rdirection}: 40px;
        }
        img {
          position: absolute !important; //!important is used to override the inline style from 3rd party content
        }
      }
    }
  }
}
#vee24LiveHelpTabHTML {
  //ID is used here to override the styles from 3rd party content
  z-index: 5 !important; //!important is used to override the inline style from 3rd party content

  .vto-active & {
    display: none !important;
  }
}

.past-purchases {
  .order-details {
    .gift-wrap__message {
      word-break: break-all;
    }
  }
}
