.product-content-grid-v1,
.top-sellers-v1 {
  background: url($template_base_path + '/product_content_grid_v1/bg-product-content-grid.png') repeat-x scroll 0 0
    $color-white;
  min-height: 300px;
  overflow: hidden;
  /* padding: 65px 0 40px 0; */
  @include swap_direction(padding, 65px 0 40px 0);
  width: 100%;
  &.top-sellers {
    padding-bottom: 10px;
  }
  .outer-wrap {
    margin: 0 auto;
    max-width: 964px;
  }
  h3 {
    color: #121212;
    font-size: 36px;
    line-height: 1;
    /* margin: 0 0 40px 0; */
    @include swap_direction(margin, 0 0 40px 0);
  }
  &.columns-5 h3 {
    /* margin: 0 0 30px 0; */
    @include swap_direction(margin, 0 0 30px 0);
  }
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-#{$rdirection}: 1px solid $color-btn-gray;
    float: #{$ldirection};
    /* margin: 0 0 0 2%; */
    @include swap_direction(margin, 0 0 0 2%);
    /* padding: 15px 2% 15px 0; */
    @include swap_direction(padding, 15px 2% 15px 0);
    width: 31.25%;
  }
  &.columns-5 li {
    /* margin: 0 0 0 1.5%; */
    @include swap_direction(margin, 0 0 0 1.5%);
    /* padding: 20px 1.5% 25px 0; */
    @include swap_direction(padding, 20px 1.5% 25px 0);
    width: 18.5%;
  }
  .row {
    border-top: 1px solid $color-btn-gray;
    /* margin: -1px 0 0 0; */
    @include swap_direction(margin, -1px 0 0 0);
    overflow: hidden;
    li {
      min-height: 330px;
    }
    &:first-child {
      border-top: none;
    }
    li {
      &:first-child {
        border-#{$ldirection}-color: $color-white;
        margin-#{$ldirection}: 0;
      }
      &:last-child {
        border: none;
      }
    }
  }
  .number {
    color: $color-btn-gray;
    display: none;
    font-family: $base-light-font-family;
    font-size: 110px;
    letter-spacing: -1px;
    line-height: 1;
  }
  &.columns-5 .number {
    font-size: 55px;
  }
  &.show-numbers .number {
    display: block;
  }
  .inner {
    padding: 0 54px;
  }
  &.columns-5 .inner {
    padding: 0;
  }
  .inner img {
    display: block;
    height: auto;
    /* margin: 0 auto 10px; */
    @include swap_direction(margin, 0 auto 10px);
    max-width: 192px;
    width: 100%;
  }
}
/* DoneDone #201 - Quickview Overlays Display - Product Grids */

.device-pc .product-content-grid-v1 #quickshop.module-spp-detail,
.device-pc .top-sellers-v1 #quickshop.module-spp-detail {
  .col2.review {
    width: 27%;
    .ratings img {
      height: 20px;
    }
  }
  .col1 > .shades {
    .shade-dd h3.col1 {
      font-size: 18px;
    }
    > .shade-dd-menu > {
      .col1 {
        margin-top: -15px !important;
      }
      .col2 .shade-chooser li {
        text-align: center;
      }
    }
  }
}
