#QAS_NODE .error_messages_display,
#form--errors--contact_us {
  margin: 0 0 10px;
  font-weight: normal;
  padding: 0;
}

.address {
  clear: both;
  overflow: hidden;
}

.contact_us .sub_contact_list li input[type='radio'] {
  margin-bottom: 12px;
  float: left;
}

.contact_us {
  .question_header {
    .caret {
      width: 190px;
    }
    &.online_order {
      margin-#{$ldirection}: -34px;
      .sub_contact_list {
        padding-#{$ldirection}: 34px;
      }
      .caret {
        background: url(/media/export/images/qs-caret.png) no-repeat scroll 65% 100% transparent;
      }
    }
    &.beauty_product {
      padding-#{$ldirection}: 0px;
      margin-#{$ldirection}: 20%;
    }
    &.loyalty_rewards {
      margin-#{$ldirection}: 40%;
    }
    &.website_tech {
      padding-#{$ldirection}: 61%;
    }
    &.store_service {
      padding-#{$ldirection}: inherit;
      margin-#{$ldirection}: 81%;
      .sub_contact_list {
        li {
          .label-wrap {
            width: 86%;
          }
        }
      }
    }
  }
  ul.category li {
    width: 19%;
    p.copy {
      font-size: 22px;
    }
  }
  .loyalty_rewards {
    div.off {
      background: url('/media/images/pc/customer_service/contact_us/contact_us_loyalty_icon.png') no-repeat scroll -2px 0;
      width: 31px;
      height: 41px;
    }
    div.on {
      background: url('/media/images/pc/customer_service/contact_us/contact_us_loyalty_icon.png') no-repeat scroll -36px
        0;
      width: 31px;
      height: 41px;
    }
  }
}

.page-footer-region {
  .block-template-footer-links-v1 {
    .clearfix {
      overflow: inherit;
    }
  }
}
