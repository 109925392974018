.tout-image-v1 {
  &.tout {
    background: none no-repeat scroll #{$rdirection} top / 245px auto rgba(0, 0, 0, 0);
    border-#{$ldirection}: 1px solid #bdbdbd;
    padding-#{$ldirection}: 20px;
    .product {
      color: #808080;
      float: #{$rdirection};
      font-size: 13px;
      font-family: $helvetica-regular-font-family;
      width: 50%;
    }
    &.no-tout-info .product {
      float: none;
    }
    .product {
      .product-image {
        float: none;
        text-align: center;
        margin: 0;
        width: 100%;
        img {
          display: block;
          height: auto;
          max-width: 100%;
          width: auto;
        }
      }
      .product-info {
        overflow: hidden;
      }
      .product-title {
        color: #231f20;
        margin-top: 5px;
      }
      .button {
        @include swap_direction(margin, 10px 0 0 0);
        a {
          background: #5eb997;
          color: #fff;
          font-size: 12px;
          font-family: $helvetica-regular-font-family;
          font-weight: bold;
          line-height: 24px;
          @include swap_direction(padding, 7px 10px 4px 10px);
          text-transform: uppercase;
        }
      }
    }
    .tout-info {
      font-size: 14px;
      font-family: $helvetica-light-font-family;
      line-height: 1.5;
      padding: 15px 0 0;
      width: auto;
    }
  }
  &.no-image .tout-info {
    width: 90%;
  }
  &.tout {
    .heading {
      color: #5eb997;
      font-family: $helvetica-light-font-family;
      font-size: 21px;
      line-height: 21px;
      text-transform: none;
    }
    .body {
      margin: 5px 0 0;
      width: 43%;
      p {
        line-height: 1.2em;
        margin: 0;
      }
    }
    .link {
      @include swap_direction(margin, 5px 0 0 0);
      a {
        color: #5eb997;
      }
    }
  }
}
/* Customer Care Our Consultants PC */
/*
 * This is breaking the gnav tout image when on the Customer Care Our Consultants page.
 * Originally the tout_image_v1 template was created to be used with the top header on the
 * Our Consultants page, but it's not used for it anymore thus commenting it out.
 *
 */
/*
.customer-care-our-online-consultants .tout-image-v1 {
  &.tout {
    border-#{$ldirection}: none;
    margin: 0;
    position: relative;
  }
  &.no-image .tout-info {
    margin-top: 20px;
  }
  &.tout .body {
    margin: 0 0 0 -100px;
    p {
      color: #46AB8C;
    }
  }
}
*/

/* iPad Styles */
@media screen and (max-width: 768px) {
  .customer-care-our-online-consultants .tout-image-v1 {
    &.no-image .tout-info {
      margin-top: 0;
    }
    &.tout .body {
      margin-#{$ldirection}: -90px;
    }
  }
}
/* node/910 tout image positioning - this should be done differently */
#node-910 .tout-image-v1 {
  &.tout-image.tout {
    background-position: #{$rdirection} 60px;
    background-size: 210px auto;
  }
}
