.enhanced-viewcart-page {
  .wrapper {
    .multi#main {
      .outer-wrap {
        width: 100%;
        @media #{$cr19-large-up} {
          max-width: 1092px;
          width: 100%;
        }
        &::before {
          height: unset;
        }
        .section-header {
          color: $color-cl-light-black;
          font-family: $helvetica-bold-font-family;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.17px;
          line-height: 2;
          text-transform: capitalize;
        }
        .section-content {
          color: $color-darkest-grey;
          font-family: $helvetica-regular-font-family;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 1.4;
        }
        #content {
          padding-bottom: 37px;
          @media #{$cr19-medium-up} {
            padding-#{$ldirection}: 0;
            width: 62%;
          }
          @media #{$cr19-large-up} {
            @include swap_direction(padding, 0 50px 0 16px);
            width: calc(100% - 360px);
          }
          .sample-items {
            .sample-options-wrapper {
              border: 1px solid $cr19-border-light;
              margin-bottom: 16px;
              padding: 4px 16px;
              .accordion-wrapper__content {
                @media #{$cr19-medium-up} {
                  padding: 0;
                }
              }
              .samples-title {
                padding: 10px 0 0;
                &__text {
                  color: $color-darkest-grey;
                  font-family: $helvetica-regular-font-family;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 1.4;
                }
              }
              .sample-panel-footnote {
                color: $color-light-grey-2;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 1.15;
                margin: 16px 0;
              }
            }
            .msg-node {
              display: none;
            }
            .sample-products {
              box-sizing: content-box;
              display: flex;
              line-height: 0;
              margin: 12px 26px 0;
              overflow: hidden;
              padding: 0 0 12px;
              &.slick-initialized {
                overflow: visible;
              }
              .slick-list {
                width: 100%;
              }
              .slick-arrow {
                &::before,
                &::after {
                  display: none;
                }
              }
              .slick-prev {
                #{$ldirection}: -30px;
                background: url('/media/images/checkout/arrow-previous-gray.png') no-repeat center;
              }
              .slick-next {
                #{$rdirection}: -30px;
                background: url('/media/images/checkout/arrow-next-gray.png') no-repeat center;
              }
              .slick-prev,
              .slick-next {
                background-size: contain;
                height: 24px;
                top: 40%;
                width: 24px;
                z-index: 1;
              }
              .slick-dots {
                bottom: 0;
                line-height: 0;
                li {
                  height: 12px;
                  margin: 0 8px;
                  width: 12px;
                  button {
                    height: 12px;
                    padding: 0;
                    width: 12px;
                    &::before {
                      font-size: 10px;
                      height: 12px;
                      line-height: 1.2;
                      width: 12px;
                    }
                  }
                }
              }
              .dynamic_sample_message {
                font-size: 14px;
                line-height: 1.6;
              }
              .sample-box {
                display: flex;
                flex: 0 0 auto;
                line-height: 1;
                margin: 0 8px 20px;
                outline: 0;
                position: relative;
                width: calc(25% - 16px);
                @media #{$cr19-medium-portrait-only} {
                  width: calc(33% - 16px);
                }
                &__button {
                  &--select {
                    align-items: center;
                    border: 2px solid $color-grey;
                    color: $color-black;
                    display: flex;
                    font-family: $helvetica-bold-font-family;
                    font-size: 15px;
                    font-weight: 500;
                    height: 34px;
                    justify-content: center;
                    letter-spacing: normal;
                    margin: 0 auto;
                    max-width: 130px;
                    width: 100%;
                  }
                }
                .product {
                  &__container {
                    padding-bottom: 40px;
                    position: relative;
                    width: 100%;
                  }
                  &__select {
                    #{$ldirection}: 0;
                    bottom: 0;
                    position: absolute;
                    width: 100%;
                  }
                  &__image-wrapper {
                    background: $cr19-bg-light;
                    margin: 0 auto;
                    padding-bottom: 10px;
                    text-align: center;
                  }
                  &__image {
                    height: 87px;
                    margin: 0 auto;
                    width: 72px;
                  }
                }
                .tooltip {
                  #{$rdirection}: 0;
                  display: inline-block;
                  position: absolute;
                  top: 0;
                  width: 100%;
                  &__info-icon {
                    #{$rdirection}: 10px;
                    border-radius: 50%;
                    color: $color-white;
                    cursor: pointer;
                    height: 15px;
                    line-height: 1.5;
                    position: absolute;
                    text-align: center;
                    top: 10px;
                    width: 15px;
                    &::before {
                      #{$rdirection}: 0;
                      background: url('/media/images/cart_enhancement/information.svg') no-repeat
                        center;
                      content: '';
                      display: inline-block;
                      height: 15px;
                      position: absolute;
                      width: 15px;
                    }
                  }
                  &-text {
                    #{$rdirection}: 0;
                    background-color: $color-white;
                    border: 1px solid $color-cl-grey-dark;
                    border-radius: 0;
                    box-sizing: border-box;
                    color: $color-cl-grey-darker;
                    display: none;
                    font-family: $helvetica-regular-font-family;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 1.4;
                    padding: 10px;
                    position: absolute;
                    text-align: center;
                    top: 34px;
                    width: 100%;
                    z-index: 99999999;
                  }
                  &:hover .tooltip-text {
                    display: block;
                  }
                  &__up-arrow-icon {
                    #{$rdirection}: 9px;
                    bottom: 100%;
                    height: 12px;
                    overflow: hidden;
                    position: absolute;
                    width: 15px;
                    &::after {
                      #{$ldirection}: 50%;
                      background-color: $color-white;
                      border: 1px solid $color-cl-grey-dark;
                      content: '';
                      height: 12px;
                      position: absolute;
                      transform: translate(-50%, 50%) rotate(45deg);
                      width: 12px;
                    }
                  }
                }
                &.selected {
                  .sample-box__button--select {
                    background-color: $color-black;
                    border-color: $color-black;
                    color: $color-white;
                  }
                }
                &.disabled {
                  .sample-box__button--select {
                    color: $color-gray-dusty;
                    pointer-events: none;
                  }
                }
              }
              .sample_details {
                label,
                input {
                  display: none;
                }
                .sample_product_name {
                  color: $pick_x_title-color;
                  font-family: $helvetica-regular-font-family;
                  font-size: 13px;
                  letter-spacing: 0;
                  line-height: 1.3;
                  margin-top: 10px;
                  text-align: left;
                }
                .cart-item {
                  &__desc {
                    &--product-size {
                      color: $color-light-grey-2;
                      font-family: $helvetica-regular-font-family;
                      font-size: 13px;
                      font-weight: normal;
                      letter-spacing: 0;
                      margin: 5px 0 10px;
                      text-align: left;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
          .empty-cart-checkout-btn {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            margin: 0;
            @media #{$cr19-medium-portrait-only} {
              flex-direction: column;
            }
            .best-seller-btn,
            .home-page-btn {
              background-color: $pick_x_title-color;
              color: $pick_cyber_title-color;
              font-size: 16px;
              font-weight: 500;
              height: 45px;
              letter-spacing: 1px;
              margin: 24px 0;
              padding: 12px 0;
              width: calc(50% - 8px);
              @media #{$cr19-medium-portrait-only} {
                width: 100%;
              }
            }
            .best-seller-btn {
              @media #{$cr19-medium-portrait-only} {
                margin-bottom: 16px;
              }
            }
            .home-page-btn {
              @media #{$cr19-medium-portrait-only} {
                margin-top: 0;
              }
            }
            .checkout-past {
              font-size: 16px;
              height: 45px;
              letter-spacing: 1px;
              margin: 0 auto;
              padding: 12px 0;
              width: 49%;
            }
          }
          .guarantee-section--empty-cart {
            .guarantee-section {
              &__title {
                color: $color-cl-light-black;
                font-family: $helvetica-regular-font-family;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.25px;
                line-height: 1.4;
                margin-bottom: 6px;
                text-align: $ldirection;
                text-transform: capitalize;
              }
              &__content {
                color: $color-btn-dark-gray;
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                text-transform: none;
              }
            }
          }
          .viewcart {
            &-bag-count {
              color: $color-black-88opacity;
              font-family: $helvetica-bold-font-family;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.3;
              margin-bottom: 10px;
            }
            &-desc {
              color: $color-darkest-grey;
              font-family: $helvetica-regular-font-family;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0.25px;
              line-height: 1.1;
              margin-bottom: 23px;
            }
          }
          .cart-items {
            border: 1px solid $color-grey;
            padding: 0 16px;
            .cart-item {
              padding: 16px 0;
              position: relative;
              &:last-child {
                border: 0;
              }
              &.viewcart_item_custom_kit {
                .cart-item__thumb {
                  background-color: $color-white;
                  height: auto;
                  padding-top: 24px;
                  .edit_kit {
                    color: $color-darkest-grey;
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: capitalize;
                  }
                }
                .cart-item__desc {
                  position: relative;
                  margin-bottom: 16px;
                  .custom-kit__heading {
                    float: #{$ldirection};
                  }
                }
                .cart-item__desc--qty-price {
                  .qty,
                  .price {
                    width: 50%;
                  }
                  .total {
                    width: 50%;
                  }
                }
              }
              &__thumb {
                @include swap_direction(margin, 0 15px 0 0);
                background-color: $color-white;
                height: auto;
                padding: 0;
                width: 80px;
              }
              &__product-image {
                max-width: 100%;
                width: 80px;
              }
              .donation-image {
                width: 100%;
              }
              &__desc {
                display: inline-block;
                float: none;
                margin-bottom: 12px;
                position: static;
                width: calc(100% - 95px);
                &--qty-price {
                  &::after {
                    content: '';
                    clear: both;
                  }
                  .qty {
                    width: 30%;
                  }
                  .price,
                  .total {
                    color: $color-darkest-grey;
                    font-family: $helvetica-bold-font-family;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: normal;
                    line-height: 1.4;
                    margin-top: 0;
                    width: 50%;
                  }
                  .total {
                    float: $rdirection;
                    text-align: $rdirection;
                  }
                  .price {
                    .strike-thru-price {
                      color: $color-light-grey-2;
                      font-family: $helvetica-regular-font-family;
                      font-size: 14px;
                      font-weight: normal;
                      letter-spacing: normal;
                      margin-#{$rdirection}: 15px;
                      text-decoration: line-through;
                    }
                  }
                  .sample-price,
                  .sample-total {
                    width: auto;
                  }
                  .sample-price {
                    font-weight: normal;
                  }
                  .sample-qty {
                    @include swap_direction(margin, 9px 10px 0 0);
                  }
                  .sample-total {
                    float: $rdirection;
                  }
                }
                &--size-color-qty {
                  align-items: center;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                  margin: 8px 0 9px;
                  width: 100%;
                }
                &--product-name {
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 1.43;
                  max-width: 339px;
                  @media #{$cr19-medium-up} {
                    width: 90%;
                  }
                  @media #{$cr19-large-up} {
                    width: 100%;
                  }
                  a {
                    color: $color-darkest-grey;
                  }
                }
                &--product-size {
                  color: $color-light-grey-2;
                  font-family: $helvetica-regular-font-family;
                  font-size: 14px;
                  font-weight: normal;
                  letter-spacing: 0;
                  line-height: 1.4;
                  margin: 0;
                }
                &--sample-product-size {
                  margin: 8px 0;
                }
                &--divider {
                  background-color: $color-gray-tier2;
                  height: 20px;
                  margin: 0 15px;
                  width: 1px;
                }
                &--color {
                  align-items: center;
                  line-height: 0;
                  .shade {
                    margin-#{$rdirection}: 8px;
                    &-name {
                      color: $color-light-grey-2;
                      font-family: $helvetica-regular-font-family;
                      font-size: 14px;
                      font-weight: normal;
                      letter-spacing: 0;
                      line-height: 1.4;
                    }
                  }
                }
                .qty {
                  font-family: $helvetica-regular-font-family;
                  font-size: 14px;
                  font-weight: normal;
                  margin: 0;
                  .qty-container {
                    .cart-qty-minus,
                    .cart-qty-plus,
                    .qty-field {
                      background-color: $color-white;
                      border-radius: 0;
                      box-shadow: none;
                      color: $color-darkest-grey;
                      float: $ldirection;
                      font-family: $helvetica-regular-font-family;
                      font-size: 11px;
                      font-weight: normal;
                      height: 25px;
                      line-height: 1.8;
                      outline: none;
                      padding: 0;
                      text-align: center;
                      width: 25px;
                      -webkit-box-shadow: none;
                    }
                    .cart-qty {
                      &-minus,
                      &-plus {
                        border: 1px solid $color_celeste_approx;
                      }
                      &-minus:disabled,
                      &-plus:disabled {
                        cursor: default;
                        .icon {
                          opacity: 0.3;
                        }
                      }
                    }
                    .qty-field {
                      appearance: none;
                      border: solid $color_celeste_approx;
                      border-width: 1px 0;
                      box-shadow: none;
                      outline: none;
                      padding: 2px;
                      -moz-appearance: none;
                      -webkit-appearance: none;
                    }
                    .icon {
                      &--plus {
                        background: url('/media/images/icons/plus-icon.png') no-repeat 0 0
                          transparent;
                      }
                      &--minus {
                        background: url('/media/images/icons/minus-icon.png') no-repeat 0 0
                          transparent;
                      }
                      &--plus,
                      &--minus {
                        background-position: 1px 7px;
                        background-size: 10px;
                        display: block;
                        height: 100%;
                        margin: 0 auto;
                        width: 12px;
                      }
                    }
                  }
                }
                .remove_link {
                  background-size: 17px;
                  height: 17px;
                  top: 16px;
                  width: 17px;
                }
                &--promo-messaging {
                  margin: 9px 0;
                  font-size: 12px;
                  font-weight: normal;
                }
              }
              &__donations-price {
                align-items: center;
                display: flex;
                margin-top: 11px;
                .qty,
                .total {
                  flex: 1;
                }
                .qty {
                  .qty-container {
                    .qty-field {
                      width: 45px;
                    }
                  }
                }
                .price {
                  display: none;
                }
              }
              &__donations-description {
                margin-bottom: 0;
                & ~ .cart-item__refill {
                  display: none;
                }
                .cart-item {
                  &__desc--sub-name {
                    color: $color-light-grey-2;
                    font-size: 14px;
                    height: 22px;
                    letter-spacing: 0;
                    text-transform: capitalize;
                  }
                }
              }
              &__refill {
                float: none;
                font-size: 14px;
                line-height: normal;
                margin-#{$ldirection}: 95px;
                .refill-select-container {
                  display: inline-block;
                  height: 45px;
                  width: 250px;
                  .refill_select {
                    @include swap_direction(padding, 10px 60px 12px 16px);
                    border-radius: 0;
                    color: $color-black-64opacity;
                    font-size: 14px;
                    letter-spacing: 0.25px;
                    line-height: 1.4;
                  }
                  &::after {
                    #{$rdirection}: 17px;
                    border: solid transparent;
                    border-width: 7px 5px 0;
                    border-top-color: $color-cl-light-black;
                    content: '';
                    top: 20px;
                  }
                }
                .replenishment-notice-container {
                  display: block !important;
                  margin-top: 0;
                }
                .replenishment__description {
                  .overlay-link {
                    background-color: $color-black;
                    border-radius: 50%;
                    color: $color-white;
                    display: inline-block;
                    font-size: 10px;
                    height: 15px;
                    line-height: 1.4;
                    padding-top: 1px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: lowercase;
                    width: 15px;
                    &::before {
                      #{$ldirection}: 50%;
                      background: url('/media/images/cart_enhancement/info-icon.png') no-repeat
                        center;
                      content: '';
                      display: block;
                      height: 8px;
                      position: absolute;
                      top: 50%;
                      transform: translate(-50%, -50%);
                      width: 1.5px;
                    }
                  }
                }
              }
            }
          }
          .gift-options-wrapper {
            border: 1px solid $color-grey;
            margin-top: 16px;
            padding: 5px 16px;
            .section-header {
              color: $color-cl-light-black;
              font-family: $helvetica-bold-font-family;
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 0.19px;
              line-height: 2;
            }
            .accordion-wrapper__content {
              margin-bottom: 12px;
              padding-top: 1px;
            }
            .gift {
              &__img {
                margin-#{$rdirection}: 25px;
                width: 69px;
              }
              &__content {
                width: calc(100% - 95px);
                .gift-wrap-form {
                  .gift-wrap-label {
                    &::before {
                      #{$ldirection}: 1px;
                      background-color: $color-light-grey;
                      border-radius: 7px;
                      height: 14px;
                      top: 3px;
                      width: 34px;
                    }
                    &::after {
                      border: 1px solid $color-lighter-grey;
                      box-shadow: none;
                      top: 1px;
                    }
                  }
                  .is-gift-input:checked ~ .gift-wrap-label::before {
                    background-color: $color-black;
                  }
                  .is-gift-input:checked ~ .gift-wrap-label::after {
                    #{$ldirection}: 17px;
                    box-shadow: 0 1px 5px $color-cl-medium-darker;
                  }
                  .gift-form-container__title {
                    display: none;
                    margin-top: 0;
                  }
                  .is-gift-input:checked ~ .gift-form-container__title {
                    display: block;
                    margin: 0;
                  }
                }
                .section-content {
                  color: $color-darkest-grey;
                  font-family: $helvetica-regular-font-family;
                  font-size: 14px;
                  font-weight: normal;
                  letter-spacing: 0;
                  line-height: 1.4;
                }
                .label-content {
                  color: $color-black;
                  font-size: 12px;
                  letter-spacing: 0;
                  .gift-yes,
                  .gift-no {
                    color: $color-darkest-grey;
                    font-family: $helvetica-regular-font-family;
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: 1.4;
                  }
                }
                .gift-success {
                  &__message {
                    &--personal-text,
                    &--text,
                    &--edit {
                      color: $color-darkest-grey;
                      font-family: $helvetica-regular-font-family;
                      font-size: 14px;
                      font-weight: normal;
                      letter-spacing: 0;
                      line-height: 1.4;
                    }
                    &--personal-text {
                      margin-#{$rdirection}: 5px;
                    }
                    &--edit {
                      cursor: pointer;
                      font-size: 13px;
                      font-weight: bold;
                      letter-spacing: 1.25px;
                      text-decoration: underline;
                      text-transform: capitalize;
                    }
                    &--divider {
                      background-color: $color-gray-tier2;
                      display: inline-block;
                      height: 20px;
                      margin: 0 10px;
                      vertical-align: middle;
                      width: 1px;
                    }
                    &--text {
                      word-break: break-all;
                    }
                  }
                }
                .gift-form-container {
                  width: 97%;
                  &__title {
                    margin-bottom: 0;
                  }
                  &__textarea {
                    margin-top: 17px;
                    .message-field {
                      appearance: none;
                      border: 1px solid $color-black-32opacity;
                      border-radius: 0;
                      outline: none;
                      padding: 15px;
                      -moz-appearance: none;
                      -webkit-appearance: none;
                      &.label-mode + label {
                        #{$ldirection}: 13px;
                        top: -13px;
                      }
                    }
                  }
                  .gift-wrap-label {
                    #{$ldirection}: 15px;
                    padding: 0 4px;
                    top: 5px;
                  }
                  &__max-char {
                    color: $color-black;
                    font-family: $helvetica-light-font-family;
                    font-size: 12px;
                    font-weight: bold;
                    float: none;
                    letter-spacing: 0;
                    line-height: 1.3;
                    margin: 7px 0 0;
                    outline: none;
                  }
                  &__submit--btn {
                    background: $color-white;
                    border: 2px solid $color-grey;
                    border-radius: 0;
                    color: $color-black-88opacity;
                    float: none;
                    font-size: 15px;
                    height: 45px;
                    margin-top: 14px;
                    padding: 0;
                    width: 140px;
                  }
                }
                &--radio-btn-container {
                  margin: 9px 0 0;
                }
              }
            }
          }
          .donations-wrapper {
            border: 1px solid $color-grey;
            margin-top: 17px;
            padding: 4px 16px;
            .section-header {
              text-transform: capitalize;
            }
            .donations-content {
              margin: 0 0 17px;
              padding: 0;
              .multi_donation {
                &_img_container {
                  border: 1px solid $color-gray-tier4;
                  border-radius: 6px;
                  float: none;
                  height: 70px;
                  margin: 12px 0 10px;
                  padding: 10px;
                  width: 124px;
                  .left.donation-image {
                    float: none;
                    height: 100%;
                    width: 100%;
                  }
                }
                .donation-title,
                .donation-description,
                .bcrf-info-link {
                  color: $color-black;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 1.43;
                  margin: 0 0 6px;
                }
                .donation-title {
                  font-weight: bold;
                }
                .donation-description {
                  padding-#{$rdirection}: 38px;
                }
                .bcrf-info-link {
                  cursor: pointer;
                  font-weight: normal;
                  margin: 6px 0 14px;
                  text-decoration: underline;
                  text-transform: capitalize;
                }
                .bca_donation {
                  display: flex;
                  flex-wrap: wrap;
                  margin: 0;
                  .form-item {
                    align-items: center;
                    display: flex;
                    letter-spacing: 0;
                    .field {
                      border: 1px solid $color-black-54opacity;
                      height: 20px;
                      padding: 2px;
                      width: 20px;
                      &:checked {
                        border-color: $color-black;
                      }
                    }
                    label {
                      letter-spacing: 0;
                      padding-#{$ldirection}: 8px;
                    }
                  }
                }
                .donation-submit__cta {
                  margin: 0;
                  #BCA_donation_submit {
                    background: $color-white;
                    border: 2px solid $color-grey;
                    color: $color-cl-darker-grey;
                    float: none;
                    font-size: 15px;
                    height: 45px;
                    margin-top: 14px;
                    text-transform: inherit;
                    width: 140px;
                  }
                }
              }
            }
          }
          .guarantee-section {
            margin: 17px 0 37px;
            &__title {
              color: $color-cl-light-black;
              font-family: $helvetica-bold-font-family;
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 0.19px;
              line-height: 1.5;
              margin-bottom: 6px;
              text-transform: capitalize;
            }
            &__description {
              color: $color-darkest-grey;
              font-family: $helvetica-regular-font-family;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0;
              line-height: 1.4;
              margin: 0;
              padding-#{$rdirection}: 38px;
            }
          }
          .replenishment-form-container {
            align-items: center;
            display: flex;
          }
          .overlay-link {
            border-radius: 50%;
            color: $color-white;
            display: flex;
            font-size: 11px;
            height: 15px;
            margin-#{$ldirection}: 16px;
            position: relative;
            width: 15px;
            &::before {
              #{$rdirection}: 0;
              background: url('/media/images/cart_enhancement/information.svg') no-repeat center;
              content: '';
              display: inline-block;
              height: 15px;
              position: absolute;
              width: 15px;
            }
          }
          .replenishment-notice-container {
            .replenishment-notice {
              color: $color-black;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 1.6;
              margin-top: 0;
            }
          }
        }
      }
      #works-well-with-wrapper {
        float: $ldirection;
        height: max-content;
        padding: 53px 0 47px;
        width: 100%;
        .section-header {
          color: $color-cl-light-black;
          font-family: $helvetica-bold-font-family;
          font-size: 28px;
          font-weight: bold;
          height: 32px;
          letter-spacing: 0.34px;
          line-height: 1.15;
          margin-bottom: 28px;
          text-align: center;
          width: 100%;
        }
        .recommended-products {
          margin: auto;
          max-width: 900px;
          position: relative;
          .navigation {
            display: none;
            .flex-prev {
              #{$ldirection}: 0;
              background: url('/media/images/checkout/Icon_prev.png') no-repeat center;
            }
            .flex-next {
              #{$rdirection}: 0;
              background: url('/media/images/checkout/Icon_next.png') no-repeat center;
            }
            .flex-prev,
            .flex-next {
              background-size: 100%;
              height: 25px;
              position: absolute;
              top: 50%;
              width: 25px;
            }
          }
          &__list {
            display: flex;
            justify-content: center;
            margin: auto;
            &--item {
              border: 1px solid $color-grey;
              list-style: none;
              margin-#{$rdirection}: 17px;
              padding: 10px 30px 52px;
              position: relative;
              text-align: center;
              width: 204px;
              .thumb {
                .thumb-inner {
                  max-height: 143px;
                  .border {
                    display: block;
                    margin: 0 auto;
                    max-height: 143px;
                    width: auto;
                  }
                }
              }
              .info {
                margin-top: 11px;
                .product-name {
                  color: $color-black-88opacity;
                  font-family: $helvetica-bold-font-family;
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 0.18px;
                  line-height: 1.4;
                  a {
                    color: $color-black-88opacity;
                    font-family: $helvetica-bold-font-family;
                    line-height: 1.43;
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
                .price {
                  color: $color-black;
                  font-family: $helvetica-bold-font-family;
                  font-size: 14px;
                  font-weight: bold;
                  letter-spacing: 0.47px;
                  line-height: 1.2;
                  margin: 6px auto 3px;
                  .strike-thru-price {
                    font-weight: normal;
                    margin-#{$rdirection}: 15px;
                    text-decoration: line-through;
                  }
                }
                .sub-head {
                  color: $color-black;
                  font-family: $helvetica-light-font-family;
                  font-size: 12px;
                  letter-spacing: 0.4px;
                  line-height: 1.4;
                  margin-top: 4px;
                }
                .rating {
                  bottom: 35px;
                  margin: 4px 0 5px;
                  img {
                    width: 80px;
                    margin: 0 auto;
                  }
                }
                .add {
                  #{$ldirection}: 0;
                  bottom: 15px;
                  margin-top: 13px;
                  position: absolute;
                  width: 100%;
                }
                .color {
                  color: $color-light-grey-2;
                  font-size: 12px;
                  line-height: 1.3;
                  margin: 6px 0 13px;
                }
                .btn-shopnow {
                  border: 0;
                  background-color: $color-black;
                  color: $color-white;
                  font-family: $helvetica-bold-font-family;
                  font-size: 14px;
                  height: 36px;
                  line-height: 1.6;
                  padding: 0 28px;
                  text-align: center;
                  text-transform: capitalize;
                  width: 122px;
                }
              }
            }
          }
          .flex-control-nav {
            bottom: 0;
            margin-top: 10px;
          }
        }
      }
      .back_to_top {
        #{$rdirection}: 40px;
        bottom: 116px;
        color: $color-nero;
        height: 56px;
        width: 56px;
        .icon--caret--up {
          background: url('/media/images/cart_enhancement/up-arrow.png') no-repeat center;
          color: $pick_cyber_title-color;
          height: 56px;
          margin: 0;
          width: 56px;
          &::before {
            content: '';
          }
        }
      }
    }
  }
  .overlay-container {
    .replenishment-notice-info {
      color: $color-darkest-grey;
      font-family: $helvetica-regular-font-family;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.25px;
      line-height: 1.1;
    }
    .close-container {
      a.close-link {
        #{$rdirection}: 0;
        background: url('/media/images/icons/plus-icon.png') no-repeat 0 0 transparent;
        background-size: 16px;
        display: block;
        height: 16px;
        position: absolute;
        top: 0;
        transform: rotate(45deg);
        width: 16px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
      }
    }
    .customer-service {
      .sidebar-page__content {
        font-size: 25px;
        line-height: 1.4;
        margin: 0 0 8px;
        text-align: center;
      }
    }
  }
  #foreground-node {
    max-width: 860px;
    width: 100%;
  }
  @if $enable-restrict-ingredient-error == true {
    .restricted-ingredient {
      color: $color-error-msg;
      background: url('#{$base-theme-path}img/icons/src/red-triangle.svg') no-repeat;
      background-size: 20px;
      margin-top: 2px;
    }
    .restricted-ingredient-error {
      font-size: 14px;
      margin-#{$ldirection}: 32px;
      width: 176px;
    }
  }
}

