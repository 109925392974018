//colors
$color_chardon_approx: #fff3ef;
$color_log_cabin_approx: #222222;
$mercury: #e5e5e5;
/* Article Pop Up */
.article_rollover_popup {
  position: absolute;
  overflow: visible;
  display: none;
  #article_rollover_popup_wrap {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    position: absolute;
    overflow: visible;
    bottom: 44px;
    text-indent: 0;
    right: -138px;
    width: 230px;
    z-index: 999;
    @include breakpoint(767px) {
      right: -110px;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -33px;
      left: 100px;
      border-style: solid;
      display: block;
      width: 0;
      z-index: 0;
      border-color: #ccc transparent transparent transparent;
      border-width: 16px 10px;
      @include breakpoint(767px) {
        left: 126px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -33px;
      left: 100px;
      border-style: solid;
      display: block;
      width: 0;
      z-index: 1;
      border-color: #ccc transparent transparent transparent;
      border-width: 16px 10px;
      @include breakpoint(767px) {
        left: 126px;
      }
    }
    .article_rollover_content {
      background-color: #00d0c1;
      color: $color-darkest-grey;
      font-family: $wink-font-family-1;
      font-size: 14px;
      line-height: 18px;
      padding: 25px 10px;
      text-align: center;
      display: block;
      .heading {
        color: #222;
        font-size: 22px;
        font-weight: bold;
        text-transform: capitalize;
        display: block;
        margin-bottom: 12px;
      }
      .popup-close .close {
        position: absolute;
        top: 15px;
        right: 15px;
      }
      .view-more {
        background-color: #fff;
        border: 1px solid #eaeaea;
        color: #222222;
        font-size: 13px;
        padding: 0 10px;
        margin-top: 12px;
        height: 30px;
        display: block;
      }
    }
  }
}

p {
  #article-pop-up {
    display: inline-block;
  }
  .article-tooltip-content {
    text-decoration: underline;
  }
}
