//Clinique Loyalty

$ipad-portrait-only: '#{$medium-only} and (orientation: portrait)' !default;
$ipad-landscape-only: '#{$medium-only} and (orientation: landscape)' !default;

#gnav_signin,
#main {
  .signin_container {
    .inner-block {
      .login-email {
        width: 39%;
        float: #{$ldirection};
      }
      .loyalty__block {
        width: 53%;
        float: #{$ldirection};
        @include swap_direction(margin, 0 0 17px 65px);
        background: url('/media/export/cms/Clinique_Loyalty_NA/5_19_CL_enrollgnav_register_v3.png')
          #{$ldirection} bottom;
        background-repeat: no-repeat;
        padding-#{$ldirection}: 75px;
        background-size: contain;
        position: relative;
        .loyalty__make {
          font-family: $helvetica-bold-font-family;
          color: $color-black;
          font-size: 21px;
          width: 65%;
        }
        .loyalty__our {
          @include swap_direction(margin, 0 0 6px 0);
          font-size: 30px;
          width: 70%;
        }
        .loyalty__text {
          font-size: 18px;
          color: $color-black;
        }
        .loyalty__subdivision {
          .loyalty__subdivision__left_block {
            display: inline-block;
            width: 47%;
            float: #{$ldirection};
            ul {
              list-style-type: disc;
              font-size: 13px;
              color: $color-black;
              @include swap_direction(padding, 15px 0 15px 15px);
              li {
                line-height: 2;
              }
            }
          }
          .loyalty__subdivision__right_block {
            display: inline-block;
            float: #{$rdirection};
            position: absolute;
            top: 10px;
            right: -20px;
            @media #{$ipad-portrait-only} {
              right: -20px;
            }
            @media #{$ipad-landscape-only} {
              right: -20px;
            }
            .loyal_image {
              width: 180px;
              @include swap_direction(margin, 9px 0 0 0);
              @media #{$ipad-portrait-only} {
                width: 190px;
              }
              @media #{$ipad-landscape-only} {
                width: 190px;
              }
            }
          }
        }
        @media #{$ipad-portrait-only} {
          @include swap_direction(margin, 0 0 17px 2%);
          @include swap_direction(padding, 0 0 0 10%);
        }
      }
      .login-email {
        form {
          .form-item,
          .row {
            &.privacy-form-container,
            &.email_promotions,
            &.loyalty {
              input[type='checkbox'] {
                @include swap_direction(margin, 4px 0 0 0);
                vertical-align: top;
              }
            }
          }
        }
      }
      .privacy-form-container.row {
        .register-terms,
        .loyalty-terms {
          width: 80%;
        }
        .loyalty-terms {
          vertical-align: top;
        }
        input[type='checkbox'] {
          margin-top: 2px;
        }
        .loyalty__rewards {
          font-weight: bold;
        }
        .loyalty__offer_email {
          width: auto;
          padding-#{$ldirection}: 16px;
          font-size: 11px;
        }
      }
      .email_promotions.row {
        .register-email-offers {
          width: 80%;
          vertical-align: top;
        }
        input[type='checkbox'] {
          margin-top: 2px;
        }
      }
    }
    .pane {
      @include swap_direction(padding, 25px 0 20px 0);
    }
  }
  .topnav-pane {
    .fourcol,
    .twocol {
      @include swap_direction(margin, 40px 0 0 0);
    }
    .twocol {
      .avatar {
        position: absolute;
        top: 0px;
        #{$rdirection}: 250px;
        .thumb {
          width: 60px;
        }
        .bio {
          float: #{$rdirection};
        }
        .row {
          .twocol {
            position: relative;
            top: 0px;
            @include swap_direction(padding, 0);
            @include swap_direction(margin, 5px 0 0 0);
            width: 100%;
            .signout {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

#signin {
  #signin-block {
    h2.myaccount-hdr + p {
      width: 75%;
    }
  }
}

#gnav_signin {
  .signin_container {
    .inner-block {
      .loyalty__block {
        @include swap_direction(margin, 0 0 17px 25px);
        .loyalty__text {
          display: inline-block;
        }
        @media #{$ipad-portrait-only} {
          @include swap_direction(margin, 0 0 17px 0);
          @include swap_direction(padding, 0 0 0 6%);
          width: 56%;
          .loyalty__our,
          .loyalty__make {
            width: 70%;
          }
          .loyalty__subdivision {
            .loyalty__subdivision__right_block {
              right: -25px;
              .loyal_image {
                width: 180px;
              }
            }
          }
        }
      }
    }
    @media #{$ipad-portrait-only} {
      .outer-wrap {
        @include swap_direction(padding, 0);
      }
    }
  }
  .topnav-pane {
    .twocol {
      .section-header,
      .section-loyalty_rewards__header,
      .section-loyalty_rewards__subheader {
        display: none;
      }
    }
  }
}

#footer {
  border: none;
  .footer-bottom {
    .loyalty_content {
      &.hidden {
        display: none;
      }
    }
  }
}
// Rewards Popup

.popup-offer-v1 {
  .opaque-wrapper {
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0%;
    width: 100%;
    z-index: 10000;
    background-color: $color-white;
    opacity: 0.7;
    @if $cr19 == true {
      background-color: $cr19-bg-dark-transparent;
      opacity: 1;
    }
  }
  .outer-wrap.signup {
    width: 535px;
    height: 395px;
    border: 1px solid $color-dark-grey;
    left: 31%;
    @include swap_direction(padding, 20px 45px 15px);
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 385px;
      border: none;
      left: 0;
      @include swap_direction(padding, 55px 0);
      top: 13%;
    }
    .popup-offer-close {
      right: 20px;
      top: 25px;
    }
    h2 {
      text-align: left;
      margin-#{$ldirection}: 20px;
      font-size: 38px;
      @media screen and (max-width: 768px) {
        font-size: 32px;
      }
    }
    .subheading {
      @include swap_direction(margin, 0 0 30px);
      width: 395px;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      p {
        text-align: #{$ldirection};
        margin-#{$ldirection}: 20px;
        color: $color-black;
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
    .signup-forms {
      .popup-offer-msg {
        left: 13%;
        bottom: 110px;
        width: 375px;
        font-size: 12px;
        color: $color-black;
        position: absolute;
        @media screen and (max-width: 768px) {
          left: 6%;
          bottom: 75px;
          width: 93%;
        }
        .popup-offer-msg-text {
          float: #{$rdirection};
          width: 355px;
          @media screen and (max-width: 768px) {
            width: 93%;
          }
        }
      }
      @media screen and (max-width: 768px) {
        .form-submit-wrap {
          .form-text {
            height: 38px;
            width: 100%;
          }
          .form-submit {
            margin-#{$ldirection}: 0;
          }
        }
      }
    }
    .popup-offer-footer-msg {
      left: 17%;
      bottom: 60px;
      width: 375px;
      font-size: 12px;
      @media screen and (max-width: 768px) {
        #{$ldirection}: 7%;
        bottom: 18px;
        width: 87%;
      }
    }
  }
}

// Rewards Popup 3 & 4

#overlay-background {
  background-color: $color-white;
  opacity: 0.7;
  @if $cr19 == true {
    background-color: $cr19-bg-dark-transparent;
    opacity: 1;
  }
}

.overlay-container.loyalty-offer-overlay {
  width: 535px;
  height: 310px;
  border: 1px solid $color-dark-grey;
  @include swap_direction(padding, 60px 55px 15px);
  @include breakpoint($ab-small-down) {
    top: 70px !important;
    border: none;
    height: auto;
  }
  .close-container {
    .close-link {
      #{$rdirection}: 20px;
      top: 25px;
      position: relative;
    }
  }
  .loyalty_popover {
    .loyalty_popover__welcome {
      .loyalty_popover__welcome-option {
        .loyalty_popover__header {
          text-align: left;
          margin-#{$ldirection}: 20px;
          font-size: 38px;
          color: $color-black;
          @include breakpoint($ab-small-down) {
            margin-#{$ldirection}: 0px;
            font-size: 30px;
          }
        }
        .loyalty_popover__margin {
          .loyalty_popover__promo-header,
          .loyalty_popover__promo-text {
            text-align: #{$ldirection};
            margin-#{$ldirection}: 20px;
            color: $color-black;
            font-family: $helvetica-light-font-family;
            width: 406px;
            font-size: 20px;
            @include breakpoint($ab-small-down) {
              margin-#{$ldirection}: 0px;
              width: auto;
              font-size: 20px;
            }
          }
        }
        .loyalty_popover__status {
          text-align: #{$ldirection};
          margin-#{$ldirection}: 20px;
          color: $color-black;
          font-family: $helvetica-light-font-family;
          width: 406px;
          font-size: 22px;
          @include breakpoint($ab-small-down) {
            margin-#{$ldirection}: 0px;
          }
        }
        .loyalty_popover__buttons {
          margin-top: 15px;
          .button {
            @include swap_direction(margin, 30px 0 0 10px);
            @include swap_direction(padding, 6px 30px);
            @include breakpoint($ab-small-down) {
              width: 70%;
              @include swap_direction(margin, 10px 0 0 0);
            }
          }
        }
      }
    }
  }
}

// Home page loyalty offer popups
// Rewards Popup 1 & 2

div#cboxOverlay {
  background: $color-white;
  @if $cr19 == true {
    background: $cr19-bg-dark-transparent;
  }
}

body.loyalty_offer_signup #cboxTopLeft,
body.loyalty_offer_signup #cboxTopCenter,
body.loyalty_offer_signup #cboxTopRight,
body.loyalty_offer_signup #cboxMiddleRight,
body.loyalty_offer_signup #cboxBottomLeft,
body.loyalty_offer_signup #cboxBottomCenter,
body.loyalty_offer_signup #cboxBottomRight,
body.loyalty_offer_signup #cboxMiddleLeft {
  display: none;
}

body.loyalty_offer_add_to_bag {
  #cboxTopLeft,
  #cboxTopCenter,
  #cboxTopRight,
  #cboxMiddleRight,
  #cboxBottomLeft,
  #cboxBottomCenter,
  #cboxBottomRight,
  #cboxMiddleLeft {
    @if $new-loyalty-popup == true {
      display: none;
    }
  }
}

body.loyalty_offer_signup,
body.loyalty_offer_add_to_bag {
  #colorbox {
    position: fixed !important;
    top: 120px !important;
    left: 390px !important;
    width: 539px !important;
    height: 450px !important;
    padding: 0 !important;
    @include breakpoint($ab-small-down) {
      top: 0% !important;
      left: auto !important;
      width: 100% !important;
      height: 485px !important;
      margin-#{$ldirection}: 0px;
      background: $color-white;
    }
    #cboxWrapper {
      border: 1px solid $color-dark-grey;
      width: 535px !important;
      height: 420px !important;
      @include breakpoint($ab-small-down) {
        width: 100% !important;
        height: auto !important;
        border: 0px;
        padding: 0 20px;
        box-sizing: border-box;
      }
      #cboxContent {
        width: 433px !important;
        height: 375px !important;
        @include breakpoint($ab-small-down) {
          width: 100% !important;
          height: auto !important;
          padding: 60px 0 0 0;
        }
        #cboxLoadedContent {
          width: 433px !important;
          height: 340px !important;
          @include breakpoint($ab-small-down) {
            width: 100% !important;
            height: 420px !important;
          }
          @if $new-loyalty-popup == true {
            width: 570px !important;
            height: 510px !important;
            @include breakpoint($ab-small-down) {
              height: 450px !important;
            }
          }
        }
        @if $new-loyalty-popup == true {
          padding: 24px;
          width: 575px !important;
          height: 510px !important;
          @include breakpoint($ab-small-down) {
            padding: 30px 20px;
            width: 90% !important;
          }
        }
      }
      @if $new-loyalty-popup == true {
        width: 624px !important;
        height: 560px !important;
        @include breakpoint($ab-small-down) {
          padding: 0;
        }
      }
    }
    @if $new-loyalty-popup == true {
      #{$ldirection}: 50% !important;
      padding: 0 !important;
      width: 624px !important;
      height: 560px !important;
      transform: translate(-50%, 0);
      @include breakpoint($cr19-small) {
        transform: translate(0, 0);
      }
    }
  }
}

body.loyalty_offer_signup {
  #cboxContent {
    padding: 45px 50px 0;
  }
}

#cboxContent {
  .loyalty_popover,
  .loyalty-offer-add-to-bag {
    .loyalty_popover__content.loyalty_popover__welcome {
      @include breakpoint($ab-small-down) {
        margin: 0;
      }
      .loyalty_popover__welcome-option {
        .loyalty_popover__status {
          @include swap_direction(margin, 15px 0 0 20px);
          @include breakpoint($ab-small-down) {
            margin: 15px 0 0 0;
            width: 100%;
            font-size: 20px;
          }
        }
        .loyalty_popover__buttons {
          @include swap_direction(margin, 30px 0 0 20px);
          @include breakpoint($ab-small-down) {
            margin-top: 20px 0 0 0;
          }
        }
      }
      .loyalty_popover__terms {
        #{$ldirection}: 21px;
      }
      @if $new-loyalty-popup == true {
        @include breakpoint($ab-small-down) {
          margin: 0;
        }
        .loyalty_popover__welcome-option {
          .loyalty_popover__status {
            margin: 25px 0 20px;
            position: relative;
            @include breakpoint($ab-small-down) {
              margin-top: 15px;
              width: 100%;
              font-size: 20px;
            }
          }
          .loyalty_popover__terms {
            #{$ldirection}: 0;
          }
          .loyalty_popover__buttons {
            margin-top: 13px;
            margin-#{$ldirection}: 0;
            @include breakpoint($ab-small-down) {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .background-img {
      @if $new-loyalty-popup == true {
        position: absolute;
        top: 0;
        #{$rdirection}: 0;
        width: 68%;
      }
    }
    .loyalty_popover__logo,
    .loyalty-offer-add-to-bag__logo {
      @include swap_direction(margin, 0 0 0 18px);
      img {
        width: 120px;
        @include breakpoint($ab-small-down) {
          width: 100px;
          margin: 0 0 10px 0;
        }
        @if $new-loyalty-popup == true {
          width: 154px;
          @include breakpoint($ab-small-down) {
            position: relative;
            margin-bottom: 10px;
          }
        }
      }
      @include breakpoint($ab-small-down) {
        margin: 0;
      }
      @if $new-loyalty-popup == true {
        margin: 0;
        width: 100%;
        @include breakpoint($ab-small-down) {
          margin: 0;
        }
      }
    }
    .loyalty_popover__content,
    .loyalty-offer-add-to-bag__content {
      .loyalty_popover__header,
      .loyalty-offer-add-to-bag__header {
        @include swap_direction(margin, 25px 0 0 18px);
        text-align: #{$ldirection};
        font-size: 26px;
        color: $color-black;
        width: 420px;
        font-family: $helvetica-bold-font-family;
        @include breakpoint($ab-small-down) {
          font-size: 28px;
          width: 100%;
          margin: 15px 0 0 0;
        }
        @if $new-loyalty-popup == true {
          margin-top: 80px;
          margin-#{$ldirection}: 0;
          position: relative;
          font-family: $helvetica-bold-font-family;
          font-size: 50px;
          width: 580px;
          letter-spacing: -0.03em;
          line-height: 52px;
          @include breakpoint($ab-small-down) {
            margin-top: 40px;
            line-height: 26px;
            width: 100%;
          }
          @media #{$cr19-medium-portrait-only} {
            margin-top: 60px;
          }
        }
      }
      .loyalty_popover__sub-header {
        @include swap_direction(margin, 10px 0 20px 18px);
        text-align: #{$ldirection};
        color: $color-black;
        width: 375px;
        font-size: 22px;
        @include breakpoint($ab-small-down) {
          width: 99%;
          font-size: 20px;
          margin-#{$ldirection}: 0px;
        }
        @if $new-loyalty-popup == true {
          margin: 25px 0;
          position: relative;
          font-size: 17px;
          line-height: 24px;
          letter-spacing: 0.02em;
          @include breakpoint($ab-small-down) {
            margin: 15px 0;
          }
        }
      }
      .loyalty_popover__margin {
        .loyalty_popover__promo-header,
        .loyalty_popover__promo-text {
          text-align: #{$ldirection};
          margin-#{$ldirection}: 20px;
          color: $color-black;
          font-family: $helvetica-light-font-family;
          width: 406px;
          font-size: 20px;
          @include breakpoint($ab-small-down) {
            @include swap_direction(margin, 0);
            width: auto;
            font-size: 20px;
          }
          @if $new-loyalty-popup == true {
            position: relative;
            margin-#{$ldirection}: 0;
            width: 500px;
          }
        }
        .loyalty_popover__promo-header {
          @include swap_direction(margin, 10px 0 0 20px);
          @include breakpoint($ab-small-down) {
            @include swap_direction(margin, 10px 0 0 0);
          }
          @if $new-loyalty-popup == true {
            margin-top: 15px;
            margin-#{$ldirection}: 0;
          }
        }
      }
      .loyalty_popover__status {
        text-align: $ldirection;
        margin: 20px 0;
        color: $color-black;
        font-family: $helvetica-light-font-family;
        width: 406px;
        font-size: 22px;
        @if $new-loyalty-popup == true {
          margin: 25px 0 20px;
        }
      }
      .loyalty_popover__buttons,
      .loyalty-offer-add-to-bag__buttons {
        @include swap_direction(margin, 15px 0 0 0);
        .button {
          @include swap_direction(margin, 10px 0 0 18px);
          padding: 6px 30px;
          @include breakpoint($ab-small-down) {
            width: 70%;
            margin: 10px 0 0 0;
            padding: 0;
          }
          @if $new-loyalty-popup == true {
            @include swap_direction(margin, 0 10px 0 0);
            padding: 20px 24px;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.01em;
            text-transform: initial;
            @include breakpoint($ab-small-down) {
              padding: 20px 24px;
              margin-top: 10px;
              width: 100%;
            }
            @media #{$cr19-medium-portrait-only} {
              margin-bottom: 12px;
              width: 100%;
            }
          }
        }
      }
      .loyalty_popover__choose {
        text-align: #{$ldirection};
        margin-#{$ldirection}: 20px;
        color: $color-black;
        font-family: $helvetica-light-font-family;
        width: 406px;
        font-size: 24px;
        @include breakpoint($ab-small-down) {
          width: 93%;
          font-size: 20px;
          margin-#{$ldirection}: 0px;
        }
      }
      .loyalty_popover__input {
        .loyalty_popover__submit-container {
          .form-text {
            margin-#{$ldirection}: 20px;
            width: 62%;
            height: 38px;
            @include breakpoint($ab-small-down) {
              width: 60%;
              margin-#{$ldirection}: 0px;
            }
            @if $new-loyalty-popup == true {
              margin-#{$ldirection}: 0;
              padding: 20px 24px;
              width: 331px;
              height: 64px;
              font-size: 17px;
              line-height: 24px;
              letter-spacing: 0.02em;
              border: 2px solid $cr19-border-light;
              box-sizing: border-box;
              @include breakpoint($ab-small-down) {
                height: 40px;
              }
            }
          }
          .form-submit {
            font-size: 1.3em;
            line-height: 1.5;
            margin-#{$ldirection}: -4px;
            padding: 6px 18px;
            vertical-align: -2px;
            @include breakpoint($ab-small-down) {
              width: auto;
            }
            @if $new-loyalty-popup == true {
              padding: 6px 18px;
              width: auto;
              min-width: 220px;
              margin-#{$ldirection}: 12px;
              text-transform: uppercase;
              @include breakpoint($ab-small-down) {
                height: 43px;
                min-width: 0;
              }
              @media #{$cr19-medium-portrait-only} {
                width: auto;
                min-width: 220px;
              }
            }
          }
          .loyalty_popover__email-empty-error {
            margin-#{$ldirection}: 20px;
            @include breakpoint($ab-small-down) {
              margin-#{$ldirection}: 0;
            }
            @if $new-loyalty-popup == true {
              margin-#{$ldirection}: 0;
            }
          }
        }
        .loyalty_popover__checkbox-container {
          #{$ldirection}: 4%;
          top: 15px;
          width: 375px;
          font-size: 12px;
          color: $color-black;
          position: relative;
          .form-checkbox {
            display: none;
          }
          label.for-styled-checkbox {
            display: inline;
            float: #{$rdirection};
            font-family: $helvetica-light-font-family;
            font-size: 12px;
            line-height: 12px;
            margin-#{$ldirection}: 0;
            width: 93%;
            @if $new-loyalty-popup == true {
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0.03em;
              width: 95%;
              @include breakpoint($ab-small-down) {
                width: 93%;
              }
              .loyalty_popover__link {
                font-family: $helvetica-light-font-family;
                border-bottom: 1px solid $cr19-text-grey;
                color: $color-black;
              }
            }
          }
          .form-checkbox + label.styled-checkbox {
            border: 1px solid $color-light-grey;
            display: inline-block;
            position: relative;
            vertical-align: bottom;
            width: 12px;
            height: 12px;
            @if $new-loyalty-popup == true {
              border: 1px solid $cr19-border-light;
            }
          }
          .form-checkbox:checked + label:after {
            @include swap_direction(padding, 0 0 0 2px);
            background-color: $color-cl-green-2;
            color: $color-white;
            content: '✓';
            font-size: 10px;
            height: 12px;
            left: 0px;
            line-height: 12px;
            position: absolute;
            top: -2px;
            width: 10px;
            @if $cr19 == true {
              background-color: $cr19-bg-black;
              color: $cr19-text-white;
            }
          }
          @include breakpoint($ab-small-down) {
            #{$ldirection}: 0;
            bottom: 75px;
            width: 100%;
          }
          @if $new-loyalty-popup == true {
            #{$ldirection}: 0;
            top: 25px;
            width: 538px;
            font-size: 16px;
            @include breakpoint($ab-small-down) {
              font-size: 12px;
            }
          }
        }
        .loyalty_popover__firstname {
          display: none;
        }
        .loyalty_popover__password {
          margin-top: 20px;
          .form-password {
            margin-#{$ldirection}: 20px;
            width: 62%;
            border: 1px solid $color-shade-grey;
            vertical-align: middle;
            color: $color-sharp-black;
            line-height: 35px;
            height: 37px;
            padding: 0 13px;
            @include breakpoint($ab-small-down) {
              width: 64%;
              margin-#{$ldirection}: 0;
            }
            @if $new-loyalty-popup == true {
              padding: 20px 24px;
              margin-#{$ldirection}: 0;
              width: 331px;
              height: 64px;
              border: 2px solid $cr19-border-light;
              box-sizing: border-box;
              font-size: 17px;
              line-height: 24px;
              letter-spacing: 0.02em;
              @include breakpoint($ab-small-down) {
                margin-#{$ldirection}: 0;
                height: 40px;
              }
              @media #{$cr19-medium-portrait-only} {
                width: 331px;
                height: 45px;
              }
            }
          }
          .form-submit {
            font-size: 1.3em;
            line-height: 1.5;
            margin-#{$ldirection}: -4px;
            padding: 6px 18px;
            vertical-align: -2px;
            @include breakpoint($ab-small-down) {
              width: auto;
            }
            @if $new-loyalty-popup == true {
              margin-#{$ldirection}: 12px;
              text-transform: initial;
              @include breakpoint($ab-small-down) {
                height: 42px;
                margin-top: 12px;
              }
              @media #{$cr19-medium-portrait-only} {
                width: auto;
                height: 44px;
                min-width: 220px;
                margin-#{$ldirection}: 10px;
              }
            }
          }
          .loyalty_popover__password-empty-error {
            margin-#{$ldirection}: 20px;
            @include breakpoint($ab-small-down) {
              margin-#{$ldirection}: 0px;
            }
            @if $new-loyalty-popup == true {
              margin-#{$ldirection}: 0;
            }
          }
        }
      }
      .loyalty_popover__terms {
        #{$ldirection}: 11%;
        display: inline-block;
        top: 30px;
        width: 375px;
        font-size: 12px;
        position: relative;
        @include breakpoint($ab-small-down) {
          width: 95%;
          #{$ldirection}: 0px;
          margin: 10px 0 0 0;
        }
        @if $new-loyalty-popup == true {
          width: 560px;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.03em;
          @include breakpoint($ab-small-down) {
            margin-top: 22px;
          }
        }
      }
      &.loyalty_popover__signup {
        .loyalty_popover__header {
          @include swap_direction(margin, 10px 0 0 18px);
          @include breakpoint($ab-small-down) {
            margin: 0;
            width: 100%;
          }
          @if $new-loyalty-popup == true {
            margin-top: 60px;
            margin-#{$ldirection}: 0;
            @include breakpoint($ab-small-down) {
              margin-top: 40px;
            }
          }
        }
      }
      @if $new-loyalty-popup == true {
        .loyalty_popover__promo-sub-title,
        .loyalty-offer-add-to-bag__promo-sub-title {
          margin-top: 14px;
          position: relative;
          color: $color-black;
          width: 500px;
          font-size: 28px;
          line-height: 32px;
          letter-spacing: -0.02em;
          @include breakpoint($ab-small-down) {
            width: 100%;
            font-size: 20px;
            margin-#{$ldirection}: 0;
            line-height: 22px;
          }
        }
        .loyalty_popover__promo-text,
        .loyalty-offer-add-to-bag__promo-text {
          margin-top: 10px;
          position: relative;
          color: $color-black;
          width: 480px;
          font-size: 17px;
          line-height: 24px;
          letter-spacing: 0.02em;
          @include breakpoint($ab-small-down) {
            width: 99%;
            font-size: 15px;
            margin-#{$ldirection}: 0;
            line-height: 16px;
          }
        }
        .loyalty_popover__offer-option,
        .loyalty-offer-add-to-bag__offer-option {
          .button--dark {
            margin-#{$rdirection}: 24px;
            @include breakpoint($ab-small-down) {
              width: 45%;
            }
            @media #{$cr19-medium-portrait-only} {
              width: 45%;
              margin-top: 12px;
            }
          }
          .button--light {
            @include breakpoint($ab-small-down) {
              width: 45%;
            }
            @media #{$cr19-medium-portrait-only} {
              width: 45%;
              margin-top: 12px;
            }
          }
        }
        .loyalty_popover__offer-note,
        .loyalty-offer-add-to-bag__offer-note {
          margin-top: 21px;
          color: $cr19-text-grey;
          width: 565px;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.03em;
          @include breakpoint($ab-small-down) {
            width: 100%;
            font-size: 12px;
            margin-#{$ldirection}: 0;
            line-height: 15px;
          }
          .loyalty-offer-add-to-bag__offer-note-link {
            font-family: $helvetica-regular-font-family;
            border-bottom: 1px solid $cr19-text-grey;
          }
        }
      }
    }
  }
  #cboxClose {
    #{$rdirection}: 20px;
    top: 25px;
    background: url('../../cl_base/img/icon-close.png') no-repeat;
    @if $new-loyalty-popup == true {
      #{$rdirection}: 27.3px;
      top: 27.3px;
      background: url('../../cl_base/img/icons/src/x-16.svg') no-repeat;
      width: 17.4px;
      height: 17.4px;
      @include breakpoint($ab-small-down) {
        width: 20px;
        height: 20px;
      }
    }
  }
}

body.loyalty_offer_signup {
  #colorbox {
    &.loyalty-popup-custom-alignment {
      &.horizontal-align {
        &--#{$ldirection} {
          #{$ldirection}: 0 !important;
          #{$rdirection}: unset !important;
          transform: unset;
          &.justify--middle {
            transform: translate(0, -50%);
          }
        }
        &--center {
          #{$ldirection}: 50% !important;
          transform: translateX(-50%);
        }
        &--#{$rdirection} {
          #{$rdirection}: 0 !important;
          #{$ldirection}: unset !important;
          transform: unset;
          @if $loyalty_top_alignment == true {
            #{$rdirection}: 10px !important;
          }
          &.justify--middle {
            transform: translate(0, -50%);
          }
        }
      }
      &.justify {
        &--top {
          top: 56px !important;
          bottom: unset !important;
          @if $loyalty_top_alignment == true {
            top: 130px !important;
            transform: translate(0, 1%);
            width: 361px !important;
          }
        }
        &--middle {
          top: 50% !important;
          transform: translate(-50%, -50%);
          @if $loyalty_top_alignment == true {
            top: 0 !important;
            transform: translate(-50%, 18%);
          }
        }
        &--bottom {
          bottom: 0 !important;
          top: unset !important;
        }
      }
    }
  }
  @if $loyalty_top_alignment == true {
    &.toolbar {
      #colorbox {
        &.loyalty-popup-custom-alignment {
          &.justify {
            &--top {
              top: 229px !important;
              transform: translate(0, 1%) !important;
              width: 361px !important;
            }
          }
        }
      }
    }
  }
}

// My Account Joining and Cancelling Loyalty
.registration_content {
  #registration-wrapper {
    .loyalty_form_container {
      @include swap_direction(margin, 0 0 15px 0);
      line-height: 1.6;
      @include breakpoint($ab-small-down) {
        @include swap_direction(margin, 5px 0 0 0);
      }
      .loyalty-message {
        padding: 0 0 0 5px;
        @include breakpoint($ab-small-down) {
          padding: 0px;
          .loyalty-checkbox {
            float: #{$ldirection};
            margin-#{$rdirection}: 5px;
          }
          .loyalty-disclaimer {
            float: #{$ldirection};
            width: 85%;
            margin-#{$ldirection}: 5px;
          }
        }
      }
      .loyalty-learn-more {
        padding: 15px 0 0 23px;
        @include breakpoint($ab-small-down) {
          padding-#{$ldirection}: 15px;
          @include swap_direction(margin, 0);
        }
      }
    }
    #isms {
      h3.section-header {
        @include swap_direction(margin, 0 0 10px 0);
      }
    }
    .email_lang_container {
      .form_element.radio {
        @include swap_direction(margin, 0 0 10px 0);
      }
    }
  }
  @include breakpoint($ab-small-down) {
    .overlay-background {
      opacity: 1;
    }
  }
}

#signin {
  font-family: $helvetica-light-font-family;
  #foreground-node {
    @include swap_direction(margin, 0 2%);
    width: 96%;
    top: 0;
  }
  #main {
    .signin_container {
      &.outer-wrap {
        max-width: 1024px;
      }
      .sign-in {
        #signin-block {
          .inner-block {
            width: 100%;
            #new-account {
              .login-email {
                width: 46%;
                float: #{$ldirection};
                form {
                  .form-item {
                    &.loyalty {
                      .loyalty_terms_condition {
                        width: 70%;
                      }
                      .loyalty_offers {
                        @include swap_direction(margin, 0 0 0 23px);
                      }
                    }
                    &.privacy-form-container {
                      span {
                        width: 75%;
                      }
                    }
                    &.email_promotions {
                      span {
                        width: 70%;
                      }
                      .register-terms {
                        vertical-align: top;
                      }
                      input[type='checkbox'] {
                        margin-top: 2px;
                      }
                    }
                  }
                }
              }
              .loyalty__block {
                width: 54%;
                float: #{$ldirection};
                @include swap_direction(margin, 0 0 17px 0px);
              }
            }
            #return-user {
              width: 46%;
            }
          }
        }
      }
    }
  }
  @include breakpoint($ab-small-down) {
    #signin-block {
      #new-account {
        .loyalty__rewards {
          font-size: 14px;
        }
        input.signup-submit {
          @include swap_direction(margin, 20px 0 0 0);
        }
      }
    }
  }
}

#registration {
  #foreground-node {
    &.loyalty-cancel-overlay {
      width: 45%;
      @include breakpoint($ab-small-down) {
        width: 99%;
      }
    }
    &.loyalty-cancellation-confirm-overlay {
      width: 45%;
      @include breakpoint($ab-small-down) {
        width: 99%;
      }
    }
    margin-#{$ldirection}: 25%;
    @include breakpoint($ab-small-down) {
      @include swap_direction(margin, 0);
      border: none;
    }
    .loyalty_cancellation_popup,
    .loyalty_cancellation_confirm_popup {
      @include swap_direction(padding, 50px);
      @include breakpoint($ab-small-down) {
        @include swap_direction(padding, 10px);
      }
      .loyalty_popover__header {
        font-size: 34px;
        color: $color-black;
        margin-bottom: 20px;
        @include breakpoint($ab-small-down) {
          font-size: 2em;
        }
      }
      .loyalty_popover__sub-header {
        font-size: 24px;
        color: $color-black;
        @include breakpoint($ab-small-down) {
          font-size: 1.5em;
        }
      }
      .loyalty_popover__contact_us-text,
      .loyalty_popover__loyalty_cancel-text {
        margin-bottom: 10px;
      }
      .loyalty_popover__buttons {
        margin-top: 25px;
        .cancel_loyalty_submit {
          margin-right: 30px;
          @include swap_direction(padding, 4px 20px);
          @include breakpoint($ab-small-down) {
            width: 120px;
            @include swap_direction(padding, 4px 11px);
            display: block;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @include breakpoint($ab-small-down) {
    .overlay-background {
      opacity: 1;
    }
  }
  #registration-wrapper {
    #pc-promotions {
      span.label-content {
        display: inline-block;
      }
    }
    .loyalty_form_container {
      span.loyalty-disclaimer {
        @include breakpoint($ab-small-down) {
          float: #{$rdirection};
          width: 90%;
        }
      }
    }
  }
}
// My Account Landing Tout
.logged-in {
  .twocol {
    width: 35%;
    .gnav-loyalty-join-block {
      @include swap_direction(margin, 25px 0 0 0);
      display: inline-block;
      .text-wrapper {
        width: 45%;
        float: #{$ldirection};
        .header {
          font-weight: bold;
          margin-bottom: 8px;
          color: $color-black;
          width: 170px;
        }
        .text {
          font-size: 14px;
        }
        .button-wrapper {
          display: block;
          .button {
            @include swap_direction(padding, 5px 25px);
            @include swap_direction(margin, 25px 0 0 0);
            background-color: $color-black;
          }
        }
      }
      .image-wrapper {
        width: 50%;
        float: #{$rdirection};
        margin-top: 40px;
      }
    }
    #my-account-loyalty-progress-section .account-loyalty {
      width: auto;
    }
  }
  .fourcol {
    width: 63%;
  }
}

#landing,
#index {
  &.is-loyalty-member {
    .logged-in {
      .twocol {
        padding-#{$rdirection}: 4%;
      }
    }
  }
}

.account-loyalty {
  .section-loyalty_rewards__subheader {
    color: $color-black;
    font-size: 27px;
    font-weight: $font-weight-bold;
  }
  .section-content {
    padding-top: 42px;
    .tier-point-msg {
      &.next-tier-points-msg {
        @include swap_direction(margin, 13px 0 0 50px);
        float: #{$ldirection};
        font-size: 17px;
        @media #{$cr19-medium-up} {
          font-size: 14px;
          margin-#{$ldirection}: 75px;
        }
        .remaining-points {
          display: inline-block;
          font-weight: $font-weight-bold;
          margin-top: 5px;
        }
      }
      &.next-anniversary-msg {
        font-size: 17px;
        float: #{$ldirection};
        line-height: 1.5;
        margin-bottom: 30px;
        padding-bottom: 20px;
        @media #{$cr19-medium-up} {
          margin: 44px 0 0;
          font-size: 14px;
          padding: 0;
        }
        .anniversary-date {
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

#loyalty__page__status {
  .loyalty_status_point {
    width: 39%;
    .account-loyalty {
      .section-loyalty_rewards__subheader {
        font-weight: $font-weight-bold;
      }
      .section-content {
        padding-top: 13px;
      }
      .loyalty__panel__points__button {
        float: none;
        a.view-all {
          font-size: 1em;
          margin-top: 16%;
          padding: 15px 25px;
          text-transform: $text-transform-capitalize;
        }
      }
      .section-loyalty_rewards__subheader {
        font-size: 27px;
        color: $color-black;
      }
    }
  }
  .loyalty_status_offers_sku {
    margin-top: 99px;
    width: 60%;
    .loyalty__account__landing__title {
      @include swap_direction(margin, -30px 0 0 0);
      .loyalty__panel__title {
        font-weight: $font-weight-bold;
      }
      h3 {
        a {
          color: $color-cl-green-2;
          text-decoration: underline;
          @if $cr19 == true {
            color: $cr19-text-grey;
          }
        }
      }
    }
    #loyalty__panel__offers__sku {
      @include box-shadow(none);
      .loyalty__panel__offers__sku__list-container {
        .flexslider {
          @include swap_direction(padding, 0 5%);
          min-width: inherit;
          .flex-viewport {
            min-width: inherit;
          }
          ul.slides {
            li {
              @include swap_direction(padding, 0 14px);
              .loyalty__panel__offers__sku__product {
                .loyalty__panel__offers__sku__product__data__wrapper {
                  min-height: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

#landing {
  &.is-loyalty-member {
    .account {
      &.breadcrumb {
        padding-bottom: 79%;
      }
    }
  }
}

.account_landing_wrapper {
  #loyalty__panel__offers__earnpoints {
    font-size: 15px;
    text-transform: capitalize;
    @media (min-width: 768px) {
      @include background(linear-gradient(white, $color-light-white));
      @include box-shadow(inset 0 -12px 12px -12px rgba(50, 50, 50, 0.2));
      @include swap_direction(padding, 0 0 40px 0);
      overflow: hidden;
      position: absolute;
      z-index: 1;
      top: 750px;
      width: 100%;
    }
    @include breakpoint(767px) {
      @include swap_direction(padding, 5%);
      border-bottom: 1px solid $color-shade-grey;
    }
    .loyalty__panel__title {
      @include swap_direction(margin, 0 0 12px 0);
      @include swap_direction(padding, 30px 0 0 0);
      color: $color-sharp-black;
      font-size: 32px;
      font-weight: $font-weight-bold;
      text-transform: $text-transform-none;
      @include breakpoint(767px) {
        @include swap_direction(padding, 0);
        .loyalty__panel__points__title {
          display: none;
        }
      }
    }
    .loyalty__panel__subtitle {
      color: $color-black;
      font-size: 18px;
      text-transform: $text-transform-none;
    }
    .flex-direction-nav {
      .flex-next {
        opacity: 1;
      }
      .flex-prev {
        opacity: 1;
      }
      .flex-disabled {
        opacity: 0;
      }
      @include breakpoint(767px) {
        display: none;
      }
    }
    .loyalty__panel__offers__earnpoints__list-container {
      .offers_flexslider {
        ol.flex-control-paging {
          bottom: -5%;
          @media #{$small-only} {
            bottom: 28%;
          }
        }
      }
    }
    .loyalty__panel__offers__earnpoints__offer-wrapper {
      margin: 0 5%;
      @media #{$cr19-medium-up} {
        @include swap_direction(margin, 0 5% 5% 5%);
      }
      .loyalty__panel__offers__earnpoints__offer-image-container {
        float: none;
        padding: 0;
      }
      div {
        @include swap_direction(padding, 0 0 5px 3px);
        color: $color-dark-grey;
        font-size: 14px;
      }
      .loyalty__panel__offers__earnpoints__offer-code-wrap {
        width: 100%;
      }
      .loyalty__panel__offers__earnpoints__button-container {
        clear: left;
        @include swap_direction(padding, 0 0 5px 0);
      }
      .loyalty__panel__offers__earnpoints__cta {
        @include swap_direction(padding, 10px 0 0 0);
        clear: left;
        font-size: 15px;
        width: 100%;
        .loyalty__panel__offers__earnpoints_offer_description {
          @include swap_direction(padding, 25px 0 0 0);
          font-size: 15px;
          color: $color-black;
        }
        .loyalty__panel__offers__earnpoints_offer_products {
          @include swap_direction(padding, 15px 0 15px 0);
          color: $product-color-price;
          clear: left;
          font-size: 15px;
          text-transform: capitalize;
        }
      }
    }
    .loyalty__panel__offers__earnpoints__list {
      .not_meet {
        .loyalty__panel__offers__earnpoints__offer-image-container {
          img {
            opacity: 0.5;
          }
        }
      }
      .flexslider {
        @include swap_direction(padding, 0);
        @include breakpoint(767px) {
          min-width: 100%;
        }
        .flex-viewport {
          @include breakpoint(767px) {
            min-width: 100%;
          }
        }
      }
    }
    .loyalty__panel__offers__earnpoints__offer {
      .loyalty__panel__offers__earnpoints__button {
        .button--offers {
          display: block;
          padding: 15px;
          text-transform: $text-transform-capitalize;
        }
        &.not_meet {
          background: $color-dark-grey;
          cursor: default;
        }
        @include breakpoint(767px) {
          @include swap_direction(padding, 0 30px);
        }
      }
    }
    .loyalty__panel__offers__offer--in-cart {
      .loyalty__panel__offers__remove {
        display: block;
      }
      .loyalty__panel__offers__button {
        display: none;
      }
    }
    .loyalty__panel__offers__offer--used {
      .loyalty__panel__offers__redeemed {
        display: block;
      }
      .loyalty__panel__offers__button {
        display: none;
      }
    }
    .loyalty__panel__offers__error {
      color: $color-notice-pink;
    }
  }
  .foundation-finder-results .recommendation-header {
    .meet-your-match-text {
      font-weight: $font-weight-bold;
    }
    .start-link {
      text-decoration: $text-decoration-underline;
    }
  }
}
