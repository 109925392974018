@mixin ff_options {
  border: 2px solid $color-btn-green;
  color: $color-btn-green;
  margin-#{$rdirection}: 20px;
  padding: 5px;
  text-decoration: none;
  text-transform: uppercase;
  background: none;
}

@mixin price {
  display: block;
  margin-bottom: 10px;
  color: $color-text-black;
  font-size: 17px;
  font-family: $helvetica-light-font-family;
}

#main section.blueocean {
  header {
    padding: 20px 0;
    height: 185px;
    background-image: url($img_base_path + '/bg-supercat-header.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: 45px;
    .header_content {
      margin: 0 auto;
      width: 960px;
      padding-#{$ldirection}: 12px;
      .blueOceanQuizButton,
      .save-list-anon {
        @include ff_options;
      }
      h1 {
        color: $color-text-black;
        width: 75%;
        span {
          color: $color-cl-green-2;
        }
      }
    }
  } /* End of Header section */

  .skin_diagnostic_results {
    padding-#{$ldirection}: 10px;
    .diagnostic-results {
      margin-bottom: 20px;
      .concern_container {
        margin-bottom: 25px;
        h2 {
          color: $color-text-black;
          margin: 0;
        }
        .all_price {
          @include price;
          margin-top: 10px;
        }
      } /* End of Concern Container */

      .prod-shelf-row {
        .products {
          .product {
            width: 20%;
            float: #{$ldirection};
            height: 400px;
            @include swap_direction(padding, 0 10px 50px 0);
            .my-account-sku {
              .btn-add-to-bag {
                margin-#{$ldirection}: 25px;
              }
              .product-image {
                width: 170px;
              }
              .product-info {
                margin-#{$ldirection}: 25px;
                .product-name {
                  min-height: 40px;
                  a {
                    color: $color-text-black;
                  }
                }
              }
            }
          }
        }
      }
    } /* End of Diagnostic Results */

    .total_price_details {
      border-top: 1px solid #dbdbdb;
      padding: 20px 0;
      .all_price {
        @include price;
      }
    }
  }
}

.blueocean_diagnostic_page {
  .blueoceanv {
    .header_content,
    .skin_diagnostic_results {
      padding-#{$ldirection}: 0;
    }
  }
}
